import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { db, fb } from "../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import withSizes from "react-sizes";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		textAlign: "center"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	},
	menu: {
		width: 200
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		color: "#35B2FF",
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	card: {
		display: "flex"
	},
	details: {
		display: "flex",
		flexDirection: "column"
	},
	content: {
		flex: "1 0 auto"
	},
	cover: {
		width: 300,
		objectFit: "cover"
	},
	productName: {
		fontSize: "24px",
		fontWeight: "300",
		width: "100%",
		display: "block",
		background: "#fff",
		textAlign: "left",
		fontFamily: '"Nunito Sans", sans-serif'
	}
});

class OfferButton extends React.Component {
	state = {
		storeName: "",
		rejectBelow: this.props.data.rejectBelow,
		acceptAbove: this.props.data.acceptAbove,
		acceptAndRejectBool: "%",
		invLowerLim: this.props.data.invLowerLim,
		endDate: this.props.data.endDate,
		startDate: this.props.data.startDate,
		isRule: this.props.data.isRule,
		open: false,
		openSuccess: false,
		openRemove: false,
		openLoading: false,
		openDialog: false,
		openDeleteDialog: false,
		openInvalid: false,
		active: this.props.buttonActive,
		openError: false,
		openLoadingAdd: false,
		openLoadingRemove: false
	};

	static propTypes = {
		data: PropTypes.shape({
			productTitle: PropTypes.string,
			productPrice: PropTypes.number,
			productId: PropTypes.number,
			rejectBelow: PropTypes.number,
			acceptAbove: PropTypes.string,
			unitDollar: PropTypes.number,
			invLowerLim: PropTypes.string,
			endDate: PropTypes.string,
			startDate: PropTypes.string,
			isRule: PropTypes.bool
		}).isRequired
	};

	handleOpen = name => {
		this.setState({ [name]: true });
	};

	handleClose = name => {
		this.setState({ [name]: false });
	};

	sessionStorageUpdated() {
		if (sessionStorage.getItem(this.props.data.productId) === "true") this.setState({ active: true });
		else this.setState({ active: false });
	}

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				db.getOfferable(snapshot).then(storeActiveCampaigns => {
					this.setState({ activeCampaigns: storeActiveCampaigns.val() }, () => {});
				});
				this.setState({ storeName: snapshot });
			})
			.catch(e => console.log("error", e));

		this.setState({ startDate: moment().add(15, "s") });
		this.setState({ endDate: moment().add(1, "days") });
		if (sessionStorage.getItem(this.props.data.productId) === "true") this.setState({ active: true });
		else this.setState({ active: false });
	}

	handleChange = (event, type) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		this.setState({ [name]: value });
	};

	clickedRowItem() {
		let newData = this.props.data;
		newData.rejectBelow = this.state.rejectBelow;
		newData.acceptAbove = this.state.acceptAbove;
		newData.acceptAndRejectBool = this.state.acceptAndRejectBool;
		newData.unitDollar = this.state.unitDollar;
		newData.invLowerLim = this.state.invLowerLim;
		newData.endDate = this.state.endDate;
		newData.startDate = this.state.startDate;
		newData.isRule = this.state.isRule;

		if (newData.isRule) {
			db.removeRules(this.state.storeName, newData).then(snapshot => {
				if (snapshot === "removed") {
					this.setState({
						rejectBelow: "",
						acceptAbove: "",
						acceptAndRejectBool: "%",
						invLowerLim: "",
						isRule: false
					});
				}
			});
		} else {
			if (Number(this.state.rejectBelow) >= Number(this.state.acceptAbove)) {
				this.handleClose("openInvalid");
			} else {
				db.submitRules(this.state.storeName, newData).then(snap => {
					if (snap === "successBoth") {
						this.setState({ isRule: true });
						this.handleClose("openSuccess");
					} else {
						if (snap === "pastDate") {
							this.handleClose("openDate");
						} else {
							this.handleOpen("open");
							console.log("Error: Couldn't change Rule");
						}
					}
				});
			}
		}
	}

	formSubmit = (collection, deleteButton) => {
		this.addOfferButton(collection, deleteButton);
	};

	addOfferButton = (collection, deleteButton) => {
		var deleteButton = this.state.active;
		if (deleteButton) this.setState({ openLoadingRemove: true });
		else this.setState({ openLoadingAdd: true });
		fb.updateOfferableProducts({
			store: this.state.storeName,
			id: this.props.data.productId,
			collection: collection,
			delete: deleteButton
		})
			.then(result => {
				if (deleteButton === false) this.setState({ openSuccess: true, openLoadingAdd: false });
				else
					this.setState({
						openRemove: true,
						openLoadingRemove: false
					});
				this.setState({
					openLoading: false,
					active: !this.state.active
				});
			})
			.catch(e => {
				console.error(e);
				this.setState({
					openError: true,
					openLoadingRemove: false,
					openLoadingAdd: false
				});
			});
	};

	render() {
		const { classes, data, isMobile } = this.props;
		const { openSuccess, openError, openRemove, openLoadingAdd, openLoadingRemove } = this.state;
		return (
			<Grid container style={{ width: "100%" }}>
				<Grid item xs={3}>
					<img
						alt={data.productTitle}
						style={{
							objectFit: "cover",
							width: "100%",
							height: "100%"
						}}
						src={data.image}
					/>
				</Grid>
				<Grid item xs={9}>
					<Paper className={classes.root} elevation={0}>
						<label htmlFor='contained-button-file' className={classes.productName}>
							<Grid container spacing={1}>
								<Grid item xs={isMobile ? 5 : 7}>
									<a
										href={data.url}
										target='_blank'
										rel='noopener noreferrer'
										style={{
											textDecoration: "none",
											color: "black"
										}}>
										<p
											style={{
												fontSize: isMobile ? "0.7em" : "1em",
												margin: "0px"
											}}>
											{data.productTitle}
										</p>
									</a>
								</Grid>
								<Grid item xs={2}></Grid>
								<Grid item xs={2}>
									<Switch
										checked={this.state.active}
										color='primary'
										onChange={() => this.addOfferButton(false)}></Switch>
								</Grid>
							</Grid>
						</label>
						<div>
							<Snackbar
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right"
								}}
								open={openError}
								onClose={() => this.handleClose("openError")}
								autoHideDuration={3000}
								message={<span id='message-id'>Something went wrong. Please try again.</span>}
							/>

							<Snackbar
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right"
								}}
								open={openRemove}
								onClose={() => this.handleClose("openRemove")}
								autoHideDuration={3000}
								ContentProps={{
									"aria-describedby": "message-id"
								}}
								message={
									<span id='message-id'>
										You have succesfully removed the EnterOffer button from {data.productTitle}
									</span>
								}
							/>

							<Snackbar
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right"
								}}
								open={openSuccess}
								onClose={() => this.handleClose("openSuccess")}
								autoHideDuration={5000}
								message={
									<span id='message-id'>
										You have succesfully added the EnterOffer button to {data.productTitle}
									</span>
								}
							/>
							<Dialog
								open={openLoadingAdd}
								onClose={() => {
									this.setState({ openLoadingAdd: false });
								}}
								disableBackdropClick={true}
								disableEscapeKeyDown={true}>
								<DialogContent style={{ textAlign: "center" }}>
									<h2>
										Adding the EnterOffer button from product
										<br /> {data.productTitle}
									</h2>
									<p>This may take up to a minute</p>
									<Lottie
										height={100}
										width={100}
										options={{
											loop: true,
											autoplay: true,
											animationData: loader.default,
											rendererSettings: {
												preserveAspectRatio: "xMidYMid slice"
											}
										}}
									/>
								</DialogContent>
							</Dialog>
							<Dialog
								open={openLoadingRemove}
								onClose={() => {
									this.setState({ openLoadingRemove: false });
								}}
								disableBackdropClick={true}
								disableEscapeKeyDown={true}>
								<DialogContent style={{ textAlign: "center" }}>
									<h2>
										Removing the EnterOffer button from product
										<br /> {data.productTitle}
									</h2>
									<p>This may take up to a minute</p>
									<Lottie
										height={100}
										width={100}
										options={{
											loop: true,
											autoplay: true,
											animationData: loader.default,
											rendererSettings: {
												preserveAspectRatio: "xMidYMid slice"
											}
										}}
									/>
								</DialogContent>
							</Dialog>
						</div>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

OfferButton.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(OfferButton));
