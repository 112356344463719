import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import { db } from "../firebase";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Refresh from "@material-ui/icons/Refresh";
import Snackbar from "@material-ui/core/Snackbar";

class PieChart extends Component {
	state = {
		data: [],
		approved: 0,
		rejected: 0,
		pending: 0,
		expired: 0,
		storeName: "",
		value: "",
		open: false
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: true,
		legendPosition: "top"
	};

	componentDidMount() {
		this.updateState(false);
	}

	updateState(notFromMount) {
		this.setState({ approved: 0, rejected: 0, pending: 0 });
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				return db.getStoreBids(snapshot, "approved");
			})
			.then(snapshot => {
				this.setState({ approved: snapshot.numChildren() });
				return db.getStoreBids(this.state.storeName, "expired");
			})
			.then(snapshot => {
				this.setState({ expired: snapshot.numChildren() });
				return db.getStoreBids(this.state.storeName, "pending");
			})
			.then(snapshot => {
				this.setState({ pending: snapshot.numChildren() });
				return db.getStoreBids(this.state.storeName, "rejected");
			})
			.then(snapshot => {
				this.setState({ rejected: snapshot.numChildren() });
				return;
			})
			.catch(e => console.log("error", e));

		if (notFromMount) {
			this.setState({ open: true });
		}
	}

	handleCloseSnackSuccess = () => {
		this.setState({ open: false });
	};

	render() {
		const { open, approved, rejected, pending, expired } = this.state;

		const chartData = {
			labels: ["PENDING", "APPROVED", "REJECTED", "EXPIRED"],
			datasets: [
				{
					label: "Offers",
					data: [pending, approved, rejected, expired],
					borderWidth: 1,
					borderColor: [
						"rgba(38, 186, 255, 1)",
						"rgba(42, 228, 155, 1)",
						"rgba(255, 77, 77, 1)",
						"rgba(255, 227, 38, 1)"
					],
					backgroundColor: [
						"rgba(38, 186, 255, .13)",
						"rgba(42, 228, 155, .13)",
						"rgba(255, 77, 77, .14)",
						"rgba(255, 227, 38, .13)"
					]
				}
			]
		};

		return (
			<div
				style={{
					width: this.props.isMobile ? null : "48%",
					verticalAlign: "middle",
					display: "grid"
				}}
			>
				<div style={{ display: "inline", textAlign: "left" }}>
					<Typography
						style={{
							color: "#000",
							fontWeight: 700,
							fontSize: "1em"
						}}
						onClick={() => this.updateState(true)}
					>
						What you do with offers
						<Tooltip title="Update Graph">
							<Refresh
								style={{
									verticalAlign: "bottom",
									color: "#35B2FF"
								}}
							/>
						</Tooltip>
					</Typography>
				</div>
				<Pie
					data={chartData}
					options={{
						legend: {
							display: this.props.displayLegend,
							position: this.props.legendPosition
						}
					}}
				/>

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={open}
					onClose={this.handleCloseSnackSuccess}
					autoHideDuration={3000}
					ContentProps={{
						"aria-describedby": "message-id"
					}}
					message={<span id="message-id">Graph Updated</span>}
				/>
			</div>
		);
	}
}

export default PieChart;
