import React, { Component } from "react";
import { NewSettingsModal } from "../components";
import { withStyles } from "@material-ui/core/styles";
import { db } from "../firebase";
import { Card, CardActionArea, CardContent, Grid, Typography, Snackbar } from "@material-ui/core";
import Money from "@material-ui/icons/AttachMoney";
import Payment from "@material-ui/icons/CreditCard";
import Color from "@material-ui/icons/ColorLens";
import Time from "@material-ui/icons/AccessTime";
import { TwitterPicker } from "react-color";
import withSizes from "react-sizes";
import Iframe from "react-iframe";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import { Button } from "@material-ui/core";

const styles = theme => ({
	root: {
		flexGrow: 1,
		marginBottom: "3em"
	},
	media: {
		objectFit: "cover"
	},
	button: {
		margin: theme.spacing(1),
		borderRadius: 0
	},

	saveButton: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		color: "#35B2FF",
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#c5c5c6"
		}
	}
});

const colors = ["#000000", "#FF6900", "#FCB900", "#00D084", "#8ED1FC", "#35B2FF", "#F6F6F8", "#EB144C", "#F78DA7", "#9900EF"];

const currencyList = [
	{
		symbol: "AU$",
		name: "Australian Dollar",
		symbol_native: "$",
		code: "AUD",
		emoji: "🇦🇺"
	},
	{
		symbol: "$",
		name: "US Dollar",
		symbol_native: "$",
		code: "USD",
		emoji: "🇺🇸"
	},
	{
		symbol: "CA$",
		name: "Canadian Dollar",
		symbol_native: "$",
		code: "CAD",
		emoji: "🇨🇦"
	},
	{
		symbol: "€",
		name: "Euro",
		symbol_native: "€",
		code: "EUR",
		emoji: "🇪🇺"
	},
	{
		symbol: "AED",
		name: "United Arab Emirates Dirham",
		symbol_native: "د.إ.‏",
		code: "AED",
		emoji: "🇦🇪"
	},
	{
		symbol: "Af",
		name: "Afghan Afghani",
		symbol_native: "؋",
		code: "AFN",
		emoji: "🇦🇫"
	},
	{
		symbol: "ALL",
		name: "Albanian Lek",
		symbol_native: "Lek",
		code: "ALL",
		emoji: "🇦🇱"
	},
	{
		symbol: "AMD",
		name: "Armenian Dram",
		symbol_native: "դր.",
		code: "AMD",
		emoji: "🇦🇲"
	},
	{
		symbol: "ƒ",
		name: "Netherlands Antillean guilder",
		symbol_native: "ƒ",
		code: "ANG",
		emoji: "🇧🇶"
	},
	{
		symbol: "Kz",
		name: "Angolan Kwanza",
		symbol_native: "Kz",
		code: "AOA",
		emoji: "🇦🇴"
	},
	{
		symbol: "AR$",
		name: "Argentine Peso",
		symbol_native: "$",
		code: "ARS",
		emoji: "🇦🇷"
	},
	{
		symbol: "man.",
		name: "Azerbaijani Manat",
		symbol_native: "ман.",
		code: "AZN",
		emoji: "🇦🇿"
	},
	{
		symbol: "KM",
		name: "Bosnia-Herzegovina Convertible Mark",
		symbol_native: "KM",
		code: "BAM",
		emoji: "🇧🇦"
	},
	{
		symbol: "Tk",
		name: "Bangladeshi Taka",
		symbol_native: "৳",
		code: "BDT",
		emoji: "🇧🇩"
	},
	{
		symbol: "BGN",
		name: "Bulgarian Lev",
		symbol_native: "лв.",
		code: "BGN",
		emoji: "🇧🇬"
	},
	{
		symbol: "BD",
		name: "Bahraini Dinar",
		symbol_native: "د.ب.‏",
		code: "BHD",
		emoji: "🇧🇭"
	},
	{
		symbol: "FBu",
		name: "Burundian Franc",
		symbol_native: "FBu",
		code: "BIF",
		emoji: "🇧🇮"
	},
	{
		symbol: "BN$",
		name: "Brunei Dollar",
		symbol_native: "$",
		code: "BND",
		emoji: "🇧🇳"
	},
	{
		symbol: "$",
		name: "Bermudian Dollar",
		symbol_native: "$",
		code: "BMD",
		emoji: "🇧🇲"
	},
	{
		symbol: "Bs",
		name: "Bolivian Boliviano",
		symbol_native: "Bs",
		code: "BOB",
		emoji: "🇧🇴"
	},
	{
		symbol: "R$",
		name: "Brazilian Real",
		symbol_native: "R$",
		code: "BRL",
		emoji: "🇧🇷"
	},
	{
		symbol: "Nu.",
		name: "Bhutanese Ngultrum",
		symbol_native: "Nu.",
		code: "BTN",
		emoji: "🇧🇹"
	},
	{
		symbol: "BWP",
		name: "Botswanan Pula",
		symbol_native: "P",
		code: "BWP",
		emoji: "🇧🇼"
	},
	{
		symbol: "BYR",
		name: "Belarusian Ruble",
		symbol_native: "BYR",
		code: "BYR",
		emoji: "🇧🇾"
	},
	{
		symbol: "BZ$",
		name: "Belize Dollar",
		symbol_native: "$",
		code: "BZD",
		emoji: "🇧🇿"
	},
	{
		symbol: "CDF",
		name: "Congolese Franc",
		symbol_native: "FrCD",
		code: "CDF",
		emoji: "🇨🇩"
	},
	{
		symbol: "CHF",
		name: "Swiss Franc",
		symbol_native: "CHF",
		code: "CHF",
		emoji: "🇨🇭"
	},
	{
		symbol: "CL$",
		name: "Chilean Peso",
		symbol_native: "$",
		code: "CLP",
		emoji: "🇨🇱"
	},
	{
		symbol: "CN¥",
		name: "Chinese Yuan",
		symbol_native: "CN¥",
		code: "CNY",
		emoji: "🇨🇳"
	},
	{
		symbol: "CO$",
		name: "Colombian Peso",
		symbol_native: "$",
		code: "COP",
		emoji: "🇨🇴"
	},
	{
		symbol: "₡",
		name: "Costa Rican Colón",
		symbol_native: "₡",
		code: "CRC",
		emoji: "🇨🇷"
	},
	{
		symbol: "CV$",
		name: "Cape Verdean Escudo",
		symbol_native: "CV$",
		code: "CVE",
		emoji: "🇨🇻"
	},
	{
		symbol: "Kč",
		name: "Czech Republic Koruna",
		symbol_native: "Kč",
		code: "CZK",
		emoji: "🇨🇿"
	},
	{
		symbol: "Fdj",
		name: "Djiboutian Franc",
		symbol_native: "Fdj",
		code: "DJF",
		emoji: "🇩🇯"
	},
	{
		symbol: "Dkr",
		name: "Danish Krone",
		symbol_native: "kr",
		code: "DKK",
		emoji: "🇩🇰"
	},
	{
		symbol: "RD$",
		name: "Dominican Peso",
		symbol_native: "RD$",
		code: "DOP",
		emoji: "🇩🇴"
	},
	{
		symbol: "DA",
		name: "Algerian Dinar",
		symbol_native: "د.ج.‏",
		code: "DZD",
		emoji: "🇩🇿"
	},
	{
		symbol: "Ekr",
		name: "Estonian Kroon",
		symbol_native: "kr",
		code: "EEK",
		emoji: "🇪🇪"
	},
	{
		symbol: "EGP",
		name: "Egyptian Pound",
		symbol_native: "ج.م.‏",
		code: "EGP",
		emoji: "🇪🇬"
	},
	{
		symbol: "Nfk",
		name: "Eritrean Nakfa",
		symbol_native: "Nfk",
		code: "ERN",
		emoji: "🇪🇷"
	},
	{
		symbol: "Br",
		name: "Ethiopian Birr",
		symbol_native: "Br",
		code: "ETB",
		emoji: "🇪🇹"
	},
	{
		symbol: "£",
		name: "Falkland Island Pound",
		symbol_native: "£",
		code: "FKP",
		emoji: "🇫🇰"
	},
	{
		symbol: "£",
		name: "British Pound Sterling",
		symbol_native: "£",
		code: "GBP",
		emoji: "🇬🇧"
	},
	{
		symbol: "GEL",
		name: "Georgian Lari",
		symbol_native: "GEL",
		code: "GEL",
		emoji: "🇬🇪"
	},
	{
		symbol: "GH₵",
		name: "Ghanaian Cedi",
		symbol_native: "GH₵",
		code: "GHS",
		emoji: "🇬🇭"
	},
	{
		symbol: "£",
		name: "Gibraltar Pound",
		symbol_native: "£",
		code: "GIP",
		emoji: "🇬🇮"
	},
	{
		symbol: "FG",
		name: "Guinean Franc",
		symbol_native: "FG",
		code: "GNF",
		emoji: "🇬🇳"
	},
	{
		symbol: "GTQ",
		name: "Guatemalan Quetzal",
		symbol_native: "Q",
		code: "GTQ",
		emoji: "🇬🇹"
	},
	{
		symbol: "HK$",
		name: "Hong Kong Dollar",
		symbol_native: "$",
		code: "HKD",
		emoji: "🇭🇰"
	},
	{
		symbol: "HNL",
		name: "Honduran Lempira",
		symbol_native: "L",
		code: "HNL",
		emoji: "🇭🇳"
	},
	{
		symbol: "kn",
		name: "Croatian Kuna",
		symbol_native: "kn",
		code: "HRK",
		emoji: "🇭🇷"
	},
	{
		symbol: "Ft",
		name: "Hungarian Forint",
		symbol_native: "Ft",
		code: "HUF",
		emoji: "🇭🇺"
	},
	{
		symbol: "Rp",
		name: "Indonesian Rupiah",
		symbol_native: "Rp",
		code: "IDR",
		emoji: "🇮🇩"
	},
	{
		symbol: "₪",
		name: "Israeli New Sheqel",
		symbol_native: "₪",
		code: "ILS",
		emoji: "🇮🇱"
	},
	{
		symbol: "Rs",
		name: "Indian Rupee",
		symbol_native: "₹",
		code: "INR",
		emoji: "🇮🇳"
	},
	{
		symbol: "IQD",
		name: "Iraqi Dinar",
		symbol_native: "د.ع.‏",
		code: "IQD",
		emoji: "🇮🇶"
	},
	{
		symbol: "IRR",
		name: "Iranian Rial",
		symbol_native: "﷼",
		code: "IRR",
		emoji: "🇮🇷"
	},
	{
		symbol: "Ikr",
		name: "Icelandic Króna",
		symbol_native: "kr",
		code: "ISK",
		emoji: "🇮🇸"
	},
	{
		symbol: "J$",
		name: "Jamaican Dollar",
		symbol_native: "$",
		code: "JMD",
		emoji: "🇯🇲"
	},
	{
		symbol: "JD",
		name: "Jordanian Dinar",
		symbol_native: "د.أ.‏",
		code: "JOD",
		emoji: "🇯🇴"
	},
	{
		symbol: "¥",
		name: "Japanese Yen",
		symbol_native: "￥",
		code: "JPY",
		emoji: "🇯🇵"
	},
	{
		symbol: "с",
		name: "Kyrgyzstani som",
		symbol_native: "с",
		code: "KES",
		emoji: "🇰🇪"
	},
	{
		symbol: "KHR",
		name: "Cambodian Riel",
		symbol_native: "៛",
		code: "KHR",
		emoji: "🇰🇭"
	},
	{
		symbol: "CF",
		name: "Comorian Franc",
		symbol_native: "FC",
		code: "KMF",
		emoji: "🇰🇲"
	},
	{
		symbol: "₩",
		name: "South Korean Won",
		symbol_native: "₩",
		code: "KRW",
		emoji: "🇰🇷"
	},
	{
		symbol: "KD",
		name: "Kuwaiti Dinar",
		symbol_native: "د.ك.‏",
		code: "KWD",
		emoji: "🇰🇼"
	},
	{
		symbol: "$",
		name: "Cayman Islands dollar",
		symbol_native: "$‏",
		code: "KYD",
		emoji: "🇰🇾"
	},
	{
		symbol: "KZT",
		name: "Kazakhstani Tenge",
		symbol_native: "тңг.",
		code: "KZT",
		emoji: "🇰🇿"
	},
	{
		symbol: "₭",
		name: "Lao kip",
		symbol_native: "₭‏",
		code: "LAK",
		emoji: "🇱🇦"
	},
	{
		symbol: "LB£",
		name: "Lebanese Pound",
		symbol_native: "ل.ل.‏",
		code: "LBP",
		emoji: "🇱🇧"
	},
	{
		symbol: "SLRs",
		name: "Sri Lankan Rupee",
		symbol_native: "SL Re",
		code: "LKR",
		emoji: "🇱🇰"
	},
	{
		symbol: "$",
		name: "Liberian Dollar",
		symbol_native: "$",
		code: "LRD",
		emoji: "🇱🇷"
	},
	{
		symbol: "Lt",
		name: "Lithuanian Litas",
		symbol_native: "Lt",
		code: "LTL",
		emoji: "🇱🇹"
	},
	{
		symbol: "Ls",
		name: "Latvian Lats",
		symbol_native: "Ls",
		code: "LVL",
		emoji: "🇱🇻"
	},
	{
		symbol: "LD",
		name: "Libyan Dinar",
		symbol_native: "د.ل.‏",
		code: "LYD",
		emoji: "🇱🇾"
	},
	{
		symbol: "MAD",
		name: "Moroccan Dirham",
		symbol_native: "د.م.‏",
		code: "MAD",
		emoji: "🇲🇦"
	},
	{
		symbol: "MDL",
		name: "Moldovan Leu",
		symbol_native: "MDL",
		code: "MDL",
		emoji: "🇲🇩"
	},
	{
		symbol: "MGA",
		name: "Malagasy Ariary",
		symbol_native: "MGA",
		code: "MGA",
		emoji: "🇲🇬"
	},
	{
		symbol: "MKD",
		name: "Macedonian Denar",
		symbol_native: "MKD",
		code: "MKD",
		emoji: "🇲🇰"
	},
	{
		symbol: "MMK",
		name: "Myanma Kyat",
		symbol_native: "K",
		code: "MMK",
		emoji: "🇲🇲"
	},
	{
		symbol: "MOP$",
		name: "Macanese Pataca",
		symbol_native: "MOP$",
		code: "MOP",
		emoji: "🇲🇴"
	},
	{
		symbol: "MURs",
		name: "Mauritian Rupee",
		symbol_native: "MURs",
		code: "MUR",
		emoji: "🇲🇺"
	},
	{
		symbol: "MK",
		name: "Malawian Kwacha",
		symbol_native: "MK",
		code: "MWK",
		emoji: "🇲🇼"
	},
	{
		symbol: "MX$",
		name: "Mexican Peso",
		symbol_native: "$",
		code: "MXN",
		emoji: "🇲🇽"
	},
	{
		symbol: "RM",
		name: "Malaysian Ringgit",
		symbol_native: "RM",
		code: "MYR",
		emoji: "🇲🇾"
	},
	{
		symbol: "MTn",
		name: "Mozambican Metical",
		symbol_native: "MTn",
		code: "MZN",
		emoji: "🇲🇿"
	},
	{
		symbol: "N$",
		name: "Namibian Dollar",
		symbol_native: "N$",
		code: "NAD",
		emoji: "🇳🇦"
	},
	{
		symbol: "₦",
		name: "Nigerian Naira",
		symbol_native: "₦",
		code: "NGN",
		emoji: "🇳🇬"
	},
	{
		symbol: "C$",
		name: "Nicaraguan Córdoba",
		symbol_native: "C$",
		code: "NIO",
		emoji: "🇳🇮"
	},
	{
		symbol: "Nkr",
		name: "Norwegian Krone",
		symbol_native: "kr",
		code: "NOK",
		emoji: "🇳🇴"
	},
	{
		symbol: "NPRs",
		name: "Nepalese Rupee",
		symbol_native: "नेरू",
		code: "NPR",
		emoji: "🇳🇵"
	},
	{
		symbol: "NZ$",
		name: "New Zealand Dollar",
		symbol_native: "$",
		code: "NZD",
		emoji: "🇳🇿"
	},
	{
		symbol: "OMR",
		name: "Omani Rial",
		symbol_native: "ر.ع.‏",
		code: "OMR",
		emoji: "🇴🇲"
	},
	{
		symbol: "B/.",
		name: "Panamanian Balboa",
		symbol_native: "B/.",
		code: "PAB",
		emoji: "🇵🇦"
	},
	{
		symbol: "S/.",
		name: "Peruvian Nuevo Sol",
		symbol_native: "S/.",
		code: "PEN",
		emoji: "🇵🇪"
	},
	{
		symbol: "₱",
		name: "Philippine Peso",
		symbol_native: "₱",
		code: "PHP",
		emoji: "🇵🇭"
	},
	{
		symbol: "PKRs",
		name: "Pakistani Rupee",
		symbol_native: "₨",
		code: "PKR",
		emoji: "🇵🇰"
	},
	{
		symbol: "zł",
		name: "Polish Zloty",
		symbol_native: "zł",
		code: "PLN",
		emoji: "🇵🇱"
	},
	{
		symbol: "₲",
		name: "Paraguayan Guarani",
		symbol_native: "₲",
		code: "PYG",
		emoji: "🇵🇾"
	},
	{
		symbol: "QR",
		name: "Qatari Rial",
		symbol_native: "ر.ق.‏",
		code: "QAR",
		emoji: "🇶🇦"
	},
	{
		symbol: "RON",
		name: "Romanian Leu",
		symbol_native: "RON",
		code: "RON",
		emoji: "🇷🇴"
	},
	{
		symbol: "din.",
		name: "Serbian Dinar",
		symbol_native: "дин.",
		code: "RSD",
		emoji: "🇷🇸"
	},
	{
		symbol: "RUB",
		name: "Russian Ruble",
		symbol_native: "руб.",
		code: "RUB",
		emoji: "🇷🇺"
	},
	{
		symbol: "RWF",
		name: "Rwandan Franc",
		symbol_native: "FR",
		code: "RWF",
		emoji: "🇷🇼"
	},
	{
		symbol: "SR",
		name: "Saudi Riyal",
		symbol_native: "ر.س.‏",
		code: "SAR",
		emoji: "🇸🇦"
	},
	{
		symbol: "$",
		name: "Solomon Islander Dollar",
		symbol_native: "$",
		code: "SBD",
		emoji: "🇸🇧"
	},
	{
		symbol: "SDG",
		name: "Sudanese Pound",
		symbol_native: "SDG",
		code: "SDG",
		emoji: "🇸🇩"
	},
	{
		symbol: "Skr",
		name: "Swedish Krona",
		symbol_native: "kr",
		code: "SEK",
		emoji: "🇸🇪"
	},
	{
		symbol: "S$",
		name: "Singapore Dollar",
		symbol_native: "$",
		code: "SGD",
		emoji: "🇸🇬"
	},
	{
		symbol: "Le",
		name: "Sierra Leonean Leone",
		symbol_native: "Le",
		code: "SLL",
		emoji: "🇸🇱"
	},
	{
		symbol: "Ssh",
		name: "Somali Shilling",
		symbol_native: "Ssh",
		code: "SOS",
		emoji: "🇸🇴"
	},
	{
		symbol: "£",
		name: "South Sudanese pound",
		symbol_native: "£",
		code: "SSP",
		emoji: "🇸🇸"
	},
	{
		symbol: "Db",
		name: "Sao Tomean Dobra",
		symbol_native: "Db",
		code: "STN",
		emoji: "🇸🇹"
	},
	{
		symbol: "SY£",
		name: "Syrian Pound",
		symbol_native: "ل.س.‏",
		code: "SYP",
		emoji: "🇸🇾"
	},
	{
		symbol: "L",
		name: "Swazi Lilangeni",
		symbol_native: "L‏",
		code: "SZL",
		emoji: "🇸🇿"
	},
	{
		symbol: "฿",
		name: "Thai Baht",
		symbol_native: "฿",
		code: "THB",
		emoji: "🇹🇭"
	},
	{
		symbol: "ЅМ",
		name: "Tajikistani Somoni",
		symbol_native: "ЅМ",
		code: "TJS",
		emoji: "🇹🇭"
	},
	{
		symbol: "DT",
		name: "Tunisian Dinar",
		symbol_native: "د.ت.‏",
		code: "TND",
		emoji: "🇹🇳"
	},
	{
		symbol: "T$",
		name: "Tongan Paʻanga",
		symbol_native: "T$",
		code: "TOP",
		emoji: "🇹🇴"
	},
	{
		symbol: "TL",
		name: "Turkish Lira",
		symbol_native: "TL",
		code: "TRY",
		emoji: "🇹🇷"
	},
	{
		symbol: "TT$",
		name: "Trinidad and Tobago Dollar",
		symbol_native: "$",
		code: "TTD",
		emoji: "🇹🇹"
	},
	{
		symbol: "NT$",
		name: "New Taiwan Dollar",
		symbol_native: "NT$",
		code: "TWD",
		emoji: "🇹🇼"
	},
	{
		symbol: "TSh",
		name: "Tanzanian Shilling",
		symbol_native: "TSh",
		code: "TZS",
		emoji: "🇹🇿"
	},
	{
		symbol: "₴",
		name: "Ukrainian Hryvnia",
		symbol_native: "₴",
		code: "UAH",
		emoji: "🇺🇦"
	},
	{
		symbol: "USh",
		name: "Ugandan Shilling",
		symbol_native: "USh",
		code: "UGX",
		emoji: "🇺🇬"
	},
	{
		symbol: "$U",
		name: "Uruguayan Peso",
		symbol_native: "$",
		code: "UYU",
		emoji: "🇺🇾"
	},
	{
		symbol: "UZS",
		name: "Uzbekistan Som",
		symbol_native: "UZS",
		code: "UZS",
		emoji: "🇺🇿"
	},
	{
		symbol: "Bs.F.",
		name: "Venezuelan Bolívar",
		symbol_native: "Bs.F.",
		code: "VEF",
		emoji: "🇻🇪"
	},
	{
		symbol: "₫",
		name: "Vietnamese Dong",
		symbol_native: "₫",
		code: "VND",
		emoji: "🇻🇳"
	},
	{
		symbol: "Vt",
		name: "Ni-Vanuatu Vatu",
		symbol_native: "Vt",
		code: "VUV",
		emoji: "🇻🇺"
	},
	{
		symbol: "FCFA",
		name: "CFA Franc BEAC",
		symbol_native: "FCFA",
		code: "XAF",
		emoji: "🇨🇫"
	},
	{
		symbol: "$",
		name: "East Caribbean Dollar",
		symbol_native: "$",
		code: "XCD",
		emoji: "🇦🇬"
	},
	{
		symbol: "CFA",
		name: "CFA Franc BCEAO",
		symbol_native: "CFA",
		code: "XOF",
		emoji: "🇨🇮"
	},
	{
		symbol: "Fr",
		name: "CFP franc",
		symbol_native: "Fr",
		code: "XPF",
		emoji: "🇵🇫"
	},
	{
		symbol: "YR",
		name: "Yemeni Rial",
		symbol_native: "ر.ي.‏",
		code: "YER",
		emoji: "🇾🇪"
	},
	{
		symbol: "R",
		name: "South African Rand",
		symbol_native: "R",
		code: "ZAR",
		emoji: "🇿🇦"
	},
	{
		symbol: "ZK",
		name: "Zambian Kwacha",
		symbol_native: "ZK",
		code: "ZMK",
		emoji: "🇿🇲"
	}
];

class Settings extends Component {
	state = {
		storeName: "",
		modalOpen: false,
		loading: true,
		target: "",
		currencyListString: "-",
		themeColorText: "#FFFFFF",
		themeColor: "#35B2FF",
		demoColor: "35B2FF",
		buttonColor: "#000000",
		activeStep: 0,
		responsePeriod: 24,
		openSnack: false,
		vertical: "top",
		horizontal: "center"
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot, loading: false });
				db.getResponsePeriod(snapshot).then(snap => {
					if (snap !== null && snap !== undefined) this.setState({ responsePeriod: snap });
					else this.setState({ responsePeriod: 24 });
				});
				db.getButtonColor(snapshot).then(snap => {
					if (snap !== null && snap !== undefined) this.setState({ buttonColor: snap.color });
					else this.setState({ buttonColor: "#000000" });
				});
				db.getThemeColor(snapshot).then(snap => {
					if (snap !== null && snap !== undefined)
						this.setState({
							themeColor: snap.color,
							themeColorText: snap.textColor,
							demoColor: String(snap.color).slice(1)
						});
					else this.setState({ themeColor: "#35B2FF" });
				});
				return db.getCurrencies(snapshot);
			})
			.then(snapshot => {
				var string = "";
				snapshot.forEach(currency => {
					var result = currencyList.find(obj => {
						if (obj.code === currency.key) return obj;
						return;
					});
					string += result ? `${result.emoji}` : ``;
					string += `${currency.key}: ${currency.val()}\n`;
					return;
				});
				string = string.slice(0, -2);
				this.setState({ currencyListString: string, loading: false });
				return;
			})
			.catch(e => {
				console.error(e);
			});
		//need to add a get button color and theme color and set those in state as well
	}

	handleOpen = target => {
		this.setState({
			modalOpen: true,
			target: target
		});
	};
	handleClose = () => {
		this.setState({
			modalOpen: false,
			target: ""
		});
		db.getCurrencies(this.state.storeName)
			.then(currencies => {
				var string = "";
				currencies.forEach(currency => {
					var result = currencyList.find(obj => {
						if (obj.code === currency.key) return obj;
						return;
					});
					string += result ? `${result.emoji}` : ``;
					string += `${currency.key}: ${currency.val()}\n`;
					return;
				});
				string = string.slice(0, -2);
				this.setState({ currencyListString: string, loading: false });
			})
			.catch(console.error);
	};
	handleChangeT = (color, event) => {
		let themeColorText = "#FFFFFF";
		var cVal = color.rgb.r * 0.209 + color.rgb.g * 0.487 + color.rgb.b * 0.254;
		if (cVal > 186) {
			themeColorText = "#000000";
		}
		let demoColor = color.hex.substr(1);
		this.setState({
			themeColor: color.hex,
			themeColorRGB: color.rgb,
			themeColorText: themeColorText,
			demoColor: demoColor
		});
	};
	handleSave = () => {
		this.setState({ openSnack: true });
		db.setThemeColor(this.state.storeName, this.state.themeColor.toUpperCase(), this.state.themeColorText);
		setTimeout(() => {
			this.setState({
				openSnack: false
			});
		}, 2000);
	};
	updateResponseVal = val => {
		this.setState({ responsePeriod: val });
	};

	render() {
		const { classes, isMobile } = this.props;
		const { demoColor, modalOpen, target, currencyListString, themeColor, responsePeriod, openSnack, vertical, horizontal } = this.state;
		var url =
			`https://app.enteroffer.com/?i=https://cdn.shopify.com/s/files/1/0091/4541/7785/products/Untitled_design_13.png?v=1570196379&logo=https://cdn.shopify.com/s/files/1/0091/4541/7785/files/Make_an_offer_2_360x.png?v=1560239097&n=Vintage%20Camera&c=100.00&o1=Brown&o2=Black&demo=true&color=` +
			demoColor;

		const offerWindow = (
			<div style={{ marginTop: "24px" }}>
				<Iframe url={url} width='100%' height='780px' frameBorder='0' display='initial' position='relative' />
			</div>
		);
		return (
			<div className={classes.root}>
				<Snackbar
					anchorOrigin={{ vertical, horizontal }}
					open={openSnack}
					// onClose={this.handleCloseSnack}
					message='Color Saved'
				/>
				<NewSettingsModal open={modalOpen} target={target} handleClose={this.handleClose} updateResponseCallback={this.updateResponseVal} />
				<h1 style={{ textAlign: "center" }}>
					<span
						style={{
							color: "#35B2FF",
							fontWeight: 900
						}}>
						ACCOUNT
					</span>
					<span style={{ fontWeight: 900 }}> SETTINGS</span>
				</h1>
				{this.state.loading ? (
					<Lottie
						height={200}
						width={200}
						options={{
							loop: true,
							autoplay: true,
							animationData: loader.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice"
							}
						}}
					/>
				) : (
					<Grid container justify='space-between' spacing={2}>
						<Grid item xs={isMobile ? 12 : 6}>
							<Card style={{ height: "100%" }}>
								<CardActionArea style={{ width: "100%", height: "100%" }} onClick={() => this.handleOpen("currency")} className={classes.media}>
									<CardContent>
										<Grid container alignItems='center' spacing={2}>
											<Grid item xs={1}>
												<Typography variant='h6' style={{ fontWeight: 900 }}>
													<Money />
												</Typography>
											</Grid>
											<Grid item xs={11}>
												<Typography variant='h6' style={{ fontWeight: 900 }}>
													CURRENCY CONVERSION
												</Typography>
												<Typography variant='subtitle1'>{currencyListString === "" ? "-" : currencyListString}</Typography>
											</Grid>
										</Grid>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
						<Grid item xs={isMobile ? 12 : 6}>
							<Card style={{ height: "100%" }}>
								<a
									href='https://dashboard.stripe.com'
									target='_blank'
									rel='noopener noreferrer'
									style={{
										textDecoration: "none",
										color: "inherit"
									}}>
									<CardActionArea style={{ width: "100%", height: "100%" }} className={classes.media}>
										<CardContent>
											<Grid container alignItems='center' spacing={2}>
												<Grid item xs={1}>
													<Typography variant='h6'>
														<Payment />
													</Typography>
												</Grid>
												<Grid item xs={11}>
													<Typography variant='h6' style={{ fontWeight: 900 }}>
														PAYMENT
													</Typography>
													<Typography variant='subtitle1'>Update Stripe settings</Typography>
													<Typography variant='subtitle1' style={{ color: "transparent" }}>
														-
													</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</CardActionArea>
								</a>
							</Card>
						</Grid>
						<Grid item xs={isMobile ? 12 : 6}>
							<Card style={{ height: "100%" }}>
								<CardActionArea style={{ width: "100%", height: "100%" }} onClick={() => this.handleOpen("button")} className={classes.media}>
									<CardContent>
										<Grid container alignItems='center' spacing={2}>
											<Grid item xs={1}>
												<Typography variant='h3'>
													<Color />
												</Typography>
											</Grid>
											<Grid item xs={11}>
												<Typography variant='h6' style={{ fontWeight: 900 }}>
													ENTEROFFER BUTTON COLOR
												</Typography>
												<Typography variant='subtitle1'>Choose a custom color for the EnterOffer button appearing on the product page</Typography>
											</Grid>
										</Grid>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
						<Grid item xs={isMobile ? 12 : 6}>
							<Card style={{ height: "100%" }}>
								<CardActionArea style={{ width: "100%", height: "100%" }} onClick={() => this.handleOpen("response")} className={classes.media}>
									<CardContent>
										<Grid container alignItems='center' spacing={2}>
											<Grid item xs={1}>
												<Typography variant='h6' style={{ fontWeight: 900 }}>
													<Time />
												</Typography>
											</Grid>
											<Grid item xs={11}>
												<Typography variant='h6' style={{ fontWeight: 900 }}>
													RESPONSE PERIOD
												</Typography>
												<Typography variant='subtitle1'>
													Current Response Period: <b>{responsePeriod}</b> hours
												</Typography>
												<Typography variant='subtitle1' style={{ color: "transparent" }}>
													-
												</Typography>
											</Grid>
										</Grid>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
						<Grid item xs={isMobile ? 12 : 6}>
							<Card>
								<CardContent>
									<Grid container alignItems='center' spacing={2}>
										<Grid item xs={1}>
											<Typography variant='h3'>
												<Color />
											</Typography>
										</Grid>
										<Grid item xs={11}>
											<Typography variant='h6' style={{ fontWeight: 900 }}>
												OFFER WINDOW THEME COLOR
											</Typography>
										</Grid>
									</Grid>
									<Card style={{ height: "600px" }} elevation={0}>
										<div
											style={{
												transform: "scale(0.6)",
												marginTop: "-150px",
												marginLeft: isMobile ? "-66px" : "auto",
												marginRight: "auto"
											}}
											className='marvel-device iphone-x'>
											<div className='notch'>
												<div className='camera'></div>
												<div className='speaker'></div>
											</div>
											<div className='top-bar'></div>
											<div className='sleep'></div>
											<div className='bottom-bar'></div>
											<div className='volume'></div>
											<div className='overflow'>
												<div className='shadow shadow--tr'></div>
												<div className='shadow shadow--tl'></div>
												<div className='shadow shadow--br'></div>
												<div className='shadow shadow--bl'></div>
											</div>
											<div className='inner-shadow'></div>
											<div className='screen'>{offerWindow}</div>
										</div>
									</Card>
									<div
										style={{
											margin: isMobile ? "-3em auto 1em" : "-5em 0em 1em 20%",
											padding: "0em 2em"
										}}>
										<TwitterPicker width={isMobile ? 250 : 300} colors={colors} color={themeColor} onChange={this.handleChangeT} />
									</div>
									<Grid container spacing={2} justify='center' alignItems='center'>
										<Grid item xs={12} style={{ textAlign: "center" }}>
											<Button onClick={this.handleSave}>Save Color</Button>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				)

				/* <br /><br />
            <Typography  variant="h4">Account</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card>
                    <CardActionArea onClick={() => this.handleOpen('currency')} style={{width: '100%', height: '100%'}} className={classes.media}>
                        <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <Typography variant="h6">
                                    <Account />
                                </Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography  variant="h6">
                                    Account
                                </Typography>
                                <Typography variant="subtitle1">
                                    {auth.currentUser.email}
                                </Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                    <CardActionArea onClick={() => this.handleOpen('currency')} style={{width: '100%', height: '100%'}} className={classes.media}>
                        <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <Typography variant="h6">
                                    <Store />
                                </Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography  variant="h6">
                                    Store
                                </Typography>
                                <Typography variant="subtitle1">
                                    {_.startCase(this.state.storeName)}
                                </Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card>
                    <CardActionArea onClick={() => this.handleOpen('currency')} style={{width: '100%', height: '100%'}} className={classes.media}>
                        <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <Typography variant="h6">
                                    <Password />
                                </Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography  variant="h6">
                                    Password
                                </Typography>
                                <Typography variant="subtitle1">
                                    &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
                                </Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </CardActionArea>
                    </Card>
                </Grid>
			</Grid> */
				}
			</div>
		);
	}
}
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles, { withTheme: true })(Settings));
