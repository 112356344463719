import React, { Component } from "react";
import { ConnectWithStripe } from "../assets";
import { fb, authBase } from "../firebase";
import { auth } from "../firebase/firebase";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
import SecurityIcon from "@material-ui/icons/Security";
import PaymentIcon from "@material-ui/icons/Payment";
import Divider from "@material-ui/core/Divider";
import MediaQuery from "react-responsive";

class StripeConnect extends Component {
	state = {
		queryString: ""
	};

	componentDidMount() {
		// db.getUserStoreName().then(snapshot => {
		this.setState({ storeName: this.props.storeName });
		// bind stripeUserId to state
		authBase.syncState(`newStores/${auth.currentUser.uid}/stripe/stripeUserId`, {
			context: this,
			state: "stripeUserId",
			defaultValue: null,
			then: () => {
				//when it is done, check whether the url and the stripeUserId suggest we have just come from callback response
				let urlParams = queryString.parse(this.props.location.search);
				if (urlParams.scope != null && urlParams.code != null && this.state.stripeUserId == null) {
					//we have received successful connection, need to query stripe to get the users details through server
					fb.addStripeRetailerOnSignup(auth.currentUser.uid, urlParams.code)
						.then(() => {
							this.props.doneStep();
							console.log("success");
						})
						.catch(console.error);
				}
			}
		});
		return;
		// })
	}

	render() {
		let urlParams = queryString.parse(this.props.location.search);
		// const clientId = 'ca_EijC5IP4ivnPrQJJMIbXeh20NrRiFseG' //test
		const clientId = "ca_EijCRxtaeHMkm2tRVkNdR2Mb2ZmuFahK"; //live
		const stripeRedirect = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=https://retailer.enteroffer.com`;
		return (
			<div
				style={{
					textAlign: "center",
					marginTop: "1.250em"
				}}>
				<MediaQuery query='(min-width: 600px)'>
					<div
						style={{
							background: "#fff",
							borderRadius: "2px",
							display: "inline-block",
							width: "50%",
							height: "auto",
							position: "relative",
							padding: "0em 2em 2em 2em",
							boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
						}}>
						<p style={{ textAlign: "center", fontSize: "1.6em" }}>
							<span
								style={{
									color: "#35B2FF",
									fontWeight: 900
								}}>
								SET UP
							</span>
							<span style={{ fontWeight: 900 }}> PAYMENTS</span>
						</p>
						<p style={{ color: "#7f7f7f", fontSize: "0.8em" }}>
							<PaymentIcon
								style={{
									verticalAlign: "middle",
									position: "relative",
									marginRight: "0.5em",
									top: "-0.03em"
								}}
							/>
							PAYMENTS POWERED BY STRIPE
						</p>
						<p>
							{" "}
							To process payments for EnterOffer, you’ll now quickly create a free account with Stripe or
							connect your Stripe account
						</p>
						<p style={{ color: "#7f7f7f", textAlign: "left" }}>
							<LockIcon
								style={{
									verticalAlign: "middle",
									position: "relative",
									marginRight: "0.5em",
									top: "-0.17em"
								}}
							/>
							Stripe handles billions of transactions with world-class security and identity verification.
						</p>
						<p style={{ color: "#7f7f7f", textAlign: "left" }}>
							<SecurityIcon
								style={{
									verticalAlign: "middle",
									position: "relative",
									marginRight: "0.5em",
									top: "-0.17em"
								}}
							/>
							The information you provide is only used to verify and set up payments for your business.
						</p>
						{/* <p style={{color: '#7f7f7f', textAlign: 'left'}}><TimeIcon style={{verticalAlign: 'middle',position: 'relative', marginRight: '0.5em', top: '-0.17em'}}/>This process should take less than 10 minutes. To finish faster, have your bank account or debit card information on hand.</p> */}
						<Divider />
						<br />
						<a href={stripeRedirect}>
							<img style={{ width: "220px" }} alt='stripe' src={ConnectWithStripe} />
						</a>
						{urlParams.error ? (
							<p
								style={{
									color: "red"
								}}>
								{urlParams.error_description}
							</p>
						) : null}
					</div>
				</MediaQuery>
				<MediaQuery query='(max-width: 600px)'>
					<div
						style={{
							background: "#fff",
							borderRadius: "2px",
							display: "inline-block",
							width: "80%",
							height: "auto",
							position: "relative",
							padding: "0em 2em 2em 2em",
							boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
						}}>
						<p style={{ textAlign: "center", fontSize: "1.6em" }}>
							<span
								style={{
									color: "#35B2FF",
									fontWeight: 900
								}}>
								SET UP
							</span>
							<span style={{ fontWeight: 900 }}> PAYMENTS</span>
						</p>
						<p style={{ color: "#7f7f7f", fontSize: "0.8em" }}>
							<PaymentIcon
								style={{
									verticalAlign: "middle",
									position: "relative",
									marginRight: "0.5em",
									top: "-0.03em"
								}}
							/>
							PAYMENTS POWERED BY STRIPE
						</p>
						<p>
							{" "}
							To process payments for EnterOffer, you’ll now quickly create a free account with Stripe or
							connect your Stripe account
						</p>
						<p style={{ color: "#7f7f7f", textAlign: "left" }}>
							<LockIcon
								style={{
									verticalAlign: "middle",
									position: "relative",
									marginRight: "0.5em",
									top: "-0.17em"
								}}
							/>
							Stripe handles billions of transactions with world-class security and identity verification.
						</p>
						<p style={{ color: "#7f7f7f", textAlign: "left" }}>
							<SecurityIcon
								style={{
									verticalAlign: "middle",
									position: "relative",
									marginRight: "0.5em",
									top: "-0.17em"
								}}
							/>
							The information you provide is only used to verify and set up payments for your business.
						</p>
						{/* <p style={{color: '#7f7f7f', textAlign: 'left'}}><TimeIcon style={{verticalAlign: 'middle',position: 'relative', marginRight: '0.5em', top: '-0.17em'}}/>This process should take less than 10 minutes. To finish faster, have your bank account or debit card information on hand.</p>            */}
						<Divider />
						<br />
						<a href={stripeRedirect}>
							<img style={{ width: "220px" }} alt='stripe' src={ConnectWithStripe} />
						</a>
						{urlParams.error ? (
							<p
								style={{
									color: "red"
								}}>
								{urlParams.error_description}
							</p>
						) : null}
					</div>
				</MediaQuery>
			</div>
		);
	}
}
export default withRouter(StripeConnect);
