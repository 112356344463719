import { authfb } from "./firebase";
export function checkInventory(store, productID, variantID, quantity = 1) {
	return new Promise((resolve, reject) => {
		var checkInventory = authfb.functions().httpsCallable("checkInventory");
		checkInventory({
			store: store,
			productID: productID,
			variantID: variantID,
			quantity: quantity,
		})
			.then((result) => {
				return resolve(result);
			})
			.catch(console.error);
	});
}

export function checkBundleInventory(store, products) {
	var allPromises = [];
	var checkInventory = authfb.functions().httpsCallable("checkInventory");
	for (let i in products) {
		let quantity = 1;
		if (products[i].quantity !== undefined && products[i].quantity !== null) {
			quantity = products[i].quantity;
		}
		allPromises.push(
			new Promise((resolve, reject) => {
				var toPush = {
					productID: products[i].productID,
					variantID: products[i].variantID,
					quantity: quantity,
				};
				checkInventory({
					store: store,
					productID: products[i].productID,
					variantID: products[i].variantID,
					quantity: quantity,
				})
					.then((result) => {
						toPush.data = result;
						return resolve(toPush);
					})
					.catch(console.error);
			})
		);
	}
	return Promise.all(allPromises).then((result) => {
		var hasNoInvItem = false;
		var noInvItems = [];
		for (let i in result) {
			if (result[i]["data"]["data"] === 1) {
				noInvItems.push(result[i]);
				hasNoInvItem = true;
			}
			if (result[i]["data"]["data"] === 2) {
				return 2;
			}
		}
		if (hasNoInvItem) {
			return noInvItems;
		} else {
			return 0;
		}
	});
}

export function approveOffer(storeName, offer) {
	var approveOffer = authfb.functions().httpsCallable("approveOffer");
	return new Promise((resolve, reject) => {
		return approveOffer({
			storeName: storeName,
			key: offer.key,
		})
			.then((result) => {
				return resolve(result);
			})
			.catch(console.error);
	});
}

export function rejectOffer(storeName, offer) {
	var rejectOffer = authfb.functions().httpsCallable("rejectOffer");
	return new Promise((resolve, reject) => {
		return rejectOffer({
			storeName: storeName,
			key: offer.key,
		})
			.then((result) => {
				return resolve(result);
			})
			.catch(console.error);
	});
}

export function deleteExpiredRules(storeName) {
	return new Promise((resolve, reject) => {
		var deleteExpiredRules = authfb.functions().httpsCallable("deleteExpiredRules");
		deleteExpiredRules({
			storeName: storeName,
		})
			.then((result) => {
				return resolve(result);
			})
			.catch(console.error);
	});
}

export function submitCartRule(rule) {
	var submitCartRule = authfb.functions().httpsCallable("submitCartRule");
	return new Promise((resolve, reject) => {
		submitCartRule(rule)
			.then((res) => {
				return resolve(res);
			})
			.catch((e) => {
				console.log(e);
				return reject(false);
			});
	});
}

export function updateOfferableProducts(data) {
	return new Promise((resolve, reject) => {
		var updateOfferableProducts = authfb.functions().httpsCallable("updateOfferableProducts");
		updateOfferableProducts(data)
			.then((result) => {
				return resolve(result);
			})
			.catch((e) => {
				console.error(e);
				return reject(e);
			});
	});
}

export function getOfferableProductInfo(store) {
	var getOfferableProductInfo = authfb.functions().httpsCallable("getOfferableProductInfo");
	return new Promise((resolve, reject) => {
		getOfferableProductInfo({
			store: store,
		})
			.then((res) => {
				return resolve(res);
			})
			.catch((e) => {
				console.error(e);
				return reject(e);
			});
	});
}

export function addStripeRetailerOnSignup(uid, code) {
	var addStripeRetailerOnSignup = authfb.functions().httpsCallable("addStripeRetailerOnSignup");
	return new Promise((resolve, reject) => {
		addStripeRetailerOnSignup({
			uid,
			code,
		})
			.then((res) => {
				console.log(res);
				return resolve(res);
			})
			.catch((e) => {
				console.error(e);
				return reject(e);
			});
	});
}

export function getCollections(storeName) {
	var getCollections = authfb.functions().httpsCallable("getShopifyStoreCollections");
	return getCollections({ storeName })
		.then((res) => {
			return res.data;
		})
		.catch((e) => {
			console.error(e);
		});
}
