import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { db } from "../firebase";
import { Typography, Snackbar, Grid, Dialog, DialogActions, DialogContent, Button } from "@material-ui/core";
import _ from "lodash";
import Help from "@material-ui/icons/Help";

const styles = theme => ({
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		backgroundColor: "#F6F6F8",
		borderRadius: "4px"
	},

	inputDrop: {
		marginLeft: " -4px",
		padding: "0.2em 1.8em 0.2em 1em",
		fontWeight: "700",
		fontSize: "0.8em"
	},
	notchedOutline: {
		borderWidth: "1px",
		borderColor: "#f6f6f8 !important"
	},
	image: {
		borderRadius: "10px",
		width: "100%",
		objectFit: "cover"
	}
});

class SuggestedPrice extends Component {
	state = {
		storeName: "",
		open: false,
		openHelp: false,
		updated: false,
		approved: [],
		pending: [],
		rejected: [],
		productArr: []
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: false,
		legendPosition: "top"
	};

	componentDidMount() {
		this.updateState(false);
	}
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
			updated: true
		});
		this.updateState(true);
	};

	updateState(notFromMount) {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				return db.getStoreBids(snapshot, "approved");
			})
			.then(snapshot => {
				this.setState({ approved: snapshot });
				return db.getStoreBids(this.state.storeName, "rejected");
			})
			.then(snapshot => {
				this.setState({ rejected: snapshot });
				return db.getStoreBids(this.state.storeName, "pending");
			})
			.then(snapshot => {
				this.getOfferData(snapshot);
			})
			.catch(console.error);

		if (notFromMount) {
			this.setState({ open: true });
		}
	}

	getOfferData = snapshot => {
		const { approved, rejected } = this.state;
		let newArray = [];
		snapshot.forEach(offer => {
			let x = offer.val();
			for (let n in x.products) {
				let newObj = {
					productName: x.products[n].productName,
					image: x.products[n].image,
					productID: x.products[n].productID,
					variantID: x.products[n].variantID,
					variantName: x.products[n].variantName,
					countA: 0,
					totalA: 0,
					countR: 0,
					totalR: 0
				};
				approved.forEach(obj => {
					let t = obj.val();
					for (let i in t.products) {
						if (
							x.products[n].productID === t.products[i].productID &&
							x.products[n].variantID === t.products[i].variantID &&
							t.cartAbandoned === false
						) {
							newObj.countA += 1;
							newObj.totalA += t.products[i].bidPrice;
						}
					}
				});
				rejected.forEach(obj => {
					let t = obj.val();
					for (let i in t.products) {
						if (
							x.products[n].productID === t.products[i].productID &&
							x.products[n].variantID === t.products[i].variantID &&
							t.cartAbandoned === false
						) {
							newObj.countR += 1;
							newObj.totalR += t.products[i].bidPrice;
						}
					}
				});
				if (!_.find(newArray, newObj) && x.products[n].offerable) {
					newArray.push(newObj);
				}
			}
		});
		this.setState({
			productArr: newArray,
			pending: snapshot,
			updated: false
		});
	};

	handleCloseSnackSuccess = () => {
		this.setState({ open: false });
	};

	handleClose = () => {
		this.setState({ openHelp: false });
	};

	render() {
		const { productArr } = this.state;
		const { classes } = this.props;
		return (
			<div style={{ textAlign: "left" }}>
				<div>
					<Typography
						style={{
							color: "#000",
							fontSize: "1em",
							fontWeight: 700
						}}>
						Suggested Pricing
						<Help
							onClick={() => this.setState({ openHelp: true })}
							style={{ verticalAlign: "super", fontSize: "12px", color: "#929292", cursor: "pointer" }}
						/>
					</Typography>
				</div>
				<br />
				{_.isEmpty(productArr) ? (
					<Typography variant='body1'>
						Suggested pricing will only appear when you have pending offers.
					</Typography>
				) : (
					_.map(productArr, (p, cnt) => {
						return (
							<Grid container spacing={2} key={cnt}>
								<Grid item xs={4}>
									<img height='100' className={classes.image} src={p.image} alt={"productImg"} />
								</Grid>
								<Grid item xs={4}>
									<Typography style={{ overflowWrap: "break-word" }}>{p.productName}</Typography>
									<Typography variant='caption'>{p.variantName}</Typography>
								</Grid>
								{p.countA === 0 ? (
									<Grid item xs={4}>
										<Typography variant='caption'>
											We do not have enough data to suggest a price.
										</Typography>
									</Grid>
								) : (
									<Grid item xs={4}>
										<Typography>
											{p.countA > 0 ? `$${Number(p.totalA / p.countA).toFixed(2)}` : "-"}
										</Typography>
										<Typography variant='caption'>
											{p.countA + p.countR > 0
												? `$${Number((p.totalR + p.totalA) / (p.countA + p.countR)).toFixed(2)}`
												: "-"}
										</Typography>
									</Grid>
								)}
							</Grid>
						);
					})
				)}
				<br />

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={this.state.open}
					onClose={this.handleCloseSnackSuccess}
					autoHideDuration={3000}
					message={<span id='message-id'>Updated</span>}
				/>

				<Dialog open={this.state.openHelp} onClose={this.handleClose}>
					<DialogContent>
						<Typography variant='h6'>What does this table show?</Typography>
						<Typography variant='caption'>Example:</Typography>
						<Grid container spacing={2}>
							<Grid item xs={4} style={{ color: "#929292" }}>
								<Typography>Product Image</Typography>
							</Grid>
							<Grid item xs={4} style={{ color: "#929292" }}>
								<Typography style={{ overflowWrap: "break-word" }}>Product Name</Typography>
								<Typography variant='caption'>Variant</Typography>
							</Grid>
							<Grid item xs={4} style={{ color: "#929292" }}>
								<Typography>Average Offer Accepted</Typography>
								<Typography variant='caption'>Average Offer Received</Typography>
							</Grid>
							<Grid item xs={4}>
								<img
									height='100'
									className={classes.image}
									src={
										"https://cdn.shopify.com/s/files/1/0091/4541/7785/products/Untitled_design_13_1024x1024@2x.png?v=1560248414"
									}
									alt={"productImg"}
								/>
							</Grid>
							<Grid item xs={4}>
								<Typography style={{ overflowWrap: "break-word" }}>Vintage Camera</Typography>
								<Typography variant='caption'>-</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography>$20</Typography>
								<Typography variant='caption'>$10</Typography>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color='primary' autoFocus>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withStyles(styles)(SuggestedPrice);
