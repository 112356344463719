import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db } from "../firebase";
import { Paper, Button, Tooltip, TextField, Snackbar } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import withSizes from "react-sizes";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1)
	}
}));
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

function EmailTemplate(props) {
	const classes = useStyles();
	const [url, setUrl] = useState("");
	const [openSnack, setOpenSnack] = useState(false);
	const [storename, setStorename] = useState("");
	useEffect(() => {
		db.getUserStoreName()
			.then(snapshot => {
				db.getUserStoreUrl(snapshot).then(snap => {
					setStorename(snapshot);
					setUrl(snap + `/admin/email_templates/abandoned_checkout_notification/edit`);
				});
			})
			.catch(e => console.log("error", e));
	}, []);
	var list = ``;
	for (let key in props.removalList) {
		list += String(props.removalList[key]) + `,`;
	}
	var html =
		`{% capture email_title %}
    {% if item_count == 1 %}
      You left an item in your cart
    {% else %}
      You left items in your cart
    {% endif %} 
  {% endcapture %}
  {% capture email_body %}
    {% if billing_address.first_name %}
      {% if item_count == 1 %}
        Dear {{ customer.first_name }}, <br><br> We have partnered with <img style="vertical-align: middle ;" height="24" width="120" src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png" alt="EnterOffer"> to let you make an offer on your cart. <br><br> Just click the EnterOffer button below.
      {% else %}
       Dear {{ customer.first_name }}, <br><br> We have partnered with <img style="vertical-align: middle ;" height="24" width="120" src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png" alt="EnterOffer"> to let you make an offer on your cart. <br><br>Just click the EnterOffer button below.
      {% endif %} 
    {% else %}
      {% if item_count == 1 %}
        Hi, <br><br> We have partnered with <img style="vertical-align: middle ;" height="24" width="120" src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png" alt="EnterOffer"> to let you make an offer on your cart. <br><br> Just click the EnterOffer button below.
      {% else %}
        Hi, <br><br> We have partnered with <img style="vertical-align: middle ;" height="24" width="120" src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png" alt="EnterOffer"> to let you make an offer on your cart. <br><br> Just click the EnterOffer button below.
      {% endif %} 
    {% endif %}
  {% endcapture %}
  
  <!DOCTYPE html>
  <html lang="en">
    <head>
    <title>{{ email_title }}</title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width">
    <link rel="stylesheet" type="text/css" href="/assets/notifications/styles.css">
    <style>
      .button__cell { background: {{ shop.email_accent_color }}; }
      a, a:hover, a:active, a:visited { color: {{ shop.email_accent_color }}; }
    </style>
  </head>
  
    <body>
      <table class="body">
        <tr>
          <td>
            <table class="header row">
    <tr>
      <td class="header__cell">
        <center>
  
          <table class="container">
            <tr>
              <td>
  
                <table class="row" style="text-align: center;">
                  <tr>
                    <td class="shop-name__cell">
                      {%- if shop.email_logo_url %}
                        <img src="{{shop.email_logo_url}}" alt="{{ shop.name }}" width="{{ shop.email_logo_width }}">
                      {%- else %}
                        <h1 class="shop-name__text">
                          <a href="{{shop.url}}">{{ shop.name }}</a>
                        </h1>
                      {%- endif %}
                    </td>
  
                  </tr>
                </table>
  
              </td>
            </tr>
          </table>
  
        </center>
      </td>
    </tr>
  </table>
  
  <table class="row content" style="text-align: center;">
    <tr>
      <td class="content__cell"  style="padding: 0px;">
        <center>
          <table class="container" style="text-align: center;">
                  <table class="row actions">
                          <tr>
                      <td class="actions__cell">
                        <table class="button main-action-cell">
                          <tr>
                  {% assign offer_url = "https://app.enteroffer.com/?s=` +
		storename +
		`" %}
                    {% assign removal_list = "` +
		list +
		`" | split: ',' %}
                  {% assign show = true %}
                  {% for line in subtotal_line_items %}
                  {% capture offer_url %}{{ offer_url }}&p={{ line.product.id }}{% endcapture %}
                  {% for prod in removal_list %}
                  {% capture pid %}{{line.product.id}}{% endcapture %}
                      {% if prod == pid %}
                          {% assign show = false %}
                      {% endif %}
                  {% endfor %}
                  {% if line.variant.title %}
                  {% capture offer_url %}{{ offer_url }}&v={{ line.variant.title}}{% endcapture %}
                  {% endif %}
                  {% endfor %}
                  {% capture offer_url %}{{ offer_url }}&carta=true{% endcapture %}
                  {% capture offer_url %}{{ offer_url }}&e={{email}}{% endcapture %}
                  {% capture offer_url %}{{ offer_url }}&utm_source=abandoned_cart&utm_medium=email&utm_campaign=` +
		storename +
		`{% endcapture %}
                            
                    </tr>
                  </table>
                  {% if show == false %}  
                  <td class="content__cell">
                          <center>
                            <table class="container">
                              <tr>
                                <td>
                                  
                                <h3>A Special Invite</h3>
                                {% if custom_message != blank %}
                                  <p>{{ custom_message }}</p>
                                {% else %}
                                  <p style="padding: 16px;">{{ email_body }}</p>
                                {% endif %}
                                <table class="row actions">
                      <tr>
                        <td class="empty-line"> </td>
                      </tr>
                      <tr>
                        <td class="actions__cell">
                          <table class="button main-action-cell">
                            <tr>
                              <td class="button__cell"><a href="{{ url }}" class="button__text">Return to your cart</a></td>
                            </tr>
                          </table>
                          {% if shop.url %}
                        <table class="link secondary-action-cell">
                          <tr>
                            <td class="link__cell">or <a href="{{ shop.url }}">Visit our store</a></td>
                          </tr>
                        </table>
                    {% endif %}
                    
                        </td>
                      </tr>
                    </table>
                    
                    
                                </td>
                              </tr>
                            </table>
                          </center>
                        </td> 
                        {% endif %} 
                  
                              </td>
                          </tr>
                          </table>
            <tr>
              <td>
              {% if show %}
              <h3>A Special Invite</h3>
         
                <p style="text-align: left ;  padding: 16px">{{ email_body }}</p>
         
              {% endif %}
          
        </center>
      </td>
    </tr>
    <tr>
      <td class="section__cell">
        <center>
          <table class="container">
            <tr>
              <td>
                
              
  <table class="row">
  {% if item_count == 1 %}
  {% for line in subtotal_line_items %}
      <tr class="order-list__item" style="border: 0px solid #fff ">
      <td class="order-list__item__cell">
        <table  style="text-align: center; margin: auto;">
          <td>
            {% if line.product.title %}
              {% assign line_title = line.product.title %}
            {% else %}
              {% assign line_title = line.title %}
            {% endif %}
  
            {% if line.quantity < line.quantity %}
              {% capture line_display %} {{ line.quantity }} of {{ line.quantity }} {% endcapture %}
            {% else %}
              {% assign line_display = line.quantity  %}
            {% endif %}
  
            <span class="order-list__item-title">{{ line_title }} x {{ line_display }}</span><br/>
            <span class="order-list__item-variant">{{ line.price | money_with_currency }}</span><br/>
            {% if line.image %}
              <img src="{{ line | img_url: 'compact_cropped' }}" style="border-radius: 0px;" align="left" width="150" height="150" class="order-list__product-image"/><br/>
            {% endif %}
            {% if show %}
            <span><b>Make an offer!</b></span><br/><br/>
            <a href={{offer_url}} target="_blank"><div style="height: 28px; width:128px !important;color: #ffffff; background: #35B2FF; box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); padding:0px;"><img style=" vertical-align: text-top ; width: 130px  !important; " width="130" src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferWhiteButton.png"></div></a>
            {% endif %}
            {% if line.refunded_quantity > 0 %}
              <span class="order-list__item-refunded">Refunded</span>
            {% endif %}
  
            {% if line.discount_allocations %}
              {% for discount_allocation in line.discount_allocations %}
                {% if discount_allocation.discount_application.target_selection != 'all' %}
                  <span class="order-list__item-discount-allocation">
                    <img src="{{ 'notifications/discounttag.png' | shopify_asset_url }}" width="18" height="18" class="discount-tag-icon" />
                    <span>
                      {{ discount_allocation.discount_application.title | upcase }}
                      (-{{ discount_allocation.amount | money }})
                    </span>
                  </span>
                {% endif %}
              {% endfor %}
            {% endif %}
          </td>
        </table>
      </td>
    </tr>{% endfor %}
    {% else %}
    {% for line in subtotal_line_items %}
    <tr class="order-list__item" style="border: 0px solid #fff ">
      <td class="order-list__item__cell">
        <table>
          <td>
            {% if line.image %}
              <img src="{{ line | img_url: 'compact_cropped' }}" style="border-radius: 0px;" align="left" width="150" height="150" class="order-list__product-image"/>
            {% endif %}
          </td>
          <td class="order-list__product-description-cell">
            {% if line.product.title %}
              {% assign line_title = line.product.title %}
            {% else %}
              {% assign line_title = line.title %}
            {% endif %}
  
            {% if line.quantity < line.quantity %}
              {% capture line_display %} {{ line.quantity }} of {{ line.quantity }} {% endcapture %}
            {% else %}
              {% assign line_display = line.quantity  %}
            {% endif %}
  
            <span class="order-list__item-title">{{ line_title }} x {{ line_display }}</span><br/>
            <span class="order-list__item-variant">{{ line.price | money_with_currency }}</span><br/>
  
            {% if line.variant.id != 'Default Title' %}
              <span class="order-list__item-variant">{{ line.variant.id }}</span><br/>
            {% endif %}
            {% if show %}
            <span><b>Make an offer!</b></span><br/><br/>
            <a href={{offer_url}} target="_blank"><div style="height: 28px; width:128px !important;color: #ffffff; background: #35B2FF; box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); padding:0px;"><img style=" vertical-align: text-top ;" width="130" src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferWhiteButton.png"></div></a>
            {% endif %}
            {% if line.refunded_quantity > 0 %}
              <span class="order-list__item-refunded">Refunded</span>
            {% endif %}
  
            {% if line.discount_allocations %}
              {% for discount_allocation in line.discount_allocations %}
                {% if discount_allocation.discount_application.target_selection != 'all' %}
                  <span class="order-list__item-discount-allocation">
                    <img src="{{ 'notifications/discounttag.png' | shopify_asset_url }}" width="18" height="18" class="discount-tag-icon" />
                    <span>
                      {{ discount_allocation.discount_application.title | upcase }}
                      (-{{ discount_allocation.amount | money }})
                    </span>
                  </span>
                {% endif %}
              {% endfor %}
            {% endif %}
          </td>
        </table>
      </td>
    </tr>{% endfor %}
    {% endif %}
  </table>
              </td>
            </tr>
          </table>
        </center>
      </td>
    </tr>
    <tr>
      <td class="footer__cell">
      <center>
              {% if show %}
          <table class="container">
          <tr>
              <td style="text-align: center;">
    <div style="background-color: #f6f6f8; height: 20px;position: absolute; right: 0; left: 0;"></div><br><br>
    <h2><b>How it Works</b></h2>
         
    <table border="0" cellpadding="0" cellspacing="0">
      <tr>
          <td align="center" valign="top" width="33%">
              <table border="0" cellpadding="10" cellspacing="0" width="100%">
                  <tr>
                      <td valign="top" style="text-align: center;">
                          <h3>1</h3>
                          Click the EnterOffer button above
                      </td>
                  </tr>
              </table>
          </td>
          <td align="center" valign="top" width="33%">
              <table border="0" cellpadding="10" cellspacing="0" width="100%">
                  <tr>
                      <td valign="top" style="text-align: center;">
                          <h3>2</h3>
                          Submit your offer
                      </td>
                  </tr>
              </table>
          </td>
          <td align="center" valign="top" width="33%">
              <table border="0" cellpadding="10" cellspacing="0" width="100%">
                  <tr>
                      <td valign="top" style="text-align: center;">
                          <h3>3</h3>
                          We receive the offer and if we accept it, the item is yours at your price!
                      </td>
                  </tr>
              </table>
          </td>
      </tr>
  </table>
    <div style="margin: 8px 0px; background-color: #f6f6f8; height: 2px;position: absolute; right: 0; left: 0;"></div><br>
    <div style="text-align: center;">Visit <a href="https://enteroffer.com/">enteroffer.com</a> for more help</div><br>
  <div style="padding: 12px;background-color: #f6f6f8; position: absolute; right: 0; left: 0;"><p><b>{{shop.name}}</b></p><p>{{shop.address.summary}}</p><p><a href="{{ unsubscribe_url }}">Unsubscribe</a></p><p>This email was sent to <u>{{customer.email}}</u> </p><p>You are receiving this email because you have previously opted in to receive emails from us.</p></div><br>
              </td>
          </tr>
          </table>
          {% endif %}
      </center>
      </td>
      </tr>
  </table>
            <img src="{{ 'notifications/spacer.png' | shopify_asset_url }}" class="spacer" height="1" />
          </td>
        </tr>
      </table>
    </body>
  </html>`;

	const handleSnack = () => {
		setOpenSnack(!openSnack);
	};

	return (
		<Paper className={classes.root} elevation={0}>
			<h2 style={{ textAlign: "center" }}>Cart Abandonment Template</h2>
			<br />
			<h3 style={{ margin: 0 }}>
				Copy the cart abandonment template below and paste it in the 'Email Body' area of your{" "}
				<a href={url} target='_blank' rel='noopener noreferrer' style={{ color: "#35b2ff" }}>
					Shopify cart abandoment
				</a>
			</h3>
			<br />
			<br />
			<p style={{ wordWrap: "break-word", color: "#929292", margin: 0 }}>
				Your cart abandoment template
				<span style={{ display: "inline", float: "right" }} onClick={handleSnack}>
					<Tooltip title='Copy'>
						<CopyToClipboard text={html}>
							<Button>
								<FileCopyIcon />
							</Button>
						</CopyToClipboard>
					</Tooltip>
				</span>
			</p>
			<br />
			<div
				style={{
					width: "100%"
				}}>
				<CopyToClipboard text={html}>
					<TextField
						rows='6'
						multiline
						onClick={handleSnack}
						InputProps={{
							readOnly: true,
							style: { fontSize: "0.8em" }
						}}
						variant='outlined'
						style={{
							cursor: "pointer",
							width: "100%",
							minWidth: "100%",
							maxWidth: "100%",
							borderRadius: "5px",
							border: "1px solid #f6f6f8",
							backgroundColor: "#f6f6f8"
						}}
						defaultValue={html}
					/>
				</CopyToClipboard>
			</div>

			<div>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={openSnack}
					onClose={handleSnack}
					autoHideDuration={3000}
					message={<span id='message-id'>Template has been copied.</span>}
				/>
			</div>
		</Paper>
	);
}

export default withSizes(mapSizesToProps)(EmailTemplate);
