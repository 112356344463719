import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import {
	PieChart,
	BarChart,
	PopularProduct,
	AvgOrderVal,
	SuggestedPrice,
	OffersData,
	ScatterChart,
} from "../components";
import Skeleton from "react-loading-skeleton";

const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: "25px 8px",
		textAlign: "center",
		color: theme.palette.text.secondary,
		boxShadow: "none",
		borderRadius: "10px",
	},
});

Graphs.propTypes = {
	classes: PropTypes.object.isRequired,
};

function Graphs(props) {
	const { classes, isMobile, refresh } = props;

	return (
		<div className={classes.root}>
			<Grid container spacing={2}>
				{isMobile ? (
					<Grid item xs={12} sm={8}>
						<Paper className={classes.paper}>
							{refresh ? (
								<div style={{ textAlign: "left" }}>
									<Skeleton height={"25px"} width={200} />
									<Skeleton height={"225px"} />
								</div>
							) : (
								<OffersData isMobile={isMobile} />
							)}
						</Paper>
					</Grid>
				) : null}
				<Grid item xs={12} sm={4}>
					<Paper
						className={classes.paper}
						style={{
							height: isMobile ? null : "400px",
						}}
					>
						{refresh ? (
							<div style={{ textAlign: "left" }}>
								<Skeleton height={props.isMobile ? null : "25px"} width={200} />
								<Skeleton height={props.isMobile ? null : "375px"} />
							</div>
						) : (
							<PopularProduct isMobile={isMobile} />
						)}
					</Paper>
				</Grid>
				<Grid item xs={12} sm={8}>
					{refresh ? (
						<Paper
							className={classes.paper}
							style={{
								display: isMobile ? null : "inline-flex",
								width: "-webkit-fill-available",
								height: isMobile ? null : "400px",
								textAlign: "left",
							}}
						>
							<div style={{ textAlign: "left", width: "100%" }}>
								<Skeleton height={props.isMobile ? null : "25px"} width={200} />
								<Skeleton height={props.isMobile ? null : "375px"} />
							</div>
						</Paper>
					) : (
						<Paper
							className={classes.paper}
							style={{
								display: isMobile ? null : "inline-flex",
								width: "-webkit-fill-available",
								height: isMobile ? null : "400px",
							}}
						>
							<PieChart isMobile={isMobile} />
							<BarChart isMobile={isMobile} />
						</Paper>
					)}
				</Grid>
				<Grid item xs={12} sm={4}>
					<Paper
						className={classes.paper}
						style={{
							height: isMobile ? null : "125px",
							marginBottom: "16px",
						}}
					>
						{refresh ? (
							<div style={{ textAlign: "left" }}>
								<Skeleton height={props.isMobile ? null : "25px"} width={200} />
								<Skeleton height={props.isMobile ? null : "100px"} />
							</div>
						) : (
							<AvgOrderVal isMobile={isMobile} />
						)}
					</Paper>
					<Paper
						className={classes.paper}
						style={{
							minHeight: isMobile ? null : "125px",
						}}
					>
						{refresh ? (
							<div style={{ textAlign: "left", width: "100%" }}>
								<Skeleton height={props.isMobile ? null : "25px"} width={200} />
								<Skeleton height={props.isMobile ? null : "100px"} />
							</div>
						) : (
							<SuggestedPrice isMobile={isMobile} />
						)}
					</Paper>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Paper
						className={classes.paper}
						style={{
							display: isMobile ? null : "inline-flex",
							width: "-webkit-fill-available",
							height: isMobile ? null : "316px",
						}}
					>
						{refresh ? (
							<div style={{ textAlign: "left", width: "100%" }}>
								<Skeleton height={props.isMobile ? null : "25px"} width={200} />
								<Skeleton height={props.isMobile ? null : "290px"} />
							</div>
						) : (
							<ScatterChart isMobile={isMobile} />
						)}
					</Paper>
				</Grid>
			</Grid>
			<br />
		</div>
	);
}

export default withStyles(styles)(Graphs);
