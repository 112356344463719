import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MediaQuery from "react-responsive";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import _ from "lodash";
import { SignUpPending } from "../components";
import { DateTimePicker } from "material-ui-pickers";
import { ConnectWithStripe } from "../assets";
import { db, fb, authBase } from "../firebase";
import Copy from "@material-ui/icons/FileCopy";
import moment from "moment";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";

var productObjects = [];
var liveTesting = false;
const styles = theme => ({
	root: {
		width: "100%"
	},
	typography: {
		fontFamily: '"Nunito Sans", sans-serif'
	},
	button: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		color: "#35B2FF",
		fontWeight: 600,
		boxShadow: "none",
		position: "absolute",
		right: "0",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	backButton: {
		margin: theme.spacing(1),
		fontWeight: 600,
		position: "absolute",
		left: "0"
	},
	productSelector: {
		background: "red",
		color: "#35B2FF"
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		background: "red",
		color: "#35B2FF"
	}
});

function getSteps() {
	// return ['Set a campaign name', 'Select product', 'Select start and end dates', 'Set reserve price (Optional)', 'Confirmation', 'Done!'];
	return ["Set a campaign name", "Select product", "Select start and end dates", "Confirmation", "Done!"];
}

function getProductNames(storeName) {
	db.getProducts(storeName)
		.then(products => {
			for (let i of products) {
				productObjects.push(i);
			}
			return;
		})
		.catch(e => {
			console.log(e);
		});
	return;
}

function validateCampaignName(campaignName) {
	if (campaignName === "") {
		return false;
	}
	return true;
}

function validateProductName(productName) {
	if (productName === "") {
		return false;
	}
	if (productName === "Select...") {
		return false;
	}
	return true;
}

//nowDate is the latest moment time object generated when user clicks "start now"
function validateDates(startDate, endDate, nowDate) {
	if (startDate === "" || endDate === "") {
		return 1;
	} else if (endDate.isSameOrBefore(startDate)) {
		return 2;
	} else if (startDate.isBefore(nowDate)) {
		return 3;
	}
	return 0;
}

class Campaigns extends Component {
	state = {
		hasConnectedStripeAccount: false,
		productsAndRules: [],
		queryString: "",
		loading: true,
		productLoading: false,
		collections: [],
		isExpanded: false,
		selectedCollection: "Select a Collection",
		selectedCollectionTitle: "",
		selectedCollectionID: "",
		isCollectionSelected: false,
		pages: "",
		storeName: "",
		total: 20,
		display: 7,
		number: 7,
		activeStep: 0,
		completed: {},
		setActiveStep: 0,
		campaignName: "",
		productName: "Select...",
		productId: "",
		productSelected: "Select...",
		productSelectedImage: "",
		startDate: "",
		endDate: "",
		campaignUrl: "",
		campaignMessage: "Example: Ivy Jacket 28/03/19",
		productSelectMessage: "Type to search",
		snackBarOpen: false,
		snackBarCopyOpen: false,
		errorMessage: "",
		timeNow: "",
		redirect: false,
		reservePrice: "",
		completeSteper: false,
		begin: false
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				// bind stripeUserId to state
				getProductNames(snapshot);
				authBase.syncState(`stores/${snapshot}/stripe/stripeUserId`, {
					context: this,
					state: "stripeUserId",
					defaultValue: null,
					then: () => {
						//when it is done, check whether the url and the stripeUserId suggest we have just come from callback response
						let urlParams = queryString.parse(this.props.location.search);
						if (urlParams.scope != null && urlParams.code != null && this.state.stripeUserId == null) {
							//we have received successful connection, need to query stripe to get the users details through server
							var urlToFetch = `https://us-central1-oaklas-bookmark-tool.cloudfunctions.net/addStripeUserToDb?storeName=${this.state.storeName}&code=${urlParams.code}`;
							if (liveTesting) {
								urlToFetch = `https://us-central1-oaklas-retailer-dev.cloudfunctions.net/addStripeUserToDb?storeName=${this.state.storeName}&code=${urlParams.code}`;
							}
							fetch(urlToFetch)
								.then(() => console.log("success"))
								.catch(e => console.error(e));
						}
					}
				});
				return fb.getCollections(snapshot);
			})
			.then(collectionsList => {
				if (!collectionsList) {
					collectionsList = [];
				}
				this.setState({ loading: false, collections: collectionsList });
				const date = moment();
				const tomDate = moment().add(1, "days");
				this.setState({ startDate: date, endDate: tomDate });
				return db.getUserStoreUrl(this.state.storeName);
			})
			.then(storeUrl => {
				this.setState({ storeUrl: storeUrl });
			})
			.catch(console.error);
	}

	handleConfirm() {
		this.setState({ completeSteper: true });
	}

	handleNext = () => {
		//Input validation
		//1st step: a campaign name must be entered
		switch (this.state.activeStep) {
			case 0:
				if (validateCampaignName(this.state.campaignName)) {
					this.setState({ activeStep: this.state.activeStep + 1 });
					if (this.state.snackBarOpen) {
						this.setState({ snackBarOpen: false });
					}
				} else {
					this.setState({ errorMessage: "Please give a campaign name!" });
					this.setState({ snackBarOpen: true });
				}
				break;
			case 1:
				if (validateProductName(this.state.productName)) {
					this.setState({ activeStep: this.state.activeStep + 1 });
				} else {
					this.setState({ errorMessage: "Please select a product!" });
					this.setState({ snackBarOpen: true });
				}
				break;
			case 2:
				var resultCode = validateDates(this.state.startDate, this.state.endDate, this.state.timeNow);
				if (resultCode === 0) {
					this.setState({ activeStep: this.state.activeStep + 1 });
				} else if (resultCode === 1) {
					this.setState({ errorMessage: "Please select dates" });
					this.setState({ snackBarOpen: true });
				} else if (resultCode === 2) {
					this.setState({
						errorMessage: "Please make sure end date is later than start date"
					});
					this.setState({ snackBarOpen: true });
				} else if (resultCode === 3) {
					this.setState({ errorMessage: "You cannot set a campaign in the past" });
					this.setState({ snackBarOpen: true });
				}
				break;
			case 3:
				// this.setState({activeStep: this.state.activeStep+1});
				// }break;
				// case 4:{
				const unixTimeStart = this.state.startDate.unix();
				const unixTimeEnd = this.state.endDate.unix();
				const currTime = moment().unix();
				var location = "future";
				if (unixTimeStart <= currTime && unixTimeEnd > currTime) {
					location = "active";
				}
				// if (this.state.reservePrice===''){
				//   console.log("reserve price is empty!");
				// }
				db.pushNewCampaign(
					this.state.storeName,
					this.state.campaignName,
					this.state.productName,
					this.state.productId,
					unixTimeStart,
					unixTimeEnd,
					this.state.reservePrice,
					location,
					this.state.productSelectedImage
				).then(result => {
					if (result === 0) {
						this.setState({ activeStep: this.state.activeStep + 1 });
					} else {
						this.setState({
							errorMessage: 'I cannot create a campaign now; please click "next" again later.'
						});
						this.setState({ snackBarOpen: true });
						//For testing purpose, it still goes to the next step but in real deployment the user should not be able to go to next step
						this.setState({ activeStep: this.state.activeStep + 1 });
					}
				});
				this.handleConfirm();
				break;
			case 4:
				//Will need to redirect the user to the list of campaigns created.
				this.setState({ redirect: true });
				break;
			default:
				console.log(this.state.activeStep);
		}
	};

	handleBack = () => {
		this.setState({ activeStep: this.state.activeStep - 1 });
	};

	handleReset = () => {
		this.setState({ activeStep: 0 });
	};

	handleBegin = () => {
		this.setState({ begin: true });
	};

	getProductsBack(collectionID) {
		let getCollectionsId;
		if (collectionID) {
			getCollectionsId = collectionID;
		} else {
			getCollectionsId = this.state.selectedCollectionID;
		}
		let productsAndRulesTest = [];
		this.setState({ productLoading: true });

		db.getProductsInCollection(this.state.storeName, getCollectionsId)
			.then(results => {
				for (let item of results) {
					productsAndRulesTest.push(item);
				}
				this.setState({ productsAndRules: productsAndRulesTest });
				this.setState({ productLoading: false });
				return;
			})
			.catch(e => console.log("error: ", e));
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChangeCollection = selectedOption => {
		this.setState({
			selectedCollection: selectedOption.value.title,
			productLoading: true,
			isExpanded: false,
			selectedCollectionTitle: selectedOption.value.title,
			isCollectionSelected: true,
			selectedCollectionID: selectedOption.value.id
		});
		this.setState({
			productName: "Select...",
			productId: "",
			productSelected: "Select...",
			productSelectedImage: ""
		});
		this.getProductsBack(selectedOption.value.id);
	};

	handleChangeProduct = selectedOption => {
		this.setState(
			{
				productName: selectedOption.value.productTitle,
				productId: selectedOption.value.productId,
				productSelected: selectedOption.value,
				productSelectedImage: selectedOption.value.image
			},
			() => {
				if (liveTesting) {
					this.setState({
						campaignUrl:
							"https://oaklas-website-dev.web.app/campaign/?store=" +
							this.state.storeName +
							"&id=" +
							this.state.productId
					});
				} else {
					this.setState({
						campaignUrl:
							"https://app.enteroffer.com/?s=" + this.state.storeName + "&p=" + this.state.productId
					});
				}
			}
		);
	};
	//date will be a moment object
	handleStartChange = date => {
		this.setState({ startDate: date });
	};

	handleEndChange = date => {
		this.setState({ endDate: date });
	};

	handleSnackBarClose = () => {
		this.setState({ snackBarOpen: false });
	};
	handleSnackBarCopyClose = () => {
		this.setState({ snackBarCopyOpen: false });
	};

	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="./view" />;
		}
	};

	handleChangeReservePrice = event => {
		this.setState({ reservePrice: event.target.value });
	};

	copyText = () => {
		const el = document.createElement("textarea");
		el.innerText = this.state.campaignUrl;
		console.log(el.innerText);
		el.setAttribute("readonly", "");
		el.style.position = "absolute";
		el.style.left = "-9999px";
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
		this.setState({ snackBarCopyOpen: true });
	};

	filterResults = () => {
		const { queryString, productsAndRules } = this.state;
		return queryString === ""
			? productsAndRules
			: _.filter(productsAndRules, x =>
					x.productTitle.toLowerCase().includes(this.state.queryString.toLowerCase())
			  );
	};

	makeOptions = () => {
		let options = [];
		this.filterResults().forEach((value, index) => {
			let product = {};
			product["label"] = value.productTitle;
			product["value"] = value;
			options.push(product);
		});

		return options;
	};
	makeCollectionOptions = () => {
		const { collections } = this.state;
		let collectionOptions = [];
		collections.forEach((value, index) => {
			let product = {};
			product["label"] = value.title;
			product["value"] = value;
			collectionOptions.push(product);
		});
		return collectionOptions;
	};
	getStepContent = stepIndex => {
		const classes = this.props;
		const { isCollectionSelected } = this.state;
		const products = this.filterResults();
		switch (stepIndex) {
			case 0:
				return (
					<div
						className="campaignName"
						style={{
							textAlign: "center",
							marginTop: "5%",
							width: "50%",
							marginLeft: "auto",
							marginRight: "auto"
						}}
					>
						<h1
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center"
							}}
						>
							Campaign Name
						</h1>
						<h3
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center",
								marginTop: "-1%",
								color: "rgba(0, 0, 0, 0.38)"
							}}
						>
							This is for your own records and will be stored in your EnterOffer dashboard. Customers will
							not be able to see this.
						</h3>
						<TextField
							id="outlined-name"
							placeholder={this.state.campaignMessage}
							value={this.state.campaignName}
							onChange={this.handleChange("campaignName")}
							autoComplete="off"
							margin="normal"
							style={{
								display: "inline-block",
								textAlign: "center",
								width: "100%",
								fontSize: "1.5em",
								backgroundColor: "#f6f6f8",
								borderRadius: "0.2em"
							}}
							required={true}
							InputProps={{
								disableUnderline: true,
								style: { fontSize: "1em", fontFamily: "Nunito Sans" }
							}}
						/>
					</div>
				);
			case 1:
				return (
					<div>
						<h1
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center"
							}}
						>
							Select Product
						</h1>
						<h3
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center",
								marginTop: "-1%",
								color: "rgba(0, 0, 0, 0.38)"
							}}
						>
							Choose a collection to search through products
						</h3>
						<Grid container spacing={2} style={{ width: "50%", margin: "0 auto" }}>
							<Grid item xs={12}>
								<Select
									value={{
										value: this.state.selectedCollection,
										label: this.state.selectedCollection
									}}
									onChange={this.handleChangeCollection}
									options={this.makeCollectionOptions()}
									style={{
										fontSize: "1.5em",
										backgroundColor: "#f6f6f8",
										borderRadius: "0.2em"
									}}
									styles={{
										...styles,
										control: (base, state) => ({
											...base,
											backgroundColor: "#f6f6f8",
											border: "none", // default border color
											boxShadow: "none",
											borderRadius: "0.2em",
											padding: "0.3em",
											width: "102%"
										})
									}}
								></Select>
							</Grid>
							{this.state.loading ? (
								<Lottie
									height={200}
									width={200}
									options={{
										loop: true,
										autoplay: true,
										animationData: loader.default,
										rendererSettings: {
											preserveAspectRatio: "xMidYMid slice"
										}
									}}
								/>
							) : (
								<Grid container justify="center" spacing={1}>
									{isCollectionSelected ? (
										<Grid
											item
											xs={12}
											style={{
												display: "block",
												marginBottom: "8%"
											}}
										></Grid>
									) : null}
								</Grid>
							)}
							{this.state.productLoading ? (
								<Lottie
									height={200}
									width={200}
									options={{
										loop: true,
										autoplay: true,
										animationData: loader.default,
										rendererSettings: {
											preserveAspectRatio: "xMidYMid slice"
										}
									}}
								/>
							) : isCollectionSelected === false && !this.state.loading ? (
								<br></br>
							) : products.length === 0 && isCollectionSelected === true ? (
								<Grid container justify="center" spacing={1}>
									<h2
										style={{
											fontFamily: '"Nunito Sans", sans-serif',
											fontWeight: "100",
											textAlign: "center",
											marginTop: "-3%"
										}}
									>
										No Products Found
									</h2>
								</Grid>
							) : (
								<Grid item xs={12} style={{ marginTop: "-10%" }}>
									<Select
										value={{
											value: this.state.productSelected,
											label: this.state.productName
										}}
										onChange={this.handleChangeProduct}
										options={this.makeOptions()}
										style={{
											fontSize: "1.5em",
											backgroundColor: "#f6f6f8",
											borderRadius: "0.2em"
										}}
										styles={{
											...styles,
											control: (base, state) => ({
												...base,
												backgroundColor: "#f6f6f8",
												border: "none", // default border color
												boxShadow: "none",
												borderRadius: "0.2em",
												padding: "0.3em",
												width: "102%"
											})
										}}
									></Select>
									<br></br>
									{this.state.productSelectedImage ? (
										<div style={{ textAlign: "center" }}>
											<img
												src={this.state.productSelectedImage}
												style={{
													objectFit: "cover",
													height: "16.25em",
													width: "11.25em",
													display: "inline-block",
													boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.1)",
													transition: "opacity 0.2s ease, box-shadow 0.2s ease",
													margin: "0em 3em 3em 3em "
												}}
												alt="productImg"
											/>
										</div>
									) : null}
								</Grid>
							)}
						</Grid>
					</div>
				);
			case 2:
				return (
					<div
						className="picker"
						style={{
							textAlign: "center",
							width: "50%",
							marginLeft: "auto",
							marginRight: "auto"
						}}
					>
						<h1
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center"
							}}
						>
							Start and End Dates
						</h1>
						<h3
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center",
								marginTop: "-1%",
								color: "rgba(0, 0, 0, 0.38)"
							}}
						>
							Choose how long the campaign will be valid for.
						</h3>
						<h3
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center",
								marginTop: "-1%",
								color: "rgba(0, 0, 0, 0.38)",
								fontSize: "15px",
								border: "1px solid #f3f3f5",
								padding: "0.625em 0.625em",
								borderRadius: "4px"
							}}
						>
							Clicking 'current time' will set campaign starting time to now and end in 24 hours.
						</h3>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<h3
									style={{
										textAlign: "center",
										color: "#7f7f7f",
										fontWeight: "200"
									}}
								>
									Start
								</h3>
								<DateTimePicker
									fullWidth
									value={this.state.startDate}
									onChange={this.handleStartChange}
									label=""
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										display: "block"
									}}
								/>
								<Button
									color="primary"
									onClick={() => {
										this.setState({ startDate: moment().add(15, "s") });
										this.setState({ endDate: moment().add(1, "days") });
										this.setState({ timeNow: moment() });
									}}
									style={{
										width: "100%",
										marginLeft: "auto",
										marginRight: "auto",
										marginTop: "5%"
									}}
								>
									Current Time
								</Button>
							</Grid>
							<Grid item xs={6}>
								<h3
									style={{
										textAlign: "center",
										color: "#7f7f7f",
										fontWeight: "200"
									}}
								>
									End
								</h3>
								<DateTimePicker
									fullWidth
									value={this.state.endDate}
									onChange={this.handleEndChange}
									label=""
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										display: "block"
									}}
								/>
							</Grid>
						</Grid>
					</div>
				);
			// case 3:
			//   return (
			//     <div style={{width: '50%', marginLeft: 'auto', marginRight: 'auto'}}>
			//       <h1 style={{
			//             fontFamily: '"Nunito Sans", sans-serif',
			//             fontWeight: '100',
			//             textAlign: 'center'
			//         }}>Reserve Price</h1>
			//         <h3 style={{
			//             fontFamily: '"Nunito Sans", sans-serif',
			//             fontWeight: '100',
			//             textAlign: 'center',
			//             marginTop: '-1%',
			//             color: 'rgba(0, 0, 0, 0.38)'
			//         }}>This is for your own records and will be stored in your EnterOffer dashboard. Customers will not be able to see this.</h3>
			//       <TextField
			//         id="standard-number"
			//         value={this.state.reservePrice}
			//         placeholder= "Enter reserve price (optional)..."
			//         onChange={this.handleChangeReservePrice}
			//         type="number"
			//         style={{width:'100%', textAlign: 'center',fontFamily: 'Nunito Sans', padding:'0.3em', fontSize:'1.5em',backgroundColor: '#f6f6f8', borderRadius: '0.2em'}}
			//         InputLabelProps={{
			//           shrink: true,
			//         }}
			//         InputProps={{disableUnderline: true, style: {fontFamily: 'Nunito Sans'}}}
			//         margin="normal"
			//       />
			//     </div>
			//   );
			case 3:
				return (
					<div style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}>
						<h1
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center"
							}}
						>
							Confirm your campaign
						</h1>
						<h3
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center",
								marginTop: "-1%",
								color: "rgba(0, 0, 0, 0.38)"
							}}
						>
							You won't be able to edit this campaign any further once it's created.
						</h3>
						<div>
							<Paper
								className={classes.root}
								elevation={0}
								square={true}
								style={{
									width: "100%",
									backgroundColor: "#f6f6f8",
									paddingTop: "0.5em",
									paddingBottom: "0.5em"
								}}
							>
								<Grid container justify="center" spacing={1}>
									<Grid item xs={4}>
										<span
											style={{
												fontSize: "0.8em",
												color: "rgba(0, 0, 0, 0.38)",
												fontWeight: "bold",
												marginLeft: "15%"
											}}
										>
											Campaign Name
										</span>
									</Grid>
									<Grid item xs={8}>
										<span style={{ fontSize: "1em", fontWeight: "bold" }}>
											{this.state.campaignName}
										</span>
									</Grid>
									<Grid item xs={4}>
										<span
											style={{
												fontSize: "0.8em",
												color: "rgba(0, 0, 0, 0.38)",
												fontWeight: "bold",
												marginLeft: "15%"
											}}
										>
											Product
										</span>
									</Grid>
									<Grid item xs={8}>
										<span style={{ fontSize: "1em", fontWeight: "bold" }}>
											{this.state.productName}
										</span>
									</Grid>
									<Grid item xs={4}>
										<span
											style={{
												fontSize: "0.8em",
												color: "rgba(0, 0, 0, 0.38)",
												fontWeight: "bold",
												marginLeft: "15%"
											}}
										>
											Start
										</span>
									</Grid>
									<Grid item xs={8}>
										<span style={{ fontSize: "1em" }}>
											{this.state.startDate.format("MMMM Do YYYY, h:mm:ss a")}
										</span>
									</Grid>
									<Grid item xs={4}>
										<span
											style={{
												fontSize: "0.8em",
												color: "rgba(0, 0, 0, 0.38)",
												fontWeight: "bold",
												marginLeft: "15%"
											}}
										>
											End
										</span>
									</Grid>
									<Grid item xs={8}>
										<span style={{ fontSize: "1em" }}>
											{this.state.endDate.format("MMMM Do YYYY, h:mm:ss a")}
										</span>
									</Grid>
								</Grid>
							</Paper>
							<br></br>
							<div style={{ textAlign: "center" }}>
								<img
									src={this.state.productSelectedImage}
									style={{
										objectFit: "cover",
										height: "16.25em",
										width: "11.25em",
										display: "inline-block",
										boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.1)",
										transition: "opacity 0.2s ease, box-shadow 0.2s ease",
										margin: "0em 3em 3em 3em "
									}}
									alt="productImg"
								/>
							</div>
						</div>
					</div>
				);
			case 4:
				return (
					<div
						className="url"
						style={{
							width: "50%",
							marginLeft: "auto",
							marginRight: "auto",
							marginTop: "5%"
						}}
					>
						<h1
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center"
							}}
						>
							Campaign Created!
						</h1>
						<h3
							style={{
								fontFamily: '"Nunito Sans", sans-serif',
								fontWeight: "100",
								textAlign: "center",
								marginTop: "-1%",
								color: "rgba(0, 0, 0, 0.38)"
							}}
						>
							Share this URL to your website, social media, or put it in an ad to allow customers to make
							an offer on this product.
						</h3>
						<Grid container justify="center" spacing={2}>
							<Grid item xs={10}>
								<Typography
									id="standard-read-only-input"
									label="Your campaign url"
									margin="normal"
									style={{ fontSize: "1.12em" }}
								>
									{this.state.campaignUrl}
								</Typography>
							</Grid>
							<Grid item xs={1}>
								<Button
									onClick={this.copyText}
									style={{
										marginLeft: "",
										background: "#f6f6f8",
										color: "#35B2FF",
										boxShadow: "none",
										float: "center"
									}}
								>
									<Copy />
								</Button>
							</Grid>
						</Grid>
					</div>
				);
			default:
				return "Uknown stepIndex";
		}
	};

	render() {
		const { classes } = this.props;
		const state = this.state;
		const steps = getSteps();

		if (state.loading) {
			return (
				<div style={{ display: "block" }}>
					<Lottie
						height={200}
						width={200}
						className={classes.progress}
						options={{
							loop: true,
							autoplay: true,
							animationData: loader.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice"
							}
						}}
					/>
				</div>
			);
		}

		let urlParams = queryString.parse(this.props.location.search);

		if (this.state.begin === false) {
			const demoUrl = "https://app.enteroffer.com/demo";
			return (
				<div>
					<MediaQuery query="(min-width: 600px)">
						<div
							className="campaignName"
							style={{
								textAlign: "center",
								marginTop: "5%",
								width: "50%",
								marginLeft: "auto",
								marginRight: "auto"
							}}
						>
							<h1 style={{ textAlign: "center" }}>
								<span
									style={{
										color: "#35B2FF",
										fontWeight: 900
									}}
								>
									CAMPAIGN
								</span>
								<span style={{ fontWeight: 900 }}> CREATOR</span>
							</h1>
							<h3
								style={{
									fontFamily: '"Nunito Sans", sans-serif',
									fontWeight: "400",
									textAlign: "center",
									color: "rgba(0, 0, 0, 0.38)",
									fontSize: "1em",
									backgroundColor: "#f6f6f8",
									padding: "0.625em 0.625em",
									borderRadius: "4px"
								}}
							>
								Create a URL you can share to your website, social media, or put into an ad to allow
								your customers to make offers on a particular product.
								<br></br>
								<br></br>Offers accepted by you from your EnterOffer Dashboard will be paid into your
								Stripe account and the order will be process in your eCommerce hosting platform.
							</h3>
							<p
								style={{
									textAlign: "center",
									color: "rgba(0, 0, 0, 0.38)",
									marginBottom: "-1.5%",
									marginTop: "4%"
								}}
							>
								Click link below for a <strong>demo</strong> of what your cutomers will see when
								clicking the URL
							</p>
							<p
								style={{
									fontFamily: '"Nunito Sans", sans-serif',
									fontWeight: "300",
									fontSize: "1.1em",
									border: "1px solid #f6f6f8",
									textAlign: "center",
									padding: "0.625em 0.625em",
									borderRadius: "8px"
								}}
							>
								<a
									href={"https://app.enteroffer.com/?demo=true&color=35B2FF#Cart"}
									target="_blank"
									rel="noopener noreferrer"
								>
									{demoUrl}
								</a>
							</p>
							<Button
								variant="contained"
								className={classes.button}
								style={{ width: "15%", marginLeft: "42.5%", marginRight: "42.5%" }}
								onClick={this.handleBegin}
							>
								<strong>Begin</strong>
							</Button>
						</div>
					</MediaQuery>
					<MediaQuery query="(max-width: 600px)">
						<div
							className="campaignName"
							style={{
								textAlign: "center",
								marginTop: "5%",
								width: "85%",
								marginLeft: "auto",
								marginRight: "auto"
							}}
						>
							<h1 style={{ textAlign: "center" }}>
								<span
									style={{
										color: "#35B2FF",
										fontWeight: 900
									}}
								>
									CAMPAIGN
								</span>
								<span style={{ fontWeight: 900 }}> CREATOR</span>
							</h1>
							<h3
								style={{
									fontFamily: '"Nunito Sans", sans-serif',
									fontWeight: "400",
									textAlign: "center",
									color: "rgba(0, 0, 0, 0.38)",
									fontSize: "1em",
									backgroundColor: "#f6f6f8",
									padding: "0.625em 0.625em",
									borderRadius: "4px"
								}}
							>
								Create a URL you can share to your website, social media, or put into an ad to allow
								your customers to make offers on a particular product.
								<br></br>
								<br></br>Offers accepted by you from your EnterOffer Dashboard will be paid into your
								Stripe account and the order will be process in your eCommerce hosting platform.
							</h3>
							<p
								style={{
									textAlign: "center",
									color: "rgba(0, 0, 0, 0.38)",
									marginBottom: "-1.5%",
									marginTop: "4%"
								}}
							>
								Click link below for a <strong>demo</strong> of what your cutomers will see when
								clicking the URL
							</p>
							<p
								style={{
									fontFamily: '"Nunito Sans", sans-serif',
									fontWeight: "300",
									fontSize: "1.1em",
									border: "1px solid #f6f6f8",
									textAlign: "center",
									padding: "0.625em 0.625em",
									borderRadius: "8px"
								}}
							>
								<a
									href={"https://app.enteroffer.com/?demo=true&color=35B2FF#Cart"}
									target="_blank"
									rel="noopener noreferrer"
								>
									{demoUrl}
								</a>
							</p>
							<Button
								variant="contained"
								className={classes.button}
								style={{ width: "15%", marginLeft: "42.5%", marginRight: "42.5%" }}
								onClick={this.handleBegin}
							>
								<strong>Begin</strong>
							</Button>
						</div>
					</MediaQuery>
				</div>
			);
		}
		//const clientId = "ca_EijC5IP4ivnPrQJJMIbXeh20NrRiFseG" //test
		const clientId = "ca_EijCRxtaeHMkm2tRVkNdR2Mb2ZmuFahK"; //live
		const stripeRedirect = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write`;
		if (this.state.storeName !== null && this.state.stripeUserId !== null && this.state.begin === true) {
			return (
				<div className={classes.root}>
					{this.renderRedirect()}
					<Snackbar
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						open={this.state.snackBarOpen}
						onClose={this.handleSnackBarClose}
						autoHideDuration={3000}
						ContentProps={{
							"aria-describedby": "message-id"
						}}
						message={<span id="message-id">{this.state.errorMessage}</span>}
					/>

					<Stepper activeStep={this.state.activeStep} alternativeLabel>
						{steps.map((label, index) =>
							index < 4 ? (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							) : (
								<Step key={label}>
									<StepLabel completed={this.state.completeSteper}>{label}</StepLabel>
								</Step>
							)
						)}
					</Stepper>
					<div>
						{this.state.activeStep === steps.length ? (
							<div>
								<Typography className={classes.instructions}>All steps completed</Typography>
								<Button onClick={this.handleReset}>Reset</Button>
							</div>
						) : (
							<div>
								<div style={{ position: "relative" }}>
									{this.getStepContent(this.state.activeStep)}
									<MediaQuery query="(min-width: 600px)">
										<div
											className={classes.buttons}
											style={{
												width: "46%",
												position: "fixed",
												top: "75%",
												display: "block",
												left: "27%",
												right: "27%"
											}}
										>
											{this.state.activeStep === steps.length - 2 ? (
												<div>
													<Button
														disabled={this.state.activeStep === 0}
														onClick={this.handleBack}
														className={classes.backButton}
													>
														Back
													</Button>
													<Button
														variant="contained"
														className={classes.button}
														onClick={this.handleNext}
													>
														Confirm
													</Button>
												</div>
											) : (
												[
													this.state.activeStep === steps.length - 1 ? (
														<Button
															variant="contained"
															className={classes.button}
															style={{
																width: "30%",
																marginLeft: "35%",
																marginRight: "35%"
															}}
															onClick={this.handleNext}
														>
															Done
														</Button>
													) : (
														<div>
															<Button
																disabled={this.state.activeStep === 0}
																onClick={this.handleBack}
																className={classes.backButton}
															>
																Back
															</Button>
															<Button
																variant="contained"
																className={classes.button}
																onClick={this.handleNext}
															>
																Next
															</Button>
														</div>
													)
												]
											)}
										</div>
									</MediaQuery>
									<MediaQuery query="(max-width: 600px)">
										<div
											className={classes.buttons}
											style={{
												width: "46%",
												position: "absolute",
												top: "100%",
												display: "block",
												left: "27%",
												right: "27%"
											}}
										>
											{this.state.activeStep === steps.length - 2 ? (
												<div>
													<Button
														disabled={this.state.activeStep === 0}
														onClick={this.handleBack}
														className={classes.backButton}
													>
														Back
													</Button>
													<Button
														variant="contained"
														className={classes.button}
														onClick={this.handleNext}
													>
														Confirm
													</Button>
												</div>
											) : (
												[
													this.state.activeStep === steps.length - 1 ? (
														<Button
															variant="contained"
															className={classes.button}
															style={{
																width: "30%",
																marginLeft: "35%",
																marginRight: "35%"
															}}
															onClick={this.handleNext}
														>
															Done
														</Button>
													) : (
														<div>
															<Button
																disabled={this.state.activeStep === 0}
																onClick={this.handleBack}
																className={classes.backButton}
															>
																Back
															</Button>
															<Button
																variant="contained"
																className={classes.button}
																onClick={this.handleNext}
															>
																Next
															</Button>
														</div>
													)
												]
											)}
										</div>
									</MediaQuery>
								</div>
							</div>
						)}
					</div>
					<Snackbar
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						open={this.state.snackBarCopyOpen}
						onClose={this.handleSnackBarCopyClose}
						autoHideDuration={3000}
						ContentProps={{
							"aria-describedby": "message-id"
						}}
						message={<span id="message-id">Copied to clipboard</span>}
					/>
				</div>
			);
		} else if (this.state.stripeUserId === null) {
			return (
				<div style={{ textAlign: "center", marginTop: "120px" }}>
					<p
						style={{
							color: "#3e3e3e",
							marginBottom: "8px",
							fontSize: "1.2em",
							fontWeight: "700"
						}}
					>
						You dont appear to have stripe connected.
					</p>
					<p style={{ color: "#7f7f7f", marginTop: "8px", marginBottom: "30px" }}>
						To ensure we have an account to pay into, please
					</p>
					<a href={stripeRedirect}>
						<img style={{ width: "220px" }} alt="stripe" src={ConnectWithStripe} />
					</a>
					{urlParams.error ? <p style={{ color: "red" }}>{urlParams.error_description}</p> : null}
				</div>
			);
		} else {
			return <SignUpPending />;
		}
	}
}
export default withRouter(withStyles(styles)(Campaigns));
