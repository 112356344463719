import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import { db } from "../firebase";
import _ from "lodash";
import { TwitterPicker } from "react-color";

const styles = theme => ({});

const colors = [
	"#000000",
	"#FF6900",
	"#FCB900",
	"#00D084",
	"#8ED1FC",
	"#35B2FF",
	"#F6F6F8",
	"#EB144C",
	"#F78DA7",
	"#9900EF"
];

const currencies = [
	{
		symbol: "AU$",
		name: "Australian Dollar",
		symbol_native: "$",
		code: "AUD",
		emoji: "🇦🇺"
	},
	{
		symbol: "$",
		name: "US Dollar",
		symbol_native: "$",
		code: "USD",
		emoji: "🇺🇸"
	},
	{
		symbol: "CA$",
		name: "Canadian Dollar",
		symbol_native: "$",
		code: "CAD",
		emoji: "🇨🇦"
	},
	{
		symbol: "€",
		name: "Euro",
		symbol_native: "€",
		code: "EUR",
		emoji: "🇪🇺"
	},
	{
		symbol: "AED",
		name: "United Arab Emirates Dirham",
		symbol_native: "د.إ.‏",
		code: "AED",
		emoji: "🇦🇪"
	},
	{
		symbol: "Af",
		name: "Afghan Afghani",
		symbol_native: "؋",
		code: "AFN",
		emoji: "🇦🇫"
	},
	{
		symbol: "ALL",
		name: "Albanian Lek",
		symbol_native: "Lek",
		code: "ALL",
		emoji: "🇦🇱"
	},
	{
		symbol: "AMD",
		name: "Armenian Dram",
		symbol_native: "դր.",
		code: "AMD",
		emoji: "🇦🇲"
	},
	{
		symbol: "ƒ",
		name: "Netherlands Antillean guilder",
		symbol_native: "ƒ",
		code: "ANG",
		emoji: "🇧🇶"
	},
	{
		symbol: "Kz",
		name: "Angolan Kwanza",
		symbol_native: "Kz",
		code: "AOA",
		emoji: "🇦🇴"
	},
	{
		symbol: "AR$",
		name: "Argentine Peso",
		symbol_native: "$",
		code: "ARS",
		emoji: "🇦🇷"
	},
	{
		symbol: "man.",
		name: "Azerbaijani Manat",
		symbol_native: "ман.",
		code: "AZN",
		emoji: "🇦🇿"
	},
	{
		symbol: "KM",
		name: "Bosnia-Herzegovina Convertible Mark",
		symbol_native: "KM",
		code: "BAM",
		emoji: "🇧🇦"
	},
	{
		symbol: "Tk",
		name: "Bangladeshi Taka",
		symbol_native: "৳",
		code: "BDT",
		emoji: "🇧🇩"
	},
	{
		symbol: "BGN",
		name: "Bulgarian Lev",
		symbol_native: "лв.",
		code: "BGN",
		emoji: "🇧🇬"
	},
	{
		symbol: "BD",
		name: "Bahraini Dinar",
		symbol_native: "د.ب.‏",
		code: "BHD",
		emoji: "🇧🇭"
	},
	{
		symbol: "FBu",
		name: "Burundian Franc",
		symbol_native: "FBu",
		code: "BIF",
		emoji: "🇧🇮"
	},
	{
		symbol: "BN$",
		name: "Brunei Dollar",
		symbol_native: "$",
		code: "BND",
		emoji: "🇧🇳"
	},
	{
		symbol: "$",
		name: "Bermudian Dollar",
		symbol_native: "$",
		code: "BMD",
		emoji: "🇧🇲"
	},
	{
		symbol: "Bs",
		name: "Bolivian Boliviano",
		symbol_native: "Bs",
		code: "BOB",
		emoji: "🇧🇴"
	},
	{
		symbol: "R$",
		name: "Brazilian Real",
		symbol_native: "R$",
		code: "BRL",
		emoji: "🇧🇷"
	},
	{
		symbol: "Nu.",
		name: "Bhutanese Ngultrum",
		symbol_native: "Nu.",
		code: "BTN",
		emoji: "🇧🇹"
	},
	{
		symbol: "BWP",
		name: "Botswanan Pula",
		symbol_native: "P",
		code: "BWP",
		emoji: "🇧🇼"
	},
	{
		symbol: "BYR",
		name: "Belarusian Ruble",
		symbol_native: "BYR",
		code: "BYR",
		emoji: "🇧🇾"
	},
	{
		symbol: "BZ$",
		name: "Belize Dollar",
		symbol_native: "$",
		code: "BZD",
		emoji: "🇧🇿"
	},
	{
		symbol: "CDF",
		name: "Congolese Franc",
		symbol_native: "FrCD",
		code: "CDF",
		emoji: "🇨🇩"
	},
	{
		symbol: "CHF",
		name: "Swiss Franc",
		symbol_native: "CHF",
		code: "CHF",
		emoji: "🇨🇭"
	},
	{
		symbol: "CL$",
		name: "Chilean Peso",
		symbol_native: "$",
		code: "CLP",
		emoji: "🇨🇱"
	},
	{
		symbol: "CN¥",
		name: "Chinese Yuan",
		symbol_native: "CN¥",
		code: "CNY",
		emoji: "🇨🇳"
	},
	{
		symbol: "CO$",
		name: "Colombian Peso",
		symbol_native: "$",
		code: "COP",
		emoji: "🇨🇴"
	},
	{
		symbol: "₡",
		name: "Costa Rican Colón",
		symbol_native: "₡",
		code: "CRC",
		emoji: "🇨🇷"
	},
	{
		symbol: "CV$",
		name: "Cape Verdean Escudo",
		symbol_native: "CV$",
		code: "CVE",
		emoji: "🇨🇻"
	},
	{
		symbol: "Kč",
		name: "Czech Republic Koruna",
		symbol_native: "Kč",
		code: "CZK",
		emoji: "🇨🇿"
	},
	{
		symbol: "Fdj",
		name: "Djiboutian Franc",
		symbol_native: "Fdj",
		code: "DJF",
		emoji: "🇩🇯"
	},
	{
		symbol: "Dkr",
		name: "Danish Krone",
		symbol_native: "kr",
		code: "DKK",
		emoji: "🇩🇰"
	},
	{
		symbol: "RD$",
		name: "Dominican Peso",
		symbol_native: "RD$",
		code: "DOP",
		emoji: "🇩🇴"
	},
	{
		symbol: "DA",
		name: "Algerian Dinar",
		symbol_native: "د.ج.‏",
		code: "DZD",
		emoji: "🇩🇿"
	},
	{
		symbol: "Ekr",
		name: "Estonian Kroon",
		symbol_native: "kr",
		code: "EEK",
		emoji: "🇪🇪"
	},
	{
		symbol: "EGP",
		name: "Egyptian Pound",
		symbol_native: "ج.م.‏",
		code: "EGP",
		emoji: "🇪🇬"
	},
	{
		symbol: "Nfk",
		name: "Eritrean Nakfa",
		symbol_native: "Nfk",
		code: "ERN",
		emoji: "🇪🇷"
	},
	{
		symbol: "Br",
		name: "Ethiopian Birr",
		symbol_native: "Br",
		code: "ETB",
		emoji: "🇪🇹"
	},
	{
		symbol: "£",
		name: "Falkland Island Pound",
		symbol_native: "£",
		code: "FKP",
		emoji: "🇫🇰"
	},
	{
		symbol: "£",
		name: "British Pound Sterling",
		symbol_native: "£",
		code: "GBP",
		emoji: "🇬🇧"
	},
	{
		symbol: "GEL",
		name: "Georgian Lari",
		symbol_native: "GEL",
		code: "GEL",
		emoji: "🇬🇪"
	},
	{
		symbol: "GH₵",
		name: "Ghanaian Cedi",
		symbol_native: "GH₵",
		code: "GHS",
		emoji: "🇬🇭"
	},
	{
		symbol: "£",
		name: "Gibraltar Pound",
		symbol_native: "£",
		code: "GIP",
		emoji: "🇬🇮"
	},
	{
		symbol: "FG",
		name: "Guinean Franc",
		symbol_native: "FG",
		code: "GNF",
		emoji: "🇬🇳"
	},
	{
		symbol: "GTQ",
		name: "Guatemalan Quetzal",
		symbol_native: "Q",
		code: "GTQ",
		emoji: "🇬🇹"
	},
	{
		symbol: "HK$",
		name: "Hong Kong Dollar",
		symbol_native: "$",
		code: "HKD",
		emoji: "🇭🇰"
	},
	{
		symbol: "HNL",
		name: "Honduran Lempira",
		symbol_native: "L",
		code: "HNL",
		emoji: "🇭🇳"
	},
	{
		symbol: "kn",
		name: "Croatian Kuna",
		symbol_native: "kn",
		code: "HRK",
		emoji: "🇭🇷"
	},
	{
		symbol: "Ft",
		name: "Hungarian Forint",
		symbol_native: "Ft",
		code: "HUF",
		emoji: "🇭🇺"
	},
	{
		symbol: "Rp",
		name: "Indonesian Rupiah",
		symbol_native: "Rp",
		code: "IDR",
		emoji: "🇮🇩"
	},
	{
		symbol: "₪",
		name: "Israeli New Sheqel",
		symbol_native: "₪",
		code: "ILS",
		emoji: "🇮🇱"
	},
	{
		symbol: "Rs",
		name: "Indian Rupee",
		symbol_native: "₹",
		code: "INR",
		emoji: "🇮🇳"
	},
	{
		symbol: "IQD",
		name: "Iraqi Dinar",
		symbol_native: "د.ع.‏",
		code: "IQD",
		emoji: "🇮🇶"
	},
	{
		symbol: "IRR",
		name: "Iranian Rial",
		symbol_native: "﷼",
		code: "IRR",
		emoji: "🇮🇷"
	},
	{
		symbol: "Ikr",
		name: "Icelandic Króna",
		symbol_native: "kr",
		code: "ISK",
		emoji: "🇮🇸"
	},
	{
		symbol: "J$",
		name: "Jamaican Dollar",
		symbol_native: "$",
		code: "JMD",
		emoji: "🇯🇲"
	},
	{
		symbol: "JD",
		name: "Jordanian Dinar",
		symbol_native: "د.أ.‏",
		code: "JOD",
		emoji: "🇯🇴"
	},
	{
		symbol: "¥",
		name: "Japanese Yen",
		symbol_native: "￥",
		code: "JPY",
		emoji: "🇯🇵"
	},
	{
		symbol: "с",
		name: "Kyrgyzstani som",
		symbol_native: "с",
		code: "KES",
		emoji: "🇰🇪"
	},
	{
		symbol: "KHR",
		name: "Cambodian Riel",
		symbol_native: "៛",
		code: "KHR",
		emoji: "🇰🇭"
	},
	{
		symbol: "CF",
		name: "Comorian Franc",
		symbol_native: "FC",
		code: "KMF",
		emoji: "🇰🇲"
	},
	{
		symbol: "₩",
		name: "South Korean Won",
		symbol_native: "₩",
		code: "KRW",
		emoji: "🇰🇷"
	},
	{
		symbol: "KD",
		name: "Kuwaiti Dinar",
		symbol_native: "د.ك.‏",
		code: "KWD",
		emoji: "🇰🇼"
	},
	{
		symbol: "$",
		name: "Cayman Islands dollar",
		symbol_native: "$‏",
		code: "KYD",
		emoji: "🇰🇾"
	},
	{
		symbol: "KZT",
		name: "Kazakhstani Tenge",
		symbol_native: "тңг.",
		code: "KZT",
		emoji: "🇰🇿"
	},
	{
		symbol: "₭",
		name: "Lao kip",
		symbol_native: "₭‏",
		code: "LAK",
		emoji: "🇱🇦"
	},
	{
		symbol: "LB£",
		name: "Lebanese Pound",
		symbol_native: "ل.ل.‏",
		code: "LBP",
		emoji: "🇱🇧"
	},
	{
		symbol: "SLRs",
		name: "Sri Lankan Rupee",
		symbol_native: "SL Re",
		code: "LKR",
		emoji: "🇱🇰"
	},
	{
		symbol: "$",
		name: "Liberian Dollar",
		symbol_native: "$",
		code: "LRD",
		emoji: "🇱🇷"
	},
	{
		symbol: "Lt",
		name: "Lithuanian Litas",
		symbol_native: "Lt",
		code: "LTL",
		emoji: "🇱🇹"
	},
	{
		symbol: "Ls",
		name: "Latvian Lats",
		symbol_native: "Ls",
		code: "LVL",
		emoji: "🇱🇻"
	},
	{
		symbol: "LD",
		name: "Libyan Dinar",
		symbol_native: "د.ل.‏",
		code: "LYD",
		emoji: "🇱🇾"
	},
	{
		symbol: "MAD",
		name: "Moroccan Dirham",
		symbol_native: "د.م.‏",
		code: "MAD",
		emoji: "🇲🇦"
	},
	{
		symbol: "MDL",
		name: "Moldovan Leu",
		symbol_native: "MDL",
		code: "MDL",
		emoji: "🇲🇩"
	},
	{
		symbol: "MGA",
		name: "Malagasy Ariary",
		symbol_native: "MGA",
		code: "MGA",
		emoji: "🇲🇬"
	},
	{
		symbol: "MKD",
		name: "Macedonian Denar",
		symbol_native: "MKD",
		code: "MKD",
		emoji: "🇲🇰"
	},
	{
		symbol: "MMK",
		name: "Myanma Kyat",
		symbol_native: "K",
		code: "MMK",
		emoji: "🇲🇲"
	},
	{
		symbol: "MOP$",
		name: "Macanese Pataca",
		symbol_native: "MOP$",
		code: "MOP",
		emoji: "🇲🇴"
	},
	{
		symbol: "MURs",
		name: "Mauritian Rupee",
		symbol_native: "MURs",
		code: "MUR",
		emoji: "🇲🇺"
	},
	{
		symbol: "MK",
		name: "Malawian Kwacha",
		symbol_native: "MK",
		code: "MWK",
		emoji: "🇲🇼"
	},
	{
		symbol: "MX$",
		name: "Mexican Peso",
		symbol_native: "$",
		code: "MXN",
		emoji: "🇲🇽"
	},
	{
		symbol: "RM",
		name: "Malaysian Ringgit",
		symbol_native: "RM",
		code: "MYR",
		emoji: "🇲🇾"
	},
	{
		symbol: "MTn",
		name: "Mozambican Metical",
		symbol_native: "MTn",
		code: "MZN",
		emoji: "🇲🇿"
	},
	{
		symbol: "N$",
		name: "Namibian Dollar",
		symbol_native: "N$",
		code: "NAD",
		emoji: "🇳🇦"
	},
	{
		symbol: "₦",
		name: "Nigerian Naira",
		symbol_native: "₦",
		code: "NGN",
		emoji: "🇳🇬"
	},
	{
		symbol: "C$",
		name: "Nicaraguan Córdoba",
		symbol_native: "C$",
		code: "NIO",
		emoji: "🇳🇮"
	},
	{
		symbol: "Nkr",
		name: "Norwegian Krone",
		symbol_native: "kr",
		code: "NOK",
		emoji: "🇳🇴"
	},
	{
		symbol: "NPRs",
		name: "Nepalese Rupee",
		symbol_native: "नेरू",
		code: "NPR",
		emoji: "🇳🇵"
	},
	{
		symbol: "NZ$",
		name: "New Zealand Dollar",
		symbol_native: "$",
		code: "NZD",
		emoji: "🇳🇿"
	},
	{
		symbol: "OMR",
		name: "Omani Rial",
		symbol_native: "ر.ع.‏",
		code: "OMR",
		emoji: "🇴🇲"
	},
	{
		symbol: "B/.",
		name: "Panamanian Balboa",
		symbol_native: "B/.",
		code: "PAB",
		emoji: "🇵🇦"
	},
	{
		symbol: "S/.",
		name: "Peruvian Nuevo Sol",
		symbol_native: "S/.",
		code: "PEN",
		emoji: "🇵🇪"
	},
	{
		symbol: "₱",
		name: "Philippine Peso",
		symbol_native: "₱",
		code: "PHP",
		emoji: "🇵🇭"
	},
	{
		symbol: "PKRs",
		name: "Pakistani Rupee",
		symbol_native: "₨",
		code: "PKR",
		emoji: "🇵🇰"
	},
	{
		symbol: "zł",
		name: "Polish Zloty",
		symbol_native: "zł",
		code: "PLN",
		emoji: "🇵🇱"
	},
	{
		symbol: "₲",
		name: "Paraguayan Guarani",
		symbol_native: "₲",
		code: "PYG",
		emoji: "🇵🇾"
	},
	{
		symbol: "QR",
		name: "Qatari Rial",
		symbol_native: "ر.ق.‏",
		code: "QAR",
		emoji: "🇶🇦"
	},
	{
		symbol: "RON",
		name: "Romanian Leu",
		symbol_native: "RON",
		code: "RON",
		emoji: "🇷🇴"
	},
	{
		symbol: "din.",
		name: "Serbian Dinar",
		symbol_native: "дин.",
		code: "RSD",
		emoji: "🇷🇸"
	},
	{
		symbol: "RUB",
		name: "Russian Ruble",
		symbol_native: "руб.",
		code: "RUB",
		emoji: "🇷🇺"
	},
	{
		symbol: "RWF",
		name: "Rwandan Franc",
		symbol_native: "FR",
		code: "RWF",
		emoji: "🇷🇼"
	},
	{
		symbol: "SR",
		name: "Saudi Riyal",
		symbol_native: "ر.س.‏",
		code: "SAR",
		emoji: "🇸🇦"
	},
	{
		symbol: "$",
		name: "Solomon Islander Dollar",
		symbol_native: "$",
		code: "SBD",
		emoji: "🇸🇧"
	},
	{
		symbol: "SDG",
		name: "Sudanese Pound",
		symbol_native: "SDG",
		code: "SDG",
		emoji: "🇸🇩"
	},
	{
		symbol: "Skr",
		name: "Swedish Krona",
		symbol_native: "kr",
		code: "SEK",
		emoji: "🇸🇪"
	},
	{
		symbol: "S$",
		name: "Singapore Dollar",
		symbol_native: "$",
		code: "SGD",
		emoji: "🇸🇬"
	},
	{
		symbol: "Le",
		name: "Sierra Leonean Leone",
		symbol_native: "Le",
		code: "SLL",
		emoji: "🇸🇱"
	},
	{
		symbol: "Ssh",
		name: "Somali Shilling",
		symbol_native: "Ssh",
		code: "SOS",
		emoji: "🇸🇴"
	},
	{
		symbol: "£",
		name: "South Sudanese pound",
		symbol_native: "£",
		code: "SSP",
		emoji: "🇸🇸"
	},
	{
		symbol: "Db",
		name: "Sao Tomean Dobra",
		symbol_native: "Db",
		code: "STN",
		emoji: "🇸🇹"
	},
	{
		symbol: "SY£",
		name: "Syrian Pound",
		symbol_native: "ل.س.‏",
		code: "SYP",
		emoji: "🇸🇾"
	},
	{
		symbol: "L",
		name: "Swazi Lilangeni",
		symbol_native: "L‏",
		code: "SZL",
		emoji: "🇸🇿"
	},
	{
		symbol: "฿",
		name: "Thai Baht",
		symbol_native: "฿",
		code: "THB",
		emoji: "🇹🇭"
	},
	{
		symbol: "ЅМ",
		name: "Tajikistani Somoni",
		symbol_native: "ЅМ",
		code: "TJS",
		emoji: "🇹🇭"
	},
	{
		symbol: "DT",
		name: "Tunisian Dinar",
		symbol_native: "د.ت.‏",
		code: "TND",
		emoji: "🇹🇳"
	},
	{
		symbol: "T$",
		name: "Tongan Paʻanga",
		symbol_native: "T$",
		code: "TOP",
		emoji: "🇹🇴"
	},
	{
		symbol: "TL",
		name: "Turkish Lira",
		symbol_native: "TL",
		code: "TRY",
		emoji: "🇹🇷"
	},
	{
		symbol: "TT$",
		name: "Trinidad and Tobago Dollar",
		symbol_native: "$",
		code: "TTD",
		emoji: "🇹🇹"
	},
	{
		symbol: "NT$",
		name: "New Taiwan Dollar",
		symbol_native: "NT$",
		code: "TWD",
		emoji: "🇹🇼"
	},
	{
		symbol: "TSh",
		name: "Tanzanian Shilling",
		symbol_native: "TSh",
		code: "TZS",
		emoji: "🇹🇿"
	},
	{
		symbol: "₴",
		name: "Ukrainian Hryvnia",
		symbol_native: "₴",
		code: "UAH",
		emoji: "🇺🇦"
	},
	{
		symbol: "USh",
		name: "Ugandan Shilling",
		symbol_native: "USh",
		code: "UGX",
		emoji: "🇺🇬"
	},
	{
		symbol: "$U",
		name: "Uruguayan Peso",
		symbol_native: "$",
		code: "UYU",
		emoji: "🇺🇾"
	},
	{
		symbol: "UZS",
		name: "Uzbekistan Som",
		symbol_native: "UZS",
		code: "UZS",
		emoji: "🇺🇿"
	},
	{
		symbol: "Bs.F.",
		name: "Venezuelan Bolívar",
		symbol_native: "Bs.F.",
		code: "VEF",
		emoji: "🇻🇪"
	},
	{
		symbol: "₫",
		name: "Vietnamese Dong",
		symbol_native: "₫",
		code: "VND",
		emoji: "🇻🇳"
	},
	{
		symbol: "Vt",
		name: "Ni-Vanuatu Vatu",
		symbol_native: "Vt",
		code: "VUV",
		emoji: "🇻🇺"
	},
	{
		symbol: "FCFA",
		name: "CFA Franc BEAC",
		symbol_native: "FCFA",
		code: "XAF",
		emoji: "🇨🇫"
	},
	{
		symbol: "$",
		name: "East Caribbean Dollar",
		symbol_native: "$",
		code: "XCD",
		emoji: "🇦🇬"
	},
	{
		symbol: "CFA",
		name: "CFA Franc BCEAO",
		symbol_native: "CFA",
		code: "XOF",
		emoji: "🇨🇮"
	},
	{
		symbol: "Fr",
		name: "CFP franc",
		symbol_native: "Fr",
		code: "XPF",
		emoji: "🇵🇫"
	},
	{
		symbol: "YR",
		name: "Yemeni Rial",
		symbol_native: "ر.ي.‏",
		code: "YER",
		emoji: "🇾🇪"
	},
	{
		symbol: "R",
		name: "South African Rand",
		symbol_native: "R",
		code: "ZAR",
		emoji: "🇿🇦"
	},
	{
		symbol: "ZK",
		name: "Zambian Kwacha",
		symbol_native: "ZK",
		code: "ZMK",
		emoji: "🇿🇲"
	}
];

class NewSettingsModal extends Component {
	state = {
		confirmPopup: false,
		currencyList: [],
		updatedCurrencies: {},
		selectedCurrency: "",
		selectedCurrencyValue: "",
		responsePeriod: 24,
		buttonColorText: "#FFFFFF",
		buttonColor: "#000000",
		buttonColorRGB: {
			r: 0,
			g: 0,
			b: 0,
			a: 1
		}
	};

	hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16)
			  }
			: null;
	}

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				db.getResponsePeriod(snapshot).then(snap => {
					this.setState({ responsePeriod: snap });
				});
				db.getButtonColor(snapshot).then(snap => {
					if (snap !== null || snap !== undefined)
						this.setState({
							buttonColor: snap.color,
							buttonColorText: snap.textColor,
							buttonColorRGB: {
								r: this.hexToRgb(snap.color).r,
								g: this.hexToRgb(snap.color).g,
								b: this.hexToRgb(snap.color).b,
								a: 1
							}
						});
					else
						this.setState({
							buttonColorText: "#FFFFFF",
							buttonColor: "#000000",
							buttonColorRGB: {
								r: 0,
								g: 0,
								b: 0,
								a: 1
							}
						});
				});
				return db.getCurrencies(snapshot);
			})
			.then(snapshot => {
				var currencyList = {};
				for (var i in currencies) {
					var obj = "";
					if (snapshot.val() && snapshot.val()[currencies[i].code]) {
						obj = snapshot.val()[currencies[i].code];
					} else {
						obj = "";
					}
					currencyList[[currencies[i].code]] = obj;
				}
				this.setState({ currencyList: currencyList });
			})
			.catch(console.error);
	}

	handleCurrencyChange = () => event => {
		this.setState({ selectedCurrency: event.target.value });
		this.setState({
			selectedCurrencyValue: this.state.currencyList[event.target.value]
		});
	};

	handleUpdateCurrency = () => event => {
		this.setState({ selectedCurrencyValue: event.target.value });
		let updatedCurrencies = { ...this.state.updatedCurrencies };
		updatedCurrencies[this.state.selectedCurrency] = Number(event.target.value);
		this.setState({
			updatedCurrencies: updatedCurrencies
		});
		console.log(this.state.updatedCurrencies);
	};

	handleResponsePeriodChange = () => event => {
		this.setState({ responsePeriod: Number(event.target.value) });
	};

	updateResponsePeriod = () => {
		db.updateResponsePeriod(this.state.storeName, this.state.responsePeriod);
		this.props.updateResponseCallback(this.state.responsePeriod);
		this.props.handleClose();
	};

	handleChange = event => {
		this.setState({ confirmPopup: event.target.checked });
		if (event.target.checked) {
			db.setUserConfirmPref(1);
		} else {
			db.setUserConfirmPref(0);
		}
	};

	saveCurrencies = () => {
		db.updateCurrencies(this.state.storeName, this.state.updatedCurrencies);
		this.props.handleClose();
	};

	handleCloseCancel = () => {
		db.getResponsePeriod(this.state.storeName).then(snap => {
			this.setState({ responsePeriod: snap });
		});
		this.props.handleClose();
	};

	handleChangeB = (color, event) => {
		let buttonColorText = "#FFFFFF";
		var cVal = color.rgb.r * 0.209 + color.rgb.g * 0.487 + color.rgb.b * 0.254;
		if (cVal > 186) {
			buttonColorText = "#000000";
		}
		this.setState({
			buttonColor: color.hex,
			buttonColorRGB: color.rgb,
			buttonColorText: buttonColorText
		});
	};

	saveButtonColor = () => {
		db.setButtonColor(this.state.storeName, this.state.buttonColor.toUpperCase(), this.state.buttonColorText);
		this.props.handleClose();
	};

	render() {
		const { classes } = this.props;
		const { buttonColor, buttonColorText } = this.state;
		return (
			<div>
				{this.props.target === "currency" ? (
					<Dialog open={this.props.open} onClose={this.props.handleClose}>
						<DialogTitle>Set Currency Conversion Rates</DialogTitle>
						<DialogContent>
							<TextField
								id='AddCurrencies'
								select
								label='Select Currency'
								value={this.state.selectedCurrency}
								fullWidth
								onChange={this.handleCurrencyChange()}
								margin='normal'
								SelectProps={{
									MenuProps: {
										className: classes.menu
									}
								}}>
								{_.map(currencies, (item, i) =>
									i === 0 ? (
										<MenuItem key={i} value={item.code} defaultValue>
											{item.emoji} {item.code}
										</MenuItem>
									) : (
										<MenuItem key={i} value={item.code}>
											{item.emoji} {item.code}
										</MenuItem>
									)
								)}
							</TextField>
							<TextField
								id='ConversionValue'
								type='number'
								label='Conversion Rate'
								fullWidth
								margin='normal'
								value={this.state.selectedCurrencyValue}
								onChange={this.handleUpdateCurrency()}></TextField>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleCloseCancel} color='primary' autoFocus='autoFocus'>
								Cancel
							</Button>
							<Button onClick={this.saveCurrencies} color='primary' autoFocus='autoFocus'>
								Save
							</Button>
						</DialogActions>
					</Dialog>
				) : this.props.target === "response" ? (
					<Dialog open={this.props.open} onClose={this.props.handleClose}>
						<DialogTitle>Set Response Period</DialogTitle>
						<DialogContent>
							<FormControl className={classes.textField}>
								<Input
									id='responsePeriod'
									label='Response Period Duration'
									type='number'
									value={this.state.responsePeriod}
									onChange={this.handleResponsePeriodChange()}
									endAdornment={<InputAdornment position='end'>Hours</InputAdornment>}
								/>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleCloseCancel} color='primary' autoFocus='autoFocus'>
								Cancel
							</Button>
							<Button onClick={this.updateResponsePeriod} color='primary' autoFocus='autoFocus'>
								Save
							</Button>
						</DialogActions>
					</Dialog>
				) : this.props.target === "button" ? (
					<Dialog open={this.props.open} onClose={this.props.handleClose}>
						<DialogTitle>EnterOffer Button Color</DialogTitle>
						<DialogContent>
							<Button
								variant='contained'
								className={classes.button}
								style={{
									backgroundColor: buttonColor,
									color: buttonColorText,
									marginBottom: "8px"
								}}>
								<img
									src={
										buttonColorText === "#FFFFFF"
											? "https://oaklas.s3-ap-southeast-2.amazonaws.com/assets/enterOfferWhite.png"
											: "https://oaklas.s3-ap-southeast-2.amazonaws.com/assets/enterOfferBlack.png"
									}
									alt='EnterOffer'
									style={{
										maxHeight: "2em",
										display: "inline-block",
										verticalAlign: "middle",
										marginRight: "0.5em"
									}}
								/>
							</Button>
							<TwitterPicker
								width={300}
								colors={colors}
								color={buttonColor}
								onChange={this.handleChangeB}
							/>
							<div
								style={{
									textAlign: "center",
									padding: "16px 0px 0px",
									marginBottom: "8px"
								}}>
								<Button className={classes.saveButton} onClick={this.saveButtonColor}>
									Save
								</Button>
							</div>
						</DialogContent>
					</Dialog>
				) : null}
			</div>
		);
	}
}

export default withStyles(styles)(NewSettingsModal);
