import React, { Component } from "react";
import "./App.css";
import { Home, Rules, Signin, Signup, PageNotFound, PageContainer, Campaigns, ReadCampaigns, MakeOfferButton, AbandonmentTemplate, Settings } from "./pages";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthorizedRoute from "./authorizedRoute";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
	overrides: {
		// Name of the component ⚛️ / style sheet
		MuiButton: {
			text: {
				background: "#f6f6f8",
				color: "#35B2FF",
				fontWeight: 600,
				boxShadow: "none",
				"&:hover": {
					backgroundColor: "#D3D3D3"
				}
			}
		}
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: ["Nunito Sans", "sans-serif"].join(",")
	},
	palette: {
		primary: {
			main: "#35B2FF"
		},
		secondary: {
			main: "#FFFFFF",
			dark: "#e3e3e3"
		}
	}
});

class App extends Component {
	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<Router>
						<Switch>
							<Route exact path='/signin' component={Signin} />
							<Route exact path='/signup' component={Signup} />
							<AuthorizedRoute exact path='/' component={HomeWrapped} />
							<AuthorizedRoute exact path='/rules' component={RulesWrapped} />
							<AuthorizedRoute exact path='/campaigns/new' component={CampaignsWrapped} />
							<AuthorizedRoute exact path='/campaigns/view' component={ReadCampaignsWrapped} />
							<AuthorizedRoute exact path='/settings' component={SettingsWrapped} />
							<AuthorizedRoute exact path='/button' component={MakeOfferButtonWrapped} />
							<AuthorizedRoute exact path='/template' component={AbandonmentTemplateWrapped} />
							<Route component={PageNotFoundWrapped} />
						</Switch>
					</Router>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		);
	}
}

const HomeWrapped = () => <PageContainer page={Home} flag={true} />;
const RulesWrapped = () => <PageContainer page={Rules} flag={false} />;
const CampaignsWrapped = () => <PageContainer page={Campaigns} flag={false} />;
const ReadCampaignsWrapped = () => <PageContainer page={ReadCampaigns} flag={false} />;
const SettingsWrapped = () => <PageContainer page={Settings} flag={false} />;
const PageNotFoundWrapped = () => <PageContainer page={PageNotFound} flag={false} />;
const MakeOfferButtonWrapped = () => <PageContainer page={MakeOfferButton} flag={false} />;
const AbandonmentTemplateWrapped = () => <PageContainer page={AbandonmentTemplate} flag={false} />;
export default App;
