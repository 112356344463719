import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { db } from "../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Switch from "@material-ui/core/Switch";
import withSizes from "react-sizes";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		textAlign: "center",
		border: "1px solid #35B2FF"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	},
	menu: {
		width: 200
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	fabStyle: {
		margin: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		color: "#35B2FF",
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	productName: {
		fontSize: "24px",
		fontWeight: "300",
		width: "100%",
		display: "block",
		background: "#fff",
		textAlign: "left",
		fontFamily: '"Nunito Sans", sans-serif'
	}
});

class MasterTemplate extends React.Component {
	state = {
		storeName: "",
		isRule: false,
		open: false,
		openSuccess: false,
		openRemove: false,
		openDialog: false,
		openLoadingRemove: false,
		openLoadingAdd: false,
		openDeleteDialog: false,
		openInvalid: false,
		active: this.props.buttonActive
	};

	static propTypes = {
		collectionName: PropTypes.string.isRequired
	};

	handleOpen = name => {
		this.setState({ [name]: true });
	};

	handleClose = name => {
		this.setState({ [name]: false });
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
			})
			.catch(e => {
				console.log("error", e);
			});
	}

	handleChange = (event, type) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		this.setState({ [name]: value });
	};

	addOfferButton = collection => {
		this.props.addCollection(this.props.collectionID, this.state.active);
		this.setState({
			openLoading: false,
			active: !this.state.active
		});
	};

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ active: props.buttonActive });
	}

	render() {
		const { classes, collectionName, isMobile } = this.props;
		const { openError, openSuccess, openRemove, openLoadingRemove, openLoadingAdd } = this.state;
		return (
			<div>
				<br />
				<Paper
					className={classes.root}
					elevation={1}
					style={{
						zoom: isMobile ? "0.88" : "1"
					}}>
					<label htmlFor='contained-button-file' className={classes.productName}>
						<span
							style={{
								fontSize: "0.8em"
							}}>
							{collectionName}
						</span>
						<span>
							<Switch
								checked={this.state.active}
								color='primary'
								onChange={() => this.addOfferButton(true)}></Switch>
						</span>
					</label>
					<div>
						<Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							open={openError}
							onClose={() => this.handleClose("openError")}
							autoHideDuration={3000}
							message={<span id='message-id'> Something went wrong. Please try again.</span>}
						/>

						<Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							open={openRemove}
							onClose={() => this.handleClose("openRemove")}
							autoHideDuration={3000}
							message={
								<span id='message-id'>
									{" "}
									You have succesfully removed the EnterOffer button from {this.props.collectionName}
								</span>
							}
						/>

						<Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							open={openSuccess}
							onClose={() => this.handleClose("openSuccess")}
							autoHideDuration={5000}
							message={
								<span id='message-id'>
									{" "}
									You have succesfully added the EnterOffer button to {this.props.collectionName}
								</span>
							}
						/>
						<Dialog
							open={openLoadingAdd}
							onClose={() => {
								this.setState({ openLoadingAdd: false });
							}}
							disableBackdropClick={true}
							disableEscapeKeyDown={true}>
							<DialogContent style={{ textAlign: "center" }}>
								<h2>
									Adding the EnterOffer button to
									<br /> "{this.props.collectionName}" collection
								</h2>
								<p>This may take up to a minute or longer depending on the size of the collection.</p>
								<Lottie
									height={100}
									width={100}
									options={{
										loop: true,
										autoplay: true,
										animationData: loader.default,
										rendererSettings: {
											preserveAspectRatio: "xMidYMid slice"
										}
									}}
								/>
							</DialogContent>
						</Dialog>
						<Dialog
							open={openLoadingRemove}
							onClose={() => {
								this.setState({ openLoadingRemove: false });
							}}
							disableBackdropClick={true}
							disableEscapeKeyDown={true}>
							<DialogContent style={{ textAlign: "center" }}>
								<h2>
									Removing the EnterOffer button from <br /> "{this.props.collectionName}" collection.
								</h2>
								<p>This may take up to a minute or longer depending on the size of the collection.</p>
								<Lottie
									height={100}
									width={100}
									options={{
										loop: true,
										autoplay: true,
										animationData: loader.default,
										rendererSettings: {
											preserveAspectRatio: "xMidYMid slice"
										}
									}}
								/>
							</DialogContent>
						</Dialog>
					</div>
				</Paper>
			</div>
		);
	}
}

MasterTemplate.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(MasterTemplate));
