import firebase from "firebase/app";
import "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import Rebase from "re-base";
var liveTesting = false;

var usersDBConfig = {
	apiKey: "AIzaSyCgiHK5cVxarmTZyPnYPgAooq7f8FQVhM0",
	authDomain: "oaklas-bookmark-tool.firebaseapp.com",
	databaseURL: "https://oaklas-users-e5979.firebaseio.com/",
	projectId: "oaklas-bookmark-tool",
	storageBucket: "oaklas-bookmark-tool.appspot.com",
	messagingSenderId: "650641977232",
};

var rulesConfig = {
	apiKey: "AIzaSyCgiHK5cVxarmTZyPnYPgAooq7f8FQVhM0",
	authDomain: "oaklas-bookmark-tool.firebaseapp.com",
	databaseURL: "https://oaklas-bookmark-tool-991c2.firebaseio.com/",
	projectId: "oaklas-bookmark-tool",
	storageBucket: "oaklas-bookmark-tool.appspot.com",
	messagingSenderId: "650641977232",
};

var authConfig = {
	apiKey: "AIzaSyCgiHK5cVxarmTZyPnYPgAooq7f8FQVhM0",
	authDomain: "oaklas-bookmark-tool.firebaseapp.com",
	databaseURL: "https://oaklas-bookmark-tool.firebaseio.com",
	projectId: "oaklas-bookmark-tool",
	storageBucket: "oaklas-bookmark-tool.appspot.com",
	messagingSenderId: "650641977232",
};

var cartAbandonmentConfig = {
	apiKey: "AIzaSyBpdri5eUiWDkmtvPzEzM0vUVLPoK6OYHE",
	databaseURL: "https://enteroffer-cart-abandonment.firebaseio.com/",
	projectId: "oaklas-bookmark-tool",
};

var offerableConfig = {
	apiKey: "AIzaSyBpdri5eUiWDkmtvPzEzM0vUVLPoK6OYHE",
	databaseURL: "https://oaklas-retailer-offerable.firebaseio.com/",
	projectId: "oaklas-bookmark-tool",
};

if (liveTesting) {
	usersDBConfig = {
		apiKey: "AIzaSyBpdri5eUiWDkmtvPzEzM0vUVLPoK6OYHE",
		authDomain: "oaklas-retailer-dev.firebaseapp.com",
		databaseURL: "https://oaklas-retailer-dev-users.firebaseio.com/",
		projectId: "oaklas-retailer-dev",
		storageBucket: "oaklas-retailer-dev.appspot.com",
		messagingSenderId: "975196738296",
	};
	rulesConfig = {
		apiKey: "AIzaSyBpdri5eUiWDkmtvPzEzM0vUVLPoK6OYHE",
		authDomain: "oaklas-retailer-dev.firebaseapp.com",
		databaseURL: "https://oaklas-retailer-dev-rules.firebaseio.com/",
		projectId: "oaklas-retailer-dev",
		storageBucket: "oaklas-retailer-dev.appspot.com",
		messagingSenderId: "975196738296",
	};
	authConfig = {
		apiKey: "AIzaSyBpdri5eUiWDkmtvPzEzM0vUVLPoK6OYHE",
		authDomain: "oaklas-retailer-dev.firebaseapp.com",
		databaseURL: "https://oaklas-retailer-dev.firebaseio.com/",
		projectId: "oaklas-retailer-dev",
		storageBucket: "oaklas-retailer-dev.appspot.com",
		messagingSenderId: "975196738296",
	};
}

const authfb = firebase.initializeApp(authConfig);
const userfb = firebase.initializeApp(usersDBConfig, "other");
const rulesfb = firebase.initializeApp(rulesConfig, "other2");
const cartAbandonmentFB = firebase.initializeApp(cartAbandonmentConfig, "other3");
const offerableFB = firebase.initializeApp(offerableConfig, "offerable");

const authdb = authfb.database();
const userdb = userfb.database();
const rulesdb = rulesfb.database();
const authStorage = authfb.storage();
const authBase = Rebase.createClass(authdb);
const cartAbandonmentDB = cartAbandonmentFB.database();
const offerableDB = offerableFB.database();

const auth = authfb.auth();
// authfb.functions().useFunctionsEmulator("http://localhost:5000");
export {
	firebase,
	auth,
	authfb,
	authdb,
	userfb,
	userdb,
	rulesfb,
	rulesdb,
	authBase,
	authStorage,
	cartAbandonmentDB,
	offerableDB,
	// firebaseDate
};
