import enterOfferLogoColor from "../assets/enterOfferLogoColor.png";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
	email: { textDecoration: "none", color: theme.palette.primary.main },
});
class SignUpPending extends Component {
	render() {
		const { classes } = this.props;
		return (
			<div style={{ textAlign: "center", marginTop: "120px" }}>
				<img src={enterOfferLogoColor} alt="EnterOffer" style={{ width: "100px" }}></img>
				<p
					style={{
						color: "#3e3e3e",
						marginBottom: "8px",
						fontSize: "1.2em",
						fontWeight: "700",
					}}
				>
					Thank you for signing up!
				</p>
				<p
					style={{
						color: "#7f7f7f",
						marginTop: "8px",
						marginBottom: "4px",
					}}
				>
					Your account is pending final approval from one of the team members at EnterOffer.
				</p>
				<p
					style={{
						color: "#7f7f7f",
						marginTop: "0px",
						marginBottom: "20px",
					}}
				>
					You will receive an email from a team member at EnterOffer once your account is active.
				</p>
				<p
					style={{
						color: "#7f7f7f",
						marginTop: "0px",
						marginBottom: "0px",
					}}
				>
					Please contact{" "}
					<a className={classes.email} href="mailto:hello@enteroffer.com">
						hello@enteroffer.com
					</a>{" "}
					if you have any queries.
				</p>
			</div>
		);
	}
}
export default withStyles(styles)(SignUpPending);
