import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import { db } from "../firebase";
import _ from "lodash";

const styles = theme => ({
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	},
	dense: {
		marginTop: 19
	},
	menu: {
		width: 200
	}
});

class SettingsModal extends Component {
	state = {
		confirmPopup: false,
		storeName: "",
		storeNames: [],
		storeIndex: 0,
		prevStoreName: "",
		prevIndex: "0"
	};

	handleChange = event => {
		this.setState({ confirmPopup: event.target.checked });
		if (event.target.checked) {
			db.setUserConfirmPref(1);
		} else {
			db.setUserConfirmPref(0);
		}
	};
	handleChangeTextfield = name => event => {
		let index = 0;
		for (let i in this.state.storeNames) {
			if (this.state.storeNames[i].value === event.target.value) {
				index = this.state.storeNames[i].storeIndex;
			}
		}
		let prevSN = this.state.storeName;
		let prevI = this.state.storeIndex;
		this.setState({
			[name]: event.target.value,
			storeIndex: index,
			prevStoreName: prevSN,
			prevIndex: prevI
		});
	};

	handleClose = () => {
		db.setStoreIndex(this.state.storeIndex);
		this.props.handleClose("apply");
		if (this.state.storeName !== this.state.prevStoreName) window.location.reload();
	};

	handleCloseCancel = () => {
		this.setState({
			storeName: this.state.prevStoreName,
			storeIndex: this.state.prevIndex
		});
		this.props.handleClose("cancel");
	};

	componentDidMount() {
		db.getUserConfirmPref()
			.then(snapshot => {
				if (snapshot === 1) {
					this.setState({ confirmPopup: true });
				} else {
					this.setState({ confirmPopup: false });
				}
			})
			.catch(e => console.log("error", e));

		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot, prevStoreName: snapshot });
			})
			.catch(e => console.log("error", e));
		db.getStoreList()
			.then(snapshot => {
				if (snapshot === null) return;
				let storesArr = snapshot[0];
				let storesObj = [];
				for (let i in storesArr) {
					let storeObj = {
						storeIndex: i,
						value: storesArr[i],
						label: _.startCase(_.camelCase(storesArr[i]))
					};
					storesObj.push(storeObj);
				}
				this.setState({
					storeNames: storesObj,
					storeIndex: snapshot[1]
				});
			})
			.catch(e => console.log("error", e));
	}

	render() {
		const { classes } = this.props;
		const { storeNames, storeName } = this.state;
		return (
			<div>
				<Dialog open={this.props.open} onClose={this.handleCloseCancel}>
					<DialogTitle id="alert-dialog-title">{"Settings"}</DialogTitle>
					<DialogContent style={{ textAlign: "center" }}>
						{_.isEmpty(storeNames) ? null : (
							<TextField
								id="standard-select-storeName"
								select
								className={classes.textField}
								value={storeName}
								onChange={this.handleChangeTextfield("storeName")}
								SelectProps={{
									MenuProps: {
										className: classes.menu
									}
								}}
								helperText="Change active store"
								margin="normal"
							>
								{storeNames.map(option => (
									<MenuItem
										key={option.storeIndex}
										name={option.storeIndex}
										id={option.storeIndex}
										value={option.value}
									>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						)}
						<h2
							style={{
								textAlign: "left",
								fontSize: "1em",
								fontWeight: "400",
								color: "#7f7f7f",
								marginTop: "20px",
								marginBottom: "40px"
							}}
						>
							Turning on the confirmation popup will mean that accepting or rejecting offers will require
							you to confirm your decision a second time.
						</h2>

						<FormControl component="fieldset" style={{ display: "block", textAlign: "center" }}>
							<FormGroup style={{ display: "block" }}>
								<FormControlLabel
									control={
										<Switch
											checked={this.state.confirmPopup}
											onChange={this.handleChange}
											value="confirmPopup"
											color="primary"
										/>
									}
									label="Confirmation Popup"
								/>
							</FormGroup>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} autoFocus>
							Done!
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withStyles(styles)(SettingsModal);
