import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { db, fb } from "../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import withSizes from "react-sizes";
import Grid from "@material-ui/core/Grid";
import { Slider } from "./index";

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		textAlign: "left"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	inputAccept: {
		color: "#22c78e"
	},
	inputReject: {
		color: "#ff4d4d"
	},
	textField: {
		marginRight: theme.spacing(2),
		width: "90%"
	},
	menu: {
		width: 200
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		color: "#35B2FF",
		fontWeight: 600,
		float: "right",
		boxShadow: "none",
		"&:hover": {
			boxShadow: "none",
			backgroundColor: "#D3D3D3"
		}
	},
	productName: {
		fontFamily: '"Nunito Sans", sans-serif',
		fontSize: "24px",
		fontWeight: "300",
		width: "100%",
		display: "block",
		paddingBottom: "10px",
		paddingTop: "10px",
		borderRadius: "5px",
		background: "#f6f6f8"
	}
});

class RulesPaper extends React.Component {
	state = {
		storeName: "",
		rejectBelow: this.props.data.rejectBelow,
		acceptAbove: this.props.data.acceptAbove,
		acceptAndRejectBool: "%",
		invLowerLim: this.props.data.invLowerLim,
		endDate: this.props.data.endDate,
		isRule: this.props.data.isRule,
		open: false,
		openSuccess: false,
		openDelete: false,
		openDate: false,
		openInvalid: false,
		openOverlap: false,
		tooltipMessage:
			"e.g. if a product cost $100 and the reject below rule was set to 30 (%), then all offers below $30 will be rejected automatically"
	};

	static propTypes = {
		data: PropTypes.shape({
			productTitle: PropTypes.string,
			productPrice: PropTypes.number,
			productId: PropTypes.number,
			rejectBelow: PropTypes.number,
			acceptAbove: PropTypes.string,
			unitDollar: PropTypes.number,
			invLowerLim: PropTypes.string,
			endDate: PropTypes.string,
			isRule: PropTypes.bool
		}).isRequired
	};

	handleOpen = name => {
		this.setState({ [name]: true });
	};

	handleClose = name => {
		this.setState({ [name]: false });
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
			})
			.catch(e => console.log("error", e));
		if (this.props.cartRule) {
			this.setState({
				tooltipMessage:
					"e.g. if a cart cost $100 and the reject below rule was set to 30 (%), then all offers below $30 will be rejected automatically"
			});
		}
	}

	handleChange = (event, type) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		this.setState({ [name]: value });
	};
	handleSliderChange = val => {
		this.setState({ rejectBelow: val[0], acceptAbove: val[1] });
	};

	clickedRowItem() {
		let newData = this.props.data;
		newData.rejectBelow = this.state.rejectBelow;
		newData.acceptAbove = this.state.acceptAbove;
		newData.acceptAndRejectBool = this.state.acceptAndRejectBool;
		newData.unitDollar = this.state.unitDollar;
		newData.invLowerLim = this.state.invLowerLim;
		newData.endDate = this.state.endDate;
		newData.isRule = this.state.isRule;
		newData.productName = this.state.productTitle;
		newData.image = this.props.data.image;

		if (newData.isRule) {
			if (this.props.cartRule === true) {
				db.removeCartRules(this.state.storeName, newData).then(snapshot => {
					if (snapshot === "removed") {
						this.handleOpen("openDelete");
						this.setState({
							rejectBelow: "",
							acceptAbove: "",
							acceptAndRejectBool: "%",
							invLowerLim: "",
							isRule: false
						});
					}
				});
			} else {
				db.removeRules(this.state.storeName, newData).then(snapshot => {
					if (snapshot === "removed") {
						this.handleOpen("openDelete");
						this.setState({
							rejectBelow: "",
							acceptAbove: "",
							acceptAndRejectBool: "%",
							invLowerLim: "",
							isRule: false
						});
					}
				});
			}
		} else {
			if (Number(this.state.rejectBelow) >= Number(this.state.acceptAbove)) {
				this.handleOpen("openInvalid");
			} else {
				if (this.props.cartRule === true) {
					newData.acceptAndRejectBool = "%";
					newData.endDate = new Date(newData.endDate).valueOf();
					let d = new Date();
					let curDate = d.valueOf();

					if (newData.endDate < curDate) {
						this.handleOpen("openDate");
						return;
					}

					if (newData.rejectBelow && newData.acceptAbove && newData.endDate) {
						fb.submitCartRule({
							endDate: newData.endDate,
							lowerAmount: this.props.data.lowerAmount,
							upperAmount: this.props.data.upperAmount,
							lowerPerc: Number(newData.rejectBelow),
							upperPerc: Number(newData.acceptAbove),
							storeName: this.state.storeName
						})
							.then(res => {
								console.log(res);
								if (res.data === true) this.handleOpen("openSuccess");
								else if (res.data === "newRuleOverlap") {
									this.handleOpen("openOverlap")();
								}
							})
							.catch(e => {
								this.handleOpen("open");
								// this.setState({loading: false})
							});
					} else {
						this.handleOpen("open");
					}
				} else {
					db.submitRules(this.state.storeName, newData).then(snap => {
						if (snap === "successBoth") {
							this.setState({ isRule: true });
							this.handleOpen("openSuccess");
						} else {
							if (snap === "pastDate") {
								this.handleOpen("openDate");
							} else {
								this.handleOpen("open");
								console.log("Error: Couldn't change Rule");
							}
						}
					});
				}
			}
		}
	}

	formSubmit = e => {
		e.preventDefault();
		this.clickedRowItem();
	};

	render() {
		const { classes, data, isMobile, cartRule, tab } = this.props;
		const {
			openInvalid,
			rejectBelow,
			acceptAbove,
			acceptAndRejectBool,
			isRule,
			open,
			endDate,
			openSuccess,
			openDelete,
			openDate,
			openOverlap
		} = this.state;
		return (
			<div>
				<Grid container spacing={isMobile ? null : 2}>
					{cartRule ? null : (
						<Grid item xs={tab === 0 ? 6 : 4}>
							<img style={{ width: "90%", objectFit: "cover" }} src={data.image} alt='ProductImg' />
						</Grid>
					)}
					<Grid
						item
						xs={cartRule ? (tab === 0 ? 12 : 9) : tab === 0 ? 6 : 5}
						style={{ padding: cartRule ? null : "0px" }}>
						<form onSubmit={e => this.formSubmit(e)}>
							<Tooltip placement='top' title={this.state.tooltipMessage}>
								<TextField
									id='acceptAbove'
									label='Accept Offers Above'
									className={classes.textField}
									value={acceptAbove}
									onChange={this.handleChange}
									name='acceptAbove'
									disabled={isRule}
									margin='normal'
									type='number'
									InputLabelProps={{
										shrink: true
									}}
									InputProps={{
										className: classes.inputAccept,
										inputProps: {
											min: 0,
											max:
												acceptAndRejectBool === "$"
													? data.productPrice
													: acceptAndRejectBool === "%"
													? 100
													: null
										},
										endAdornment: (
											<InputAdornment position='end'>
												<span style={{ color: "#22c78e" }}>%</span>
											</InputAdornment>
										)
									}}
								/>
							</Tooltip>
							<Tooltip placement='top' title={this.state.tooltipMessage}>
								<TextField
									id='rejectBelow'
									label='Reject Offers Below'
									className={classes.textField}
									value={rejectBelow}
									onChange={this.handleChange}
									name='rejectBelow'
									margin='normal'
									disabled={isRule}
									type='number'
									InputLabelProps={{
										shrink: true
									}}
									InputProps={{
										className: classes.inputReject,
										inputProps: {
											min: 0,
											max:
												acceptAndRejectBool === "$"
													? data.productPrice
													: acceptAndRejectBool === "%"
													? 100
													: null
										},
										endAdornment: (
											<InputAdornment position='end'>
												<span style={{ color: "#ff4d4d" }}>%</span>
											</InputAdornment>
										)
									}}
								/>
							</Tooltip>
							<TextField
								id='endDate'
								label='End Date'
								type='date'
								value={endDate}
								margin='normal'
								name='endDate'
								disabled={isRule}
								className={classes.textField}
								onChange={this.handleChange}
								InputLabelProps={{
									shrink: true
								}}
							/>
							<div margin='normal'>
								{isRule ? (
									<Button
										type='submit'
										color='primary'
										variant='contained'
										className={classes.button}>
										Remove
									</Button>
								) : (
									<Button
										type='submit'
										color='primary'
										variant='contained'
										className={classes.button}>
										SUBMIT
									</Button>
								)}
							</div>
						</form>
					</Grid>
					{tab === 0 ? null : (
						<Grid item xs={3} style={{ padding: "0px" }}>
							<Slider
								low={rejectBelow}
								high={acceptAbove}
								sliderChange={this.handleSliderChange}
								isMobile={isMobile}
							/>
						</Grid>
					)}
				</Grid>
				<div>
					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right"
						}}
						open={open}
						onClose={() => this.handleClose("open")}
						autoHideDuration={3000}
						ContentProps={{
							"aria-describedby": "message-id"
						}}
						message={
							<span id='message-id'>Your Rule is invalid. Please make sure all values are filled</span>
						}
					/>

					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right"
						}}
						open={openSuccess}
						onClose={() => this.handleClose("openSuccess")}
						autoHideDuration={3000}
						ContentProps={{
							"aria-describedby": "message-id"
						}}
						message={<span id='message-id'>Your Rule was successfully added</span>}
					/>
					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right"
						}}
						open={openDelete}
						onClose={() => this.handleClose("openDelete")}
						autoHideDuration={3000}
						ContentProps={{
							"aria-describedby": "message-id"
						}}
						message={<span id='message-id'>Your Rule was successfully removed</span>}
					/>

					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right"
						}}
						open={openDate}
						onClose={() => this.handleClose("openDate")}
						autoHideDuration={3000}
						ContentProps={{
							"aria-describedby": "message-id"
						}}
						message={<span id='message-id'>Please enter a date greater than the current date</span>}
					/>

					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right"
						}}
						open={openInvalid}
						onClose={() => this.handleClose("openInvalid")}
						autoHideDuration={3000}
						ContentProps={{
							"aria-describedby": "message-id"
						}}
						message={<span id='message-id'>Accept Above value must be greater than Reject Below</span>}
					/>

					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right"
						}}
						open={openOverlap}
						onClose={() => this.handleClose("openOverlap")}
						autoHideDuration={3000}
						ContentProps={{
							"aria-describedby": "message-id"
						}}
						message={
							<span id='message-id'>
								The price range of the new rule cannot overlap with an existing rule.
							</span>
						}
					/>
				</div>
			</div>
		);
	}
}

RulesPaper.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 700,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(RulesPaper));
