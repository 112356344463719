import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { db } from "../firebase";
import { Typography, Tooltip, Snackbar, Grid } from "@material-ui/core";
import Refresh from "@material-ui/icons/Refresh";
import Moment from "moment";

class SalesBarChart extends Component {
	state = {
		approved: [],
		sales: 0,
		sales30days: 0,
		storeName: "",
		value: "",
		open: false
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: false,
		legendPosition: "top"
	};

	componentDidMount() {
		this.updateState(false);
	}

	updateState(notFromMount) {
		if (notFromMount) {
			for (var i = 0; i < 4; i++) {
				this.setState({
					approved: {
						[Moment()
							.subtract(i, "months")
							.month()]: 0
					}
				});
			}
		}
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				return db.getStoreBids(snapshot, "approved");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "approved");
				return;
			})
			.catch(console.error);

		if (notFromMount) {
			this.setState({ open: true });
		}
	}

	getOfferData = (snapshot, location) => {
		let sales = 0;
		let sales30days = 0;
		snapshot.forEach(offer => {
			if (
				offer.val().bidPrice !== undefined &&
				offer.val().bidPrice !== null &&
				offer.val().payment &&
				offer.val().order
			)
				sales = sales + Number(offer.val().bidPrice);
			if (
				Moment().diff(offer.val().submittedDate, "months") < 4 &&
				offer.val().bidPrice &&
				offer.val().payment &&
				offer.val().order
			) {
				var month = Moment(offer.val().submittedDate).month();
				var temp = Object.assign({}, this.state[location]);
				if (temp[month] === undefined) temp[month] = 0;
				temp[month] = Number(temp[month]) + Number(offer.val().bidPrice);
				this.setState({ [location]: temp });
			}
			if (Moment().diff(offer.val().submittedDate, "days") < 30 && offer.val().payment && offer.val().order) {
				if (offer.val().bidPrice !== undefined && offer.val().bidPrice !== null)
					sales30days = sales30days + Number(offer.val().bidPrice);
			}
		});
		this.setState({ sales: sales, sales30days: sales30days });
	};

	handleCloseSnackSuccess = () => {
		this.setState({ open: false });
	};

	render() {
		const chartData = {
			labels: [
				Moment()
					.subtract(3, "months")
					.format("MMM"),
				Moment()
					.subtract(2, "months")
					.format("MMM"),
				Moment()
					.subtract(1, "months")
					.format("MMM"),
				Moment().format("MMM")
			],
			datasets: [
				{
					label: "APPROVED",
					data: [
						this.state.approved[
							Moment()
								.subtract(3, "months")
								.month()
						],
						this.state.approved[
							Moment()
								.subtract(2, "months")
								.month()
						],
						this.state.approved[
							Moment()
								.subtract(1, "months")
								.month()
						],
						this.state.approved[Moment().month()]
					],
					borderWidth: 1,
					borderColor: "rgba(42, 228, 155, 1)",
					backgroundColor: "rgba(42, 228, 155, .13)",
					fill: false
				}
			]
		};

		return (
			<div style={{ height: this.props.isMobile ? null : "250px" }}>
				<div style={{ display: "inline", textAlign: "left" }}>
					<Typography
						style={{
							color: "#000",
							fontSize: "1em",
							fontWeight: 700
						}}
						onClick={() => this.updateState(true)}>
						Sales{" "}
						<Tooltip title='Update Graph'>
							<Refresh
								style={{
									verticalAlign: "bottom",
									color: "#35B2FF"
								}}
							/>
						</Tooltip>
					</Typography>
				</div>
				<Grid container style={{ textAlign: "left" }}>
					<Grid item xs={6}>
						<Typography
							style={{
								color: "#929292",
								fontSize: "12px"
							}}>
							All time
						</Typography>
						<Typography
							style={{
								color: "#3e3e3e",
								fontSize: "16px"
							}}>
							${this.state.sales.toFixed(2)}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							style={{
								color: "#929292",
								fontSize: "12px"
							}}>
							Past 30 days
						</Typography>
						<Typography
							style={{
								color: "#3e3e3e",
								fontSize: "16px"
							}}>
							${this.state.sales30days.toFixed(2)}
						</Typography>
					</Grid>
				</Grid>
				<br />
				<Bar
					data={chartData}
					height={100}
					options={{
						legend: {
							display: this.props.displayLegend,
							position: this.props.legendPosition
						},
						scales: {
							yAxes: [
								{
									ticks: {
										min: 0,
										maxTicksLimit: 5,
										callback: function(value) {
											if (value % 1 === 0) {
												return value;
											}
										}
									}
								}
							]
						}
					}}
				/>

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={this.state.open}
					onClose={this.handleCloseSnackSuccess}
					autoHideDuration={3000}
					message={<span id='message-id'>Graph Updated</span>}
				/>
			</div>
		);
	}
}

export default SalesBarChart;
