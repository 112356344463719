import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { authBase } from "../firebase";
import { auth } from "../firebase/firebase";
import { fb } from "../firebase";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/Lock";
import SecurityIcon from "@material-ui/icons/Security";
import PaymentIcon from "@material-ui/icons/Payment";
import InfoIcon from "@material-ui/icons/Info";

const styles = (theme) => ({
	button: {
		borderRadius: "0.6em",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "1.1em",
		fontSize: "0.9em",
		boxShadow: "none",
		border: "1px solid #e3e3e3",
		"&:hover": {
			boxShadow: "none",
			borderColor: theme.palette.primary.main,
		},
	},
	info: {
		fontSize: "0.85em",
		display: "flex",
		textDecoration: "none",
		color: theme.palette.primary.main,
		textAlign: "center",
		margin: 0,
		marginBottom: theme.spacing(3),
		cursor: "pointer",
		justifyContent: "center",
		alignItems: "center",
	},
});
class ShopifyStripeConnect extends Component {
	state = {
		shopifySuccess: false,
		stripeSuccess: false,
		open: false,
	};

	handleInfo = () => {
		this.setState({ open: !this.state.open });
	};

	componentDidMount() {
		const urlParams = queryString.parse(this.props.location.search);
		this.setState({ loading: false });
		authBase.syncState(`newStores/${auth.currentUser.uid}/meta/shopifySuccess`, {
			context: this,
			defaultValue: false,
			state: "shopifySuccess",
			then: () => {
				if (this.state.shopifySuccess === true || urlParams.shopify === "true") {
					this.setState({ shopifySuccess: true });
					this.props.handleChange("shopifySuccess", true);
					console.log("Shopify Success");
				}
			},
		});
		authBase.syncState(`newStores/${auth.currentUser.uid}/meta/stripe`, {
			context: this,
			state: "stripeUserId",
			defaultValue: null,
			then: async () => {
				if (
					urlParams.scope !== undefined &&
					urlParams.code !== undefined &&
					(this.state.stripeUserId === null || this.state.stripeUserId === undefined)
				) {
					await fb
						.addStripeRetailerOnSignup(auth.currentUser.uid, urlParams.code)
						.then(() => {
							this.setState({ stripeSuccess: true });
							this.props.handleChange("stripeSuccess", true);
							console.log("Stripe Success");
						})
						.catch((e) => {
							console.error(e);
						});
				} else if (this.state.stripeUserId !== undefined && this.state.stripeUserId !== null) {
					this.setState({ stripeSuccess: true });
					this.props.handleChange("stripeSuccess", true);
				}
			},
		});
	}

	render() {
		const { classes, fullScreen, error } = this.props;
		const { shopifySuccess, stripeSuccess } = this.state;
		const urlParams = queryString.parse(this.props.location.search);
		const clientId = "ca_EijCRxtaeHMkm2tRVkNdR2Mb2ZmuFahK"; //live
		const stripeRedirect = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=https://retailer.enteroffer.com`;
		const successStyle = {
			backgroundColor: "#22c78e22",
			color: "#22c78e",
			border: "1px solid #22c78e",
			cursor: "default",
			"&:hover": {
				pointerEvents: "none",
			},
		};
		const regularStyle = {
			backgroundColor: "transparent",
			color: "#929292cc",
			// border: "1px solid #e3e3e3"
		};
		const errorStyle = {
			backgroundColor: "transparent",
			color: "#929292cc",
			border: "1px solid #f44336",
		};

		return (
			<div>
				<a
					style={{ textDecoration: "none" }}
					href={
						this.props.shopifyUrl && this.props.shopifyUrl.indexOf(".myshopify.com") !== -1
							? `https://us-central1-oaklas-shopify.cloudfunctions.net/shopifyApp/shopify/?shop=${this.props.shopifyUrl}`
							: null
					}
				>
					<div
						className={classes.button}
						style={shopifySuccess ? successStyle : error === 6 ? errorStyle : regularStyle}
						onClick={
							(!this.props.shopifyUrl || this.props.shopifyUrl.indexOf(".myshopify.com") === -1) &&
							!shopifySuccess
								? () =>
										this.props.setError({
											errorMessage: "Please ensure your MyShopify URL is valid.",
											errorSnackbarOpen: true,
											error: 4,
										})
								: null
						}
					>
						{shopifySuccess ? "Shopify Connected" : "Connect Shopify"}
					</div>
				</a>
				<br />
				<a style={{ textDecoration: "none" }} href={stripeSuccess ? null : stripeRedirect}>
					<div
						className={classes.button}
						style={stripeSuccess ? successStyle : error === 7 ? errorStyle : regularStyle}
					>
						{stripeSuccess ? "Stripe Connected" : "Connect Stripe"}
					</div>
				</a>
				{urlParams.error ? (
					<p
						style={{
							color: "#f00",
						}}
					>
						{urlParams.error_description}
					</p>
				) : null}
				<div className={classes.info} onClick={this.handleInfo}>
					<InfoIcon fontSize="small" /> <p>Info</p>
				</div>
				<Dialog
					fullScreen={fullScreen}
					open={this.state.open}
					onClose={this.handleInfo}
					aria-labelledby="stripe-user-info"
				>
					<DialogContent style={{ textAlign: "center" }}>
						<p style={{ textAlign: "center", fontSize: "1.6em" }}>
							<span
								style={{
									color: "#35B2FF",
									fontWeight: 900,
								}}
							>
								SET UP
							</span>
							<span style={{ fontWeight: 900 }}> PAYMENTS</span>
						</p>
						<p style={{ color: "#7f7f7f", fontSize: "0.8em" }}>
							<PaymentIcon
								style={{
									verticalAlign: "middle",
									position: "relative",
									marginRight: "0.5em",
									top: "-0.088em",
								}}
							/>
							PAYMENTS POWERED BY STRIPE
						</p>
						<p>
							{" "}
							To process payments for EnterOffer, you’ll need to create a free account with Stripe or
							connect your Stripe account
						</p>
						<p style={{ color: "#7f7f7f", textAlign: "left" }}>
							<LockIcon
								style={{
									verticalAlign: "middle",
									position: "relative",
									marginRight: "0.5em",
									top: "-0.17em",
								}}
							/>
							Stripe handles billions of transactions with world-class security and identity verification.
						</p>
						<p style={{ color: "#7f7f7f", textAlign: "left" }}>
							<SecurityIcon
								style={{
									verticalAlign: "middle",
									position: "relative",
									marginRight: "0.5em",
									top: "-0.17em",
								}}
							/>
							The information you provide is only used to verify and set up payments for your business.
						</p>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleInfo} color="primary" autoFocus>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
export default withMobileDialog()(withRouter(withStyles(styles)(ShopifyStripeConnect)));
