import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Toolbar, AppBar, Typography, Grid } from "@material-ui/core";
import withSizes from "react-sizes";
import moment from "moment";

const styles = (theme) => ({
	toolbar: {
		alignitems: "center",
		justifyContent: "space-between",
	},
	appBar: {
		top: "auto",
		bottom: 0,
		position: "unset !important",
		width: "100vw",
		marginLeft: "-8px",
		marginTop: "1vw",
		backgroundColor: "transparent",
		boxShadow: "none",
	},
	footerLinks: {
		backgroundColor: "#3F414C",
		width: "100%",
		marginTop: "1vw",
		color: "#FFF",
	},
});

class BottomNav extends Component {
	render() {
		const { classes, isMobile } = this.props;

		return (
			<AppBar color="primary" className={classes.appBar}>
				<div className={classes.footerLinks} style={{ padding: isMobile ? null : "0px !important" }}>
					<Grid
						container
						style={{
							paddingLeft: isMobile ? null : "24px",
							zoom: isMobile ? 0.9 : null,
							lineHeight: isMobile ? 1.8 : null,
						}}
					>
						<Grid item style={{ padding: isMobile ? "0px 8px" : null }} xs={isMobile ? 4 : 3}>
							<p>
								<b>EnterOffer</b>
							</p>
							<p>
								<a
									href="https://enteroffer.com/about"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									About Us
								</a>
								<br />
								<a
									href="https://enteroffer.com/terms"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Term of Use
								</a>
								<br />
								<a
									href="https://enteroffer.com/privacy"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Privacy Policy
								</a>
								<br />
							</p>
						</Grid>
						<Grid item style={{ padding: isMobile ? "0px 8px" : null }} xs={isMobile ? 4 : 3}>
							<p>
								<b>Help & Support</b>
							</p>
							<p>
								<a
									href="https://enteroffer.com/faq"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									FAQ
								</a>
								<br />
								<a
									href="https://enteroffer.com/#contactUs"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Contact Us
								</a>
								<br />
							</p>
						</Grid>
						<Grid item style={{ padding: isMobile ? "0px 8px" : null }} xs={isMobile ? 4 : 3}>
							<p>
								<b>Follow Us</b>
							</p>
							<p>
								<a
									href="https://www.instagram.com/enteroffer/"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Instagram
								</a>
								<br />
								<a
									href="https://www.facebook.com/enteroffer/"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Facebook
								</a>
								<br />
								<a
									href="https://twitter.com/EnterOffer"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										textDecoration: "none",
										color: "#FFF",
									}}
								>
									Twitter
								</a>
								<br />
							</p>
						</Grid>
					</Grid>
				</div>
				<Toolbar className={classes.toolbar}>
					<div>
						<img
							style={{ width: "9em" }}
							src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
							alt="EnterOffer"
						/>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							style={{
								fontWeight: "100",
								color: "#929292",
								fontSize: isMobile ? "12px" : null,
								textAlign: isMobile ? "right" : null,
							}}
						>
							COPYRIGHT &copy; {moment().year()} EnterOffer
						</Typography>
					</div>
				</Toolbar>
			</AppBar>
		);
	}
}
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 700,
});

export default withSizes(mapSizesToProps)(withStyles(styles)(BottomNav));
