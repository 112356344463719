import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
	Button,
	Tabs,
	Tab,
	Snackbar,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	Tooltip,
	Paper,
	Card,
} from "@material-ui/core";
import { db, fb } from "../firebase";
import { Refresh, ChevronRight } from "@material-ui/icons";
import { HelpModal, ProductDrawer } from "./index";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import withSizes from "react-sizes";

function desc(a, b, orderBy) {
	return a[orderBy] < b[orderBy] ? 1 : -1;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
	return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
	{
		sno: 0,
		id: "product",
		numeric: false,
		disablePadding: false,
		label: "Offers",
	},
	{
		sno: 6,
		id: "discountCalc",
		numeric: false,
		disablePadding: true,
		label: "Discount (%)",
	},
];

const rejectRows = rows.slice(0, -2);

function getTotalCost(row) {
	let totalCost = 0;
	for (let i in row.products) {
		totalCost += Number(row.products[i].cost);
	}
	return totalCost;
}
class EnhancedTableHead extends React.Component {
	createSortHandler = (property) => (event) => {
		this.props.onRequestSort(event, property);
	};

	handleResponseChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const { order, orderBy, transCol, orderCol } = this.props;
		var usedRows;
		if (!transCol && !orderCol) {
			usedRows = rejectRows;
		} else {
			usedRows = rows;
		}
		return (
			<TableHead>
				<TableRow>
					{usedRows.map((row) => {
						return (
							<TableCell
								style={{
									textAlign: "left",
									fontSize: "1em",
									color: row.sno === 0 ? "#000" : "#929292",
									fontWeight: row.sno === 0 ? 700 : null,
								}}
								key={row.id}
								padding={row.disablePadding ? "none" : "default"}
								sortDirection={orderBy === row.id ? order : false}
							>
								<Tooltip
									title="Sort"
									placement={row.numeric ? "bottom-end" : "bottom-start"}
									enterDelay={300}
								>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>
								{row.sno === 0 ? (
									<Refresh
										onClick={() =>
											this.props.refresh(this.props.typeName, true, this.props.storeName)
										}
										style={{
											color: "#35B2FF",
											marginLeft: "1em",
											verticalAlign: "bottom",
										}}
									/>
								) : null}
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	transCol: PropTypes.bool,
	orderCol: PropTypes.bool,
};

const styles = (theme) => ({
	root: {
		marginTop: -theme.spacing(6),
		boxShadow: "none",
	},

	innerroot: {
		border: "2px solid #f6f6f8",
		marginBottom: "2px",
		borderRadius: "10px",
	},
	table: {
		minWidth: 280,
		textAlign: "left",
	},
	button: {
		color: "#35B2FF",
		margin: "0em 0.5em",
		verticalAlign: "baseline",
	},
	tableWrapper: {
		overflowX: "auto",
	},
	divLogo: {
		paddingTop: "30px",
	},
	media: {
		width: "auto",
		height: "70px",
		marginLeft: "auto",
		marginRight: "auto",
		display: "block",
	},
	titleText: {
		paddingTop: "50px",
	},
	noItems: {
		textAlign: "center",
		margin: theme.spacing(2),
	},
	choiceButton1: {
		"&:hover": {
			backgroundColor: "#22c78e44 !important",
		},
	},
	choiceButton2: {
		"&:hover": {
			backgroundColor: "#ff4d4d11 !important",
		},
	},
	bundlePNames: {
		paddingLeft: "6% !important",
	},
	tabsStyle: {
		display: "inline-flex",
		borderRadius: "30px",
		backgroundColor: "#35B2FF44",
		padding: "4px",
		minHeight: "30px !important",
	},
	tabStyle: {
		padding: 0,
		fontWeight: 700,
		borderRadius: "50px",
		textTransform: "capitalize",
		fontSize: "0.9em",
		// minWidth:'100px !important',
		minHeight: "30px !important",
	},
});

class EnhancedTable extends React.Component {
	handleCloseDialogCancel = () => {
		this.setState({ openDialog: false });
	};

	handleCloseDialogConfirm = () => {
		var row = this.state.row;
		var inventoryError = "Products:";
		var internalError = "Sorry your approval did not go through. Please try again later.";
		if (this.state.functionType === "approved") {
			this.setState({ offerDecideDialog: true });
			fb.checkBundleInventory(this.state.storeName, row.products)
				.then((result) => {
					if (result === 0) {
						const { data } = this.state;
						let tempList = data;
						row.itemStatus = this.state.functionType;
						row.approved = true;

						this.setState({ data: tempList });
						if (this.state.functionType === "approved") {
							this.setState({
								offerAcceptDialog: true,
								offerDecideDialog: false,
							});
							fb.approveOffer(this.state.storeName, row).then((result) => {
								this.getItemsFunc(this.state.typeName.toLowerCase(), true, this.state.storeName);
								this.setState({
									offerAcceptDialog: false,
								});
							});
						} else {
							const { data } = this.state;
							let tempList = data;
							row.itemStatus = this.state.functionType;
							row.rejected = true;

							this.setState({ data: tempList });
							fb.rejectOffer(this.state.storeName, row).then((result) => {
								this.getItemsFunc(this.state.typeName.toLowerCase(), true, this.state.storeName);
								this.setState({
									offerAcceptDialog: false,
								});
							});
						}
					} else if (result === 2) {
						this.setState({
							offerDecideDialog: false,
							offerResultTitle: "Offer Submit Error",
							offerResultText: internalError,
							offerResultDialog: true,
						});
					} else {
						for (let i in result) {
							for (let j in row.products) {
								if (result[i].productID === row.products[j].productID) {
									inventoryError += " " + row.products[j].productName + ", ";
								}
							}
						}
						inventoryError = inventoryError.substr(0, inventoryError.length - 2);
						inventoryError +=
							" out of inventory. Please restock on shopify admin if you wish to approve this offer.";
						this.setState({
							offerDecideDialog: false,
							offerResultTitle: "Out of stock",
							offerResultText: inventoryError,
							offerResultDialog: true,
						});
					}
				})
				.catch(console.error);
		} else {
			const { data } = this.state;
			let tempList = data;
			row.itemStatus = this.state.functionType;
			row.rejected = true;

			this.setState({ data: tempList });
			fb.rejectOffer(this.state.storeName, row).then((result) => {
				this.getItemsFunc(this.state.typeName.toLowerCase(), true, this.state.storeName);
				this.setState({ offerAcceptDialog: false });
			});
		}

		this.setState({ openDialog: false });
	};

	clickedRowItem(row, functionType) {
		var inventoryError = "Products:";
		var internalError = "Sorry your approval did not go through. Please try again later.";
		if (!row.approved && !row.rejected) {
			db.getUserConfirmPref().then((snapshot) => {
				if (snapshot === 1) {
					this.setState({
						openDialog: true,
						functionType: functionType,
						row: row,
					});
				} else {
					if (functionType === "approved") {
						this.setState({ offerDecideDialog: true });
						fb.checkBundleInventory(this.state.storeName, row.products)
							.then((result) => {
								if (result === 0) {
									const { data } = this.state;
									let tempList = data;
									row.itemStatus = functionType;
									row.approved = true;

									this.setState({ data: tempList });

									this.setState({ data: tempList });
									this.setState({
										offerAcceptDialog: true,
										offerDecideDialog: false,
									});
									fb.approveOffer(this.state.storeName, row).then((result) => {
										this.getItemsFunc(
											this.state.typeName.toLowerCase(),
											true,
											this.state.storeName
										);
										this.setState({
											offerAcceptDialog: false,
										});
									});
								} else if (result === 2) {
									this.setState({
										offerDecideDialog: false,
										offerResultTitle: "Offer Submit Error",
										offerResultText: internalError,
										offerResultDialog: true,
									});
								} else {
									for (let i in result) {
										for (let j in row.products) {
											if (result[i].productID === row.products[j].productID) {
												inventoryError += " " + row.products[j].productName + ", ";
											}
										}
									}
									inventoryError = inventoryError.substr(0, inventoryError.length - 2);
									inventoryError +=
										" out of inventory. Please restock on shopify admin if you wish to approve this offer.";
									this.setState({
										offerDecideDialog: false,
										offerResultTitle: "Out of stock",
										offerResultText: inventoryError,
										offerResultDialog: true,
									});
								}
							})
							.catch(console.error);
					} else {
						const { data } = this.state;
						let tempList = data;
						row.itemStatus = functionType;
						row.rejected = true;

						this.setState({ data: tempList });
						fb.rejectOffer(this.state.storeName, row).then((result) => {
							this.getItemsFunc(this.state.typeName.toLowerCase(), true, this.state.storeName);
							this.setState({ offerAcceptDialog: false });
						});
					}
				}
			});
		}
	}

	didApproveOrReject(typeOfClose) {
		this.setState({ openMessage: false });
	}

	state = {
		order: "desc",
		openSuccess: false,
		openMessage: false,
		showAreYouSureMessage: true,
		orderBy: "submittedDate",
		selected: [],
		type: 0,
		storeName: "",
		typeName: "Pending",
		data: [
			{
				id: -1,
				itemKey: "",
				discountCalc: "",
				currency: "",
				bidPrice: "",
				listPrice: "",
				inventory: "",
				product: "",
				email: "",
				submittedDate: "",
				approve: "",
				reject: "",
			},
		],
		dataLength: 0,
		page: 0,
		rowsPerPage: 10,
		loading: true,
		openDialog: false,
		functionType: "",
		row: {},
		offerDecideDialog: false,
		offerResultDialog: false,
		offerAcceptDialog: false,
		offerResultTitle: "",
		offerResultText: "",
		openDrawer: false,
		chosenRow: -1,
	};

	handleChange = (event, type) => {
		this.setState({ type });
		this.setState({ loading: true });
		if (type === 0) {
			this.getItemsFunc("pending", false, this.state.storeName);
			this.setState({ typeName: "Pending", page: 0 });
		} else if (type === 1) {
			this.getItemsFunc("approved", false, this.state.storeName);
			this.setState({ typeName: "Approved", page: 0 });
		} else if (type === 2) {
			this.getItemsFunc("rejected", false, this.state.storeName);
			this.setState({ typeName: "Rejected", page: 0 });
		} else if (type === 3) {
			this.getItemsFunc("expired", false, this.state.storeName);
			this.setState({ typeName: "Expired", page: 0 });
		}
	};

	snapshotToArray(snapshot) {
		var returnArr = [];
		var i = 0;
		snapshot.forEach(function (childSnapshot) {
			var item = childSnapshot.val();
			item.itemStatus = "";
			if (item.approved === true && item.rejected === false) {
				item.itemStatus = "approved";
			} else if (item.approved === false && item.rejected === true) {
				item.itemStatus = "rejected";
			}

			item.key = childSnapshot.key;
			item.id = i;
			i = i + 1;
			returnArr.push(item);
		});
		return returnArr;
	}

	getItemsFunc(whichType, didClickUpdate, storeName) {
		if (didClickUpdate) {
			this.props.refreshCallback();
		}
		db.getStoreBids(storeName, whichType)
			.then((snapshot) => {
				if (snapshot !== "noData") {
					this.setState({
						data: this.snapshotToArray(snapshot),
						dataLength: this.snapshotToArray(snapshot).length,
						loading: false,
					});
				}
				if (this.state.dataLength === 0) {
					this.setState({ loading: false });
				}
				this.setState({ loading: false });
				if (didClickUpdate) {
					this.setState({ openSuccess: true });
				}
			})
			.catch((e) => console.log("error", e));
	}

	componentDidMount() {
		db.getUserStoreName()
			.then((snapshot) => {
				if (snapshot == null) {
					return "noData";
				} else {
					this.setState({ storeName: snapshot });
					this.getItemsFunc("pending", false, snapshot);
				}
			})
			.catch((e) => console.log("error", e));
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}
		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value });
	};

	handleClickSnackSuccess = () => {
		this.setState({ openSuccess: false });
	};

	handleClose = () => {
		this.setState({ offerResultDialog: false });
	};
	isSelected = (id) => this.state.selected.indexOf(id) !== -1;

	handleOpenDrawer = (id) => {
		this.setState({ openDrawer: true, chosenRow: id });
	};
	handleCloseDrawer = () => {
		this.setState({ openDrawer: false, chosenRow: -1 });
	};
	handleRefresh = (t, b, s) => {
		this.getItemsFunc(t, b, s);
	};

	render() {
		const { classes, isMobile } = this.props;
		const {
			data,
			order,
			orderBy,
			selected,
			rowsPerPage,
			page,
			typeName,
			openSuccess,
			dataLength,
			offerDecideDialog,
			offerResultTitle,
			offerResultText,
			offerResultDialog,
			offerAcceptDialog,
		} = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

		return (
			<div>
				<Paper className={classes.root}>
					<HelpModal
						open={this.state.openMessage}
						isAreYouSure={true}
						handleClose={(typeOfClose) => this.didApproveOrReject(typeOfClose)}
					/>
					<div
						style={{
							display: "inline-grid",
							width: "100%",
							marginTop: "2.5em",
							marginBottom: "1em",
						}}
					>
						<Tabs
							className={classes.tabsStyle}
							value={this.state.type}
							TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
							onChange={this.handleChange}
							variant={isMobile ? "fullWidth" : null}
						>
							<Tab
								className={classes.tabStyle}
								style={{
									color: this.state.type !== 0 ? "#000" : "#FFF",
									backgroundColor: this.state.type !== 0 ? "transparent" : "#35B2FF",
								}}
								label="Pending"
							/>
							<Tab
								className={classes.tabStyle}
								style={{
									color: this.state.type !== 1 ? "#000" : "#FFF",
									backgroundColor: this.state.type !== 1 ? "transparent" : "#35B2FF",
								}}
								label="Approved"
							/>
							<Tab
								className={classes.tabStyle}
								style={{
									color: this.state.type !== 2 ? "#000" : "#FFF",
									backgroundColor: this.state.type !== 2 ? "transparent" : "#35B2FF",
								}}
								label="Rejected"
							/>
							<Tab
								className={classes.tabStyle}
								style={{
									color: this.state.type !== 3 ? "#000" : "#FFF",
									backgroundColor: this.state.type !== 3 ? "transparent" : "#35B2FF",
								}}
								label="Expired"
							/>
						</Tabs>
					</div>

					<div className={classes.tableWrapper}>
						{this.state.loading ? (
							<Lottie
								height={200}
								width={200}
								options={{
									loop: true,
									autoplay: true,
									animationData: loader.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
							/>
						) : dataLength === 0 ? (
							<Card
								elevation={1}
								className={classes.noItems}
								style={{
									// marginBottom: '300px',
									minHeight: 15 * emptyRows,
								}}
							>
								<div className={classes.divLogo}>
									<Typography
										className={classes.titleText}
										style={{
											color: "#3e3e3e",
											fontSize: "1.5em",
											paddingTop: "0px",
											marginBottom: "8px",
										}}
									>
										No Items In {this.state.typeName}
									</Typography>
									{typeName === "Pending" ? (
										<Typography
											style={{
												color: "#7f7f7f",
												marginBottom: "1em",
											}}
										>
											Great Work!
										</Typography>
									) : null}

									<Refresh
										onClick={() => this.getItemsFunc(this.state.typeName.toLowerCase(), true)}
										style={{
											fontSize: "1.6em",
											color: "#35B2FF",
											marginBottom: "1em",
										}}
									/>
								</div>
							</Card>
						) : (
							<Card className={classes.innerroot} elevation={1}>
								<Table padding="none" className={classes.table} aria-labelledby="tableTitle">
									<EnhancedTableHead
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
										storeName={this.state.storeName}
										refresh={this.handleRefresh}
										typeName={this.state.typeName.toLowerCase()}
										rowCount={dataLength}
										transCol={true}
										orderCol={true}
										resCol={this.state.typeName !== "Pending" ? false : true}
									/>
									<TableBody>
										{stableSort(data, getSorting(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((n) => {
												if (n.id !== -1) {
													if (n.itemCount > 1) {
														var obj = {};
														for (let key in n.products) {
															obj[key] = n.products[key];
														}
													}
													const isSelected = this.isSelected(n.id);
													return (
														<Fragment key={n.id}>
															<TableRow
																role="checkbox"
																aria-checked={isSelected}
																tabIndex={-1}
																key={n.id}
																onClick={() => this.handleOpenDrawer(n.id)}
															>
																<ProductDrawer
																	key={n.id}
																	open={
																		n.id === this.state.chosenRow &&
																		this.state.openDrawer
																	}
																	data={n}
																	productData={obj}
																	closeDrawer={this.handleCloseDrawer}
																	totalCost={Number(getTotalCost(n)).toFixed(2)}
																	tab={this.state.typeName}
																	response={(data, resp) =>
																		this.clickedRowItem(data, resp)
																	}
																/>
																{n.itemCount > 1 ? (
																	<TableCell
																		id="product"
																		style={{
																			paddingLeft: "2%",
																			color:
																				typeName === "Approved" &&
																				(!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null &&
																					  n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		Bundle ({n.itemCount})
																		{n.cartAbandoned ? (
																			<Tooltip title="Cart Abandonment Offer">
																				<span
																					style={{
																						verticalAlign: "super",
																						fontSize: "0.8em",
																						color: "#22c78e",
																						cursor: "pointer",
																					}}
																				>
																					{" "}
																					CA
																				</span>
																			</Tooltip>
																		) : null}
																		{n.spp === true || n.spp === false ? (
																			<Tooltip title="Single Product Promotion">
																				<span
																					style={{
																						verticalAlign: "super",
																						fontSize: "0.8em",
																						color: "#f9499f",
																						cursor: "pointer",
																					}}
																				>
																					{" "}
																					SPP ({n.spp === true ? "5%" : "2%"})
																				</span>
																			</Tooltip>
																		) : null}
																		{n.boost !== null && n.boost !== undefined ? (
																			<img
																				src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/RocketBoost.png"
																				height={"16px"}
																				alt=""
																			/>
																		) : null}
																	</TableCell>
																) : n.products !== undefined && n.products !== null ? (
																	<TableCell
																		id="product"
																		style={{
																			paddingLeft: "2%",
																			color:
																				typeName === "Approved" &&
																				(!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null &&
																					  n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{n.products[0].productName}
																		{Number(n.products[0].quantity) > 1
																			? ` (${Number(n.products[0].quantity)})`
																			: null}
																		{n.cartAbandoned ? (
																			<Tooltip title="Cart Abandonment Offer">
																				<span
																					style={{
																						verticalAlign: "super",
																						fontSize: "0.8em",
																						color: "#22c78e",
																						cursor: "pointer",
																					}}
																				>
																					{" "}
																					CA
																				</span>
																			</Tooltip>
																		) : null}
																		{n.spp === true || n.spp === false ? (
																			<Tooltip title="Single Product Promotion">
																				<span
																					style={{
																						verticalAlign: "super",
																						fontSize: "0.8em",
																						color: "#f9499f",
																						cursor: "pointer",
																					}}
																				>
																					{" "}
																					SPP ({n.spp === true ? "5%" : "2%"})
																				</span>
																			</Tooltip>
																		) : null}
																		{n.boost !== null && n.boost !== undefined ? (
																			<img
																				src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/RocketBoost.png"
																				height={"16px"}
																				alt=""
																			/>
																		) : null}
																	</TableCell>
																) : (
																	<TableCell
																		id="product"
																		style={{
																			paddingLeft: "2%",
																			color:
																				typeName === "Approved" &&
																				(!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null &&
																					  n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{n.productName}
																		{Number(n.quantity) > 1
																			? ` (${Number(n.quantity)})`
																			: null}
																		{n.cartAbandoned ? (
																			<Tooltip title="Cart Abandonment Offer">
																				<span
																					style={{
																						verticalAlign: "super",
																						fontSize: "0.8em",
																						color: "#22c78e",
																						cursor: "pointer",
																					}}
																				>
																					{" "}
																					CA
																				</span>
																			</Tooltip>
																		) : null}
																		{n.spp === true || n.spp === false ? (
																			<Tooltip title="Single Product Promotion">
																				<span
																					style={{
																						verticalAlign: "super",
																						fontSize: "0.8em",
																						color: "#f9499f",
																						cursor: "pointer",
																					}}
																				>
																					{" "}
																					SPP ({n.spp === true ? "5%" : "2%"})
																				</span>
																			</Tooltip>
																		) : null}
																		{n.boost !== null && n.boost !== undefined ? (
																			<img
																				src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/RocketBoost.png"
																				height={"16px"}
																				alt=""
																			/>
																		) : null}
																	</TableCell>
																)}
																<TableCell
																	id="discountCalc"
																	style={{
																		textAlign: "left",
																		color:
																			typeName === "Approved" &&
																			(!n.payment || !n.order)
																				? "#f00"
																				: n.boost !== null &&
																				  n.boost !== undefined
																				? "#35b2ff"
																				: null,
																	}}
																>
																	{isNaN(Number(n.discountCalc)) ? null : (
																		<span>
																			<span
																				style={{
																					verticalAlign:
																						"-webkit-baseline-middle",
																				}}
																			>
																				{Number(n.discountCalc).toFixed(2)}
																			</span>
																			<span
																				style={{
																					float: "right",
																					color: "#929292",
																				}}
																			>
																				<ChevronRight
																					style={{
																						verticalAlign: "bottom",
																					}}
																				/>
																			</span>
																		</span>
																	)}
																</TableCell>
															</TableRow>
															{n.itemCount > 1
																? Object.keys(obj).map((key) => (
																		<TableRow
																			role="checkbox"
																			aria-checked={isSelected}
																			tabIndex={-1}
																			key={obj[key].variantID}
																			selected={true}
																			style={{
																				backgroundColor: "#f6f6f8",
																			}}
																		>
																			<TableCell className={classes.bundlePNames}>
																				{obj[key].productName}
																			</TableCell>
																			<TableCell
																				style={{
																					textAlign: "left",
																				}}
																			>
																				{n.cartAbandoned
																					? "-"
																					: Number(
																							obj[key].discountCalc
																					  ).toFixed(2)}
																			</TableCell>
																		</TableRow>
																  ))
																: null}
														</Fragment>
													);
												} else {
													return null;
												}
											})}
										<Dialog open={this.state.openDialog} onClose={this.handleCloseDialog}>
											<DialogContent
												style={{
													fontSize: "1.2em",
												}}
											>
												{this.state.functionType === "approved" ? (
													<div>
														<p>
															Are you sure you want to approve this offer? <br />
															<b>This is not reversible.</b>
														</p>
														<br />
														<p
															style={{
																color: "#929292",
																fontSize: "0.8em",
															}}
														>
															You can remove this confirmation under settings
														</p>
													</div>
												) : (
													<div>
														<p>
															Are you sure you want to reject this offer? <br />
															<b>This is not reversible.</b>
														</p>
														<br />
														<p
															style={{
																color: "#929292",
																fontSize: "0.8em",
															}}
														>
															You can remove this confirmation under settings
														</p>
													</div>
												)}
											</DialogContent>
											<DialogActions
												style={{
													margin: "0.5em",
												}}
											>
												<Button
													onClick={this.handleCloseDialogCancel}
													color="primary"
													style={{
														marginRight: "0.5em",
													}}
												>
													Cancel
												</Button>
												<Button
													className={
														this.state.functionType === "approved"
															? classes.choiceButton1
															: classes.choiceButton2
													}
													onClick={this.handleCloseDialogConfirm}
													style={{
														color:
															this.state.functionType === "approved"
																? "#22c78e"
																: "#ff4d4d",
													}}
													autoFocus
												>
													{this.state.functionType === "approved"
														? "Approve Offer"
														: "Reject Offer"}
												</Button>
											</DialogActions>
										</Dialog>

										{emptyRows > 0 && (
											<TableRow
												style={{
													height: 4 * emptyRows,
													borderBottom: "1px solid transparent",
												}}
											>
												<TableCell
													colSpan={6}
													style={{
														borderBottom: "1px solid transparent",
													}}
												/>
											</TableRow>
										)}
									</TableBody>
								</Table>
								<TablePagination
									component="div"
									count={dataLength}
									rowsPerPage={rowsPerPage}
									labelRowsPerPage="Offers per page"
									page={page}
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
							</Card>
						)}
					</div>
				</Paper>

				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					open={openSuccess}
					onClose={this.handleClickSnackSuccess}
					autoHideDuration={3000}
					ContentProps={{
						"aria-describedby": "message-id",
					}}
					message={<span id="message-id">Page Updated</span>}
				/>
				<Dialog
					titleStyle={{ textAlign: "center" }}
					open={offerDecideDialog}
					onClose={() => {
						this.setState({ offerDecideDialog: false });
					}}
					disableBackdropClick={true}
					disableEscapeKeyDown={true}
				>
					<DialogTitle style={{ textAlign: "center" }}>Checking Inventory</DialogTitle>
					<DialogContent style={{ textAlign: "center" }}>
						<p>This may take up to a minute...</p>
						<Lottie
							height={100}
							width={100}
							options={{
								loop: true,
								autoplay: true,
								animationData: loader.default,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
						/>
					</DialogContent>
				</Dialog>
				<Dialog
					titleStyle={{ textAlign: "center" }}
					open={offerAcceptDialog}
					onClose={() => {
						this.setState({ offerAcceptDialog: false });
					}}
					disableBackdropClick={true}
					disableEscapeKeyDown={true}
				>
					<DialogTitle style={{ textAlign: "center" }}>Accepting Offer</DialogTitle>
					<DialogContent style={{ textAlign: "center" }}>
						<p>Taking payment and creating order...</p>
						<Lottie
							height={100}
							width={100}
							options={{
								loop: true,
								autoplay: true,
								animationData: loader.default,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
						/>
					</DialogContent>
				</Dialog>
				<Dialog
					open={offerResultDialog}
					onClose={() => {
						this.setState({ offerResultDialog: false });
					}}
				>
					<DialogTitle style={{ textAlign: "center" }}>{offerResultTitle}</DialogTitle>
					<DialogContent style={{ textAlign: "center" }}>
						<p>{offerResultText}</p>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary" autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 1000,
});

export default withSizes(mapSizesToProps)(withStyles(styles)(EnhancedTable));
