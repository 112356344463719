import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { firebase } from "./firebase";

class AuthorizedRoute extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pending: true,
			loggedIn: undefined
		};
	}

	UNSAFE_componentWillMount() {
		firebase.auth().onAuthStateChanged(user => {
			this.setState({
				pending: false,
				loggedIn: !!user
			});
		});
	}

	render() {
		const { component: Component, ...rest } = this.props;
		return (
			<Route
				{...rest}
				render={renderProps => {
					if (this.state.pending) return null;
					return this.state.loggedIn ? (
						<Component {...renderProps} />
					) : (
						<Redirect
							to={{
								pathname: "/signin",
								state: {
									from: renderProps.location
								}
							}}
						/>
					);
				}}
			/>
		);
	}
}

export default AuthorizedRoute;
