import React, { Component } from "react";
import { BundleTableView, Graphs, SalesGraphs, SignUpForm, MobileTableView, BottomNav } from "../components";
import { db } from "../firebase";
import withSizes from "react-sizes";

// import EnterOfferLogoColor from '../assets/EnterOfferLogoColor.png'

class Home extends Component {
	state = {
		data: [],
		loading: true,
		logo: "",
		refresh: false
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({
					storeName: snapshot,
					loading: false
				});
				return db.isOnline(this.state.storeName);
			})
			.catch(e => {
				console.error(e);
			});
	}
	handleRefresh = () => {
		this.setState({ refresh: true });
		setTimeout(() => {
			this.setState({ refresh: false });
		}, 2000);
	};

	render() {
		const { isMobile } = this.props;
		const { loading, storeName, refresh } = this.state;
		var retailerLogo = `https://s3-ap-southeast-2.amazonaws.com/oaklas/logos/${storeName}Logo.png`;
		if (loading === true) return null;
		else if (storeName !== null)
			return (
				<div container='container' spacing={0}>
					<div
						style={{
							textAlign: "center",
							margin: isMobile ? "3em 3em 0em" : "2em"
						}}>
						<img
							src={retailerLogo}
							alt={storeName}
							style={{
								maxWidth: isMobile ? "75%" : "25%",
								maxHeight: " 60px",
								display: "inline-block",
								objectFit: "cover",
								backgroundColor: "#fff"
							}}
						/>
					</div>
					<div
						style={{
							padding: 0,
							margin: 0
						}}>
						<SalesGraphs isMobile={isMobile} refresh={refresh} />
						{isMobile ? <MobileTableView refreshCallback={this.handleRefresh} /> : <BundleTableView refreshCallback={this.handleRefresh} />}

						{isMobile ? null : <br />}
						{isMobile ? null : <br />}
						{isMobile ? <Graphs flag={false} isMobile={isMobile} efresh={refresh} /> : <Graphs flag={true} isMobile={isMobile} refresh={refresh} />}
					</div>
					<BottomNav />
				</div>
			);
		else {
			this.props.cb("Not Signed Up");
			return (
				<div style={{ height: "100vh" }}>
					<SignUpForm />
				</div>
			);
		}
	}
}
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 700
});

export default withSizes(mapSizesToProps)(Home);
