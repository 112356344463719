import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { db } from "../firebase";
import _ from "lodash";
import { Typography, Snackbar, Grid, TextField, MenuItem } from "@material-ui/core";
import Moment from "moment";

const timeRanges = [
	{
		value: 30,
		label: "Past 30 days"
	},
	{
		value: 14,
		label: "Past 14 days"
	},
	{
		value: 7,
		label: "Past 7 days"
	}
];
const styles = theme => ({
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		backgroundColor: "#F6F6F8",
		borderRadius: "4px"
	},

	inputDrop: {
		marginLeft: " -4px",
		padding: "0.2em 1.8em 0.2em 1em",
		fontWeight: "700",
		fontSize: "0.8em"
	},
	notchedOutline: {
		borderWidth: "1px",
		borderColor: "#f6f6f8 !important"
	}
});

class PopularProduct extends Component {
	state = {
		storeName: "",
		max: 0,
		conversions: 0,
		avgAcceptedPrice: 0,
		avgAcceptedDisc: 0,
		product: {},
		products: [],
		productList: [],
		productCounts: [],
		open: false,
		timeRange: 30,
		updated: false
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: false,
		legendPosition: "top"
	};

	componentDidMount() {
		this.updateState(false);
	}
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
			updated: true
		});
		this.updateState(true);
	};

	updateState(notFromMount) {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				return db.getStoreBids(snapshot, "approved");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "approved");
				return db.getStoreBids(this.state.storeName, "expired");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "expired");
				return db.getStoreBids(this.state.storeName, "rejected");
			})
			.then(snapshot => {
				return this.getOfferData(snapshot, "rejected");
			})
			.catch(console.error);

		if (notFromMount) {
			this.setState({ open: true });
		}
	}

	getOfferData = (snapshot, location) => {
		let products = this.state.updated ? [] : this.state.products;
		let productList = this.state.updated ? [] : this.state.productList;
		let productCounts = [];
		snapshot.forEach(offer => {
			if (Moment().diff(offer.val().submittedDate, "days") < this.state.timeRange) {
				let arr = offer.val().products;
				if (offer.val().products !== undefined && offer.val().products !== null) {
					arr.forEach(p => {
						if (offer.val().approved) p.approved = true;
						else p.approved = false;
						products.push(p);
						if (productList.indexOf(p.productName) === -1) {
							productList.push(p.productName);
						}
					});
				}
			}
		});

		if (location === "rejected") {
			productList.forEach(item => {
				let count = 0;
				let prObj = {};
				count = products.filter(obj => obj.productName === item).length;
				prObj[item] = count;
				productCounts.push(prObj);
			});
			this.findProduct(products, productCounts);
		}
		this.setState({
			products: products,
			productList: productList,
			productCounts: productCounts,
			updated: false
		});
	};

	findProduct = (products, productCounts) => {
		var max = 0;
		var maxName = "";
		productCounts.forEach(element => {
			if (element[Object.keys(element)[0]] > max) {
				max = element[Object.keys(element)[0]];
				maxName = Object.keys(element)[0];
			}
		});

		var newArray = products.filter(function(el) {
			return el.productName === maxName;
		});
		newArray = newArray.sort((a, b) => (a.submittedDate > b.submittedDate ? 1 : -1));
		let conversions = newArray.filter(obj => obj.approved === true);

		let avgAcceptedPrice = conversions.reduce((total, next) => total + next.bidPrice, 0) / conversions.length;

		let avgAcceptedDisc = conversions.reduce((total, next) => total + next.discountCalc, 0) / conversions.length;

		this.setState({
			product: newArray[0],
			max: max,
			conversions: conversions.length,
			avgAcceptedPrice: avgAcceptedPrice,
			avgAcceptedDisc: avgAcceptedDisc,
			updated: false
		});
	};

	handleCloseSnackSuccess = () => {
		this.setState({ open: false });
	};

	render() {
		const { product, max, conversions, avgAcceptedDisc, avgAcceptedPrice } = this.state;
		const { classes } = this.props;
		return (
			<div style={{ textAlign: "left" }}>
				<div>
					<Typography
						style={{
							color: "#000",
							fontSize: "1em",
							fontWeight: 700
						}}>
						Most popular product
					</Typography>
				</div>
				<TextField
					id='outlined-select-timeRange'
					select
					className={classes.textField}
					value={this.state.timeRange}
					onChange={this.handleChange("timeRange")}
					style={{ marginRight: this.props.isMobile ? "4px" : null }}
					InputProps={{
						classes: {
							input: classes.inputDrop,
							notchedOutline: classes.notchedOutline
						}
					}}
					// margin='normal'
					variant='outlined'>
					{timeRanges.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br />
				<br />
				{_.isEmpty(product) ? (
					<h3 style={{ textAlign: "center" }}>No sales made in the selected date range.</h3>
				) : (
					<Grid container style={{ textAlign: "left" }} spacing={4}>
						<Grid item xs={5} style={{ textAlign: "center" }}>
							<img
								alt={product.productName}
								src={product.image}
								height={150}
								style={{
									maxWidth: "100%",
									objectFit: "contain",
									backgroundColor: "#fff"
								}}
							/>
						</Grid>
						<Grid item xs={7}>
							<Typography
								style={{
									color: "#3e3e3e",
									fontSize: "18px",
									fontWeight: "700"
								}}>
								{product.productName}
								<br />${Number(product.listPrice).toFixed(2)}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography
								style={{
									color: "#929292",
									fontSize: "12px"
								}}>
								Offers
							</Typography>
							<Typography
								style={{
									color: "#3e3e3e",
									fontSize: "16px"
								}}>
								{max}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography
								style={{
									color: "#929292",
									fontSize: "12px"
								}}>
								Conversions
							</Typography>
							<Typography
								style={{
									color: "#3e3e3e",
									fontSize: "16px"
								}}>
								{conversions}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography
								style={{
									color: "#929292",
									fontSize: "12px"
								}}>
								Average Accepted Offer Price
							</Typography>
							<Typography
								style={{
									color: "#3e3e3e",
									fontSize: "16px"
								}}>
								{avgAcceptedPrice ? `$${avgAcceptedPrice.toFixed(2)}` : "-"}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography
								style={{
									color: "#929292",
									fontSize: "12px"
								}}>
								Average Accepted Discount
							</Typography>
							<Typography
								style={{
									color: "#3e3e3e",
									fontSize: "16px"
								}}>
								{avgAcceptedDisc ? `${avgAcceptedDisc.toFixed(2)}%` : "-"}
							</Typography>
						</Grid>
					</Grid>
				)}
				<br />

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={this.state.open}
					onClose={this.handleCloseSnackSuccess}
					autoHideDuration={3000}
					message={<span id='message-id'>Updated</span>}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(PopularProduct);
