import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { db } from "../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import { HelpModal } from "./index";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import withSizes from "react-sizes";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		textAlign: "left"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	inputAccept: {
		color: "#22c78e",
		height: "3.5em"
	},
	inputReject: {
		color: "#ff4d4d",
		height: "3.5em"
	},
	textField: {
		width: "90%",
		height: "5em"
	},
	menu: {
		width: 200
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	fabStyle: {
		margin: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		color: "#35B2FF",
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			boxShadow: "none",
			backgroundColor: "#c5c5c6"
		}
	},
	productName: {
		fontFamily: '"Nunito Sans", sans-serif',
		fontSize: "24px",
		fontWeight: "300",
		width: "100%",
		display: "block",
		paddingBottom: "10px",
		paddingTop: "10px",
		borderRadius: "5px",
		background: "#f6f6f8"
	}
});

class MasterRule extends React.Component {
	state = {
		storeName: "",
		rejectBelow: "",
		rejectBelowBool: "$",
		acceptAbove: "",
		acceptAboveBool: "$",
		invLowerLim: "",
		endDate: "",
		isRule: false,
		open: false,
		openSuccess: false,
		openDelete: false,
		openDate: false,
		openDialog: false,
		openDeleteDialog: false,
		openInvalid: false
	};

	static propTypes = {
		collectionName: PropTypes.string.isRequired
	};

	handleOpen = name => {
		this.setState({ [name]: true });
	};

	handleClose = name => {
		this.setState({ [name]: false });
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
			})
			.catch(e => console.log("error", e));
	}

	handleChange = (event, type) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		this.setState({ [name]: value });
	};

	submitMasterRule() {
		const { endDate, rejectBelow, acceptAbove } = this.state;
		let d = new Date();
		let curDate = d.valueOf();
		if (endDate === "" && rejectBelow === "" && acceptAbove === "") {
			this.handleSnack("open");
		} else if (endDate < curDate) {
			this.handleClickSnackDate();
		} else if (Number(rejectBelow) >= Number(acceptAbove)) {
			this.handleSnack("openInvalid");
		} else if (rejectBelow && acceptAbove && endDate) {
			this.setState({ openDialog: true });
		} else {
			this.handleSnack("open");
		}
	}

	sendDataBackToParent() {
		let data = {
			rejectBelow: this.state.rejectBelow,
			acceptAbove: this.state.acceptAbove,
			rejectBelowBool: "%",
			acceptAboveBool: "%",
			endDate: this.state.endDate,
			isRule: this.state.isRule
		};

		this.props.onSelectRule(data);
		this.handleSnack("openSuccess");
		this.setState({ rejectBelow: "", acceptAbove: "", rejectBelowBool: "%", acceptAboveBool: "%", isRule: false });
	}

	sendDeleteDataBackToParent() {
		this.props.onDeleteRule();
		this.handleSnack("openDelete");
	}

	formSubmit = e => {
		e.preventDefault();
		this.submitMasterRule();
	};

	render() {
		const { classes, collectionName, isMobile } = this.props;
		const {
			openInvalid,
			rejectBelow,
			acceptAbove,
			isRule,
			endDate,
			open,
			openSuccess,
			openDelete,
			openDate
		} = this.state;

		return (
			<div>
				<HelpModal
					open={this.state.openDialog}
					isSubmitMasterRule={true}
					handleClose={e => {
						this.setState({ openDialog: false });
						if (e === "apply") {
							console.log(e);
							this.sendDataBackToParent();
						}
					}}
				/>

				<HelpModal
					open={this.state.openDeleteDialog}
					isDeleteMasterRule={true}
					handleClose={e => {
						this.setState({ openDeleteDialog: false });
						if (e === "apply") {
							console.log(e);
							this.sendDeleteDataBackToParent();
						}
					}}
				/>
				<br />
				<Paper className={classes.root} elevation={1} style={{ zoom: isMobile ? "0.88" : "1" }}>
					<p style={{ color: "#929292", margin: "0em" }}>Collection Rule</p>
					<p style={{ margin: "0.5em 0em", fontSize: "1.2em" }}>{collectionName}</p>
					<Divider fullWidth />
					<form onSubmit={e => this.formSubmit(e)}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Tooltip
									placement='top'
									title='e.g. if a product cost $100 and the accept above rule was set to 70 (%), then all offers above $70 will be accepted automatically'>
									<TextField
										id='acceptAbove'
										label='Accept All Offers Above'
										className={classes.textField}
										value={acceptAbove}
										onChange={this.handleChange}
										name='acceptAbove'
										margin='normal'
										type='number'
										InputLabelProps={{
											shrink: true
										}}
										InputProps={{
											className: classes.inputAccept,
											inputProps: {
												min: 0,
												max: 100,
												readOnly: isRule ? true : false
											},
											endAdornment: (
												<InputAdornment position='end'>
													<span
														style={{
															color: "#22c78e"
														}}>
														%
													</span>
												</InputAdornment>
											)
										}}
									/>
								</Tooltip>
								<TextField
									id='endDate'
									label='End Date'
									type='date'
									value={endDate}
									name='endDate'
									className={classes.textField}
									onChange={this.handleChange}
									margin='normal'
									InputLabelProps={{
										shrink: true
									}}
									InputProps={{
										readOnly: isRule ? true : false
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<Tooltip
									placement='top'
									title='e.g. if a product cost $100 and the reject below rule was set to 30 (%), then all offers below $30 will be rejected automatically'>
									<TextField
										id='rejectBelow'
										label='Reject All Offers Below'
										className={classes.textField}
										value={rejectBelow}
										onChange={this.handleChange}
										name='rejectBelow'
										margin='normal'
										type='number'
										InputLabelProps={{
											shrink: true
										}}
										InputProps={{
											className: classes.inputReject,
											inputProps: {
												min: 0,
												max: 100,
												readOnly: isRule ? true : false
											},
											endAdornment: (
												<InputAdornment position='end'>
													<span
														style={{
															color: "#ff4d4d"
														}}>
														%
													</span>
												</InputAdornment>
											)
										}}
									/>
								</Tooltip>

								<div
									style={{
										width: "80%",
										marginTop: "1em",
										zoom: isMobile ? "0.85" : "1"
									}}>
									<Button
										type='submit'
										color='#35B2FF'
										variant='contained'
										className={classes.button}
										margin='normal'>
										Submit
									</Button>
									<Button
										variant='fab'
										mini
										color='secondary !important'
										aria-label='delete'
										className={classes.button}
										onClick={() => {
											this.setState({
												openDeleteDialog: true
											});
										}}>
										<DeleteOutline />
									</Button>
								</div>
							</Grid>
						</Grid>
					</form>

					<div>
						{/* Move into your own file */}
						<Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							open={open}
							onClose={() => this.handleClose("open")}
							autoHideDuration={3000}
							ContentProps={{
								"aria-describedby": "message-id"
							}}
							message={
								<span id='message-id'>
									Your Rule is invalid. Please make sure all values are filled
								</span>
							}
						/>

						<Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							open={openSuccess}
							onClose={() => this.handleClose("openSuccess")}
							autoHideDuration={5000}
							ContentProps={{
								"aria-describedby": "message-id"
							}}
							message={<span id='message-id'>You have succesfully added a Master Rule</span>}
						/>
						<Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							open={openDelete}
							onClose={() => this.handleClose("openDelete")}
							autoHideDuration={5000}
							ContentProps={{
								"aria-describedby": "message-id"
							}}
							message={<span id='message-id'>You have succesfully deleted a Master Rule</span>}
						/>

						<Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							open={openDate}
							onClose={() => this.handleClose("openDate")}
							autoHideDuration={3000}
							ContentProps={{
								"aria-describedby": "message-id"
							}}
							message={
								<span id='message-id'>
									{endDate !== ""
										? "Please enter a date greater than the current date"
										: "Please enter an end date"}
								</span>
							}
						/>

						<Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							open={openInvalid}
							onClose={() => this.handleClose("openInvalid")}
							autoHideDuration={3000}
							ContentProps={{
								"aria-describedby": "message-id"
							}}
							message={
								<span id='message-id'>
									{acceptAbove === "" || rejectBelow === ""
										? "Please enter both threshold values"
										: "Reject Below value must be smaller than Accept Above value"}
								</span>
							}
						/>
					</div>
				</Paper>
			</div>
		);
	}
}

MasterRule.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 700,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(MasterRule));
