import React, { Component } from "react";
import { Scatter } from "react-chartjs-2";
import { db } from "../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Moment from "moment";
import _ from "lodash";

class ScatterChart extends Component {
	state = {
		sunday: [],
		monday: [],
		tuesday: [],
		wednesday: [],
		thursday: [],
		friday: [],
		saturday: [],
		storeName: "",
		value: "",
		open: false,
		finalData: [],
		sizes: []
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: true,
		legendPosition: "top"
	};

	componentDidMount() {
		this.updateState(false);
	}
	showAlert() {
		alert("Hello World");
	}

	updateState(notFromMount) {
		if (notFromMount) {
			for (var i = 0; i < 4; i++) {
				this.setState({
					approved: {
						[Moment()
							.subtract(i, "months")
							.month()]: 0
					}
				});
				this.setState({
					rejected: {
						[Moment()
							.subtract(i, "months")
							.month()]: 0
					}
				});
				this.setState({
					expired: {
						[Moment()
							.subtract(i, "months")
							.month()]: 0
					}
				});
			}
		}
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				return db.getStoreBids(snapshot, "approved");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "approved");
				return db.getStoreBids(this.state.storeName, "expired");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "expired");
				return db.getStoreBids(this.state.storeName, "rejected");
			})
			.then(snapshot => {
				return this.getOfferData(snapshot, "rejected");
			})
			.catch(console.error);

		if (notFromMount) {
			this.setState({ open: true });
		}
	}

	getOfferData = (snapshot, location) => {
		snapshot.forEach(offer => {
			if (Moment().diff(offer.val().submittedDate, "months") < 3) {
				var day = _.toLower(Moment(offer.val().submittedDate).format("dddd"));
				var hr = Moment(offer.val().submittedDate).hour();
				var temp = Object.assign({}, this.state[day]);
				temp[hr] = temp[hr] + 1 || 1;
				this.setState({ [day]: temp });
			}
		});
		if (location === "rejected") {
			this.makeData();
		}
	};

	makeData = () => {
		const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = this.state;
		var data = [];
		var sizes = [];
		for (let val in sunday) {
			let pointObj = { x: "Sunday", y: val };
			data.push(pointObj);
			sizes.push(sunday[val] > 1 ? sunday[val] : 2);
		}
		for (let val in monday) {
			let pointObj = { x: "Monday", y: val };
			data.push(pointObj);
			sizes.push(monday[val] > 1 ? monday[val] : 2);
		}
		for (let val in tuesday) {
			let pointObj = { x: "Tuesday", y: val };
			data.push(pointObj);
			sizes.push(tuesday[val] > 1 ? tuesday[val] : 2);
		}
		for (let val in wednesday) {
			let pointObj = { x: "Wednesday", y: val };
			data.push(pointObj);
			sizes.push(wednesday[val] > 1 ? wednesday[val] : 2);
		}
		for (let val in thursday) {
			let pointObj = { x: "Thursday", y: val };
			data.push(pointObj);
			sizes.push(thursday[val] > 1 ? thursday[val] : 2);
		}
		for (let val in friday) {
			let pointObj = { x: "Friday", y: val };
			data.push(pointObj);
			sizes.push(friday[val] > 1 ? friday[val] : 2);
		}
		for (let val in saturday) {
			let pointObj = { x: "Saturday", y: val };
			data.push(pointObj);
			sizes.push(saturday[val] > 1 ? saturday[val] : 2);
		}
		let max = Math.max(...sizes);
		if (max > 25) {
			let factor = 25 / max;
			sizes = sizes.map(function(element) {
				return element * factor;
			});
		}
		this.setState({ finalData: data, sizes: sizes });
	};

	handleCloseSnackSuccess = () => {
		this.setState({ open: false });
	};

	render() {
		const data = {
			labels: ["Scatter"],
			datasets: [
				{
					fill: false,
					backgroundColor: "#fff",
					pointBackgroundColor: "#35b2ff",
					pointBorderColor: "#fff",
					pointBorderWidth: 1,
					pointHoverRadius: this.state.sizes,
					pointHoverBackgroundColor: "#35b2ff",
					pointHoverBorderColor: "#000",
					pointHoverBorderWidth: 2,
					pointRadius: this.state.sizes,
					pointHitRadius: 10,
					data: this.state.finalData
				}
			]
		};

		return (
			<div
				style={{
					width: this.props.isMobile ? null : "-webkit-fill-available",
					verticalAlign: "middle",
					display: "grid"
				}}>
				<div style={{ display: "inline", textAlign: "left" }}>
					<Typography
						style={{
							color: "#000",
							fontSize: "1em",
							fontWeight: 700
						}}>
						When offers are placed
					</Typography>
				</div>
				<div
					style={{
						position: "relative",
						height: this.props.isMobile ? null : "300px",
						width: "100%"
					}}>
					<Scatter
						data={data}
						options={{
							maintainAspectRatio: false,
							legend: {
								display: false
							},
							tooltips: {
								enabled: true,
								callbacks: {
									label: function(tooltipItem, data) {
										var label = "";
										let str = tooltipItem.yLabel;
										if (str >= 12) {
											str = str - 12;
											if (str === 0) label = "12 PM";
											else label = str + " PM";
										} else if (str === 0) label = "12 AM";
										else label = str + " AM";
										return label;
									}
								}
							},
							scales: {
								xAxes: [
									{
										type: "category",
										labels: [
											"Monday",
											"Tuesday",
											"Wednesday",
											"Thursday",
											"Friday",
											"Saturday",
											"Sunday"
										]
									}
								],
								yAxes: [
									{
										ticks: {
											max: 23,
											min: 0,
											stepSize: 4,
											reverse: true,
											callback: function(value) {
												let str = value;
												if (str >= 12) {
													str = str - 12;
													if (str === 0) return "12 PM";
													else return str + " PM";
												} else if (str === 0) return "12 AM";
												else return str + " AM";
											}
										}
									}
								]
							}
						}}
					/>
				</div>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={this.state.open}
					onClose={this.handleCloseSnackSuccess}
					autoHideDuration={3000}
					ContentProps={{
						"aria-describedby": "message-id"
					}}
					message={<span id='message-id'>Graph Updated</span>}
				/>
			</div>
		);
	}
}

export default ScatterChart;
