import React, { Component } from "react";
import { db, fb } from "../firebase";
import {
	Typography,
	Tooltip,
	Snackbar,
	Divider,
	Grid,
	Paper,
	Dialog,
	DialogActions,
	DialogContent,
	Button
} from "@material-ui/core";
import Refresh from "@material-ui/icons/Refresh";
import { Link } from "react-router-dom";
import _ from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";

class OffersData extends Component {
	state = {
		data: [],
		totalOffers: 0,
		offers: 0,
		liveProducts: [],
		liveProductsLen: 0,
		totalCartAbandonment: 0,
		totalSPP: 0,
		totalCampaign: 0,
		products: [],
		storeName: "",
		value: "",
		open: false,
		openList: false,
		openSnack: false,
		pid: 0
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: true,
		legendPosition: "top"
	};

	componentDidMount() {
		db.getSPP().then(snapshot => {
			this.setState({ pid: snapshot.id });
		});
		this.updateState(false);
	}
	snapshotToArray(snapshot) {
		var returnArr = [];
		snapshot.forEach(function(childSnapshot) {
			var item = childSnapshot.val();
			returnArr.push(item);
		});
		returnArr = returnArr.sort();
		return returnArr;
	}

	updateState(notFromMount) {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				fb.getOfferableProductInfo(snapshot).then(snap => {
					this.setState({
						liveProducts: snap.data,
						liveProductsLen: snap.data.length
					});
				});
				return db.getStoreBids(snapshot, "approved");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "approved", notFromMount);
				return db.getStoreBids(this.state.storeName, "expired");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "expired");
				return db.getStoreBids(this.state.storeName, "rejected");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "rejected");
				return db.getStoreBids(this.state.storeName, "pending");
			})
			.then(snapshot => {
				return this.getOfferData(snapshot, "pending");
			})
			.catch(console.error);

		if (notFromMount) {
			this.setState({ open: true });
		}
	}

	getOfferData = (snapshot, location, notFromMount) => {
		let products = notFromMount ? [] : this.state.products;
		let totalCA = notFromMount ? 0 : this.state.totalCartAbandonment;
		let totalSPP = notFromMount ? 0 : this.state.totalSPP;
		let total = notFromMount ? 0 : this.state.offers;
		snapshot.forEach(offer => {
			let arr = offer.val().products;
			total = total + 1;
			if (
				offer.val().cartAbandoned !== undefined &&
				offer.val().cartAbandoned !== null &&
				offer.val().cartAbandoned
			)
				totalCA = totalCA + 1;
			if (offer.val().spp !== undefined && offer.val().spp !== null) totalSPP = totalSPP + 1;
			if (offer.val().products !== undefined && offer.val().products !== null) {
				arr.forEach(p => {
					if (offer.val().cartAbtandoned !== undefined && offer.val().cartAbandoned !== null) {
						p.cartAbandoned = offer.val().cartAbandoned;
					} else p.cartAbandoned = false;
					if (offer.val().spp !== undefined && offer.val().spp !== null) {
						p.spp = offer.val().spp;
					} else p.spp = null;
					products.push(p);
				});
			}
		});

		this.setState({
			products: products,
			offers: total,
			totalOffers: total - totalCA - totalSPP,
			totalCartAbandonment: totalCA,
			totalSPP: totalSPP
		});
	};

	handleOpen = name => {
		this.setState({ [name]: true });
	};

	handleClose = name => {
		this.setState({ [name]: false });
	};

	render() {
		const {
			open,
			totalCartAbandonment,
			totalOffers,
			totalSPP,
			liveProducts,
			liveProductsLen,
			offers,
			openList,
			openSnack,
			pid
		} = this.state;
		const { isMobile } = this.props;
		return (
			<div style={{ height: this.props.isMobile ? null : "250px" }}>
				<div style={{ display: "inline", textAlign: "left" }}>
					<Typography
						style={{
							color: "#000",
							fontWeight: 700,
							fontSize: "1em"
						}}
						onClick={() => this.updateState(true)}>
						Where do your {offers} offers come from{" "}
						<Tooltip title='Update'>
							<Refresh
								style={{
									verticalAlign: "bottom",
									color: "#35B2FF"
								}}
							/>
						</Tooltip>
					</Typography>
					<Typography
						style={{
							color: "#929292",
							fontSize: "12px"
						}}>
						All time
					</Typography>
				</div>
				<br />
				<Grid container spacing={isMobile ? 2 : 6}>
					<Grid item xs>
						<Typography
							style={{
								color: "#000",
								fontWeight: 700,
								fontSize: "18px"
							}}>
							{totalOffers}
						</Typography>
						<Typography
							style={{
								color: "#000",
								fontSize: "14px",

								height: isMobile ? "36px" : null
							}}>
							Offer Button
						</Typography>
						<Divider style={{ margin: "1em 0em" }} />
						<Paper
							style={{
								padding: "8px",
								height: isMobile ? "250px" : "125px",
								position: "relative"
							}}
							elevation={0}>
							<Typography
								style={{
									color: "#929292",
									fontSize: "12px",
									textAlign: "left"
								}}>
								Currently live on
							</Typography>
							<Typography
								style={{
									color: "#3e3e3e",
									fontSize: "14px",
									textAlign: "left",
									cursor: "pointer",
									textDecoration: "underline"
								}}
								onClick={() => this.handleOpen("openList")}>
								{liveProductsLen} products
							</Typography>
							<Link to='/button' style={{ textDecoration: "none" }}>
								<Typography
									color='primary'
									style={{
										fontSize: "14px",
										textAlign: "left",
										position: "absolute",
										bottom: 0,
										marginBottom: "16px"
									}}>
									Add to more products
								</Typography>
							</Link>
						</Paper>
					</Grid>
					<Grid item xs>
						<Typography
							style={{
								color: "#000",
								fontWeight: 700,
								fontSize: "18px"
							}}>
							{totalCartAbandonment}
						</Typography>
						<Typography
							style={{
								color: "#000",
								fontSize: "14px",

								height: isMobile ? "36px" : null
							}}>
							Cart Abandonment
						</Typography>
						<Divider style={{ margin: "1em 0em" }} />
						<Paper
							style={{
								backgroundColor: "#F6F6F8",
								padding: "8px",
								height: isMobile ? "250px" : "125px",
								position: "relative"
							}}
							elevation={0}>
							<Typography
								style={{
									color: "#929292",
									fontSize: "14px",
									textAlign: "left"
								}}>
								Add make an offer to your cart abadonment emails to receive more offers.
							</Typography>
							<Link to='/template' style={{ textDecoration: "none" }}>
								<Typography
									color='primary'
									style={{
										fontSize: "14px",
										textAlign: "left",
										position: "absolute",
										bottom: 0,
										marginBottom: "16px"
									}}>
									Add to emails
								</Typography>
							</Link>
						</Paper>
					</Grid>
					<Grid item xs>
						<Typography
							style={{
								color: "#000",
								fontWeight: 700,
								fontSize: "18px"
							}}>
							{totalSPP}
						</Typography>
						<Typography
							style={{
								color: "#000",
								fontSize: "14px",

								height: isMobile ? "36px" : null
							}}>
							Single Product Promotion
						</Typography>
						<Divider style={{ margin: "1em 0em" }} />
						<Paper
							style={{
								backgroundColor: this.state.storeName === "iceJewellery" ? "#FFF" : "#F6F6F8",
								padding: "8px",
								height: isMobile ? "250px" : "125px",
								position: "relative"
							}}
							elevation={0}>
							{this.state.storeName === "iceJewellery" ? (
								<div>
									<Typography
										style={{
											color: "#929292",
											fontSize: "14px",
											textAlign: "left"
										}}>
										Live link:
										<br />
										<a
											href={`https://app.enteroffer.com/?s=iceJewellery&p=${pid}&spp=false`}
											target='_blank'
											rel='noopener noreferrer'
											style={{
												textDecoration: "none",
												color: "#35B2FF"
											}}>
											{`https://app.enteroffer.com/?s=iceJewellery&p=${pid}&spp=false`}
										</a>
									</Typography>
									<div onClick={() => this.handleOpen("openSnack")} style={{ textAlign: "center" }}>
										<Tooltip title='Copy'>
											<CopyToClipboard text={"https://enteroffer.com/icejewellery"}>
												<Button style={{ zoom: "0.6" }}>
													<FileCopyIcon />
												</Button>
											</CopyToClipboard>
										</Tooltip>
									</div>
								</div>
							) : (
								<Typography
									style={{
										color: "#929292",
										fontSize: "14px",
										textAlign: "left"
									}}>
									Contact EnterOffer to get involved.
								</Typography>
							)}
							<a
								href='https://enteroffer.com/#contactUs'
								target='_blank'
								rel='noopener noreferrer'
								style={{
									textDecoration: "none",
									color: "#FFF"
								}}>
								<Typography
									color='primary'
									style={{
										fontSize: "14px",
										textAlign: "left",
										position: "absolute",
										bottom: 0,
										marginBottom: "16px"
									}}>
									Contact Us
								</Typography>
							</a>
						</Paper>
					</Grid>
					{/* <Grid item xs>
						<Typography
							style={{
								color: "#000",
								fontWeight: 700,
								fontSize: "18px"
							}}>
							{totalCampaign}
						</Typography>
						<Typography
							style={{
								color: "#000",
								fontSize: "14px",

								height: isMobile ? "36px" : null
							}}>
							Campaign
						</Typography>
						<Divider style={{ margin: "1em 0em" }} />
						<Paper
							style={{
								backgroundColor: "#F6F6F8",
								padding: "8px",
								height: isMobile ? "250px" : "100px",
								position: "relative"
							}}
							elevation={0}>
							<Typography
								style={{
									color: "#929292",
									fontSize: "14px",
									textAlign: "left"
								}}>
								Pick a product and generate an offer URL to add
								to your website, socials or email to your
								database.
							</Typography>
							<Link
								to='/template'
								style={{ textDecoration: "none" }}>
								<Typography
									color='primary'
									style={{
										fontSize: "14px",
										textAlign: "left",
										position: "absolute",
										bottom: 0,
										marginBottom: "16px"
									}}>
									Create campaign URL
								</Typography>
							</Link>
						</Paper>
					</Grid>
				 */}
				</Grid>

				<Dialog open={openList} onClose={() => this.handleClose("openList")}>
					<DialogContent>
						<Typography gutterBottom variant='h6' align='center' style={{ fontWeight: 700 }}>
							Live Product(s) List{" "}
						</Typography>
						{_.map(liveProducts, (p, cnt) => {
							return (
								<Typography gutterBottom key={cnt}>
									{p.title}
								</Typography>
							);
						})}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.handleClose("openList")} color='primary' autoFocus>
							Done
						</Button>
					</DialogActions>
				</Dialog>

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={open}
					onClose={() => this.handleClose("open")}
					autoHideDuration={3000}
					message={<span id='message-id'>Updated</span>}
				/>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={openSnack}
					onClose={() => this.handleClose("openSnack")}
					autoHideDuration={2000}
					message={<span id='message-id'>Link has been copied.</span>}
				/>
			</div>
		);
	}
}

export default OffersData;
