import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Tooltip,
	Paper,
	MenuItem,
	Button,
	Select,
	Card,
	CardMedia,
	Snackbar,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormGroup,
	FormControl,
	FormControlLabel,
	Checkbox,
	Tabs,
	Tab,
	IconButton,
	TextField,
	CircularProgress,
	Grid,
} from "@material-ui/core";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { db } from "../firebase";
import { fb } from "../firebase";
import Done from "@material-ui/icons/Done";
import Expired from "@material-ui/icons/AccessAlarm";
import FilterList from "@material-ui/icons/FilterList";
import Refresh from "@material-ui/icons/Refresh";
import Clear from "@material-ui/icons/Clear";
import ResetFilter from "@material-ui/icons/RotateLeft";
import SearchIcon from "@material-ui/icons/Search";
import { HelpModal } from "./index";
import { EnterOfferLogoColor } from "../assets";
import moment from "moment";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import withSizes from "react-sizes";
import _ from "lodash";

function desc(a, b, orderBy) {
	return a[orderBy] < b[orderBy] ? 1 : -1;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
	return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
if (localStorage.getItem("columns") === null) {
	localStorage.setItem("columns", JSON.stringify([true, true, false, true, false, true, true, true, true, false, false, true, true, true, true]));
}

const rows = [
	{
		sno: 0,
		id: "product",
		numeric: false,
		disablePadding: false,
		label: "Offers",
	},
	{
		sno: 1,
		id: "variant",
		numeric: false,
		disablePadding: false,
		label: "Variant",
	},
	{
		sno: 2,
		id: "sku",
		numeric: false,
		disablePadding: false,
		label: "SKU",
	},
	{
		sno: 3,
		id: "currency",
		numeric: false,
		disablePadding: true,
		label: "Currency",
	},
	{
		sno: 4,
		id: "country",
		numeric: false,
		disablePadding: true,
		label: "Country",
	},
	{
		sno: 5,
		id: "listPrice",
		numeric: false,
		disablePadding: true,
		label: "List Price",
	},
	{
		sno: 6,
		id: "costPrice",
		numeric: false,
		disablePadding: true,
		label: "Cost Price",
	},
	{
		sno: 7,
		id: "bidPrice",
		numeric: false,
		disablePadding: true,
		label: "Offer Price",
	},
	{
		sno: 8,
		id: "discountCalc",
		numeric: false,
		disablePadding: true,
		label: "Discount (%)",
	},
	{
		sno: 9,
		id: "shipping",
		numeric: false,
		disablePadding: true,
		label: "Shipping (Cost)",
	},
	{
		sno: 10,
		id: "customerName",
		numeric: false,
		disablePadding: false,
		label: "Name",
	},
	{
		sno: 11,
		id: "customerEmail",
		numeric: false,
		disablePadding: false,
		label: "Email",
	},
	{
		sno: 12,
		id: "submittedDate",
		numeric: false,
		disablePadding: false,
		label: "Submission Date",
	},
	{
		sno: 13,
		id: "Response",
		numeric: false,
		disablePadding: true,
		label: "Response",
	},
];

const rowsWithoutResp = [
	{
		sno: 0,
		id: "product",
		numeric: false,
		disablePadding: false,
		label: "Offers",
	},
	{
		sno: 1,
		id: "variant",
		numeric: false,
		disablePadding: false,
		label: "Variant",
	},
	{
		sno: 2,
		id: "sku",
		numeric: false,
		disablePadding: false,
		label: "SKU",
	},
	{
		sno: 3,
		id: "currency",
		numeric: false,
		disablePadding: true,
		label: "Currency",
	},
	{
		sno: 4,
		id: "country",
		numeric: false,
		disablePadding: true,
		label: "Country",
	},
	{
		sno: 5,
		id: "listPrice",
		numeric: false,
		disablePadding: true,
		label: "List Price",
	},
	{
		sno: 6,
		id: "costPrice",
		numeric: false,
		disablePadding: true,
		label: "Cost Price",
	},
	{
		sno: 7,
		id: "bidPrice",
		numeric: false,
		disablePadding: true,
		label: "Offer Price",
	},
	{
		sno: 8,
		id: "discountCalc",
		numeric: false,
		disablePadding: true,
		label: "Discount (%)",
	},
	{
		sno: 9,
		id: "shipping",
		numeric: false,
		disablePadding: true,
		label: "Shipping (Cost)",
	},
	{
		sno: 10,
		id: "customerName",
		numeric: false,
		disablePadding: false,
		label: "Name",
	},
	{
		sno: 11,
		id: "customerEmail",
		numeric: false,
		disablePadding: false,
		label: "Email",
	},
	{
		sno: 12,
		id: "submittedDate",
		numeric: false,
		disablePadding: false,
		label: "Submission Date",
	},
	{
		sno: 14,
		id: "transaction",
		numeric: false,
		disablePadding: true,
		label: "Transaction successful",
	},
	{
		sno: 15,
		id: "order",
		numeric: false,
		disablePadding: true,
		label: "Order created",
	},
];

function getTotalCost(row) {
	let totalCost = 0;
	for (let i in row.products) {
		totalCost += Number(row.products[i].cost);
	}
	return totalCost;
}
class EnhancedTableHead extends React.Component {
	createSortHandler = (property) => (event) => {
		this.props.onRequestSort(event, property);
	};

	handleResponseChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const { order, orderBy, resCol, orderCol, toShowCols } = this.props;
		var usedRows;
		if (!resCol) {
			usedRows = rowsWithoutResp;
		} else {
			usedRows = rows;
		}

		return (
			<TableHead>
				<TableRow>
					{usedRows.map((row) => {
						return (
							<TableCell
								style={{
									textAlign: "left",
									fontSize: "1em",
									color: row.sno === 0 ? "#000" : "#929292",
									fontWeight: row.sno === 0 ? 700 : null,
									display:
										row.sno === 13 && !orderCol
											? "table-cell"
											: row.sno === 13 && orderCol
											? "none"
											: toShowCols[row.sno]
											? "table-cell"
											: "none",
									whiteSpace: "nowrap",
									overflow: "hidden",
									width: "100px",
								}}
								key={row.id}
								padding={row.disablePadding ? "none" : "default"}
								sortDirection={orderBy === row.id ? order : false}
							>
								<Tooltip title="Sort" placement={row.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
									<TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	toHideCols: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	resCol: PropTypes.bool,
	orderCol: PropTypes.bool,
};

const toolbarStyles = (theme) => ({
	root: {
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === "light"
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
	spacer: {
		flex: "1 1 100%",
	},
	actions: {
		color: theme.palette.text.secondary,
	},
	title: {
		flex: "0 0 auto",
	},
});

let EnhancedTableToolbar = (props) => {
	const { classes } = props;

	return (
		<Toolbar className={classes.root}>
			<div className={classes.spacer} />
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
	root: {
		boxShadow: "none",
		backgroundColor: "transparent",
	},
	innerroot: {
		border: "2px solid #f6f6f8",
		// boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)"
		marginBottom: "2px",
		borderRadius: "10px",
		overflow: "auto",
	},
	table: {
		minWidth: 900,
		textAlign: "left",
	},
	button: {
		color: theme.palette.primary.main,
		margin: "0em 0.5em",
		verticalAlign: "baseline",
	},
	button2: {
		background: "#f6f6f8",
		color: theme.palette.primary.main,
		fontWeight: 600,
		margin: "0em 0.5em",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3",
			boxShadow: "none",
		},
	},
	tableWrapper: {
		overflowX: "auto",
	},
	divLogo: {
		paddingTop: "30px",
	},
	media: {
		width: "auto",
		height: "70px",
		marginLeft: "auto",
		marginRight: "auto",
		display: "block",
	},
	titleText: {
		paddingTop: "50px",
	},
	noItems: {
		textAlign: "center",
		margin: "4px",
	},
	choiceButton1: {
		"&:hover": {
			backgroundColor: "#22c78e44 !important",
		},
	},
	choiceButton2: {
		"&:hover": {
			backgroundColor: "#ff4d4d11 !important",
		},
	},
	bundlePNames: {
		paddingLeft: "20px !important",
	},
	tabsStyle: {
		display: "inline-flex",
		borderRadius: "30px",
		backgroundColor: "#35B2FF44",
		padding: "4px",
		minHeight: "30px !important",
	},
	tabStyle: {
		padding: 0,
		fontWeight: 700,
		borderRadius: "50px",
		textTransform: "capitalize",
		fontSize: "0.9em",
		minWidth: "100px !important",
		minHeight: "30px !important",
	},
	textField: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(2),
		width: 200,
	},
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
	inputDrop: {
		marginLeft: " -8px",
		padding: "0.4em 1.8em 0.4em 1em",
		fontWeight: 500,
		fontSize: "1em",
	},
});

class EnhancedTable extends React.Component {
	handleCloseDialogCancel = () => {
		this.setState({ openDialog: false });
	};

	handleCloseDialogConfirm = () => {
		var row = this.state.row;
		var inventoryError = "Products:";
		var internalError = "Sorry your approval did not go through. Please try again later.";
		if (this.state.functionType === "approved") {
			this.setState({ offerDecideDialog: true });
			fb.checkBundleInventory(this.state.storeName, row.products)
				.then((result) => {
					if (result === 0) {
						const { data } = this.state;
						let tempList = data;
						row.itemStatus = this.state.functionType;
						row.approved = true;

						this.setState({ data: tempList });
						if (this.state.functionType === "approved") {
							this.setState({
								offerAcceptDialog: true,
								offerDecideDialog: false,
							});
							fb.approveOffer(this.state.storeName, row).then((result) => {
								console.log("APPROVED:" + result.result);
								this.getItemsFunc(this.state.typeName.toLowerCase(), true);
								this.setState({
									offerAcceptDialog: false,
								});
							});
						} else {
							const { data } = this.state;
							let tempList = data;
							row.itemStatus = this.state.functionType;
							row.rejected = true;

							this.setState({ data: tempList });
							fb.rejectOffer(this.state.storeName, row).then((result) => {
								console.log("REJECTED1:" + result.result);
								this.getItemsFunc(this.state.typeName.toLowerCase(), true);
								this.setState({
									offerAcceptDialog: false,
								});
							});
						}
					} else if (result === 2) {
						this.setState({
							offerDecideDialog: false,
							offerResultTitle: "Offer Submit Error",
							offerResultText: internalError,
							offerResultDialog: true,
						});
					} else {
						for (let i in result) {
							for (let j in row.products) {
								if (result[i].productID === row.products[j].productID) {
									inventoryError += " " + row.products[j].productName + ", ";
								}
							}
						}
						inventoryError = inventoryError.substr(0, inventoryError.length - 2);
						inventoryError += " out of inventory. Please restock on shopify admin if you wish to approve this offer.";
						this.setState({
							offerDecideDialog: false,
							offerResultTitle: "Out of stock",
							offerResultText: inventoryError,
							offerResultDialog: true,
						});
					}
				})
				.catch(console.error);
			this.setState({ openDialog: false });
		} else {
			const { data } = this.state;
			let tempList = data;
			row.itemStatus = this.state.functionType;
			row.rejected = true;

			this.setState({ data: tempList, loadingReject: true });
			fb.rejectOffer(this.state.storeName, row)
				.then((result) => {
					console.log("REJECTED2:" + result.result);
					this.setState({ offerAcceptDialog: false });
				})
				.then((next) => {
					this.getItemsFunc(this.state.typeName.toLowerCase(), true);
					this.setState({ openDialog: false, loadingReject: false });
				});
		}
	};

	clickedRowItem(row, functionType) {
		var inventoryError = "Products:";
		var internalError = "Sorry your approval did not go through. Please try again later.";
		if (!row.approved && !row.rejected) {
			db.getUserConfirmPref().then((snapshot) => {
				if (snapshot === 1) {
					this.setState({
						openDialog: true,
						functionType: functionType,
						row: row,
					});
				} else {
					if (functionType === "approved") {
						this.setState({ offerDecideDialog: true });
						fb.checkBundleInventory(this.state.storeName, row.products)
							.then((result) => {
								if (result === 0) {
									const { data } = this.state;
									let tempList = data;
									row.itemStatus = functionType;
									row.approved = true;
									this.setState({
										data: tempList,
										offerAcceptDialog: true,
										offerDecideDialog: false,
									});
									fb.approveOffer(this.state.storeName, row).then((result) => {
										console.log("APPROVED:" + result.result);
										this.getItemsFunc(this.state.typeName.toLowerCase(), true);
										this.setState({
											offerAcceptDialog: false,
										});
									});
								} else if (result === 2) {
									this.setState({
										offerDecideDialog: false,
										offerResultTitle: "Offer Submit Error",
										offerResultText: internalError,
										offerResultDialog: true,
									});
								} else {
									for (let i in result) {
										for (let j in row.products) {
											if (result[i].productID === row.products[j].productID) {
												inventoryError += " " + row.products[j].productName + ", ";
											}
										}
									}
									inventoryError = inventoryError.substr(0, inventoryError.length - 2);
									inventoryError += " out of inventory. Please restock on shopify admin if you wish to approve this offer.";
									this.setState({
										offerDecideDialog: false,
										offerResultTitle: "Out of stock",
										offerResultText: inventoryError,
										offerResultDialog: true,
									});
								}
							})
							.catch(console.error);
					} else {
						const { data } = this.state;
						let tempList = data;
						row.itemStatus = functionType;
						row.rejected = true;

						this.setState({ data: tempList });
						fb.rejectOffer(this.state.storeName, row).then((result) => {
							console.log("REJECTED3:" + result.result);
							this.getItemsFunc(this.state.typeName.toLowerCase(), true);
							this.setState({ offerAcceptDialog: false });
						});
					}
				}
			});
		}
	}

	didApproveOrReject(typeOfClose) {
		this.setState({ openMessage: false });
	}

	state = {
		order: "desc",
		openSuccess: false,
		openMessage: false,
		showAreYouSureMessage: true,
		orderBy: "submittedDate",
		filterBy: "Filter",
		searchVal: "",
		filter: false,
		selected: [],
		type: 0,
		storeName: "",
		typeName: "Pending",
		data: [
			{
				id: -1,
				itemKey: "",
				discountCalc: "",
				currency: "",
				bidPrice: "",
				listPrice: "",
				inventory: "",
				product: "",
				email: "",
				submittedDate: "",
				approve: "",
				reject: "",
			},
		],
		filteredData: [],
		dataLength: 0,
		page: 0,
		rowsPerPage: 10,
		loading: true,
		openDialog: false,
		functionType: "",
		row: {},
		offerDecideDialog: false,
		offerResultDialog: false,
		offerAcceptDialog: false,
		offerResultTitle: "",
		offerResultText: "",
		loadingReject: false,
		openColsDialog: false,
		toShowCols: JSON.parse(localStorage.getItem("columns")),
		openOfferInfo: false,
		clickedRow: {},
	};

	handleChange = (event, type) => {
		this.setState({ type });
		this.setState({ loading: true });
		this.handleFilterReset();
		if (type === 0) {
			this.getItemsFunc("pending", false);
			this.setState({ typeName: "Pending", page: 0 });
		} else if (type === 1) {
			this.getItemsFunc("approved", false);
			this.setState({ typeName: "Approved", page: 0 });
		} else if (type === 2) {
			this.getItemsFunc("rejected", false);
			this.setState({ typeName: "Rejected", page: 0 });
		} else if (type === 3) {
			this.getItemsFunc("expired", false);
			this.setState({ typeName: "Expired", page: 0 });
		} else if (type === 4) {
			this.getItemsFunc("all", false);
			this.setState({ typeName: "All", page: 0 });
		}
	};

	snapshotToArray(snapshot) {
		var returnArr = [];
		var i = 0;
		snapshot.forEach(function(childSnapshot) {
			var item = childSnapshot.val();
			item.itemStatus = "";
			if (item.approved === true && item.rejected === false) {
				item.itemStatus = "approved";
			} else if (item.approved === false && item.rejected === true) {
				item.itemStatus = "rejected";
			}

			item.key = childSnapshot.key;
			item.id = i;
			i = i + 1;
			returnArr.push(item);
		});
		return returnArr;
	}

	getItemsFunc(whichType, didClickUpdate) {
		if (didClickUpdate) {
			this.props.refreshCallback();
		}
		if (whichType === "all") {
			var allData = [];
			db.getStoreBids(this.state.storeName, "approved")
				.then((snapshot) => {
					allData = this.snapshotToArray(snapshot);
					return db.getStoreBids(this.state.storeName, "expired");
				})
				.then((snapshot) => {
					let temp = this.snapshotToArray(snapshot);
					allData = _.concat(allData, temp);
					return db.getStoreBids(this.state.storeName, "rejected");
				})
				.then((snapshot) => {
					let temp = this.snapshotToArray(snapshot);
					allData = _.concat(allData, temp);
					return db.getStoreBids(this.state.storeName, "pending");
				})
				.then((snapshot) => {
					let temp = this.snapshotToArray(snapshot);
					allData = _.concat(allData, temp);
					this.setState({
						data: allData,
						dataLength: allData.length,
						loading: false,
					});
					if (didClickUpdate) {
						this.setState({ openSuccess: true });
					}
					return;
				})
				.catch(console.error);
		} else {
			db.getStoreBids(this.state.storeName, whichType)
				.then((snapshot) => {
					if (snapshot !== "noData") {
						this.setState({
							data: this.snapshotToArray(snapshot),
							dataLength: this.snapshotToArray(snapshot).length,
							loading: false,
						});
					}
					if (this.state.dataLength === 0) {
						this.setState({ loading: false });
					}
					this.setState({ loading: false });
					if (didClickUpdate) {
						this.setState({ openSuccess: true });
					}
				})
				.catch((e) => console.log("error", e));
		}
	}

	componentDidMount() {
		db.getUserStoreName()
			.then((snapshot) => {
				if (snapshot == null) {
					return "noData";
				} else {
					this.setState({ storeName: snapshot });
					this.getItemsFunc("pending", false);
				}
			})
			.catch((e) => console.log("error", e));
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}
		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value });
	};

	handleClickSnackSuccess = () => {
		this.setState({ openSuccess: false });
	};

	handleClose = () => {
		this.setState({ offerResultDialog: false });
	};
	isSelected = (id) => this.state.selected.indexOf(id) !== -1;

	handleClickOpenColsDialog = () => {
		this.setState({ openColsDialog: true });
	};

	handleCloseColsDialog = (type) => {
		if (type === 0) {
			this.setState({
				openColsDialog: false,
				toShowCols: [true, true, false, true, false, true, false, true, true, false, false, false, true, true, true, true],
			});
			localStorage.setItem(
				"columns",
				JSON.stringify([true, true, false, true, false, true, false, true, true, false, false, false, true, true, true, true])
			);
		} else {
			this.setState({
				openColsDialog: false,
			});
		}
	};

	handleHideCols = (e) => {
		var hideCols = this.state.toShowCols;
		if (hideCols[e.target.id]) {
			hideCols[e.target.id] = false;
		} else {
			hideCols[e.target.id] = true;
		}
		this.setState({ toShowCols: hideCols });
		localStorage.setItem("columns", JSON.stringify(hideCols));
	};
	handleChangeFilter = (name) => (event) => {
		this.setState({ [name]: event.target.value });
		if (name === "searchVal") this.handleFilterBy(event.target.value, true);
		else this.handleFilterBy(event.target.value, false);
	};

	handleFilterBy = (searchExp, flag) => {
		const { data, filterBy, searchVal } = this.state;
		var searchPattern = !flag ? new RegExp("^" + _.lowerCase(searchVal)) : new RegExp("^" + _.lowerCase(searchExp));
		var newData =
			!flag && filterBy === "Filter"
				? _.filter(data, (obj) => searchPattern.test(_.lowerCase(obj[searchExp])))
				: flag && filterBy !== "Filter"
				? _.filter(data, (obj) => searchPattern.test(_.lowerCase(obj[filterBy])))
				: _.filter(data, function(obj) {
						return (
							searchPattern.test(_.lowerCase(obj["firstName"])) ||
							searchPattern.test(_.lowerCase(obj["lastName"])) ||
							searchPattern.test(_.lowerCase(obj["email"]))
						);
				  });

		this.setState({
			filter: true,
			filteredData: newData,
		});
	};

	handleFilterReset = () => {
		this.setState({
			filter: false,
			filteredData: this.state.data,
			filterBy: "Filter",
			searchVal: "",
		});
	};

	handleRowClick = (row) => {
		if (this.state.storeName === "iceJewellery" || this.state.storeName === "productsRUs") {
			this.setState({ clickedRow: row, openOfferInfo: true });
		}
	};

	render() {
		const { classes, isMobile } = this.props;
		const {
			// data,
			order,
			orderBy,
			selected,
			rowsPerPage,
			page,
			typeName,
			openSuccess,
			dataLength,
			offerDecideDialog,
			offerResultTitle,
			offerResultText,
			offerResultDialog,
			offerAcceptDialog,
			toShowCols,
			filter,
			openOfferInfo,
			clickedRow,
		} = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

		const filters = [
			{
				value: "Filter",
				label: "Filter",
			},
			{
				value: "firstName",
				label: "First Name",
			},
			{
				value: "lastName",
				label: "Last Name",
			},
			{
				value: "email",
				label: "Email",
			},
		];
		var data = !filter ? this.state.data : this.state.filteredData;
		return (
			<div>
				<Paper className={classes.root}>
					<HelpModal open={this.state.openMessage} isAreYouSure={true} handleClose={(typeOfClose) => this.didApproveOrReject(typeOfClose)} />
					<div
						style={{
							display: isMobile ? "inline-grid" : null,
							width: "100%",
							marginBottom: "1em",
						}}
					>
						<Tabs
							className={classes.tabsStyle}
							value={this.state.type}
							TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
							onChange={this.handleChange}
							variant={isMobile ? "fullWidth" : null}
						>
							<Tab
								className={classes.tabStyle}
								style={{
									color: this.state.type !== 0 ? "#000" : "#FFF",
									backgroundColor: this.state.type !== 0 ? "transparent" : "#35B2FF",
								}}
								label="Pending"
							/>
							<Tab
								className={classes.tabStyle}
								style={{
									color: this.state.type !== 1 ? "#000" : "#FFF",
									backgroundColor: this.state.type !== 1 ? "transparent" : "#35B2FF",
								}}
								label="Approved"
							/>
							<Tab
								className={classes.tabStyle}
								style={{
									color: this.state.type !== 2 ? "#000" : "#FFF",
									backgroundColor: this.state.type !== 2 ? "transparent" : "#35B2FF",
								}}
								label="Rejected"
							/>
							<Tab
								className={classes.tabStyle}
								style={{
									color: this.state.type !== 3 ? "#000" : "#FFF",
									backgroundColor: this.state.type !== 3 ? "transparent" : "#35B2FF",
								}}
								label="Expired"
							/>
							<Tab
								className={classes.tabStyle}
								style={{
									color: this.state.type !== 4 ? "#000" : "#FFF",
									backgroundColor: this.state.type !== 4 ? "transparent" : "#35B2FF",
								}}
								label="All"
							/>
						</Tabs>
						<p
							style={{
								margin: isMobile ? 0 : "0em 2em",
								right: "0",
								display: isMobile ? null : "inline-block",
								position: isMobile ? null : " absolute",
								textAlign: isMobile ? "center" : null,
							}}
						>
							<Tooltip title="Refresh Offers">
								<IconButton onClick={() => this.getItemsFunc(typeName.toLowerCase(), true)} className={classes.button}>
									<Refresh style={{ fontSize: "1.2em" }} />
								</IconButton>
							</Tooltip>
							<Tooltip title="Filter Columns">
								<IconButton onClick={this.handleClickOpenColsDialog} className={classes.button}>
									<FilterList style={{ fontSize: "1.2em" }} />
								</IconButton>
							</Tooltip>
						</p>
					</div>
					<div>
						<TextField
							placeholder="Search"
							className={classes.textField}
							value={this.state.searchVal}
							color="primary"
							InputProps={{
								classes: { input: classes.inputDrop },
							}}
							onKeyPress={(ev) => {
								if (ev.key === "Enter") {
									this.handleFilterBy(this.state.searchVal, true);
								}
							}}
							onChange={this.handleChangeFilter("searchVal")}
							margin="normal"
						/>

						<TextField
							select
							defaultValue="Search by"
							className={classes.textField}
							value={this.state.filterBy}
							color="primary"
							onChange={this.handleChangeFilter("filterBy")}
							InputProps={{
								classes: { input: classes.inputDrop },
							}}
							SelectProps={{
								MenuProps: {
									className: classes.menu,
								},
							}}
							margin="normal"
						>
							{filters.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
						<IconButton
							type="submit"
							color="primary"
							onClick={() => this.handleFilterBy(this.state.searchVal, true)}
							variant="contained"
							className={classes.button2}
						>
							<SearchIcon />
						</IconButton>
						<Tooltip title="Reset Search">
							<IconButton type="submit" color="primary" onClick={() => this.handleFilterReset()} variant="contained" className={classes.button2}>
								<ResetFilter />
							</IconButton>
						</Tooltip>
					</div>
					<Dialog open={this.state.openColsDialog} onClose={this.handleCloseColsDialog} fullScreen={isMobile}>
						<DialogTitle>Choose the columns you would like to see</DialogTitle>
						<DialogContent>
							<FormControl component="fieldset" style={{ padding: "0em 2em" }}>
								<FormGroup>
									{rows.slice(0, (rows.length + 1) / 2).map((row) => (
										<FormControlLabel
											key={row.sno}
											control={
												<Checkbox
													id={Number(row.sno)}
													disabled={row.sno === 13}
													key={row.sno}
													color="primary"
													checked={row.sno === 13 ? true : this.state.toShowCols[row.sno]}
													onChange={this.handleHideCols}
													value={row.id}
												/>
											}
											label={row.label}
										/>
									))}
								</FormGroup>
							</FormControl>
							<FormControl component="fieldset" style={{ padding: "0em 2em" }}>
								<FormGroup>
									{rows.slice((rows.length + 1) / 2, rows.length).map((row) => (
										<FormControlLabel
											key={row.sno}
											control={
												<Checkbox
													id={row.sno}
													disabled={row.sno === 13}
													key={row.sno}
													color="primary"
													checked={row.sno === 13 ? true : this.state.toShowCols[row.sno]}
													onChange={this.handleHideCols}
													value={row.id}
												/>
											}
											label={row.label}
										/>
									))}
								</FormGroup>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.handleCloseColsDialog(0)}>Reset</Button>
							<Button onClick={() => this.handleCloseColsDialog(1)}>Done</Button>
						</DialogActions>
					</Dialog>

					{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
					<div className={classes.tableWrapper}>
						{this.state.loading ? (
							<Lottie
								height={200}
								width={200}
								options={{
									loop: true,
									autoplay: true,
									animationData: loader.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
							/>
						) : dataLength === 0 ? (
							<Card
								elevation={1}
								className={classes.noItems}
								style={{
									// marginBottom: '300px',
									height: 15 * emptyRows,
								}}
							>
								<div className={classes.divLogo}>
									<CardMedia
										component="img"
										className={classes.media}
										width="auto"
										image={EnterOfferLogoColor}
										style={{
											display: "none",
										}}
									/>
									<Typography
										className={classes.titleText}
										style={{
											color: "#3e3e3e",
											fontSize: "1.5em",
											paddingTop: "0px",
										}}
									>
										No Items In {typeName}
									</Typography>
									<br />
									{typeName === "Pending" ? (
										<Typography
											style={{
												color: "#7f7f7f",
											}}
										>
											Great Work!
										</Typography>
									) : null}
								</div>
							</Card>
						) : (
							<Card className={classes.innerroot} elevation={1} id="dashboard-table">
								<Table padding="none" className={classes.table} aria-labelledby="tableTitle">
									<EnhancedTableHead
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
										toHideCols={this.handleHideCols}
										toShowCols={this.state.toShowCols}
										rowCount={dataLength}
										orderCol={typeName === "Rejected" || typeName === "Expired" ? true : false}
										resCol={typeName === "Approved" ? false : true}
									/>
									<TableBody>
										{stableSort(data, getSorting(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((n, k) => {
												if (n.id !== -1) {
													if (n.itemCount > 1) {
														var obj = {};
														for (let key in n.products) {
															obj[key] = n.products[key];
														}
													}
													const isSelected = this.isSelected(n.id);
													console.log(n);
													return (
														<Fragment key={k}>
															<Tooltip
																title={
																	typeName === "Approved" && (!n.payment || !n.order)
																		? "The payment for this order has failed and the order will not be created. The customer has been contacted."
																		: ""
																}
															>
																<TableRow role="checkbox" aria-checked={isSelected} tabIndex={-1} key={n.id}>
																	{n.itemCount > 1 ? (
																		<TableCell
																			id="product"
																			style={{
																				display: toShowCols[0] ? null : "none",
																				paddingLeft: "2%",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																		>
																			Bundle ({n.itemCount})
																			{n.cartAbandoned ? (
																				<Tooltip title="Cart Abandonment Offer">
																					<span
																						style={{
																							verticalAlign: "super",
																							fontSize: "0.8em",
																							color: "#22c78e",
																							cursor: "pointer",
																						}}
																					>
																						{" "}
																						CA
																					</span>
																				</Tooltip>
																			) : null}
																			{n.spp === true || n.spp === false ? (
																				<Tooltip title="Single Product Promotion">
																					<span
																						style={{
																							verticalAlign: "super",
																							fontSize: "0.8em",
																							color: "#f9499f",
																							cursor: "pointer",
																						}}
																					>
																						{" "}
																						SPP ({n.spp === true ? "5%" : "2%"})
																					</span>
																				</Tooltip>
																			) : null}
																		</TableCell>
																	) : n.products !== undefined && n.products !== null ? (
																		<TableCell
																			id="product"
																			style={{
																				display: toShowCols[0] ? null : "none",
																				paddingLeft: "2%",
																				cursor: this.state.storeName === "iceJewellery" ? "pointer" : null,
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																			onClick={() => this.handleRowClick(n)}
																		>
																			{n.products[0].productName}
																			{Number(n.products[0].quantity) > 1 ? ` (${Number(n.products[0].quantity)})` : null}
																			{n.cartAbandoned ? (
																				<Tooltip title="Cart Abandonment Offer">
																					<span
																						style={{
																							verticalAlign: "super",
																							fontSize: "0.8em",
																							color: "#22c78e",
																							cursor: "pointer",
																						}}
																					>
																						{" "}
																						CA
																					</span>
																				</Tooltip>
																			) : null}
																			{n.spp === true || n.spp === false ? (
																				<Tooltip title="Single Product Promotion">
																					<span
																						style={{
																							verticalAlign: "super",
																							fontSize: "0.8em",
																							color: "#f9499f",
																							cursor: "pointer",
																						}}
																					>
																						{" "}
																						SPP ({n.spp === true ? "5%" : "2%"})
																					</span>
																				</Tooltip>
																			) : null}
																		</TableCell>
																	) : (
																		<TableCell
																			id="product"
																			style={{
																				display: toShowCols[0] ? null : "none",
																				paddingLeft: "2%",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																		>
																			{n.productName}
																			{Number(n.quantity) > 1 ? ` (${Number(n.quantity)})` : null}
																			{n.cartAbandoned ? (
																				<Tooltip title="Cart Abandonment Offer">
																					<span
																						style={{
																							verticalAlign: "super",
																							fontSize: "0.8em",
																							color: "#22c78e",
																							cursor: "pointer",
																						}}
																					>
																						{" "}
																						CA
																					</span>
																				</Tooltip>
																			) : null}
																			{n.spp === true || n.spp === false ? (
																				<Tooltip title="Single Product Promotion">
																					<span
																						style={{
																							verticalAlign: "super",
																							fontSize: "0.8em",
																							color: "#f9499f",
																							cursor: "pointer",
																						}}
																					>
																						{" "}
																						SPP ({n.spp === true ? "5%" : "2%"})
																					</span>
																				</Tooltip>
																			) : null}
																		</TableCell>
																	)}
																	{n.itemCount === 1 && n.products !== undefined && n.products !== null ? (
																		<TableCell
																			id="variant"
																			style={{
																				display: toShowCols[1] ? null : "none",
																				paddingLeft: "2%",
																				textAlign: "center",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																		>
																			{n.products[0].variantName}
																		</TableCell>
																	) : (
																		<TableCell
																			id="variant"
																			style={{
																				display: toShowCols[1] ? null : "none",
																				paddingLeft: "2%",
																				textAlign: "center",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																		>
																			{n.variantName}
																		</TableCell>
																	)}
																	{n.itemCount === 1 && n.products !== undefined && n.products !== null ? (
																		<TableCell
																			id="sku"
																			style={{
																				display: toShowCols[2] ? null : "none",
																				paddingLeft: "2%",
																				textAlign: "center",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																		>
																			{n.products[0].sku}
																		</TableCell>
																	) : (
																		<TableCell
																			id="sku"
																			style={{
																				display: toShowCols[2] ? null : "none",
																				paddingLeft: "2%",
																				textAlign: "center",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																		>
																			{n.sku}
																		</TableCell>
																	)}
																	<TableCell
																		id="currency"
																		style={{
																			display: toShowCols[3] ? null : "none",
																			textAlign: "left",
																			color:
																				typeName === "Approved" && (!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null && n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{n.currency}
																	</TableCell>
																	<TableCell
																		id="country"
																		style={{
																			display: toShowCols[4] ? null : "none",
																			textAlign: "left",
																			color:
																				typeName === "Approved" && (!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null && n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{n.shippingAddress ? n.shippingAddress.country_code : null}
																	</TableCell>
																	<TableCell
																		id="listPrice"
																		style={{
																			display: toShowCols[5] ? null : "none",
																			textAlign: "left",
																			color:
																				typeName === "Approved" && (!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null && n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{`${Number(
																			n.listPrice / (n.products && n.products.length === 1 ? n.products[0].quantity : 1)
																		).toFixed(2)} ${
																			n.products && n.products.length === 1
																				? n.products[0].quantity !== 1
																					? "(" + n.products[0].quantity + ")"
																					: ""
																				: ""
																		}`}
																	</TableCell>
																	{n.itemCount === 1 ? (
																		<TableCell
																			id="costPrice"
																			style={{
																				display: toShowCols[6] ? null : "none",
																				textAlign: "left",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																		>
																			{`${n.products[0].cost ? Number(n.products[0].cost).toFixed(2) : "-"} ${
																				n.products.length === 1
																					? n.products[0].quantity !== 1
																						? "(" + n.products[0].quantity + ")"
																						: ""
																					: ""
																			}`}
																		</TableCell>
																	) : (
																		<TableCell
																			id="costPrice"
																			style={{
																				display: toShowCols[6] ? null : "none",
																				textAlign: "left",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																		>
																			{getTotalCost(n) ? Number(getTotalCost(n)).toFixed(2) : null}
																		</TableCell>
																	)}
																	<TableCell
																		id="bidPrice"
																		style={{
																			display: toShowCols[7] ? null : "none",
																			textAlign: "left",
																			color:
																				typeName === "Approved" && (!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null && n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{isNaN(Number(n.bidPrice))
																			? null
																			: n.products
																			? n.products[0].quantity > 1 || n.quantity > 1
																				? !!!n.products[0]
																					? `${Number(n.products[0].bidPrice).toFixed(2)} (${n.products[0].quantity})`
																					: `${Number(
																							n.bidPrice / (n.products.length === 1 ? n.products[0].quantity : 1)
																					  ).toFixed(2)} ${
																							n.products.length === 1 ? "(" + n.products[0].quantity + ")" : ""
																					  }`
																				: Number(n.bidPrice / n.products[0].quantity).toFixed(2)
																			: null}
																		{n.boost !== null && n.boost !== undefined ? (
																			<img
																				alt="offer boost"
																				src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/RocketBoost.png"
																				height={"16px"}
																			/>
																		) : null}
																	</TableCell>
																	<TableCell
																		id="discountCalc"
																		style={{
																			display: toShowCols[8] ? null : "none",
																			textAlign: "left",
																			color:
																				typeName === "Approved" && (!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null && n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{isNaN(Number(n.discountCalc)) ? null : Number(n.discountCalc).toFixed(2)}
																	</TableCell>
																	<TableCell
																		id="shipping"
																		style={{
																			display: toShowCols[9] ? null : "none",
																			textAlign: "left",
																			color:
																				typeName === "Approved" && (!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null && n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{n.shipping ? `${n.shipping.name} (${n.shipping.price})` : "-"}
																	</TableCell>
																	<TableCell
																		id="customerName"
																		style={{
																			display: toShowCols[10] ? null : "none",
																			textAlign: "left",
																			color:
																				typeName === "Approved" && (!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null && n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{n.firstName ? n.firstName : "-"} {n.lastName ? n.lastName : ""}
																	</TableCell>
																	<TableCell
																		id="customerEmail"
																		style={{
																			display: toShowCols[11] ? null : "none",
																			textAlign: "left",
																			color:
																				typeName === "Approved" && (!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null && n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{n.email ? n.email : n.shippingAddress ? n.shippingAddress.email : "-"}
																	</TableCell>
																	<TableCell
																		id="submittedDate"
																		style={{
																			display: toShowCols[12] ? null : "none",
																			textAlign: "left",
																			color:
																				typeName === "Approved" && (!n.payment || !n.order)
																					? "#f00"
																					: n.boost !== null && n.boost !== undefined
																					? "#35b2ff"
																					: null,
																		}}
																	>
																		{moment(n.submittedDate).format("MMM DD YYYY h:mm:ss a")}
																	</TableCell>
																	{typeName === "Pending" || typeName === "All" ? (
																		<TableCell
																			id="response"
																			style={{
																				// display: toShowCols[12] ? null : "none",
																				textAlign: "left",
																				color:
																					n.approved && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																		>
																			<div>
																				{(!n.approved && n.rejected) || typeName === "Expired" ? (
																					<div
																						color="secondary"
																						style={{
																							textAlign: "left",
																						}}
																						value={"a"}
																					>
																						<Clear />
																					</div>
																				) : null}
																				{n.approved && !n.rejected ? (
																					<div
																						color="secondary"
																						style={{
																							textAlign: "left",
																						}}
																						value={"b"}
																					>
																						<Done />
																					</div>
																				) : null}
																				{n.expired ? (
																					<div
																						color="secondary"
																						style={{
																							textAlign: "left",
																						}}
																						value={"b"}
																					>
																						<Expired />
																					</div>
																				) : null}
																				{typeName !== "Expired" &&
																				!n.expired &&
																				!n.approved &&
																				!n.rejected &&
																				n.payment === undefined &&
																				n.order === undefined ? (
																					<div>
																						<FormControl
																							style={{
																								display: "inline-block",
																							}}
																						>
																							<Select
																								value={n.itemStatus}
																								onChange={(e) => this.clickedRowItem(n, e.target.value)}
																								inputProps={{
																									name: "response",
																									id: "reponse",
																								}}
																							>
																								<MenuItem value={"approved"}>
																									<Done
																										style={{
																											color: "green",
																										}}
																									/>
																								</MenuItem>
																								<MenuItem value={"rejected"}>
																									<Clear
																										style={{
																											color: "red",
																										}}
																									/>
																								</MenuItem>
																							</Select>
																						</FormControl>
																					</div>
																				) : null}
																			</div>
																		</TableCell>
																	) : null}
																	{typeName !== "Approved" ? null : (
																		<TableCell
																			style={{
																				display: toShowCols[14] ? null : "none",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																			id="transaction"
																		>
																			{n.payment !== null && n.payment === false ? (
																				<div
																					color="secondary"
																					style={{
																						textAlign: "left",
																					}}
																					value={"a"}
																				>
																					<Clear />
																				</div>
																			) : null}
																			{n.payment !== null && n.payment === true ? (
																				<div
																					color="secondary"
																					style={{
																						textAlign: "left",
																					}}
																					value={"a"}
																				>
																					<Done />
																				</div>
																			) : n.payment === undefined && n.autoResponse === true ? (
																				"Processing"
																			) : null}
																		</TableCell>
																	)}
																	{typeName !== "Approved" ? null : (
																		<TableCell
																			style={{
																				display: toShowCols[15] ? null : "none",
																				color:
																					typeName === "Approved" && (!n.payment || !n.order)
																						? "#f00"
																						: n.boost !== null && n.boost !== undefined
																						? "#35b2ff"
																						: null,
																			}}
																			id="order"
																		>
																			{n.order !== null && n.order === false ? (
																				<div
																					color="secondary"
																					style={{
																						textAlign: "left",
																					}}
																					value={"a"}
																				>
																					<Clear />
																				</div>
																			) : null}
																			{n.order !== null && n.order === true ? (
																				<div
																					color="secondary"
																					style={{
																						textAlign: "left",
																					}}
																					value={"a"}
																				>
																					<Done />
																				</div>
																			) : n.order === undefined && n.autoResponse === true ? (
																				"Processing"
																			) : null}
																		</TableCell>
																	)}
																</TableRow>
															</Tooltip>
															{n.itemCount > 1
																? Object.keys(obj).map((key) => (
																		<TableRow
																			role="checkbox"
																			aria-checked={isSelected}
																			tabIndex={-1}
																			key={obj[key].variantID}
																			selected={true}
																		>
																			<TableCell
																				className={classes.bundlePNames}
																				style={{
																					display: toShowCols[0] ? null : "none",
																					// paddingLeft: "16px !important"
																				}}
																			>
																				{obj[key].productName}
																				{Number(obj[key].quantity) > 1 ? ` (${Number(obj[key].quantity)})` : null}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[1] ? null : "none",
																					textAlign: "center",
																					// paddingLeft: "16px !important"
																				}}
																			>
																				{obj[key].variantName}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[2] ? null : "none",
																					textAlign: "center",
																					// paddingLeft: "16px !important"
																				}}
																			>
																				{obj[key].sku}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[3] ? null : "none",
																					textAlign: "left",
																				}}
																			>
																				{" "}
																			</TableCell>
																			<TableCell
																				id="country"
																				style={{
																					display: toShowCols[4] ? null : "none",
																					textAlign: "left",
																				}}
																			>
																				{" "}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[5] ? null : "none",
																					textAlign: "left",
																				}}
																			>
																				{`${Number(obj[key].listPrice).toFixed(2)} ${
																					Number(obj[key].quantity) > 1 ? "(" + Number(obj[key].quantity) + ")" : ""
																				}`}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[6] ? null : "none",
																					textAlign: "left",
																				}}
																			>
																				{`${obj[key].cost ? Number(obj[key].cost).toFixed(2) : "-"} ${
																					Number(obj[key].quantity) > 1 ? "(" + Number(obj[key].quantity) + ")" : ""
																				}`}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[7] ? null : "none",
																					textAlign: "left",
																				}}
																			>
																				{" "}
																				{n.cartAbandoned ? "-" : Number(obj[key].bidPrice).toFixed(2)}
																				{Number(obj[key].quantity) > 1 ? ` (${Number(obj[key].quantity)})` : null}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[8] ? null : "none",
																					textAlign: "left",
																				}}
																			>
																				{n.cartAbandoned ? "-" : Number(obj[key].discountCalc).toFixed(2)}
																			</TableCell>

																			<TableCell
																				style={{
																					display: toShowCols[9] ? null : "none",
																					textAlign: "left",
																				}}
																			>
																				{" "}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[10] ? null : "none",
																					textAlign: "left",
																				}}
																			>
																				{" "}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[11] ? null : "none",
																					textAlign: "left",
																				}}
																			>
																				{" "}
																			</TableCell>
																			<TableCell
																				style={{
																					display: toShowCols[12] ? null : "none",
																					textAlign: "left",
																				}}
																			></TableCell>
																			{typeName === "Pending" || typeName === "All" ? (
																				<TableCell
																					style={
																						{
																							// display: toShowCols[12] ? null : "none"
																						}
																					}
																				>
																					<div>
																						{(!obj[key].approved && obj[key].rejected) ||
																						this.state.typeName === "Expired" ? (
																							<div
																								color="secondary"
																								style={{
																									textAlign: "left",
																								}}
																								value={"a"}
																							>
																								<Clear />
																							</div>
																						) : null}
																						{obj[key].approved && !obj[key].rejected ? (
																							<div
																								color="secondary"
																								style={{
																									textAlign: "left",
																								}}
																								value={"b"}
																							>
																								<Done />
																							</div>
																						) : null}
																					</div>
																				</TableCell>
																			) : null}
																			{typeName !== "Approved" ? null : (
																				<TableCell
																					style={{
																						display: toShowCols[14] ? null : "none",
																					}}
																				>
																					{obj[key].payment !== null && obj[key].payment === false ? (
																						<div
																							color="secondary"
																							style={{
																								textAlign: "left",
																							}}
																							value={"a"}
																						>
																							<Clear />
																						</div>
																					) : null}
																					{obj[key].payment !== null && obj[key].payment === true ? (
																						<div
																							color="secondary"
																							style={{
																								textAlign: "left",
																							}}
																							value={"a"}
																						>
																							<Done />
																						</div>
																					) : null}
																				</TableCell>
																			)}
																			{typeName !== "Approved" ? null : (
																				<TableCell
																					style={{
																						display: toShowCols[15] ? null : "none",
																					}}
																				>
																					{obj[key].order !== null && obj[key].order === false ? (
																						<div
																							color="secondary"
																							style={{
																								textAlign: "left",
																							}}
																							value={"a"}
																						>
																							<Clear />
																						</div>
																					) : null}
																					{obj[key].order !== null && obj[key].order === true ? (
																						<div
																							color="secondary"
																							style={{
																								textAlign: "left",
																							}}
																							value={"a"}
																						>
																							<Done />
																						</div>
																					) : null}
																				</TableCell>
																			)}
																		</TableRow>
																  ))
																: null}
														</Fragment>
													);
												} else {
													return null;
												}
											})}

										{emptyRows > 0 && (
											<TableRow
												style={{
													height: 4 * emptyRows,
													borderBottom: "1px solid transparent",
												}}
											>
												<TableCell
													colSpan={6}
													style={{
														borderBottom: "1px solid transparent",
													}}
												/>
											</TableRow>
										)}
									</TableBody>
								</Table>
								<TablePagination
									component="div"
									style={{
										float: "left !important",
										textAlign: "left !important",
									}}
									count={dataLength}
									rowsPerPage={rowsPerPage}
									page={page}
									labelRowsPerPage="Offers per page"
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
							</Card>
						)}
						<Dialog open={this.state.openDialog} onClose={this.handleCloseDialog}>
							<DialogContent
								style={{
									fontSize: "1.2em",
								}}
							>
								{this.state.functionType === "approved" ? (
									<div>
										<p>
											Are you sure you want to approve this offer? <br />
											<b>This is not reversible.</b>
										</p>
										<br />
										<p
											style={{
												color: "#929292",
												fontSize: "0.8em",
											}}
										>
											You can remove this confirmation under settings
										</p>
									</div>
								) : (
									<div>
										<p>
											Are you sure you want to reject this offer? <br />
											<b>This is not reversible.</b>
										</p>
										<br />
										<p
											style={{
												color: "#929292",
												fontSize: "0.8em",
											}}
										>
											You can remove this confirmation under settings
										</p>
									</div>
								)}
							</DialogContent>
							<DialogActions
								style={{
									margin: "0.5em",
								}}
							>
								{" "}
								{this.state.loadingReject ? null : (
									<Button
										onClick={this.handleCloseDialogCancel}
										color="primary"
										style={{
											marginRight: "0.5em",
										}}
									>
										Cancel
									</Button>
								)}
								<Button
									className={this.state.functionType === "approved" ? classes.choiceButton1 : classes.choiceButton2}
									disabled={this.state.loadingReject}
									onClick={this.handleCloseDialogConfirm}
									style={{
										color: this.state.functionType === "approved" ? "#22c78e" : "#ff4d4d",
									}}
									autoFocus
								>
									{this.state.loadingReject ? (
										<CircularProgress size={24} className={classes.circularProgress} />
									) : this.state.functionType === "approved" ? (
										"Approve Offer"
									) : (
										"Reject Offer"
									)}
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				</Paper>

				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					open={openSuccess}
					onClose={this.handleClickSnackSuccess}
					autoHideDuration={3000}
					message={<span id="message-id">Page Updated</span>}
				/>
				<Dialog
					style={{ textAlign: "left" }}
					open={offerDecideDialog}
					onClose={() => {
						this.setState({ offerDecideDialog: false });
					}}
					disableBackdropClick={true}
					disableEscapeKeyDown={true}
				>
					<DialogTitle style={{ textAlign: "center" }}>Checking Inventory</DialogTitle>
					<DialogContent style={{ textAlign: "left" }}>
						<p>This may take up to a minute...</p>
						<Lottie
							height={100}
							width={100}
							options={{
								loop: true,
								autoplay: true,
								animationData: loader.default,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
						/>
					</DialogContent>
				</Dialog>
				<Dialog
					style={{ textAlign: "left" }}
					open={offerAcceptDialog}
					onClose={() => {
						this.setState({ offerAcceptDialog: false });
					}}
					disableBackdropClick={true}
					disableEscapeKeyDown={true}
				>
					<DialogTitle style={{ textAlign: "center" }}>Accepting Offer</DialogTitle>
					<DialogContent style={{ textAlign: "left" }}>
						<p>Taking payment and creating order...</p>
						<Lottie
							height={100}
							width={100}
							options={{
								loop: true,
								autoplay: true,
								animationData: loader.default,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
						/>
					</DialogContent>
				</Dialog>
				<Dialog
					open={offerResultDialog}
					onClose={() => {
						this.setState({ offerResultDialog: false });
					}}
				>
					<DialogTitle style={{ textAlign: "center" }}>{offerResultTitle}</DialogTitle>
					<DialogContent style={{ textAlign: "left" }}>
						<p>{offerResultText}</p>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary" autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={openOfferInfo}
					onClose={() => {
						this.setState({ openOfferInfo: false });
					}}
				>
					<DialogTitle style={{ textAlign: "center" }}>Full Offer Information</DialogTitle>
					<DialogContent style={{ textAlign: "left" }}>
						<h4>Product:</h4>
						{clickedRow.products ? (
							<Grid container>
								<Grid item xs={3}>
									<img src={clickedRow.products[0].image} alt="productImg" height="150" style={{ objectFit: "contain", width: "100%" }} />
								</Grid>
								<Grid item xs={9}>
									<p>
										Product Name: <br />
										<b>{clickedRow.products[0].productName}</b>
									</p>
									<p>
										Product Variant: <br />
										<b>{clickedRow.products[0].variantName}</b>
									</p>
								</Grid>
							</Grid>
						) : null}
						<p>
							Name:{" "}
							<b>
								{clickedRow.firstName} {clickedRow.lastName}
							</b>
						</p>
						{clickedRow.shippingAddress ? (
							<div>
								<p>
									Email: <b>{clickedRow.shippingAddress.email ? clickedRow.shippingAddress.email : "-"}</b>
								</p>
								<p>
									Phone: <b>{clickedRow.shippingAddress.phoneNo ? clickedRow.shippingAddress.phoneNo : "-"}</b>
								</p>
							</div>
						) : null}
						<p>
							Offer Price: <b>${clickedRow.bidPrice}</b>
						</p>
						<p>
							List Price: <b>${clickedRow.listPrice}</b>
						</p>
						<p>
							Discount: <b>{clickedRow.discountCalc}%</b>
						</p>
						<p>
							Discount Amount: <b>${clickedRow.listPrice - clickedRow.bidPrice}</b>
						</p>
						{clickedRow.shipping ? (
							<p>
								Shipping: <b>{clickedRow.shipping.name}</b>
							</p>
						) : null}
						{clickedRow.shipping ? (
							<p>
								Shipping Price: <b>${clickedRow.shipping.price}</b>
							</p>
						) : null}

						<p>
							<b>Shipping Address:</b>
						</p>
						{clickedRow.shippingAddress ? (
							<Grid container>
								<Grid item xs={3}>
									<p>Line 1:</p>
									<p>Line 2:</p>
									<p>City:</p>
									<p>State:</p>
									<p>Post Code:</p>
									<p>Country:</p>
								</Grid>
								<Grid item xs={9}>
									<p>{clickedRow.shippingAddress.line1}</p>
									<p>{clickedRow.shippingAddress.line2 ? clickedRow.shippingAddress.line2 : "-"}</p>
									<p>{clickedRow.shippingAddress.city}</p>
									<p>{clickedRow.shippingAddress.state}</p>
									<p>{clickedRow.shippingAddress.address_zip}</p>
									<p>{clickedRow.shippingAddress.country}</p>
								</Grid>
							</Grid>
						) : null}
						<p>
							<b>Billing Address:</b>
						</p>
						{clickedRow.billingAddress ? (
							<Grid container>
								<Grid item xs={3}>
									<p>Line 1:</p>
									<p>Line 2:</p>
									<p>City:</p>
									<p>State:</p>
									<p>Post Code:</p>
									<p>Country:</p>
								</Grid>
								<Grid item xs={9}>
									<p>{clickedRow.billingAddress.line1}</p>
									<p>{clickedRow.billingAddress.line2 ? clickedRow.billingAddress.line2 : "-"}</p>
									<p>{clickedRow.billingAddress.city}</p>
									<p>{clickedRow.billingAddress.state}</p>
									<p>{clickedRow.billingAddress.address_zip}</p>
									<p>{clickedRow.billingAddress.country}</p>
								</Grid>
							</Grid>
						) : null}
						<p>
							Submitted Date: <b>{moment(clickedRow.submittedDate).format("MMM DD YYYY h:mm:ss a")}</b>
						</p>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								this.setState({ openOfferInfo: false });
							}}
							color="primary"
							autoFocus
						>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 900,
});

export default withSizes(mapSizesToProps)(withStyles(styles)(EnhancedTable));
