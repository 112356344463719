import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { db } from "../firebase";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { EnterOfferLogoColor } from "../assets";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
var liveTesting = false;

const styles = theme => ({
	card: {
		maxWidth: 345
	},
	media: {
		height: 140
	}
});

class readCampaigns extends Component {
	state = {
		counter: 0,
		data: [
			{
				id: -1,
				campaignKey: "",
				campaignName: "",
				campaignUrl: "",
				image: "",
				productId: "",
				productName: "",
				startDateNum: "",
				startDateInWords: "",
				endDateNum: "",
				endDateInWords: "",
				deleted: false
			}
		],
		typeName: "active",
		type: 0,
		loading: true,
		expanded: null,
		storeName: ""
	};
	// campaignUrl: "https://enteroffer.com/campaigns/store?="+this.state.storeName+"&id="+this.state.productId

	createData(
		campaignKey,
		campaignName,
		productId,
		productName,
		startDateNum,
		startDateInWords,
		endDateNum,
		endDateInWords,
		image
	) {
		const { counter } = this.state;
		if (liveTesting) {
			this.setState({
				campaignUrl:
					"https://oaklas-website-dev.web.app/campaign/?store=" +
					this.state.storeName +
					"&id=" +
					productId +
					"&url=" +
					this.state.storeUrl
			});
		} else {
			this.setState({
				campaignUrl:
					"https://app.enteroffer.com/?s=" +
					this.state.storeName +
					"&p=" +
					productId +
					"&url=" +
					this.state.storeUrl
			});
		}
		this.setState({
			counter: counter + 1
		});
		return {
			id: counter,
			campaignKey,
			campaignName,
			productId,
			productName,
			startDateNum,
			startDateInWords,
			endDateNum,
			endDateInWords,
			image
		};
	}

	getCampaignsFunc(whichType, didClickUpdate) {
		db.getStoreCampaigns(this.state.storeName, whichType)
			.then(snapshot => {
				if (snapshot !== "noData") {
					let tempData = [];
					snapshot.forEach(x => {
						let productId = x.child("productId").val();
						if (productId != null) {
							let startDate = moment.unix(x.child("startDate").val());
							let endDate = moment.unix(x.child("endDate").val());
							let startDateInWords = moment(startDate).format(
								"MMMM Do YYYY, h:mm:ss a"
							);
							let endDateInWords = moment(endDate).format("MMMM Do YYYY, h:mm:ss a");
							let name = x.child("name").val();
							let data = this.createData(
								x.key,
								name,
								productId,
								x.child("productName").val(),
								startDate,
								startDateInWords,
								endDate,
								endDateInWords,
								x.child("image").val()
							);
							if (name && !x.child("makeOffer").val()) {
								tempData.unshift(data);
							}
						}
					});
					this.setState({ data: tempData, loading: false });
				}
				if (this.state.data.length === 0) {
					this.setState({ loading: false });
				}
				this.setState({ loading: false });
				if (didClickUpdate) {
					this.setState({ openSuccess: true });
				}
			})
			.catch(e => console.log("error", e));
	}

	handleChange = (event, type) => {
		this.setState({ type });
		this.setState({ loading: true });
		if (type === 0) {
			this.getCampaignsFunc("active", false);
			this.setState({ typeName: "active" });
		} else if (type === 1) {
			this.getCampaignsFunc("history", false);
			this.setState({ typeName: "history" });
		} else if (type === 2) {
			this.getCampaignsFunc("future", false);
			this.setState({ typeName: "future" });
		}
	};

	handleDeleteCampaign = (
		index,
		storeName,
		typeName,
		campaignKey,
		campaignName,
		productName,
		productId,
		startDateNum,
		endDateNum
	) => {
		console.log(
			"storeName: " +
				storeName +
				" typeName: " +
				typeName +
				" campaignKey: " +
				campaignKey +
				" campaignName: " +
				campaignName +
				" productName: " +
				productName +
				" productId: " +
				productId +
				" startDateNum " +
				startDateNum +
				" endDateNum: " +
				moment().unix()
		);
		//startDateNum is a moment object, so it needs to be changed into unix seconds.
		startDateNum = startDateNum.unix();
		endDateNum = moment().unix();
		db.deleteCampaign(
			storeName,
			typeName,
			campaignKey,
			campaignName,
			productName,
			productId,
			startDateNum,
			endDateNum
		);
		// window.location.reload();
		var list = [...this.state.data];
		list.splice(index, 1);
		this.setState({ data: list });
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				if (snapshot == null) {
					return "noData";
				} else {
					this.setState({ storeName: snapshot });
					this.getCampaignsFunc("active", false);
				}
				return db.getUserStoreUrl(snapshot);
			})
			.then(storeUrl => {
				this.setState({ storeUrl: storeUrl });
			})
			.catch(e => console.log("error", e));
	}

	handleChangePanel = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false
		});
	};

	render() {
		const { expanded } = this.state;

		if (this.state.typeName === "active") {
			var type = "Current";
		} else if (this.state.typeName === "history") {
			type = "Past";
		} else if (this.state.typeName === "future") {
			type = "Scheduled";
		}
		return (
			<div style={{ textAlign: "center" }}>
				<MediaQuery query="(min-width: 600px)">
					<div style={{ display: "inline-block", width: "60%" }}>
						<Tabs
							value={this.state.type}
							onChange={this.handleChange}
							variant="fullWidth"
							style={{ marginTop: "3%", marginBottom: "5%", fontSize: "3em" }}
							indicatorColor="primary"
							textColor="primary"
						>
							<Tab label="Current" style={{ fontSize: "0.4em" }} />
							<Tab label="Past" style={{ fontSize: "0.4em" }} />
							<Tab label="Scheduled" style={{ fontSize: "0.4em" }} />
						</Tabs>
						{this.state.loading ? (
							<Lottie
								height={200}
								width={200}
								options={{
									loop: true,
									autoplay: true,
									animationData: loader.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice"
									}
								}}
							/>
						) : this.state.data.length === 0 ? (
							<div>
								<CardMedia
									component="img"
									width="5px"
									style={{
										height: "3%",
										width: "10%",
										marginLeft: "45%",
										marginRight: "45%",
										marginBottom: "5%"
									}}
									image={EnterOfferLogoColor}
								/>
								<Typography variant="display1">No {type} Campaigns</Typography>
								<br />
								<Link to="/campaigns/new" style={{ textDecoration: "none" }}>
									<Button style={{ textAlign: "center", marginTop: "6%" }}>
										New Campaign
									</Button>
								</Link>
							</div>
						) : (
							<div>
								<Link to="/campaigns/new" style={{ textDecoration: "none" }}>
									<Typography
										variant="body1"
										style={{
											textAlign: "left",
											marginBottom: "2%",
											marginLeft: "2%",
											color: "#35B2FF"
										}}
									>
										New Campaign
									</Typography>
								</Link>
								{this.state.data.map((value, index) => {
									return (
										<ExpansionPanel
											key={index}
											expanded={expanded === index}
											onChange={this.handleChangePanel(index)}
										>
											<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
												<div>
													<Typography
														variant="body1"
														style={{ fontWeight: "bold" }}
													>
														{value.campaignName}
													</Typography>
												</div>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<div style={{ textAlign: "left", width: "100%" }}>
													<Paper
														elevation={0}
														square={true}
														style={{
															width: "100%",
															fontSize: "1.1em",
															backgroundColor: "#f6f6f8",
															paddingTop: "0.5em",
															paddingBottom: "0.5em"
														}}
													>
														<Grid
															container
															justify="center"
															spacing={2}
														>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%"
																	}}
																>
																	Campaign Name
																</Typography>
															</Grid>
															<Grid item xs={8}>
																<Typography
																	style={{ fontSize: "0.9em" }}
																>
																	{value.campaignName}
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%"
																	}}
																>
																	Product
																</Typography>
															</Grid>
															<Grid item xs={8}>
																<Typography
																	style={{ fontSize: "0.9em" }}
																>
																	{value.productName}
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%"
																	}}
																>
																	Start
																</Typography>
															</Grid>
															<Grid item xs={8}>
																<Typography
																	style={{ fontSize: "0.9em" }}
																>
																	{value.startDateInWords}
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%"
																	}}
																>
																	End
																</Typography>
															</Grid>
															<Grid item xs={8}>
																<Typography
																	style={{ fontSize: "0.9em" }}
																>
																	{value.endDateInWords}
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%"
																	}}
																>
																	Campaign Url
																</Typography>
															</Grid>
															<Grid item xs={8}>
																{liveTesting ? (
																	<a
																		href={
																			"https://oaklas-website-dev.web.app/campaign/?store=" +
																			this.state.storeName +
																			"&id=" +
																			value.productId +
																			"&url=" +
																			this.state.storeUrl
																		}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<Typography
																			style={{
																				fontSize: "0.9em",
																				wordWrap:
																					"break-word",
																				paddingRight: "8px"
																			}}
																		>
																			{"https://app.enteroffer.com/?s=" +
																				this.state
																					.storeName +
																				"&p=" +
																				value.productId +
																				"&url=" +
																				this.state.storeUrl}
																		</Typography>
																	</a>
																) : (
																	<a
																		href={
																			"https://app.enteroffer.com/?s=" +
																			this.state.storeName +
																			"&p=" +
																			value.productId +
																			"&url=" +
																			this.state.storeUrl
																		}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<Typography
																			style={{
																				fontSize: "0.9em",
																				wordWrap:
																					"break-word",
																				paddingRight: "8px"
																			}}
																		>
																			{"https://app.enteroffer.com/?s=" +
																				this.state
																					.storeName +
																				"&p=" +
																				value.productId +
																				"&url=" +
																				this.state.storeUrl}
																		</Typography>
																	</a>
																)}
															</Grid>
														</Grid>
													</Paper>
													<br></br>
													{value.image ? (
														<div style={{ textAlign: "center" }}>
															<img
																src={value.image}
																style={{
																	objectFit: "cover",
																	height: "16.25em",
																	width: "11.25em",
																	display: "inline-block",
																	boxShadow:
																		"0px 10px 30px 0px rgba(0, 0, 0, 0.1)",
																	transition:
																		"opacity 0.2s ease, box-shadow 0.2s ease",
																	margin: "0em 3em 3em 3em "
																}}
																alt="productImg"
															/>
														</div>
													) : null}
												</div>
											</ExpansionPanelDetails>
											<ExpansionPanelActions style={{ padding: 0 }}>
												{this.state.type === 0 ? (
													<IconButton
														key="close"
														aria-label="Close"
														color="inherit"
														// index = {index}
														style={{
															position: "absolute",
															right: "8%",
															top: "1%",
															fontFamily: '"Nunito Sans", sans-serif',
															fontSize: "0.8em",
															borderRadius: "4px",
															marginTop: "0.2em"
														}}
														onClick={() => {
															this.handleDeleteCampaign(
																index,
																this.state.storeName,
																this.state.typeName,
																value.campaignKey,
																value.campaignName,
																value.productName,
																value.productId,
																value.startDateNum,
																value.endDateNum
															);
														}}
													>
														STOP
													</IconButton>
												) : (
													[
														this.state.type === 1 ? null : (
															<IconButton
																key="delete"
																aria-label="Close"
																color="inherit"
																// index = {index}
																style={{
																	position: "absolute",
																	right: "8%",
																	top: "1%",
																	fontFamily:
																		'"Nunito Sans", sans-serif',
																	fontSize: "0.8em",
																	borderRadius: "40px"
																}}
																onClick={() => {
																	this.handleDeleteCampaign(
																		index,
																		this.state.storeName,
																		this.state.typeName,
																		value.campaignKey,
																		value.campaignName,
																		value.productName,
																		value.productId,
																		value.startDateNum,
																		value.endDateNum
																	);
																}}
															>
																<Delete />
															</IconButton>
														)
													]
												)}
											</ExpansionPanelActions>
										</ExpansionPanel>
									);
								})}
							</div>
						)}
					</div>
				</MediaQuery>
				<MediaQuery query="(max-width: 600px)">
					<div style={{ display: "inline-block" }}>
						<Tabs
							value={this.state.type}
							onChange={this.handleChange}
							variant="fullWidth"
							style={{ marginTop: "3%", marginBottom: "5%", fontSize: "3em" }}
							indicatorColor="primary"
							textColor="primary"
						>
							<Tab label="Current" style={{ fontSize: "0.4em" }} />
							<Tab label="Past" style={{ fontSize: "0.4em" }} />
							<Tab label="Scheduled" style={{ fontSize: "0.4em" }} />
						</Tabs>
						{this.state.loading ? (
							<Lottie
								height={200}
								width={200}
								options={{
									loop: true,
									autoplay: true,
									animationData: loader.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice"
									}
								}}
							/>
						) : this.state.data.length === 0 ? (
							<div>
								<CardMedia
									component="img"
									width="5px"
									style={{
										height: "3%",
										width: "10%",
										marginLeft: "45%",
										marginRight: "45%",
										marginBottom: "5%"
									}}
									image={EnterOfferLogoColor}
								/>
								<Typography variant="display1">No {type} Campaigns</Typography>
								<Link to="/campaigns/new" style={{ textDecoration: "none" }}>
									<Button style={{ textAlign: "center", marginTop: "6%" }}>
										New Campaign
									</Button>
								</Link>
							</div>
						) : (
							<div>
								<Link to="/campaigns/new" style={{ textDecoration: "none" }}>
									<Typography
										variant="body1"
										style={{
											textAlign: "left",
											marginBottom: "2%",
											marginLeft: "2%",
											color: "#35B2FF"
										}}
									>
										New Campaign
									</Typography>
								</Link>
								{this.state.data.map((value, index) => {
									return (
										<ExpansionPanel
											key={index}
											expanded={expanded === index}
											onChange={this.handleChangePanel(index)}
										>
											<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
												<Typography
													variant="body1"
													style={{ fontWeight: "bold" }}
												>
													{value.campaignName}
												</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<div style={{ textAlign: "left", width: "100%" }}>
													<Paper
														elevation={0}
														square={true}
														style={{
															width: "110%",
															marginLeft: "-5%",
															fontSize: "1.1em",
															backgroundColor: "#f6f6f8",
															paddingTop: "0.5em",
															paddingBottom: "0.5em"
														}}
													>
														<Grid
															container
															justify="center"
															spacing={2}
														>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%",
																		wordWrap: "break-word"
																	}}
																>
																	Campaign Name
																</Typography>
															</Grid>
															<Grid item xs={8}>
																<Typography
																	style={{ fontSize: "0.9em" }}
																>
																	{value.campaignName}
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%"
																	}}
																>
																	Product
																</Typography>
															</Grid>
															<Grid item xs={8}>
																<Typography
																	style={{ fontSize: "0.9em" }}
																>
																	{value.productName}
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%"
																	}}
																>
																	Start
																</Typography>
															</Grid>
															<Grid item xs={8}>
																<Typography
																	style={{ fontSize: "0.9em" }}
																>
																	{value.startDateInWords}
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%"
																	}}
																>
																	End
																</Typography>
															</Grid>
															<Grid item xs={8}>
																<Typography
																	style={{ fontSize: "0.9em" }}
																>
																	{value.endDateInWords}
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography
																	style={{
																		fontSize: "0.7em",
																		color:
																			"rgba(0, 0, 0, 0.38)",
																		textTransform: "uppercase",
																		marginLeft: "15%"
																	}}
																>
																	Campaign Url
																</Typography>
															</Grid>
															<Grid item xs={8}>
																{liveTesting ? (
																	<a
																		href={
																			"https://oaklas-website-dev.web.app/campaign/?store=" +
																			this.state.storeName +
																			"&id=" +
																			value.productId +
																			"&url=" +
																			this.state.storeUrl
																		}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<Typography
																			style={{
																				fontSize: "0.9em",
																				wordWrap:
																					"break-word",
																				paddingRight: "8px"
																			}}
																		>
																			{"https://app.enteroffer.com/?s=" +
																				this.state
																					.storeName +
																				"&p=" +
																				value.productId +
																				"&url=" +
																				this.state.storeUrl}
																		</Typography>
																	</a>
																) : (
																	<a
																		href={
																			"https://app.enteroffer.com/?s=" +
																			this.state.storeName +
																			"&p=" +
																			value.productId +
																			"&url=" +
																			this.state.storeUrl
																		}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<Typography
																			style={{
																				fontSize: "0.9em",
																				wordWrap:
																					"break-word",
																				paddingRight: "8px"
																			}}
																		>
																			{"https://app.enteroffer.com/?s=" +
																				this.state
																					.storeName +
																				"&p=" +
																				value.productId +
																				"&url=" +
																				this.state.storeUrl}
																		</Typography>
																	</a>
																)}
															</Grid>
														</Grid>
													</Paper>
													<br></br>
													{value.image ? (
														<div style={{ textAlign: "center" }}>
															<img
																src={value.image}
																style={{
																	objectFit: "cover",
																	height: "16.25em",
																	width: "11.25em",
																	display: "inline-block",
																	boxShadow:
																		"0px 10px 30px 0px rgba(0, 0, 0, 0.1)",
																	transition:
																		"opacity 0.2s ease, box-shadow 0.2s ease",
																	margin: "0em 3em 3em 3em "
																}}
																alt="productImg"
															/>
														</div>
													) : null}
												</div>
											</ExpansionPanelDetails>
											<ExpansionPanelActions
												style={{ padding: "0 !important" }}
											>
												{this.state.type === 0 ? (
													<IconButton
														key="close"
														aria-label="Close"
														color="inherit"
														// index = {index}
														style={{
															position: "absolute",
															right: "12%",
															top: "1%",
															fontFamily: '"Nunito Sans", sans-serif',
															fontSize: "0.8em",
															borderRadius: "4px",
															marginTop: "0.2em"
														}}
														onClick={() => {
															this.handleDeleteCampaign(
																index,
																this.state.storeName,
																this.state.typeName,
																value.campaignKey,
																value.campaignName,
																value.productName,
																value.productId,
																value.startDateNum,
																value.endDateNum
															);
														}}
													>
														STOP
													</IconButton>
												) : (
													[
														this.state.type === 1 ? null : (
															<IconButton
																key="delete"
																aria-label="Close"
																color="inherit"
																// index = {index}
																style={{
																	position: "absolute",
																	right: "12%",
																	top: "1%",
																	fontFamily:
																		'"Nunito Sans", sans-serif',
																	fontSize: "0.8em",
																	borderRadius: "40px"
																}}
																onClick={() => {
																	this.handleDeleteCampaign(
																		index,
																		this.state.storeName,
																		this.state.typeName,
																		value.campaignKey,
																		value.campaignName,
																		value.productName,
																		value.productId,
																		value.startDateNum,
																		value.endDateNum
																	);
																}}
															>
																<Delete />
															</IconButton>
														)
													]
												)}
											</ExpansionPanelActions>
										</ExpansionPanel>
									);
								})}
							</div>
						)}
					</div>
				</MediaQuery>
			</div>
		);
	}
}

export default withStyles(styles)(readCampaigns);
