import React, { Component } from "react";
import { RulesPaper, MasterRule, CartRules } from "../components";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import { db, fb } from "../firebase";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import withSizes from "react-sizes";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";

const styles = theme => ({
	root: {
		flexGrow: 1,
		paddingLeft: theme.spacing(1) * 3,
		paddingRight: theme.spacing(1) * 3,
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1) * 3
	},
	paper: {
		height: 30,
		width: 80
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
		textAlign: "left",
		flexBasis: "40%",
		flexShrink: 0
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
		width: "50%",
		display: "inline-block",
		marginLeft: "5%"
	},
	control: {
		padding: theme.spacing(1) * 2
	},
	margin: {
		margin: theme.spacing(1) * 2
	},
	button: {},
	fabStyle: {
		margin: theme.spacing(1) * 2
	}
});

class Rules extends Component {
	state = {
		productsAndRules: [],
		queryString: "",
		loading: true,
		productLoading: false,
		collections: [],
		isExpanded: false,
		selectedCollection: "Select a Collection",
		selectedCollectionTitle: "",
		selectedCollectionID: "",
		isCollectionSelected: false,
		pages: "",
		storeName: "",
		total: 20,
		display: 7,
		number: 7,
		tabName: "Overview",
		type: 0,
		expanded: null,
		allProducts: [],
		cartRulesOverview: []
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				return fb.getCollections(snapshot);
			})
			.then(collectionsList => {
				if (!collectionsList) {
					collectionsList = [];
				}
				this.setState({ collections: collectionsList });
				return fb.deleteExpiredRules(this.state.storeName);
			})
			.then(x => {
				return db.getProductRules(this.state.storeName);
			})
			.then(rules => {
				this.setState({ productRulesOverview: rules });
			})
			.then(y => {
				return db.getCartRules(this.state.storeName);
			})
			.then(cartRules => {
				this.setState({ cartRulesOverview: cartRules });
				this.setState({ loading: false });
			})
			.catch(console.error);
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
		if (name === "selectedCollection") {
			this.selectCollection(this.state.collections[event.target.value]);
		}
	};

	handleChangeTab = (event, type) => {
		this.setState({ type });
		if (type === 0) {
			this.setState({ tabName: "Overview" });
			db.getProductRules(this.state.storeName)
				.then(rules => {
					this.setState({ productRulesOverview: rules });
					return db.getCartRules(this.state.storeName);
				})
				.then(cartRules => {
					this.setState({ cartRulesOverview: cartRules });
				});
		} else if (type === 1) {
			this.setState({ tabName: "Product" });
		} else if (type === 2) {
			this.setState({ tabName: "Cart" });
		}
	};

	filterResults = () => {
		const { queryString, productsAndRules } = this.state;
		return queryString === ""
			? productsAndRules
			: _.filter(productsAndRules, x =>
					x.productTitle.toLowerCase().includes(this.state.queryString.toLowerCase())
			  );
	};

	selectCollection(collection) {
		this.setState({
			productLoading: true,
			isExpanded: false,
			selectedCollectionTitle: collection.title,
			isCollectionSelected: true,
			selectedCollectionID: collection.id
		});
		this.getProductsBack(collection.id);
	}

	deleteMasterRuleValue = () => {
		db.removeMasterRules(this.state.storeName, this.state.productsAndRules).then(() => {
			this.getProductsBack();
		});
	};

	gatheringMasterRuleValue = masterRule => {
		db.submitMasterRules(this.state.storeName, masterRule, this.state.productsAndRules).then(() => {
			this.getProductsBack();
		});
	};

	getProductsBack(collectionID) {
		let getCollectionsId;
		if (collectionID) {
			getCollectionsId = collectionID;
		} else {
			getCollectionsId = this.state.selectedCollectionID;
		}
		let productsAndRulesTest = [];
		this.setState({ productLoading: true });

		db.getProductsInCollection(this.state.storeName, getCollectionsId)
			.then(results => {
				for (let item of results) {
					productsAndRulesTest.push(item);
				}
				this.setState({ productsAndRules: productsAndRulesTest });
				this.setState({ productLoading: false });
				return;
			})
			.catch(e => console.log("error: ", e));
	}

	handleChangeCollection = selectedOption => {
		this.setState({
			selectedCollection: selectedOption.value.title,
			productLoading: true,
			isExpanded: false,
			selectedCollectionTitle: selectedOption.value.title,
			isCollectionSelected: true,
			selectedCollectionID: selectedOption.value.id
		});
		this.setState({
			productName: "Select...",
			productId: "",
			productSelected: "Select...",
			productSelectedImage: ""
		});
		this.getProductsBack(selectedOption.value.id);
	};

	makeCollectionOptions = () => {
		const { collections } = this.state;
		let collectionOptions = [];
		collections.forEach((value, index) => {
			let product = {};
			product["label"] = value.title;
			product["value"] = value;
			collectionOptions.push(product);
		});
		return collectionOptions;
	};

	handleChangePanel = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false
		});
	};

	render() {
		const { classes, isMobile } = this.props;
		const {
			selectedCollectionTitle,
			isCollectionSelected,
			expanded,
			productRulesOverview,
			cartRulesOverview
		} = this.state;
		const products = this.filterResults();

		return (
			<div>
				{this.state.loading ? (
					<div style={{ display: "inline-block", height: "70vh" }}>
						<Lottie
							height={300}
							width={300}
							options={{
								loop: true,
								autoplay: true,
								animationData: loader.default,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice"
								}
							}}
							style={{
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%) translateX(-50%)",
								left: "50%"
							}}
						/>
					</div>
				) : (
					<Grid
						container
						direction='column'
						spacing={2}
						style={{
							width: isMobile ? "auto" : "80%",
							margin: "0 auto",
							marginBottom: "10em"
						}}>
						{!this.state.loading ? (
							<div style={{ width: "100%" }}>
								<h1 style={{ textAlign: "center" }}>
									<span
										style={{
											color: "#35B2FF",
											fontWeight: 900
										}}>
										PRICE
									</span>
									<span style={{ fontWeight: 900 }}> RULES</span>
								</h1>
								<Tabs
									value={this.state.type}
									onChange={this.handleChangeTab}
									variant='fullWidth'
									style={{ marginTop: "3%", marginBottom: "5%", fontSize: "3em" }}
									indicatorColor='primary'
									textColor='primary'>
									<Tab
										label='Overview'
										style={{
											fontSize: "0.4em",
											textTransform: "capitalize",
											color: "#35B2FF"
										}}
									/>
									<Tab
										label='Product'
										style={{
											fontSize: "0.4em",
											textTransform: "capitalize",
											color: "#35B2FF"
										}}
									/>
									<Tab
										label='Cart / Cart Abandonment'
										style={{
											fontSize: isMobile ? "0.35em" : "0.4em",
											textTransform: "capitalize",
											color: "#35B2FF"
										}}
									/>
								</Tabs>
							</div>
						) : null}
						{!this.state.loading ? (
							<div>
								{this.state.type === 1 ? (
									<div
										style={{
											width: isMobile ? "auto" : "75%",
											margin: "0 auto"
										}}>
										<h3
											style={{
												fontWeight: "100",
												textAlign: "center",
												color: "#929292"
											}}>
											Choose a collection to search through products
										</h3>
										<Select
											value={{
												value: this.state.selectedCollection,
												label: this.state.selectedCollection
											}}
											onChange={this.handleChangeCollection}
											options={this.makeCollectionOptions()}
											style={{
												fontSize: "1.5em",
												backgroundColor: "#f6f6f8",
												borderRadius: "0.2em"
											}}
											styles={{
												...styles,
												control: (base, state) => ({
													...base,
													backgroundColor: "#f6f6f8",
													border: "none", // default border color
													boxShadow: "none",
													zIndex: "1000 !important",
													borderRadius: "0.2em",
													padding: "0.3em",
													width: "100%"
												})
											}}></Select>
										{isCollectionSelected ? (
											<TextField
												id='standard-full-width'
												value={this.state.queryString}
												placeholder='Search for a Product'
												onChange={this.handleChange("queryString")}
												fullWidth
												margin='normal'
												style={{
													display: "inline-block",
													textAlign: "center",
													width: "100%",
													fontSize: "1em",
													backgroundColor: "#f6f6f8",
													borderRadius: "0.2em"
												}}
												required={true}
												InputProps={{
													disableUnderline: true,
													style: {
														fontSize: "1em",
														fontFamily: "Nunito Sans",
														padding: "8px"
													}
												}}
											/>
										) : null}
										{this.state.loading ? (
											<Lottie
												height={200}
												width={200}
												options={{
													loop: true,
													autoplay: true,
													animationData: loader.default,
													rendererSettings: {
														preserveAspectRatio: "xMidYMid slice"
													}
												}}
											/>
										) : (
											<Grid
												container
												justify='center'
												spacing={1}
												style={{ display: "block", marginBottom: "25px" }}>
												{isCollectionSelected && !this.state.queryString ? (
													<Grid item xs={12}>
														<MasterRule
															collectionName={selectedCollectionTitle}
															onSelectRule={this.gatheringMasterRuleValue}
															onDeleteRule={this.deleteMasterRuleValue}
														/>
													</Grid>
												) : null}
											</Grid>
										)}
										{this.state.productLoading ? (
											<Lottie
												height={200}
												width={200}
												options={{
													loop: true,
													autoplay: true,
													animationData: loader.default,
													rendererSettings: {
														preserveAspectRatio: "xMidYMid slice"
													}
												}}
											/>
										) : products.length === 0 && isCollectionSelected === true ? (
											<h1
												style={{
													fontFamily: "sans-serif",
													fontWeight: "100",
													textAlign: "center",
													marginTop: "200px"
												}}>
												No Products Found
											</h1>
										) : (
											<Grid container justify='center' spacing={1}>
												{_.map(products, (rule, i) => (
													<ExpansionPanel
														style={{ width: "100%" }}
														key={products[i].productId}
														expanded={expanded === products[i].productId}
														onChange={this.handleChangePanel(products[i].productId)}>
														<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
															{expanded === products[i].productId ? (
																<div>
																	<Typography style={{ color: "#929292" }}>
																		Product Rule
																	</Typography>
																	<Typography
																		style={{
																			fontSize: "1.2em"
																		}}>
																		{products[i].productTitle}
																	</Typography>
																</div>
															) : (
																<Typography className={classes.heading}>
																	{products[i].productTitle}
																</Typography>
															)}
															{expanded === products[i].productId ? null : products[i]
																	.acceptAbove ? (
																<Typography className={classes.secondaryHeading}>
																	Accept above{" "}
																	<span
																		style={{
																			color: "#22c78e",
																			fontWeight: "700"
																		}}>
																		{products[i].acceptAbove}%
																	</span>
																</Typography>
															) : null}
														</ExpansionPanelSummary>
														<ExpansionPanelDetails>
															<RulesPaper
																data={rule}
																id={products[i].productId}
																onSelectRule={this.gatheringMasterButtonValue}
																tab={this.state.type}
															/>
														</ExpansionPanelDetails>
													</ExpansionPanel>
												))}
											</Grid>
										)}
									</div>
								) : this.state.type === 0 ? (
									<div>
										{this.state.loading ? (
											<Lottie
												height={200}
												width={200}
												options={{
													loop: true,
													autoplay: true,
													animationData: loader.default,
													rendererSettings: {
														preserveAspectRatio: "xMidYMid slice"
													}
												}}
											/>
										) : (
											<Grid container justify='center' spacing={2}>
												<Grid item xs={isMobile ? 12 : 6}>
													<h3
														style={{
															fontWeight: "100",
															textAlign: "center",
															color: "#929292"
														}}>
														PRODUCTS
													</h3>
													{productRulesOverview.length === 0 ? (
														<Paper
															className={classes.root}
															style={{
																backgroundColor: "#f6f6f8"
															}}
															elevation={0}>
															<Typography
																style={{
																	fontFamily: '"Nunito Sans", sans-serif',
																	fontWeight: "100",
																	textAlign: "center",
																	color: "#f6f6f8"
																}}
																variant='subtitle1'
																component='h4'>
																-
															</Typography>
															<AddIcon
																style={{
																	fontWeight: "100",
																	display: "block",
																	fontSize: 100,
																	color: "#35B2FF",
																	marginLeft: "auto",
																	marginRight: "auto",
																	cursor: "pointer"
																}}
																onClick={() => this.setState({ type: 1 })}
															/>
															<Typography
																style={{
																	fontFamily: '"Nunito Sans", sans-serif',
																	fontWeight: "100",
																	textAlign: "center",
																	color: "#35B2FF",
																	cursor: "pointer"
																}}
																variant='h6'
																component='h4'
																onClick={() => this.setState({ type: 1 })}>
																Add Product Price Rule
															</Typography>
															<br />
															<br />
															<Divider fullwidth='true' />
															<br />
															<Typography
																style={{
																	fontWeight: "800",
																	textAlign: "center",
																	color: "#3e3e3e"
																}}
																variant='subtitle1'
																component='h4'>
																You don’t yet have any product price rules set
															</Typography>
															<br />
															<Typography
																style={{
																	fontWeight: "100",
																	textAlign: "center",
																	color: "#3e3e3e"
																}}
																variant='subtitle1'
																component='h4'>
																Product price rules allow you to automate offer
																acceptance and rejection of individual products. You set
																the upper range of which offers should be automatically
																accepted and the lower range where they should be
																rejected.
															</Typography>
														</Paper>
													) : (
														_.map(
															productRulesOverview.sort((a, b) =>
																a.productTitle > b.productTitle ? 1 : -1
															),
															(rule, i) => (
																<ExpansionPanel
																	key={productRulesOverview[i].productId}
																	expanded={
																		expanded === productRulesOverview[i].productId
																	}
																	onChange={this.handleChangePanel(
																		productRulesOverview[i].productId
																	)}>
																	<ExpansionPanelSummary
																		expandIcon={<ExpandMoreIcon />}>
																		{expanded ===
																		productRulesOverview[i].productId ? (
																			<div>
																				<Typography
																					style={{
																						color: "#929292"
																					}}>
																					Product Rule
																				</Typography>
																				<Typography
																					style={{
																						fontSize: "1.2em",
																						fontWeight: "700"
																					}}>
																					{
																						productRulesOverview[i]
																							.productTitle
																					}
																				</Typography>
																			</div>
																		) : (
																			<Typography className={classes.heading}>
																				{productRulesOverview[i].productTitle}
																			</Typography>
																		)}
																		{expanded ===
																		productRulesOverview[i]
																			.productId ? null : productRulesOverview[i]
																				.acceptAbove ? (
																			<Typography
																				className={classes.secondaryHeading}>
																				Accept above{" "}
																				<span
																					style={{
																						color: "#22c78e",
																						fontWeight: "700"
																					}}>
																					{
																						productRulesOverview[i]
																							.acceptAbove
																					}
																					%
																				</span>
																			</Typography>
																		) : null}
																	</ExpansionPanelSummary>
																	<ExpansionPanelDetails>
																		<RulesPaper
																			data={rule}
																			id={i}
																			onSelectRule={
																				this.gatheringMasterButtonValue
																			}
																			cartRule={false}
																			tab={this.state.type}
																		/>
																	</ExpansionPanelDetails>
																</ExpansionPanel>
															)
														)
													)}
												</Grid>
												<Grid item xs={isMobile ? 12 : 6}>
													<h3
														style={{
															fontFamily: '"Nunito Sans", sans-serif',
															fontWeight: "100",
															textAlign: "center",
															color: "#929292"
														}}>
														CART
													</h3>
													{cartRulesOverview.length === 0 ? (
														<Paper
															className={classes.root}
															style={{
																backgroundColor: "#f6f6f8"
															}}
															elevation={0}>
															<Typography
																style={{
																	fontFamily: '"Nunito Sans", sans-serif',
																	fontWeight: "100",
																	textAlign: "center",
																	color: "#f6f6f8"
																}}
																variant='subtitle1'
																component='h4'>
																-
															</Typography>
															<AddIcon
																style={{
																	fontWeight: "100",
																	display: "block",
																	fontSize: 100,
																	color: "#35B2FF",
																	marginLeft: "auto",
																	marginRight: "auto",
																	cursor: "pointer"
																}}
																onClick={() => this.setState({ type: 2 })}
															/>

															<Typography
																style={{
																	fontFamily: '"Nunito Sans", sans-serif',
																	fontWeight: "100",
																	textAlign: "center",
																	color: "#35B2FF",
																	cursor: "pointer"
																}}
																variant='h6'
																component='h4'
																onClick={() => this.setState({ type: 2 })}>
																Add Cart Price Rule
															</Typography>
															<br />
															<br />
															<Divider fullwidth='true' />
															<br />
															<Typography
																style={{
																	fontFamily: '"Nunito Sans", sans-serif',
																	fontWeight: "800",
																	textAlign: "center",
																	color: "#3e3e3e"
																}}
																variant='subtitle1'
																component='h4'>
																You don’t yet have any cart price rules set
															</Typography>
															<br />
															<Typography
																style={{
																	fontFamily: '"Nunito Sans", sans-serif',
																	fontWeight: "100",
																	textAlign: "center",
																	color: "#3e3e3e"
																}}
																variant='subtitle1'
																component='h4'>
																Cart price rules allow you to automate offer acceptance
																and rejection of customer carts. You set the price range
																of the cart total where the sum of offer products should
																be automatically accepted and the lower range where they
																should be rejected.
															</Typography>
														</Paper>
													) : (
														_.map(
															cartRulesOverview.sort((a, b) =>
																Number(a.lowerAmount) > Number(b.lowerAmount) ? 1 : -1
															),
															(rule, i) => (
																<ExpansionPanel
																	key={cartRulesOverview[i].productId}
																	expanded={
																		expanded === cartRulesOverview[i].productId
																	}
																	onChange={this.handleChangePanel(
																		cartRulesOverview[i].productId
																	)}>
																	<ExpansionPanelSummary
																		expandIcon={<ExpandMoreIcon />}>
																		{expanded === cartRulesOverview[i].productId ? (
																			<div>
																				<Typography
																					style={{
																						color: "#929292"
																					}}>
																					Cart Rule
																				</Typography>
																				<Typography
																					style={{
																						fontSize: "1.2em",
																						fontWeight: "700"
																					}}>
																					${cartRulesOverview[i].lowerAmount}{" "}
																					- $
																					{cartRulesOverview[i].upperAmount}
																				</Typography>
																			</div>
																		) : (
																			<Typography className={classes.heading}>
																				${cartRulesOverview[i].lowerAmount} - $
																				{cartRulesOverview[i].upperAmount}
																			</Typography>
																		)}
																		{expanded ===
																		cartRulesOverview[i]
																			.productId ? null : cartRulesOverview[i]
																				.acceptAbove ? (
																			<Typography
																				className={classes.secondaryHeading}>
																				Accept above{" "}
																				<span
																					style={{
																						color: "#22c78e",
																						fontWeight: "700"
																					}}>
																					{cartRulesOverview[i].acceptAbove}%{" "}
																				</span>
																				<Tooltip
																					title={
																						cartRulesOverview[i]
																							.cartAbandonment
																							? "Cart Abandonment Rule"
																							: "Cart Rule"
																					}>
																					<span
																						style={{
																							float: "right",
																							color: cartRulesOverview[i]
																								.cartAbandonment
																								? "#22c78e"
																								: null
																						}}>
																						{cartRulesOverview[i]
																							.cartAbandonment
																							? "CA"
																							: "C"}
																					</span>
																				</Tooltip>
																			</Typography>
																		) : null}
																	</ExpansionPanelSummary>
																	<ExpansionPanelDetails
																		style={{
																			backgroundColor: "#fff"
																		}}>
																		<RulesPaper
																			data={rule}
																			id={i}
																			onSelectRule={
																				this.gatheringMasterButtonValue
																			}
																			cartRule={true}
																			tab={this.state.type}
																		/>
																	</ExpansionPanelDetails>
																</ExpansionPanel>
															)
														)
													)}
												</Grid>
											</Grid>
										)}
									</div>
								) : (
									<div>
										{this.state.loading ? (
											<Lottie
												height={200}
												width={200}
												options={{
													loop: true,
													autoplay: true,
													animationData: loader.default,
													rendererSettings: {
														preserveAspectRatio: "xMidYMid slice"
													}
												}}
											/>
										) : (
											<Grid container justify='center' spacing={1}>
												<Grid
													item
													xs={12}
													style={{
														display: "block",
														marginBottom: "20px"
													}}>
													<p
														style={{
															color: "#929292",
															textAlign: "center",
															margin: 0
														}}>
														Cart Price Rules apply when the total cart price is within the
														range defined by you.
													</p>
													<p
														style={{
															color: "#929292",
															textAlign: "center",
															margin: "8px"
														}}>
														These will be checked if all products in the customer's cart
														accepted by the Product Price Rules.
													</p>
													<CartRules
														onSelectRule={this.gatheringMasterRuleValue}
														onDeleteRule={this.deleteMasterRuleValue}
													/>
												</Grid>
											</Grid>
										)}
									</div>
								)}
							</div>
						) : null}
					</Grid>
				)}
			</div>
		);
	}
}

Rules.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 700,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(Rules));
// export default Rules
