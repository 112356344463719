import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid, SwipeableDrawer, Button } from "@material-ui/core";
import { Done, Clear } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: 400,
		flexGrow: 1,
		borderRadius: "10px",
		padding: "36px 16px"
	},
	header: {
		display: "flex",
		alignItems: "center",
		borderRadius: "10px",
		padding: "16px"
	},
	list: {
		width: "80vw",
		background: "#f6f6f8"
	},
	fullInfo: {
		background: "#fff",
		padding: "18px 14px 36px 14px"
	},
	buttonAccept: {
		margin: "16px",
		background: "#22c78e22",
		color: "#22c78e",
		fontWeight: 700,
		boxShadow: "none"
	},
	buttonReject: {
		margin: "16px",
		background: "#ff4d4d22",
		color: "#ff4d4d",
		fontWeight: 700,
		boxShadow: "none"
	}
}));

function TextMobileStepper(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);
	let array = [];
	let obj = {};
	Object.keys(props.multPro).forEach(key => {
		obj["productName"] = props.multPro[key].productName;
		obj["variantName"] = props.multPro[key].variantName;
		obj["sku"] = props.multPro[key].variantName;
		obj["quantity"] = props.multPro[key].variantName;
		array.push(obj);
	});
	const [steps, setsteps] = React.useState(array);
	var maxSteps = steps.length;

	useEffect(() => {
		let array = [];
		let obj = {};
		Object.keys(props.multPro).forEach(key => {
			obj = {};
			obj["product"] = props.multPro[key].productName;
			obj["image"] = props.multPro[key].image;
			obj["variant"] = props.multPro[key].variantName ? props.multPro[key].variantName : "-";
			obj["sku"] = props.multPro[key].sku ? props.multPro[key].sku : "-";
			obj["quantity"] = props.multPro[key].quantity;
			obj["discount"] = props.multPro[key].discountCalc;
			obj["price"] = props.multPro[key].listPrice;
			obj["offer"] = props.multPro[key].bidPrice;
			obj["cost"] = props.multPro[key].cost ? props.multPro[key].cost : "-";
			array.push(obj);
		});
		setsteps(array);
	}, [props.multPro]);

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	return (
		<div className={classes.root}>
			<Paper elevation={0} className={classes.header}>
				<Grid container spacing={1}>
					<Grid item xs={5}>
						<img
							alt={steps[activeStep].product}
							src={steps[activeStep].image}
							style={{
								maxWidth: "100%",
								objectFit: "cover",
								backgroundColor: "#fff"
							}}
						/>
					</Grid>
					<Grid item xs={7}>
						<Typography
							style={{
								color: "#3e3e3e"
							}}>
							{steps[activeStep].product}
							<br />
							{props.currencySign}
							{steps[activeStep].price}
						</Typography>
					</Grid>
					{props.cartAbandoned ? (
						<Grid item xs={12}>
							<Typography
								style={{
									color: "#3e3e3e"
								}}>
								This is a cart abandonment offer
							</Typography>
						</Grid>
					) : (
						<Grid item xs={6}>
							<Typography
								style={{
									color: "#929292",
									fontSize: "14px",
									marginBottom: "-4px"
								}}>
								Offer
							</Typography>
							<Typography
								style={{
									color: "#3e3e3e"
								}}>
								{props.currencySign}
								{steps[activeStep].offer}
							</Typography>
						</Grid>
					)}
					{props.cartAbandoned ? null : (
						<Grid item xs={6}>
							<Typography
								style={{
									color: "#929292",
									fontSize: "14px",
									marginBottom: "-4px"
								}}>
								Discount
							</Typography>
							<Typography
								style={{
									color: "#3e3e3e"
								}}>
								{steps[activeStep].discount}%
							</Typography>
						</Grid>
					)}
					<Grid item xs={6}>
						<Typography
							style={{
								color: "#929292",
								fontSize: "14px",
								marginBottom: "-4px"
							}}>
							Variant
						</Typography>
						<Typography
							style={{
								color: "#3e3e3e"
							}}>
							{steps[activeStep].variant}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							style={{
								color: "#929292",
								fontSize: "14px",
								marginBottom: "-4px"
							}}>
							SKU
						</Typography>
						<Typography
							style={{
								color: "#3e3e3e"
							}}>
							{steps[activeStep].sku}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							style={{
								color: "#929292",
								fontSize: "14px",
								marginBottom: "-4px"
							}}>
							Cost Price
						</Typography>
						<Typography
							style={{
								color: "#3e3e3e"
							}}>
							{" "}
							{steps[activeStep].cost === "-"
								? "-"
								: `${props.currencySign}
							${steps[activeStep].cost}`}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
			{props.itemCount > 1 ? (
				<MobileStepper
					steps={maxSteps}
					position='static'
					variant='dots'
					style={{ borderRadius: "10px", background: "transparent" }}
					activeStep={activeStep}
					nextButton={
						<Button size='small' onClick={handleNext} disabled={activeStep === maxSteps - 1}>
							Next
							{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
						</Button>
					}
					backButton={
						<Button size='small' onClick={handleBack} disabled={activeStep === 0}>
							{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
							Back
						</Button>
					}
				/>
			) : null}
		</div>
	);
}

export default function ProductDrawer(props) {
	const classes = useStyles();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [data, setData] = useState({
		currency: props.data.currency,
		currencySign: props.data.currencySign,
		cartAbandoned: props.data.cartAbandoned ? props.data.cartAbandoned : false,
		country: props.data.shippingAddress ? props.data.shippingAddress.country_code : "",
		listPrice: Number(props.data.listPrice).toFixed(2),
		costPrice: Number(props.totalCost).toFixed(2),
		offerPrice: Number(props.data.bidPrice).toFixed(2),
		discount: Number(props.data.discountCalc).toFixed(2),
		email: props.data.email ? props.data.email : props.data.shippingAddress ? props.data.shippingAddress.email : "",
		submissionDate: moment(props.data.submittedDate).format("DD/MM/YY h:mm a"),
		transaction: props.data.payment,
		order: props.data.order,
		itemStatus: props.data.itemStatus,
		itemCount: props.data.itemCount
	});

	const toggleDrawer = open => event => {
		if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setDrawerOpen(open);
	};

	useEffect(() => {
		setDrawerOpen(props.open);
	}, [props.open]);

	useEffect(() => {
		if (!drawerOpen) {
			props.closeDrawer();
		}
	}, [drawerOpen]);

	const handleButtonClick = resp => {
		props.response(props.data, resp);
		setDrawerOpen(false);
	};

	const list = side => (
		<div className={classes.list} role='presentation'>
			{props.data.products ? <TextMobileStepper multPro={props.data.products} currencySign={data.currencySign} itemCount={data.itemCount} cartAbandoned={data.cartAbandoned} /> : null}
			<Grid container spacing={1} className={classes.fullInfo}>
				<Grid item xs={6}>
					<Typography
						style={{
							color: "#929292",
							fontSize: "14px",
							marginBottom: "-4px"
						}}>
						Total List Price
					</Typography>
					<Typography
						style={{
							color: "#3e3e3e"
						}}>
						{data.listPrice === "NaN" ? "—" : `${data.currencySign}${data.listPrice}`}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography
						style={{
							color: "#929292",
							fontSize: "14px",
							marginBottom: "-4px"
						}}>
						Total Cost Price
					</Typography>
					<Typography
						style={{
							color: "#3e3e3e"
						}}>
						{isNaN(data.costPrice) ? "—" : `${data.currencySign}${data.costPrice}`}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography
						style={{
							color: "#929292",
							fontSize: "14px",
							marginBottom: "-4px"
						}}>
						Total Discount
					</Typography>
					<Typography
						style={{
							color: "#3e3e3e"
						}}>
						{data.discount ? `${data.discount}%` : "—"}
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography
						style={{
							color: "#929292",
							fontSize: "14px",
							marginBottom: "-4px"
						}}>
						Currency
					</Typography>
					<Typography
						style={{
							color: "#3e3e3e"
						}}>
						{data.currency ? data.currency : "—"}
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography
						style={{
							color: "#929292",
							fontSize: "14px",
							marginBottom: "-4px"
						}}>
						Country
					</Typography>
					<Typography
						style={{
							color: "#3e3e3e"
						}}>
						{data.country ? data.country : "—"}
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography
						style={{
							color: "#929292",
							fontSize: "14px",
							marginBottom: "-4px"
						}}>
						Name
					</Typography>
					<Typography
						style={{
							color: "#3e3e3e",
							wordWrap: "break-word"
						}}>
						{props.data.firstName ? `${props.data.firstName} ${props.data.lastName}` : "—"}
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography
						style={{
							color: "#929292",
							fontSize: "14px",
							marginBottom: "-4px"
						}}>
						Email
					</Typography>
					<Typography
						style={{
							color: "#3e3e3e",
							wordWrap: "break-word"
						}}>
						{data.email ? data.email : "—"}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						style={{
							color: "#929292",
							fontSize: "14px",
							marginBottom: "-4px"
						}}>
						Submission Date
					</Typography>
					<Typography
						gutterBottom
						style={{
							color: "#3e3e3e"
						}}>
						{data.submissionDate ? data.submissionDate : "—"}
					</Typography>
				</Grid>

				{props.tab !== "Pending" ? (
					<Grid item container xs={12}>
						<Grid item xs={3} style={{ color: "#929292", fontSize: "14px" }}>
							Transaction:
						</Grid>
						<Grid item xs={3} style={{ textAlign: "center" }}>
							{data.transaction ? <Done /> : <Clear />}
						</Grid>
						<Grid item xs={3} style={{ color: "#929292", fontSize: "14px" }}>
							Order:
						</Grid>
						<Grid item xs={3} style={{ textAlign: "center" }}>
							{data.order ? <Done /> : <Clear />}
						</Grid>
					</Grid>
				) : null}
				<Grid
					item
					xs={12}
					style={{
						border: "1px solid #929292",
						borderRadius: "10px",
						textAlign: "center",
						padding: "12px",
						margin: "4px"
					}}>
					<Typography
						style={{
							color: "#929292",
							fontSize: "14px",
							marginBottom: "-4px"
						}}>
						Total Offer Price
					</Typography>
					<Typography
						style={{
							color: "#3e3e3e"
						}}>
						{data.offerPrice ? data.offerPrice : "—"}
					</Typography>
				</Grid>

				{props.tab === "Pending" ? (
					<Grid item xs style={{ textAlign: "center" }}>
						<Button variant='contained' className={classes.buttonReject} onClick={() => handleButtonClick("rejected")} margin='normal'>
							Reject
						</Button>
					</Grid>
				) : null}
				{props.tab === "Pending" ? (
					<Grid item xs style={{ textAlign: "center" }}>
						<Button variant='contained' className={classes.buttonAccept} onClick={() => handleButtonClick("approved")} margin='normal'>
							Accept
						</Button>
					</Grid>
				) : null}
			</Grid>
		</div>
	);

	return (
		<SwipeableDrawer anchor='right' open={drawerOpen} onOpen={toggleDrawer(true)} onClose={toggleDrawer(false)}>
			{list("right")}
		</SwipeableDrawer>
	);
}
