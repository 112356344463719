import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";

const styles = theme => ({});

class HelpModal extends Component {
	handleClose = () => {
		this.props.handleClose("apply");
	};

	handleCloseCancel = () => {
		this.props.handleClose("cancel");
	};

	render() {
		return (
			<div>
				{this.props.page === "/rules" ? (
					<Dialog
						open={this.props.open}
						onClose={this.handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{"How To Make Rules"}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-img'>
								1. Find the product you would like to set a rule for <br />
								2. <b> Reject Below</b> will reject all offers below this value <br />
								3. <b> Accept above</b> will accept all offers above this value <br />
								4. <b> End date </b> is when the rule will stop running <br />
								5. <b> Click Submit </b>to save a rule <br />
								<br />
								Set a single <b> Master Rule </b> and it will be applied to all the products in a
								collection <br />
								<br />
								<b> Note </b> <br />
								Click Remove to edit or delete the content of a given rule. Set Accept Above value to
								RRP if you do not want to automatically accept any offers for a given product.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} autoFocus>
								Got It
							</Button>
						</DialogActions>
					</Dialog>
				) : this.props.page === "/campaigns/new" || this.props.page === "/campaigns/view" ? (
					<Dialog
						open={this.props.open}
						onClose={this.handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{"How To Create A New Campaign"}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-img'>
								1. Label the campaign so that you can identify it <br />
								2. Choose your product. In order to choose a product, it must sit in a collection on
								your shopify store <br />
								3. Select the time and length of your campaign. The ‘current time’ button will ensure
								that the campaign start time is now <br />
								4. Go over the details of the campaign and confirm. <br />
								5. Use the link provided to distribute to all your customers through any medium. <br />
								<br />
								Access your dashboard to view all the offers that come in. <br />
								If you want to automate acceptance/rejection, go to ‘rules’ in the main menu.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} autoFocus>
								Got It
							</Button>
						</DialogActions>
					</Dialog>
				) : this.props.isSubmitMasterRule ? (
					<Dialog
						open={this.props.open}
						onClose={this.handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{"Submitting Master Rule"}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-img'>
								Submitting a master rule will apply this rule to every product in the current
								collection. <br />
								The master rule will override all current rules for the products in this collection.
								<br /> Click <b>Submit</b> to continue.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleCloseCancel} autoFocus>
								Cancel
							</Button>
							<Button onClick={this.handleClose} autoFocus>
								Submit
							</Button>
						</DialogActions>
					</Dialog>
				) : this.props.isDeleteMasterRule ? (
					<Dialog
						open={this.props.open}
						onClose={this.handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{"Delete Master Rule"}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-img'>
								Are you sure you want to delete all rules for products in this collection?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleCloseCancel} autoFocus>
								Cancel
							</Button>
							<Button onClick={this.handleClose} autoFocus>
								Delete
							</Button>
						</DialogActions>
					</Dialog>
				) : this.props.isDeleteCartRules ? (
					<Dialog
						open={this.props.open}
						onClose={this.handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{"Delete Cart Rule"}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-img'>
								Are you sure you want to delete this rule?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleCloseCancel} autoFocus>
								Cancel
							</Button>
							<Button onClick={this.handleClose} autoFocus>
								Delete
							</Button>
						</DialogActions>
					</Dialog>
				) : this.props.isAreYouSure ? (
					<Dialog
						open={this.props.open}
						onClose={this.handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{"Delete Master Rule"}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-img'>
								Are you sure you want to Accept/Reject this offer
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleCloseCancel} autoFocus>
								Cancel
							</Button>
							<Button onClick={this.handleClose} autoFocus>
								Delete
							</Button>
						</DialogActions>
					</Dialog>
				) : (
					<Dialog
						open={this.props.open}
						onClose={this.handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{"How To Accept And Reject Bids"}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-img'>
								Click the <Done /> icon to accept a bid.
								<br />
								Click the <Close /> icon to reject a bid.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} autoFocus>
								Got It
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(HelpModal);
