import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import { authStorage } from "../firebase";
import { auth } from "../firebase/firebase";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import ImageIcon from "@material-ui/icons/Image";
import Done from "@material-ui/icons/CheckCircle";
import Fade from "react-reveal/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const styles = (theme) => ({
	container: {
		display: "flex",
		flexWrap: "wrap",
		textAlign: "center",
	},
	textField: {
		display: "inline-block",
		width: "100%",

		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInputLabel-outlined ": {
			fontSize: "0.9em",
		},
		"& .MuiOutlinedInput-root": {
			fontSize: "0.9em",
			borderRadius: "0.6em",
			"& fieldset": {
				borderColor: theme.palette.secondary.dark,
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
		"& .MuiOutlinedInput-input": { padding: "1.2em" },
	},
	button: {
		border: "1px solid #e3e3e3",
		borderRadius: "0.6em",
		backgroundColor: "transparent",
		color: "#929292cc",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: 155,
		boxShadow: "none",
		"&:hover": {
			boxShadow: "none",
			borderColor: theme.palette.primary.main + "!important",
		},
	},
	input: {
		display: "none",
	},
	card: {
		minWidth: 275,
		border: "1px solid #f6f6f8",
		borderRadius: "4px",
		padding: "10px",
		marginTop: "20px",
	},

	dense: {
		marginTop: 16,
	},
	menu: {
		width: 200,
	},
	paper: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "350px",
	},
});

class storeInfoForm extends React.Component {
	state = {
		storeName: "",
		brn: "",
		shopifyUrl: "",
		storeURL: "",
		imageUrl: "",
		imageLoading: false,
		clickedArr: [false, false, false, false],
	};

	componentDidMount() {
		let c = [false, false, false, false];
		if (this.props.info.storeName) c[0] = true;
		if (this.props.info.storeURL) c[1] = true;
		if (this.props.info.abn) c[2] = true;
		if (this.props.info.shopifyUrl) c[3] = true;

		this.setState({
			storeName: this.props.info.storeName,
			brn: this.props.info.abn,
			storeURL: this.props.info.storeURL,
			shopifyUrl: this.props.info.shopifyUrl,
			imageUrl: this.props.info.imageUrl,
			clickedArr: c,
		});
	}

	handleChange = (field) => (event) => {
		this.props.handleChange(field, event.target.value);
		this.setState({ [field]: event.target.value });
	};

	handleCapture = (e) => {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			this.setState(() => ({ image }));
			var fileType = image.type.slice(image.type.indexOf("/") + 1, image.type.length);
			const uploadTask = authStorage.ref(`storeLogos/${auth.currentUser.uid}.${fileType}`).put(image);
			uploadTask.on(
				"state_changed",
				(snapshot) => {
					this.setState({ imageLoading: true });
				},
				(error) => {
					// error function ....
					this.setState({ imageLoading: false });
					console.log(error);
				},
				() => {
					// complete function ....
					authStorage
						.ref(`storeLogos/${auth.currentUser.uid}.${fileType}`)
						.getDownloadURL()
						.then((imageUrl) => {
							this.setState({
								imageUrl: imageUrl,
								imageLoading: false,
							});
							this.props.handleChange("imageUrl", this.state.imageUrl);
						});
				}
			);
		}
	};

	handleClickAway = (i) => (e) => {
		let c = this.state.clickedArr;
		c[Number(i)] = true;
		var url = this.state.storeURL;
		var sUrl = this.state.shopifyUrl;
		const regex1 = RegExp(`([\\w\-])+\\.{1}([a-zA-Z]{2,63})([\\/\\w-]*)*\\/?\\??([^#\n\r]*)?#?([^\n\r]*)`);
		const regex2 = RegExp("[^.s]+.myshopify.com");
		c[1] = regex1.test(url) ? true : false;
		c[3] = regex2.test(sUrl) ? true : false;
		this.setState({ clickedArr: c });
	};
	handleKeyDown = (i) => (e) => {
		if (e.key === "Tab") {
			let c = this.state.clickedArr;
			c[Number(i)] = true;
			var url = this.state.storeURL;
			var sUrl = this.state.shopifyUrl;
			const regex1 = RegExp(`([\\w\-])+\\.{1}([a-zA-Z]{2,63})([\\/\\w-]*)*\\/?\\??([^#\n\r]*)?#?([^\n\r]*)`);
			const regex2 = RegExp("[^.s]+.myshopify.com");
			c[1] = regex1.test(url) ? true : false;
			c[3] = regex2.test(sUrl) ? true : false;
			this.setState({ clickedArr: c });
		}
	};

	render() {
		const { classes, error, clicked } = this.props;
		const { clickedArr } = this.state;
		return (
			<div className={classes.paper}>
				<input
					accept="image/*"
					className={classes.input}
					id="contained-button-file"
					onChange={this.handleCapture}
					type="file"
				/>
				<label htmlFor="contained-button-file">
					<div
						onChange={this.handleCapture}
						style={{
							border:
								this.state.imageUrl !== undefined ? null : error === 5 ? "1px solid #ff000088" : null,
							boxShadow: this.state.imageUrl !== undefined ? null : error === 5 ? "0 0 2px #f00" : null,
						}}
						className={classes.button}
					>
						<div
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								textAlign: "center",
							}}
						>
							{this.state.imageUrl !== undefined && this.state.imageLoading === false ? (
								<Fade>
									<img
										src={this.state.imageUrl}
										alt="Logo Url"
										style={{
											display: "inline-block",
											maxHeight: "150px",
											maxWidth: "300px",
											marginLeft: "auto",
											marginRight: "auto",
										}}
									/>
								</Fade>
							) : this.state.imageLoading === true ? (
								<div
									style={{
										left: "50%",
										top: "50%",
										marginLeft: "auto",
										marginRight: "auto",
										display: "inline-block",
									}}
								>
									<Lottie
										height={150}
										width={150}
										options={{
											loop: true,
											autoplay: true,
											animationData: loader.default,
											rendererSettings: {
												preserveAspectRatio: "xMidYMid slice",
											},
										}}
									/>
								</div>
							) : (
								<span style={{ textAlign: "center" }}>
									<ImageIcon style={{ fontSize: "2.4em" }} />
									<p style={{ margin: 6, fontSize: "0.9em" }}>Upload Logo</p>
								</span>
							)}
						</div>
					</div>
				</label>

				<form className={classes.container} noValidate autoComplete="off">
					<ClickAwayListener onClickAway={this.handleClickAway("0")}>
						<TextField
							id="outlined-name"
							label="Store Name"
							fullWidth
							autoFocus
							onKeyDown={this.handleKeyDown("0")}
							error={error === 1}
							variant="outlined"
							value={this.state.storeName}
							onChange={this.handleChange("storeName")}
							margin="normal"
							className={classes.textField}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{this.state.storeName && clickedArr[0] && error === 0 ? (
											<Done style={{ color: "#22c78e" }} />
										) : (
											""
										)}
									</InputAdornment>
								),
							}}
							InputLabelProps={{
								style: {
									color: "#929292cc",
									fontSize: "0.9em",
								},
							}}
						/>
					</ClickAwayListener>
					<ClickAwayListener onClickAway={this.handleClickAway("1")}>
						<TextField
							label="Store URL"
							value={this.state.storeURL}
							fullWidth
							onKeyDown={this.handleKeyDown("1")}
							error={error === 2}
							variant="outlined"
							onChange={this.handleChange("storeURL")}
							margin="normal"
							className={classes.textField}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{this.state.storeURL && clickedArr[1] && error === 0 ? (
											<Done style={{ color: "#22c78e" }} />
										) : (
											""
										)}
									</InputAdornment>
								),
							}}
							InputLabelProps={{
								style: {
									color: "#929292cc",
									fontSize: "0.9em",
								},
							}}
						/>
					</ClickAwayListener>
					<ClickAwayListener onClickAway={this.handleClickAway("2")}>
						<Tooltip
							title="A unique identifier and may be either a state tax ID number or a registered identification number. Australian Equivalent: ABN"
							placement="top"
						>
							<TextField
								id="outlined-number"
								label="Business Registration Number"
								fullWidth
								onKeyDown={this.handleKeyDown("2")}
								error={error === 3}
								variant="outlined"
								value={this.state.brn}
								onChange={this.handleChange("brn")}
								type="number"
								margin="normal"
								className={classes.textField}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{this.state.brn && clickedArr[2] && error === 0 ? (
												<Done style={{ color: "#22c78e" }} />
											) : (
												""
											)}
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										color: "#929292cc",
										fontSize: "0.9em",
									},
								}}
							/>
						</Tooltip>
					</ClickAwayListener>
					<ClickAwayListener onClickAway={this.handleClickAway("3")}>
						<TextField
							value={this.state.shopifyUrl}
							onChange={this.handleChange("shopifyUrl")}
							label="MyShopify URL"
							disabled={this.props.info.shopifySuccess}
							margin="normal"
							className={classes.textField}
							fullWidth
							onKeyDown={this.handleKeyDown("3")}
							error={error === 4}
							variant="outlined"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{this.state.shopifyUrl && clickedArr[3] && error === 0 ? (
											<Done style={{ color: "#22c78e" }} />
										) : (
											""
										)}
									</InputAdornment>
								),
							}}
							InputLabelProps={{
								style: {
									color: "#929292cc",
									fontSize: "0.9em",
								},
							}}
						/>
					</ClickAwayListener>
					<p
						style={{
							width: "100%",
							margin: 0,
							color: "#929292cc",
							fontSize: "0.9em",
						}}
					>
						For example, nice-shop.myshopify.com
					</p>
				</form>
				<br />
				<br />
			</div>
		);
	}
}

storeInfoForm.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(storeInfoForm);
