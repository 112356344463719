import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import NestedList from "./nestedList";

const styles = {
	list: {
		width: 250
	},
	fullList: {
		width: "auto"
	}
};

class SideDrawer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			drawerStatus: false
		};
	}

	toggleDrawer = open => () => {
		console.log(this.props.drawerStatus);
	};

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<Drawer open={this.props.drawerStatus} onClose={this.props.toggleDrawer}>
					<NestedList />
				</Drawer>
			</div>
		);
	}
}

SideDrawer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SideDrawer);
