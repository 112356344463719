import React, { Component } from "react";
import { ProductTemplate, MasterTemplate, EmailTemplate } from "../components";
import _ from "lodash";
import { db, fb } from "../firebase";
import { Paper, TextField, Button, Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import withSizes from "react-sizes";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		height: 30,
		width: 80
	},
	control: {
		padding: theme.spacing(2)
	},
	margin: {
		margin: theme.spacing(2)
	},
	button: {},
	fabStyle: {
		margin: theme.spacing(2)
	},
	heading: {
		textAlign: "center",
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular
	},
	media: {
		marginTop: "1.5em"
	},
	checked: true,
	dialogPaper: {
		minHeight: "50vh",
		maxHeight: "50vh"
	}
});

class AbandonmentTemplate extends Component {
	state = {
		productsAndRules: [],
		queryString: "",
		loading: true,
		productLoading: false,
		collections: [],
		isExpanded: false,
		selectedCollection: "Select a Collection",
		selectedCollectionTitle: "",
		selectedCollectionID: "",
		isCollectionSelected: false,
		pages: "",
		storeName: "",
		name: "Select Product",
		productSelectedImage: "",
		display: true,
		activeCampaigns: [],
		futureCampaigns: [],
		masterActive: false,
		removalList: [],
		saved: false,
		removeCol: false
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				db.getStoreCampaigns(snapshot, "active").then(storeActiveCampaigns => {
					this.setState({ activeCampaigns: storeActiveCampaigns.val() }, () => {});
				});
				db.getStoreCampaigns(snapshot, "future").then(storeFutureCampaigns => {
					this.setState({
						futureCampaigns: storeFutureCampaigns.val()
					});
				});
				let tempList = [];
				db.getCartAbandonmentProducts(snapshot).then(result => {
					_.map(result, (value, key) => {
						tempList.push(Number(key));
					});
				});
				this.setState({ storeName: snapshot, removalList: tempList });
				return fb.getCollections(snapshot);
			})
			.then(collectionsList => {
				if (!collectionsList) {
					collectionsList = [];
					// db.getProducts()
				}
				this.setState({ loading: false, collections: collectionsList });
			})
			.catch(e => console.log("error: ", e));
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
		if (name === "selectedCollection") {
			this.selectCollection(this.state.collections[event.target.value]);
		}
	};

	filterResults = () => {
		const { queryString, productsAndRules } = this.state;
		return queryString === ""
			? productsAndRules
			: _.filter(productsAndRules, x =>
					x.productTitle.toLowerCase().includes(this.state.queryString.toLowerCase())
			  );
	};

	selectCollection(collection) {
		this.setState({
			productLoading: true,
			isExpanded: false,
			selectedCollectionTitle: collection.title,
			isCollectionSelected: true,
			selectedCollectionID: collection.id,
			display: false
		});
		this.getProductsBack(collection.id);
	}

	getProductsBack(collectionID) {
		let getCollectionsId;
		if (collectionID) {
			getCollectionsId = collectionID;
		} else {
			getCollectionsId = this.state.selectedCollectionID;
		}
		let productsAndRulesTest = [];
		this.setState({ productLoading: true });
		// const activeCampaigns = this.state.activeCampaigns
		db.getProductsInCollectionButton(this.state.storeName, getCollectionsId)
			.then(results => {
				for (let item of results) {
					productsAndRulesTest.push(item);
				}
				var t = productsAndRulesTest.every(curr => {
					return !this.state.removalList.includes(curr.productId);
				});
				this.setState({
					productsAndRules: productsAndRulesTest,
					productLoading: false,
					masterActive: t
				});
				return;
			})
			.catch(console.error);
	}

	gatheringMasterTemplateValue = () => {
		this.getProductsBack();
	};

	handleChangeCollection = selectedOption => {
		this.setState({
			selectedCollection: selectedOption.value.title,
			productLoading: true,
			isExpanded: false,
			selectedCollectionTitle: selectedOption.value.title,
			isCollectionSelected: true,
			selectedCollectionID: selectedOption.value.id,
			display: false
		});
		this.setState({
			productName: "Select...",
			productId: "",
			productSelected: "Select...",
			productSelectedImage: ""
		});
		this.getProductsBack(selectedOption.value.id);
	};

	makeCollectionOptions = () => {
		const { collections } = this.state;
		let collectionOptions = [];
		collections.forEach((value, index) => {
			let product = {};
			product["label"] = value.title;
			product["value"] = value;
			collectionOptions.push(product);
		});
		return collectionOptions;
	};

	handleAddRemoveProduct = pid => {
		var tempList = this.state.removalList;
		if (!tempList.includes(pid)) {
			tempList.push(Number(pid));
		} else {
			tempList = _.remove(tempList, function(n) {
				return n !== pid;
			});
		}
		this.setState({
			removalList: tempList
		});
		let productsAndRulesTest = [];
		db.getProductsInCollectionButton(this.state.storeName, this.state.selectedCollectionID)
			.then(results => {
				for (let item of results) {
					productsAndRulesTest.push(item);
				}
				var t = productsAndRulesTest.every(curr => {
					return !this.state.removalList.includes(curr.productId);
				});
				this.setState({
					masterActive: t
				});
				return;
			})
			.catch(console.error);
	};
	handleAddRemoveCollection = (cid, active) => {
		var tempList = this.state.removalList;
		this.setState({ removeCol: true });
		db.getProductsInCollectionButton(this.state.storeName, cid)
			.then(results => {
				for (let item of results) {
					if (!tempList.includes(item.productId) && active) {
						tempList.push(Number(item.productId));
					} else {
						tempList = _.remove(tempList, function(n) {
							return n !== Number(item.productId);
						});
					}
				}
				this.setState({
					removalList: tempList,
					removeCol: false
				});
				return;
			})
			.catch(console.error);
	};
	handleSave = () => {
		this.setState({ saved: true });
		db.updateCartAbandonmentProducts(this.state.removalList, this.state.storeName);
	};

	render() {
		const { classes, isMobile } = this.props;
		const { selectedCollectionTitle, isCollectionSelected, selectedCollectionID, removalList } = this.state;
		const products = this.filterResults();

		return (
			<div>
				<Grid
					container
					direction="column"
					spacing={2}
					style={{
						width: isMobile ? "auto" : "50%",
						margin: "0 auto"
					}}
				>
					{!this.state.loading ? (
						<div>
							<h1 style={{ textAlign: "center" }}>
								<span
									style={{
										color: "#35B2FF",
										fontWeight: 900
									}}
								>
									SELECT
								</span>
								<span style={{ fontWeight: 900 }}> PRODUCT(S)</span>
							</h1>
							<h3
								style={{
									fontFamily: '"Nunito Sans", sans-serif',
									fontWeight: "100",
									textAlign: "center",
									marginTop: "-1%",
									color: "rgba(0, 0, 0, 0.38)"
								}}
							>
								Select a collection to deselect products and exclude from EnterOffer Cart Abandonment
								Email Template
							</h3>
							<h4
								style={{
									fontFamily: '"Nunito Sans", sans-serif',
									fontWeight: "100",
									textAlign: "center",
									marginTop: "-1%",
									color: "rgba(0, 0, 0, 0.38)"
								}}
							>
								NOTE: All products are offerable (selected) by default.
							</h4>
						</div>
					) : null}
					{!this.state.loading ? (
						<div style={{ textAlign: "center" }}>
							<Select
								value={{
									value: this.state.selectedCollection,
									label: this.state.selectedCollection
								}}
								onChange={this.handleChangeCollection}
								options={this.makeCollectionOptions()}
								style={{
									fontSize: "1.5em",
									backgroundColor: "#f6f6f8",
									borderRadius: "0.2em"
								}}
								styles={{
									...styles,
									control: (base, state) => ({
										...base,
										backgroundColor: "#f6f6f8",
										border: "none", // default border color
										boxShadow: "none",
										borderRadius: "0.2em",
										padding: "0.3em",
										width: "100%"
									})
								}}
							></Select>
						</div>
					) : null}
					<div className={classes.root} style={{ textAlign: "center" }}>
						{isCollectionSelected ? (
							<TextField
								id="standard-full-width"
								value={this.state.queryString}
								placeholder="Search for a Product"
								onChange={this.handleChange("queryString")}
								fullWidth
								margin="normal"
								style={{
									display: "inline-block",
									textAlign: "center",
									width: "100%",
									fontSize: "1em",
									backgroundColor: "#f6f6f8",
									borderRadius: "0.2em"
								}}
								required={true}
								InputProps={{
									disableUnderline: true,
									style: {
										fontSize: "1em",
										fontFamily: "Nunito Sans",
										padding: "8px"
									}
								}}
							/>
						) : null}
						<br />
						<br />
						{!this.state.loading ? <Button onClick={this.handleSave}>Make Template</Button> : null}
					</div>
					<div
						style={{
							textAlign: "center"
						}}
					>
						<Dialog
							fullScreen={isMobile}
							open={this.state.saved}
							classes={{
								paper: isMobile ? null : classes.dialogPaper
							}}
							onClose={() => {
								this.setState({ saved: false });
							}}
						>
							<DialogContent
								style={{
									maxHeight: isMobile ? "80vh" : "75vh",
									paddingTop: 0,
									paddingBottom: 0
								}}
							>
								<EmailTemplate removalList={removalList} />
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										this.setState({ saved: false });
									}}
									style={{ margin: "auto" }}
									color="primary"
								>
									Done
								</Button>
							</DialogActions>
						</Dialog>
					</div>

					{this.state.loading ? (
						<div style={{ display: "inline-block", height: "60vh" }}>
							<Lottie
								height={300}
								width={300}
								options={{
									loop: true,
									autoplay: true,
									animationData: loader.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice"
									}
								}}
								style={{
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%) translateX(-50%)",
									left: "50%"
								}}
							/>
						</div>
					) : (
						<Grid container justify="center" spacing={1}>
							{isCollectionSelected ? (
								<Grid
									item
									xs={12}
									style={{
										display: "block",
										marginBottom: "25px"
									}}
								>
									<MasterTemplate
										collectionName={selectedCollectionTitle}
										onSelectRule={this.gatheringMasterTemplateValue}
										onDeleteRule={this.deleteMasterTemplateValue}
										addCollection={this.handleAddRemoveCollection}
										collectionID={selectedCollectionID}
										buttonActive={this.state.masterActive}
										updateProducts={this.updateProducts}
									/>
								</Grid>
							) : null}
						</Grid>
					)}
					{this.state.productLoading ? (
						<div style={{ display: "inline-block", height: "70vh" }}>
							<Lottie
								height={300}
								width={300}
								options={{
									loop: true,
									autoplay: true,
									animationData: loader.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice"
									}
								}}
								style={{
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%) translateX(-50%)",
									left: "50%"
								}}
							/>
						</div>
					) : products.length === 0 && isCollectionSelected === true ? (
						<h1
							style={{
								fontFamily: "sans-serif",
								fontWeight: "100",
								textAlign: "center",
								marginTop: "200px"
							}}
						>
							No Products Found
						</h1>
					) : this.state.removeCol ? (
						<Lottie
							height={300}
							width={300}
							options={{
								loop: true,
								autoplay: true,
								animationData: loader.default,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice"
								}
							}}
						/>
					) : (
						<Grid container spacing={isMobile ? 1 : 2}>
							{_.map(products, (rule, i) => (
								<Grid item xs={isMobile ? 12 : 6} key={i}>
									<Paper
										style={{
											spacing: {
												unit: 1,
												color: "primary"
											},
											textAlign: "center",
											height: "100%"
										}}
									>
										<ProductTemplate
											data={rule}
											addProduct={this.handleAddRemoveProduct}
											removalList={removalList}
											id={products[i].productId}
											onSelectRule={this.gatheringMasterTemplateValue}
											buttonActive={products[i].checked}
										/>
									</Paper>
								</Grid>
							))}
						</Grid>
					)}
				</Grid>
				{/* {_.map(products, (rule, i) => (
          <span style={{ display: "inline" }}>{products[i].productId},</span>
        ))} */}
				<br />
				<br />
				<br />
			</div>
		);
	}
}

AbandonmentTemplate.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(AbandonmentTemplate));
