import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import { SalesBarChart, OffersData } from "../components";
import Skeleton from "react-loading-skeleton";

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		padding: "25px 8px",
		textAlign: "center",
		color: theme.palette.text.secondary,
		boxShadow: "none",
		borderRadius: "10px"
	}
});

SalesGraphs.propTypes = {
	classes: PropTypes.object.isRequired
};

function SalesGraphs(props) {
	const { classes, refresh } = props;

	return (
		<div className={classes.root}>
			{/* {refresh ? (
				<Lottie
					height={200}
					width={200}
					options={{
						loop: true,
						autoplay: true,
						animationData: loader.default,
						rendererSettings: {
							preserveAspectRatio: "xMidYMid slice"
						}
					}}
				/>
			) : ( */}
			<Grid container spacing={2}>
				<Grid item xs={12} sm={4}>
					<Paper className={classes.paper}>
						{refresh ? (
							<div style={{ textAlign: "left" }}>
								<Skeleton height={"25px"} width={200} />
								<Skeleton height={props.isMobile ? "125px" : "225px"} />
							</div>
						) : (
							<SalesBarChart isMobile={props.isMobile} />
						)}
					</Paper>
				</Grid>
				{props.isMobile ? null : (
					<Grid item xs={12} sm={8}>
						<Paper className={classes.paper}>
							{refresh ? (
								<div style={{ textAlign: "left" }}>
									<Skeleton height={"25px"} width={200} />
									<Skeleton height={"225px"} />
								</div>
							) : (
								<OffersData isMobile={props.isMobile} />
							)}
						</Paper>
					</Grid>
				)}
			</Grid>
			{/* )} */}
			<br />
		</div>
	);
}

export default withStyles(styles)(SalesGraphs);
