import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import enterOfferBlue from "../assets/enterOfferBlue.png";
import { Link, Redirect } from "react-router-dom";
import withSizes from "react-sizes";
import Fade from "react-reveal/Fade";
import { HelpModalFooter, ErrorSnackbar } from "../components";
import { authfb, authdb } from "../firebase";
import {
	Button,
	CssBaseline,
	TextField,
	Grid,
	Container,
	LinearProgress,
	Checkbox,
	Typography,
	CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	"@global": {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		cursor: "pointer",
		color: "#f6f6f8",
		fontWeight: 700,
		background: theme.palette.primary.main,
		border: "none",
		letterSpacing: "1px",
		margin: "2em auto 0em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	root: {
		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: theme.palette.secondary.dark,
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
	},
}));

function NewSignup(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [values, setValues] = useState({
		email: "",
		password: "",
		name: "",
		errorType: 0,
		errorOpen: false,
		errorMessage: "",
		signUpSuccess: false,
		flag: false,
		checked: false,
		openTerms: false,
		loading: false,
	});

	const handleChange = (name) => (event) => {
		if (name === "checked") {
			setValues({ ...values, [name]: event.target.checked });
		} else {
			setValues({ ...values, [name]: event.target.value });
		}
	};

	const signup = (event) => {
		event.preventDefault();
		if (values.email === "") {
			setValues({ ...values, errorOpen: true, errorType: 2 });
			return;
		} else if (values.name === "") {
			setValues({ ...values, errorOpen: true, errorType: 2 });
			return;
		} else if (values.password === "") {
			setValues({ ...values, errorOpen: true, errorType: 2 });
			return;
		} else if (!values.checked) {
			setValues({ ...values, errorOpen: true, errorType: 1 });
			return;
		} else {
			setValues({ ...values, loading: true });
		}
		authfb
			.auth()
			.createUserWithEmailAndPassword(values.email, values.password)
			.then(async (user) => {
				console.log(user);
				const firstName = values.name.substr(
					0,
					values.name.indexOf(" ") !== -1 ? values.name.indexOf(" ") : values.name.length
				);
				const lastName =
					values.name.indexOf(" ") !== -1 ? values.name.substr(values.name.indexOf(" ") + 1) : "";
				console.log(firstName, lastName);
				await authdb
					.ref(`/newStores/${user.user.uid}/meta`)
					.set({
						firstName: firstName,
						lastName: lastName,
						email: values.email,
					})
					.then(() => {
						if (user) {
							setValues({ ...values, loading: false, isSignedIn: !!user });
						}
					});
			})
			.catch((e) => {
				if (e.code === "auth/email-already-in-use") {
					setValues({ ...values, errorOpen: true, errorType: 3 });
				} else if (e.code === "auth/invalid-email") {
					setValues({ ...values, errorOpen: true, errorType: 4 });
				} else if (e.code === "auth/operation-not-allowed") {
					setValues({ ...values, errorOpen: true, errorType: 5 });
				} else if (e.code === "auth/weak-password") {
					setValues({ ...values, errorOpen: true, errorType: 6 });
				} else {
					setValues({ ...values, errorOpen: true, errorType: 0 });
				}
				console.error(e);
			});
	};

	const callbackCloseError = () => {
		setValues({ ...values, errorOpen: false });
	};
	const handleClickOpenTerms = () => {
		setValues({ ...values, openTerms: true });
	};

	if (!values.isSignedIn) {
		return (
			<Container component="main" maxWidth="xs">
				<ErrorSnackbar
					errorType={values.errorType}
					stripeError={values.errorMessage}
					open={values.errorOpen}
					parentCallbackCloseError={callbackCloseError}
				/>
				<CssBaseline />
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					spacing={0}
					direction="column"
					style={{ display: "flex" }}
				>
					<Fade>
						<div
							style={{
								width: "25em",
								border: "1px solid #f6f6f8",
								boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
								borderRadius: "5px",
								position: "fixed",
								top: "10%",
								padding: "3.75em 3.125em 4.375em 3.125em",
							}}
						>
							<a href="https://enteroffer.com/">
								<img
									src={enterOfferBlue}
									alt="enteroffer"
									style={{
										width: "150px",
										display: "block",
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: "2.5em",
										marginTop: "-1.25em",
									}}
								/>
							</a>
							<h2
								style={{
									textAlign: "center",
									paddingLeft: "0.625em",
									paddingRight: "0.625em",
									fontSize: "1.5em",
									fontWeight: "800",
									color: "#3e3e3e",
									textTransform: "uppercase",
									letterSpacing: "1",
								}}
							>
								SIGN UP
							</h2>
							<form className={classes.form} noValidate>
								<TextField
									margin="normal"
									variant="outlined"
									className={classes.root}
									fullWidth
									id="email"
									onChange={handleChange("email")}
									label="Email"
									name="email"
									autoComplete="email"
									autoFocus
								/>
								<TextField
									margin="normal"
									className={classes.root}
									fullWidth
									variant="outlined"
									id="name"
									onChange={handleChange("name")}
									label="First & Last Name"
									name="name"
									autoComplete="name"
								/>
								<TextField
									margin="normal"
									variant="outlined"
									className={classes.root}
									fullWidth
									onChange={handleChange("password")}
									name="password"
									label="Password"
									type="password"
									id="password"
								/>
								<HelpModalFooter
									openTerms={values.openTerms}
									handleClose={() => setValues({ ...values, openTerms: false })}
								/>
								<Checkbox
									checked={values.checked}
									onChange={handleChange("checked")}
									value="checked"
									color="primary"
								/>
								<Typography
									style={{
										fontSize: "0.9em",
										fontWeight: "700",
										color: "#7f7f7f",
										margin: "0",
										display: "inline",
									}}
								>
									Agree to the{" "}
									<u onClick={handleClickOpenTerms} style={{ cursor: "pointer" }}>
										terms and conditions
									</u>
								</Typography>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
									onClick={signup}
								>
									{!values.loading ? (
										"Sign Up"
									) : (
										<CircularProgress size={24} style={{ color: theme.palette.secondary.main }} />
									)}
								</Button>
							</form>
							<p
								style={{
									marginTop: "2em",
									textAlign: "center",
									cursor: "pointer",
								}}
							>
								<Link
									variant="body2"
									to={{
										pathname: "/signin",
										search: props.location.search,
									}}
									style={{
										textDecoration: "none",
										color: theme.palette.primary.main,
										fontSize: "1.2em",
									}}
								>
									Already have an account? Sign in!
								</Link>
							</p>
							{values.flag ? <LinearProgress /> : null}
						</div>
					</Fade>
				</Grid>
			</Container>
		);
	} else {
		return (
			<Redirect
				to={{
					pathname: "/",
				}}
			/>
		);
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(NewSignup);
