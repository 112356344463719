import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import { db, authBase } from "../firebase";
import { auth } from "../firebase/firebase";
import Grid from "@material-ui/core/Grid";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import Fade from "react-reveal/Fade";
import withSizes from "react-sizes";
import { StoreInfoForm, ShopifyStripeConnect, SignUpPending } from "./";

const styles = theme => ({
	root: {
		width: "100%"
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	typography: {
		fontFamily: '"Nunito Sans", sans-serif'
	},
	buttons: {
		width: "365px",
		marginTop: "2em"
	},

	buttonFinish: {
		textTransform: "capitalize",
		borderRadius: "0.6em",
		background: theme.palette.primary.main,
		color: "#FFF",
		fontSize: "0.9em",
		padding: "1.1em",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			boxShadow: "none"
		}
	},
	backButton: {
		margin: theme.spacing(1)
	}
});

class SignUpForm extends React.Component {
	state = {
		storeName: "",
		storeURL: "",
		shopifyUrl: "",
		brn: "",
		imageUrl: "",
		errorSnackbarOpen: false,
		errorMessage: "",
		error: 0,
		loading: true,
		clicked: false,
		stripeSuccess: false,
		shopifySuccess: false,
		finished: false
	};
	UNSAFE_componentWillMount() {
		db.getNewStoreInfo("")
			.then(res => {
				this.setState({
					storeName: res.storeName,
					brn: res.abn,
					storeURL: res.url,
					shopifyUrl: res.shopName,
					imageUrl: res.imageUrl,
					finished: res.finished
				});
			})
			.then(() => {
				this.setState({ loading: false });
			})
			.catch(console.error);
	}

	setError = error => {
		this.setState(error);
	};

	finishSignUp = () => {
		this.setState({ clicked: true });
		if (this.state.storeName === "") {
			this.setState({
				errorMessage: "Please input your store name.",
				errorSnackbarOpen: true,
				error: 1
			});
		} else if (this.state.storeURL === "" || this.state.storeURL === undefined) {
			this.setState({
				errorMessage: "Please input your store URL.",
				errorSnackbarOpen: true,
				error: 2
			});
		} else if (this.state.brn === "" || this.state.brn === undefined) {
			this.setState({
				errorMessage: "Please input your Business/Company Registration Number.",
				errorSnackbarOpen: true,
				error: 3
			});
		} else if (this.state.shopifyUrl === "" || this.state.shopifyUrl === undefined) {
			this.setState({
				errorMessage: "Please input your MyShopify URL.",
				errorSnackbarOpen: true,
				error: 4
			});
		} else if (this.state.shopifyUrl.indexOf(".myshopify.com") < 0) {
			this.setState({
				errorMessage: "Please ensure your MyShopify URL is valid.",
				errorSnackbarOpen: true,
				error: 4
			});
		} else if (this.state.imageUrl === "" || this.state.imageUrl === undefined) {
			this.setState({
				errorMessage: "Please upload your store logo.",
				errorSnackbarOpen: true,
				error: 5
			});
		} else if (!this.state.shopifySuccess) {
			this.setState({
				errorMessage: "Please connect your Shopify store.",
				errorSnackbarOpen: true,
				error: 6
			});
		} else if (!this.state.stripeSuccess) {
			this.setState({
				errorMessage: "Please connect your Stripe account.",
				errorSnackbarOpen: true,
				error: 7
			});
		} else {
			db.finishedSignup()
				.then(res => {
					this.setState({ finished: true });
				})
				.catch(e => {
					this.setState({
						errorMessage: "An error has occured. Please Try Resubmitting. If this continues, please contact hello@enteroffer.com",
						errorSnackbarOpen: true,
						error: 8
					});
				});
		}
	};

	handleErrorSnackBarClose = () => {
		this.setState({ errorSnackbarOpen: false });
	};

	handleChange = (field, value) => {
		authBase.syncState(`newStores/${auth.currentUser.uid}/meta/storeName`, {
			context: this,
			state: "storeName",
			defaultValue: ""
		});
		authBase.syncState(`newStores/${auth.currentUser.uid}/meta/url`, {
			context: this,
			state: "storeURL",
			defaultValue: ""
		});
		authBase.syncState(`newStores/${auth.currentUser.uid}/meta/shopName`, {
			context: this,
			state: "shopifyUrl",
			defaultValue: ""
		});
		authBase.syncState(`newStores/${auth.currentUser.uid}/meta/abn`, {
			context: this,
			state: "brn",
			defaultValue: ""
		});
		authBase.syncState(`newStores/${auth.currentUser.uid}/meta/imageUrl`, {
			context: this,
			state: "imageUrl",
			defaultValue: ""
		});
		this.setState({ [field]: value });
	};

	render() {
		const { classes, isMobile } = this.props;
		const { clicked } = this.state;
		if (this.state.loading)
			return (
				<Lottie
					height={200}
					width={200}
					style={{ marginTop: "10%" }}
					options={{
						loop: true,
						autoplay: true,
						animationData: loader.default,
						rendererSettings: {
							preserveAspectRatio: "xMidYMid slice"
						}
					}}
				/>
			);
		return (
			<div className={classes.root}>
				<div style={{ backgroundColor: "transparent", margin: isMobile ? "50px auto" : "20px auto" }}>
					{this.state.finished ? (
						<div>
							<SignUpPending />
						</div>
					) : (
						<Grid container direction='column' justify='flex-start' alignItems='center' spacing={1}>
							<Fade>
								<Grid item xs={12} className={classes.instructions}>
									<StoreInfoForm
										info={{
											storeName: this.state.storeName,
											abn: this.state.brn,
											storeURL: this.state.storeURL,
											shopifyUrl: this.state.shopifyUrl,
											imageUrl: this.state.imageUrl,
											shopifySuccess: this.state.shopifySuccess
										}}
										handleChange={this.handleChange}
										error={this.state.error}
										clicked={clicked}
									/>
									<ShopifyStripeConnect shopifyUrl={this.state.shopifyUrl} handleChange={this.handleChange} error={this.state.error} setError={this.setError} />
									<Button variant='contained' fullWidth onClick={this.finishSignUp} className={classes.buttonFinish}>
										Finish Signup
									</Button>
								</Grid>
							</Fade>
						</Grid>
					)}
				</div>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={this.state.errorSnackbarOpen}
					onClose={this.handleErrorSnackBarClose}
					autoHideDuration={3000}
					ContentProps={{
						"aria-describedby": "message-id"
					}}
					message={<span id='message-id'> {this.state.errorMessage}</span>}
				/>
			</div>
		);
	}
}

SignUpForm.propTypes = {
	classes: PropTypes.object
};
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 900
});

export default withSizes(mapSizesToProps)(withStyles(styles)(SignUpForm));
