import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { db } from "../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import withSizes from "react-sizes";

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		textAlign: "center"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	},
	menu: {
		width: 200
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		color: "#35B2FF",
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	card: {
		display: "flex"
	},
	details: {
		display: "flex",
		flexDirection: "column"
	},
	content: {
		flex: "1 0 auto"
	},
	cover: {
		width: 300,
		objectFit: "cover"
	},
	productName: {
		fontSize: "24px",
		fontWeight: "300",
		width: "100%",
		display: "block",
		background: "#fff",
		textAlign: "left",
		fontFamily: '"Nunito Sans", sans-serif'
	}
});

class ProductTemplate extends React.Component {
	state = {
		storeName: "",
		open: false,
		openSuccess: false,
		openRemove: false,
		openLoading: false,
		openDialog: false,
		openDeleteDialog: false,
		openInvalid: false,
		active: this.props.buttonActive,
		openError: false
	};

	static propTypes = {
		data: PropTypes.shape({
			productTitle: PropTypes.string,
			productPrice: PropTypes.number,
			productId: PropTypes.number,
			unitDollar: PropTypes.number
		}).isRequired
	};

	handleOpen = name => {
		this.setState({ [name]: true });
	};

	handleClose = name => {
		this.setState({ [name]: false });
	};

	sessionStorageUpdated() {
		if (sessionStorage.getItem(this.props.data.productId) === "true") this.setState({ active: true });
		else this.setState({ active: false });
	}

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
			})
			.catch(e => console.log("error", e));
		if (this.props.removalList.includes(this.props.data.productId)) {
			this.setState({ active: false });
		} else this.setState({ active: true });
	}

	handleChange = (event, type) => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		this.setState({ [name]: value });
	};

	addProductTemplate = collection => {
		this.props.addProduct(this.props.id);
		this.setState({
			active: !this.state.active
		});
	};

	render() {
		const { classes, data, isMobile } = this.props;
		const { openSuccess, openError, openRemove } = this.state;
		return (
			<Grid container style={{ width: "100%", height: "100%" }}>
				<Grid item xs={3}>
					<img
						alt={data.productTitle}
						style={{
							objectFit: "cover",
							width: "100%",
							height: "100%"
						}}
						src={data.image}
					/>
				</Grid>
				<Grid item xs={9}>
					<Paper className={classes.root} elevation={0}>
						<label htmlFor='contained-button-file' className={classes.productName}>
							<Grid container spacing={1}>
								<Grid item xs={isMobile ? 7 : 7}>
									<a
										href={data.url}
										target='_blank'
										rel='noopener noreferrer'
										style={{
											textDecoration: "none",
											color: "black"
										}}>
										<p
											style={{
												fontSize: "0.7em",
												margin: "0px"
											}}>
											{data.productTitle}
										</p>
									</a>
								</Grid>
								<Grid item xs={2}>
									<Switch
										checked={this.state.active}
										color='primary'
										onChange={() => this.addProductTemplate(false)}
										// onChange={() =>
										// 	this.props.addProduct(this.props.id)
										// }
									></Switch>
								</Grid>
							</Grid>
						</label>
						<div>
							<Snackbar
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right"
								}}
								open={openError}
								onClose={() => this.handleClose("openError")}
								autoHideDuration={3000}
								message={<span id='message-id'>Something went wrong. Please try again.</span>}
							/>

							<Snackbar
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right"
								}}
								open={openRemove}
								onClose={() => this.handleClose("openRemove")}
								autoHideDuration={3000}
								ContentProps={{
									"aria-describedby": "message-id"
								}}
								message={
									<span id='message-id'>
										You have succesfully removed the EnterOffer button from {data.productTitle}
									</span>
								}
							/>

							<Snackbar
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right"
								}}
								open={openSuccess}
								onClose={() => this.handleClose("openSuccess")}
								autoHideDuration={5000}
								message={
									<span id='message-id'>
										You have succesfully added the EnterOffer button to {data.productTitle}
									</span>
								}
							/>
						</div>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

ProductTemplate.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(ProductTemplate));
