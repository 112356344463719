import React, { Component } from "react";
import { Navigation, SideDrawer } from "../components";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import withSizes from "react-sizes";

class PageContainer extends Component {
	static propTypes = {
		page: PropTypes.func.isRequired
	};
	constructor() {
		super();
		this.state = { isDrawerOpen: false, singup: false };
	}
	toggleDrawer = () => {
		this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
	};

	signup = () => {
		this.setState({ signup: true });
	};

	render() {
		const { page: Page, flag, isMobile } = this.props;

		return (
			<Grid container spacing={2} direction='column'>
				<Grid item xs={12}>
					<Navigation isMobile={isMobile} toggleDrawer={this.toggleDrawer} />
					<SideDrawer drawerStatus={this.state.isDrawerOpen} toggleDrawer={this.toggleDrawer} />
				</Grid>
				<Grid
					item
					xs={12}
					style={{
						backgroundImage: this.state.signup ? null : flag ? "linear-gradient(white, #f6f6f8)" : null
					}}>
					<div
						style={{
							fontFamily: "Nunito Sans",
							display: "flex",
							flexDirection: "column",
							marginTop: isMobile && flag ? null : "2.5em"
						}}>
						<div
							style={{
								flex: 1,
								position: "relative"
							}}>
							<Grid container justify='center'>
								<Grid item xs={flag ? 12 : 11}>
									<Page cb={this.signup} />
								</Grid>
							</Grid>
						</div>
					</div>
				</Grid>
			</Grid>
		);
	}
}
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 700
});

export default withSizes(mapSizesToProps)(PageContainer);
