import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Up from "@material-ui/icons/ArrowUpward";
import Down from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles({
	root: {
		height: "80%"
		// textAlign: "center"
	}
});

export default function RangeSlider(props) {
	const classes = useStyles();
	const [value, setValue] = useState([props.low, props.high]);

	if (value[0] === "" && value[1] === "") {
		setValue([25, 75]);
	}

	useEffect(() => {
		setValue([props.low, props.high]);
	}, [props.low, props.high]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		props.sliderChange(newValue);
	};

	var marks = [
		{
			value: value[0],
			label: props.isMobile ? (
				<span>
					Reject
					<Down style={{ verticalAlign: "bottom" }} />
				</span>
			) : (
				<span>
					Reject Below
					<Down style={{ verticalAlign: "bottom" }} />
				</span>
			)
		},
		{
			value: value[1],
			label: props.isMobile ? (
				<span>
					Accept
					<Up style={{ verticalAlign: "bottom" }} />
				</span>
			) : (
				<span>
					Accept Above
					<Up style={{ verticalAlign: "bottom" }} />
				</span>
			)
		}
	];

	return (
		<div className={classes.root}>
			<Slider
				orientation='vertical'
				track='inverted'
				value={value}
				style={{ color: "#929292" }}
				onChange={handleChange}
				valueLabelDisplay='auto'
				marks={marks}
			/>
		</div>
	);
}
