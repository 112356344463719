import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import RulesIcon from "@material-ui/icons/Build";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import WebsiteIcon from "@material-ui/icons/Explore";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import MakeOfferButtonIcon from "@material-ui/icons/LocalOffer";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import { withRouter } from "react-router-dom";

const styles = theme => ({
	root: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	icon: {
		color: "#35B2FF"
	}
});

class NestedList extends React.Component {
	state = {
		open: false
	};

	handleClick = () => {
		this.setState(state => ({ open: !state.open }));
	};

	render() {
		const { classes } = this.props;

		return (
			<div>
				<Typography variant="subtitle1" color="inherit" style={{ flex: 1 }}>
					<img
						src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
						style={{
							paddingLeft: "8px",
							paddingTop: "16px",
							width: "12em"
						}}
						alt="EnterOffer"
					/>
				</Typography>
				<List component="nav" className={classes.root} style={{ width: "16em" }}>
					<Link to="/" style={{ textDecoration: "none", color: "#35b2ff" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/" ? (
									<DashboardIcon className={classes.icon} />
								) : (
									<DashboardIcon />
								)}
							</ListItemIcon>
							<ListItemText primary="Dashboard" style={{ color: "#000" }} />
						</ListItem>
					</Link>
					<Link to="/button" style={{ textDecoration: "none", color: "#35b2ff" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/button" ? (
									<MakeOfferButtonIcon className={classes.icon} />
								) : (
									<MakeOfferButtonIcon />
								)}
							</ListItemIcon>
							<ListItemText primary="EnterOffer Button" style={{ color: "#000" }} />
						</ListItem>
					</Link>
					<Link to="/template" style={{ textDecoration: "none", color: "#35b2ff" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/template" ? (
									<FormatAlignLeftIcon className={classes.icon} />
								) : (
									<FormatAlignLeftIcon />
								)}
							</ListItemIcon>
							<ListItemText primary="Cart Abandonment Email Template" style={{ color: "#000" }} />
						</ListItem>
					</Link>
					{/* <ListItem button onClick={this.handleClick}>
                        <ListItemIcon>
                            {this.props.location.pathname ===
                                '/campaigns/view' ||
                            this.props.location.pathname ===
                                '/campaigns/new' ? (
                                <CampaignsIcon className={classes.icon} />
                            ) : (
                                <CampaignsIcon />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary='Campaigns'
                            style={{ color: '#000' }}
                        />
                        {this.state.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>*/}
					{/* <Collapse in={this.state.open} timeout='auto' unmountOnExit> 
                        <List component='div' disablePadding>
                            <Link
                                to='/campaigns/new'
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit'
                                }}
                            >
                                <ListItem button className={classes.nested}>
                                    <ListItemIcon>
                                        {this.props.location.pathname ===
                                        '/campaigns/new' ? (
                                            <NewCampaignIcon
                                                className={classes.icon}
                                            />
                                        ) : (
                                            <NewCampaignIcon />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary='New Campaign'
                                        style={{ color: '#000' }}
                                    />
                                </ListItem>
                            </Link>
                            <Link
                                to='/campaigns/view'
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit'
                                }}
                            >
                                <ListItem button className={classes.nested}>
                                    <ListItemIcon>
                                        {this.props.location.pathname ===
                                        '/campaigns/view' ? (
                                            <ViewCampaignsIcon
                                                className={classes.icon}
                                            />
                                        ) : (
                                            <ViewCampaignsIcon />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary='View Campaigns'
                                        style={{ color: '#000' }}
                                    />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                     */}
					<Link to="/rules" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/rules" ? (
									<RulesIcon className={classes.icon} />
								) : (
									<RulesIcon />
								)}
							</ListItemIcon>
							<ListItemText primary="Rules" style={{ color: "#000" }} />
						</ListItem>
					</Link>
				</List>
				<Divider />
				<List>
					<Link to="/settings" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/settings" ? (
									<SettingsIcon className={classes.icon} />
								) : (
									<SettingsIcon />
								)}
							</ListItemIcon>
							<ListItemText primary="Settings" style={{ color: "#000" }} />
						</ListItem>
					</Link>
				</List>
				<Divider />
				<List>
					<a
						href="https://enteroffer.com"
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: "none", color: "inherit" }}
					>
						<ListItem button>
							<ListItemIcon>
								<WebsiteIcon />
							</ListItemIcon>
							<ListItemText primary="Website" style={{ color: "#000" }} />
						</ListItem>
					</a>
				</List>
			</div>
		);
	}
}

NestedList.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(NestedList));
