import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import enterOfferBlue from "../assets/enterOfferBlue.png";
import withSizes from "react-sizes";
import { Link, Redirect } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Container, Grid, TextField, CssBaseline, Button, CircularProgress } from "@material-ui/core";
import { HelpModalFooter, ErrorSnackbar } from "../components";
import { authfb } from "../firebase";

const useStyles = makeStyles((theme) => ({
	"@global": {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		cursor: "pointer",
		color: "#f6f6f8",
		fontWeight: 700,
		background: theme.palette.primary.main,
		border: "none",
		letterSpacing: "1px",
		margin: "2em auto 0em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},

	root: {
		".MuiCircularProgress-colorPrimary": {
			color: "white",
		},
		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: theme.palette.secondary.dark,
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
	},
}));

function SignIn(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [values, setValues] = useState({
		email: "",
		password: "",
		errorType: 14,
		errorOpen: false,
		errorMessage: "",
		isSignedIn: false,
		checked: false,
		openTerms: false,
		loading: false,
	});
	const handleChange = (name) => (event) => {
		setValues({ ...values, [name]: event.target.value });
	};

	const signin = (event) => {
		event.preventDefault();
		setValues({ ...values, loading: true });
		authfb
			.auth()
			.signInWithEmailAndPassword(values.email, values.password)
			.then((user) => {
				setValues({ ...values, loading: false, isSignedIn: !!user });
			})
			.catch((e) => {
				if (e.code === "auth/user-disabled") {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 7 });
				} else if (e.code === "auth/invalid-email") {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 4 });
				} else if (e.code === "auth/user-not-found") {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 8 });
				} else if (e.code === "auth/wrong-password") {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 8 });
				} else {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 0 });
				}
				console.error(e);
			});
	};

	const callbackCloseError = (param) => {
		setValues({ ...values, errorOpen: false });
	};

	if (!values.isSignedIn) {
		return (
			<Container component="main" maxWidth="xs">
				<ErrorSnackbar
					errorType={values.errorType}
					stripeError={values.errorMessage}
					open={values.errorOpen}
					parentCallbackCloseError={callbackCloseError}
				/>
				<CssBaseline />
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					spacing={0}
					direction="column"
					style={{ display: "flex" }}
				>
					<Fade>
						<div
							style={{
								width: "25em",
								border: "1px solid #f6f6f8",
								boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
								borderRadius: "5px",
								position: "fixed",
								top: "10%",
								padding: "3.75em 3.125em 4.375em 3.125em",
							}}
						>
							<a href="https://enteroffer.com/">
								<img
									src={enterOfferBlue}
									alt="enteroffer"
									style={{
										width: "150px",
										display: "block",
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: "2.5em",
										marginTop: "-1.25em",
									}}
								/>
							</a>
							<h2
								style={{
									textAlign: "center",
									paddingLeft: "0.625em",
									paddingRight: "0.625em",
									fontSize: "1.5em",
									fontWeight: "800",
									color: "#3e3e3e",
									textTransform: "uppercase",
									letterSpacing: "1",
								}}
							>
								SIGN IN
							</h2>
							<form className={classes.form} noValidate>
								<TextField
									margin="normal"
									className={classes.root}
									fullWidth
									variant="outlined"
									id="email"
									onChange={handleChange("email")}
									label="Email"
									name="email"
									autoComplete="email"
									autoFocus
								/>
								<TextField
									margin="normal"
									variant="outlined"
									className={classes.root}
									fullWidth
									onChange={handleChange("password")}
									name="password"
									label="Password"
									type="password"
									id="password"
								/>
								<HelpModalFooter
									openTerms={values.openTerms}
									handleClose={() => setValues({ ...values, openTerms: false })}
								/>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
									onClick={signin}
								>
									{!values.loading ? (
										"Sign In"
									) : (
										<CircularProgress size={24} style={{ color: theme.palette.secondary.main }} />
									)}
								</Button>
							</form>
							<p
								style={{
									marginTop: "2em",
									textAlign: "center",
									cursor: "pointer",
								}}
							>
								<Link
									variant="body2"
									to={{
										pathname: "/signup",
										search: props.location.search,
									}}
									style={{
										textDecoration: "none",
										color: theme.palette.primary.main,
										fontSize: "1.2em",
									}}
								>
									Don't have an account? Sign up!
								</Link>
							</p>
						</div>
					</Fade>
				</Grid>
			</Container>
		);
	} else {
		return (
			<Redirect
				to={{
					pathname: "/",
					search: window.location.search,
				}}
			/>
		);
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(SignIn);
