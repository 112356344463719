import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { db } from "../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Moment from "moment";

class BarChart extends Component {
	state = {
		approved: [],
		rejected: [],
		pending: [],
		expired: [],
		storeName: "",
		value: "",
		open: false
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: true,
		legendPosition: "top"
	};

	componentDidMount() {
		this.updateState(false);
	}
	showAlert() {
		alert("Hello World");
	}

	updateState(notFromMount) {
		if (notFromMount) {
			for (var i = 0; i < 4; i++) {
				this.setState({
					approved: {
						[Moment()
							.subtract(i, "months")
							.month()]: 0
					}
				});
				this.setState({
					rejected: {
						[Moment()
							.subtract(i, "months")
							.month()]: 0
					}
				});
				this.setState({
					expired: {
						[Moment()
							.subtract(i, "months")
							.month()]: 0
					}
				});
			}
		}
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				return db.getStoreBids(snapshot, "approved");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "approved");
				return db.getStoreBids(this.state.storeName, "expired");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "expired");
				return db.getStoreBids(this.state.storeName, "rejected");
			})
			.then(snapshot => {
				return this.getOfferData(snapshot, "rejected");
			})
			.catch(console.error);

		if (notFromMount) {
			this.setState({ open: true });
		}
	}

	getOfferData = (snapshot, location) => {
		snapshot.forEach(offer => {
			if (Moment().diff(offer.val().submittedDate, "months") < 4 && offer.val().bidPrice) {
				var month = Moment(offer.val().submittedDate).month();
				var temp = Object.assign({}, this.state[location]);
				temp[month] = temp[month] + 1 || 1;
				this.setState({ [location]: temp });
			}
		});
	};

	handleCloseSnackSuccess = () => {
		this.setState({ open: false });
	};

	render() {
		const chartData = {
			labels: [
				Moment()
					.subtract(3, "months")
					.format("MMM"),
				Moment()
					.subtract(2, "months")
					.format("MMM"),
				Moment()
					.subtract(1, "months")
					.format("MMM"),
				Moment().format("MMM")
			],
			datasets: [
				{
					label: "APPROVED",
					data: [
						this.state.approved[
							Moment()
								.subtract(3, "months")
								.month()
						],
						this.state.approved[
							Moment()
								.subtract(2, "months")
								.month()
						],
						this.state.approved[
							Moment()
								.subtract(1, "months")
								.month()
						],
						this.state.approved[Moment().month()]
					],
					borderWidth: 1,
					borderColor: "rgba(42, 228, 155, 1)",
					backgroundColor: "rgba(42, 228, 155, .13)",
					fill: false
				},
				{
					label: "REJECTED",
					data: [
						this.state.rejected[
							Moment()
								.subtract(3, "months")
								.month()
						],
						this.state.rejected[
							Moment()
								.subtract(2, "months")
								.month()
						],
						this.state.rejected[
							Moment()
								.subtract(1, "months")
								.month()
						],
						this.state.rejected[Moment().month()]
					],
					borderWidth: 1,
					borderColor: "rgba(255, 77, 77, 1)",
					backgroundColor: "rgba(255, 77, 77, .14)",
					fill: false
				},
				{
					label: "EXPIRED",
					data: [
						this.state.expired[
							Moment()
								.subtract(3, "months")
								.month()
						],
						this.state.expired[
							Moment()
								.subtract(2, "months")
								.month()
						],
						this.state.expired[
							Moment()
								.subtract(1, "months")
								.month()
						],
						this.state.expired[Moment().month()]
					],
					borderWidth: 1,
					borderColor: "rgba(255, 227, 38, 1)",
					backgroundColor: "rgba(255, 227, 38, .14)",
					fill: false
				}
			]
		};

		return (
			<div
				style={{
					width: this.props.isMobile ? null : "48%",
					verticalAlign: "middle",
					display: "grid"
				}}>
				<div style={{ display: "inline", textAlign: "left" }}>
					<Typography
						style={{
							color: "transparent",
							fontWeight: 700,
							fontSize: "1em"
						}}>
						---
					</Typography>
				</div>
				<Bar
					data={chartData}
					options={{
						legend: {
							display: this.props.displayLegend,
							position: this.props.legendPosition
						},
						scales: {
							yAxes: [
								{
									ticks: {
										min: 0,
										callback: function(value) {
											if (value % 1 === 0) {
												return value;
											}
										}
									}
								}
							]
						}
					}}
				/>

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={this.state.open}
					onClose={this.handleCloseSnackSuccess}
					autoHideDuration={3000}
					ContentProps={{
						"aria-describedby": "message-id"
					}}
					message={<span id='message-id'>Graph Updated</span>}
				/>
			</div>
		);
	}
}

export default BarChart;
