import React from "react";
import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Tooltip,
	Button,
	Dialog,
	TextField,
	DialogActions,
	DialogContent,
	DialogTitle,
	Snackbar,
	MenuItem
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { auth } from "../firebase";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { HelpModal } from "./index";
import { SettingsModal } from "./index";
import Settings from "@material-ui/icons/Settings";
import Help from "@material-ui/icons/Help";
import SignOut from "@material-ui/icons/PowerSettingsNew";
import { db } from "../firebase";
import MenuIcon from "@material-ui/icons/Menu";
import MediaQuery from "react-responsive";
import withSizes from "react-sizes";

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	AppBar: {
		position: "fixed",
		boxShadow: "none",
		background: "#fff",
		color: "#3e3e3e",
		padding: "0.5em"
	},
	button: {
		color: "#35B2FF",
		margin: "auto"
	},
	link: {
		textDecoration: "none"
	},
	menuButton: {
		marginLeft: -10,
		marginRight: 5,
		color: "#3e3e3e"
	},
	tooltip: {
		fontSize: "3.125em"
	},
	menu: {
		width: 200
	}
});

class Navigation extends React.Component {
	state = {
		open: false,
		toggleDrawer: false,
		currencyList: [],
		openCurDialog: false,
		showSnackFalseValues: false,
		showSnackSuccess: false,
		currencyAlreadyAdded: false,
		disableCurButton: false,
		currencyAddList: [
			"USD",
			"CAD",
			"EUR",
			"AED",
			"AFN",
			"ALL",
			"AMD",
			"ARS",
			"AUD",
			"AZN",
			"BAM",
			"BDT",
			"BGN",
			"BHD",
			"BIF",
			"BND",
			"BOB",
			"BRL",
			"BWP",
			"BYR",
			"BZD",
			"CDF",
			"CHF",
			"CLP",
			"CNY",
			"COP",
			"CRC",
			"CVE",
			"CZK",
			"DJF",
			"DKK",
			"DOP",
			"DZD",
			"EEK",
			"EGP",
			"ERN",
			"ETB",
			"GBP",
			"GEL",
			"GHS",
			"GNF",
			"GTQ",
			"HKD",
			"HNL",
			"HRK",
			"HUF",
			"IDR",
			"ILS",
			"INR",
			"IQD",
			"IRR",
			"ISK",
			"JMD",
			"JOD",
			"JPY",
			"KES",
			"KHR",
			"KMF",
			"KRW",
			"KWD",
			"KZT",
			"LBP",
			"LKR",
			"LTL",
			"LVL",
			"LYD",
			"MAD",
			"MDL",
			"MGA",
			"MKD",
			"MMK",
			"MOP",
			"MUR",
			"MXN",
			"MYR",
			"MZN",
			"NAD",
			"NGN",
			"NIO",
			"NOK",
			"NPR",
			"NZD",
			"OMR",
			"PAB",
			"PEN",
			"PHP",
			"PKR",
			"PLN",
			"PYG",
			"QAR",
			"RON",
			"RSD",
			"RUB",
			"RWF",
			"SAR",
			"SDG",
			"SEK",
			"SGD",
			"SOS",
			"SYP",
			"THB",
			"TND",
			"TOP",
			"TRY",
			"TTD",
			"TWD",
			"TZS",
			"UAH",
			"UGX",
			"UYU",
			"UZS",
			"VEF",
			"VND",
			"XAF",
			"XOF",
			"YER",
			"ZAR",
			"ZMK"
		],
		settingsOpen: false,
		storeName: ""
	};

	handleClickOpenHelp = page => {
		this.setState({ open: true, page: page });
	};

	handleClickOpenSettings = () => {
		this.setState({ settingsOpen: true });
	};

	handleCloseSnack = () => {
		this.setState({
			showFalseValuesSnack: false,
			showSnackSuccess: false,
			currencyAlreadyAdded: false
		});
	};

	handleCloseCancel = () => {
		this.setState({ openCurDialog: false, currencyList: false });
	};

	signOut = () => {
		auth.doSignOut();
	};

	handleChangeSelect = () => event => {
		const { currencyAddList, currencyList } = this.state;
		const currency = currencyAddList[event.target.value];
		var index = currencyList
			.map(e => {
				return e.currency;
			})
			.indexOf(currency);
		if (index >= 0) {
			this.setState({ showSnackSuccess: true, currencyAlreadyAdded: true });
		} else {
			this.setState({
				currencyList: [...this.state.currencyList, { currency: currency, rate: 1, hasChanged: false }]
			});
		}
	};

	getCurrencies = () => {
		this.setState({ disableCurButton: true });
		db.getUserStoreName().then(storeName => {
			this.setState({ storeName: storeName });
			return db.getCurrencies(storeName).then(rates => {
				rates.forEach(x => {
					this.setState({
						currencyList: [
							...this.state.currencyList,
							{ currency: x.key, rate: x.val(), hasChanged: false }
						]
					});
				});
				this.setState({ openCurDialog: true, disableCurButton: false });
			});
		});
	};

	findWithAttr(array, attr, value) {
		for (var i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}

	handleChange = name => event => {
		const items = this.state.currencyList;

		const index = this.findWithAttr(items, "currency", name);
		if (index > -1) {
			items[index].rate = Number(event.target.value);
			items[index].hasChanged = true;
		}

		this.setState({ currencyList: items });
	};

	saveCurrencies = () => {
		const items = this.state.currencyList;
		let anyFalseValues = false;
		for (let i of items) {
			if (isNaN(i.rate) || i.rate <= 0) {
				anyFalseValues = true;
				break;
			}
		}
		if (anyFalseValues) {
			this.setState({ showSnackFalseValues: true });
		} else {
			db.saveCurrencies(this.state.storeName, items);
			this.setState({ showSnackSuccess: true });
			this.handleCloseCancel();
		}
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				if (snapshot == null) {
					return "noData";
				} else {
					this.setState({ storeName: snapshot });
				}
			})
			.catch(e => console.log("error", e));
	}

	render() {
		const { classes } = this.props;
		const { currencyList, currencyAddList } = this.state;
		if (this.state.storeName === null)
			return (
				<div className={classes.root}>
					<AppBar className={classes.AppBar}>
						<Toolbar>
							<Typography color='inherit' style={{ flex: 1 }}>
								<Link to='/'>
									<img
										src='https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png'
										style={{ verticalAlign: "middle", width: "9em" }}
										alt=''
									/>
								</Link>
							</Typography>
							<Tooltip title='Sign Out'>
								<IconButton type='button' className={classes.button} onClick={() => this.signOut()}>
									<SignOut />
								</IconButton>
							</Tooltip>
						</Toolbar>
					</AppBar>
				</div>
			);

		return (
			<div className={classes.root}>
				<HelpModal
					open={this.state.open}
					page={this.state.page}
					openDash={false}
					handleClose={() => this.setState({ open: false })}
				/>
				<SettingsModal
					open={this.state.settingsOpen}
					handleClose={() => this.setState({ settingsOpen: false })}
				/>
				<AppBar className={classes.AppBar}>
					<Toolbar style={{ padding: this.props.isMobile ? 0 : null }}>
						{this.state.storeName !== null ? (
							<IconButton type='button' onClick={this.props.toggleDrawer} className={classes.menuButton}>
								<MenuIcon />
							</IconButton>
						) : null}
						<MediaQuery query='(min-width: 600px)'>
							<Typography variant='subtitle1' color='inherit' style={{ flex: 1 }}>
								<Link to='/'>
									<img
										src='https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png'
										style={{ verticalAlign: "middle", width: "9em" }}
										alt=''
									/>
								</Link>
							</Typography>
						</MediaQuery>
						{this.state.storeName !== null && !this.props.isMobile ? null : (
							<Link to='/'>
								<Typography
									color='inherit'
									style={{
										flex: 1,
										marginRight: "10px",
										fontWeight: "600"
									}}>
									<img
										src='https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png'
										style={{
											width: "8em",
											verticalAlign: "middle"
										}}
										alt='EnterOffer'
									/>
								</Typography>
							</Link>
						)}
						<div>
							<Tooltip title='Settings'>
								<IconButton
									type='button'
									className={classes.button}
									onClick={this.handleClickOpenSettings}>
									<Settings />
								</IconButton>
							</Tooltip>
							<Tooltip title='Help'>
								<IconButton
									type='button'
									className={classes.button}
									onClick={() => this.handleClickOpenHelp(this.props.location.pathname)}>
									<Help />
								</IconButton>
							</Tooltip>
							<Tooltip title='Sign out'>
								<IconButton type='button' className={classes.button} onClick={() => this.signOut()}>
									<SignOut />
								</IconButton>
							</Tooltip>
						</div>
					</Toolbar>
				</AppBar>

				<Dialog
					open={this.state.openCurDialog}
					onClose={this.handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'>
					<DialogTitle id='alert-dialog-title'>{"Set global currency converter"}</DialogTitle>
					<DialogContent>
						{currencyList &&
							_.map(currencyList, (item, i) => (
								<form className={classes.container} noValidate autoComplete='off' key={i}>
									<TextField
										id='standard-currency'
										type='number'
										fullWidth
										label={item.currency}
										className={classes.textField}
										value={item.rate}
										onChange={this.handleChange(item.currency)}
										margin='normal'
										variant='outlined'
									/>
								</form>
							))}

						<TextField
							id='AddCurrencies'
							select
							label='Select Currencies'
							value={this.state.currencyAddList}
							fullWidth
							onChange={this.handleChangeSelect()}
							margin='normal'
							SelectProps={{
								MenuProps: {
									className: classes.menu
								}
							}}>
							{_.map(currencyAddList, (item, i) => (
								<MenuItem key={i} value={i}>
									{item}
								</MenuItem>
							))}
						</TextField>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleCloseCancel} color='primary' autoFocus>
							Cancel
						</Button>
						<Button onClick={this.saveCurrencies} color='primary' autoFocus>
							Save
						</Button>
					</DialogActions>
				</Dialog>

				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					open={this.state.showSnackFalseValues}
					onClose={this.handleCloseSnack}
					autoHideDuration={6000}
					ContentProps={{
						"aria-describedby": "message-id"
					}}
					message={<span id='message-id'>Please ensure all values are numbers greater than 0</span>}
				/>

				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					open={this.state.showSnackSuccess}
					onClose={this.handleCloseSnack}
					autoHideDuration={6000}
					ContentProps={{
						"aria-describedby": "message-id"
					}}
					message={
						<span id='message-id'>
							{this.state.currencyAlreadyAdded
								? "This currency has already been added"
								: "Currencies Saved"}
						</span>
					}
				/>
			</div>
		);
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withRouter(withStyles(styles)(Navigation)));
