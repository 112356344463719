import React, { Component } from "react";
import { db } from "../firebase";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

class PageNotFound extends Component {
	state = {
		data: [],
		loading: true
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				console.log(snapshot);
				this.setState({ storeName: snapshot, loading: false });
			})
			.catch(e => {
				console.error(e);
			});
	}

	render() {
		return (
			<div
				style={{
					top: "40%",
					left: "50%",
					marginLeft: "-70px",
					position: "fixed",
					textAlign: "center"
				}}
			>
				<div style={{ display: "inline-block" }}>
					<Typography style={{ fontSize: 40 }}>404</Typography>
					<Link to="/" style={{ textDecoration: "none" }}>
						<Button>Return to Home</Button>
					</Link>
				</div>
			</div>
		);
	}
}

export default PageNotFound;
