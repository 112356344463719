import { authdb, authfb, userdb, auth, rulesdb, firebase, cartAbandonmentDB, offerableDB } from "./firebase";

export function getUserStoreName() {
	return new Promise((resolve, reject) => {
		var userID = auth.currentUser.uid;
		userdb
			.ref(`${userID}/`)
			.once("value")
			.then((snap) => {
				if (snap.val() === null) return resolve(null);
				if (snap.val().storeName === undefined) return resolve(null);
				if (typeof snap.val().storeName === "object") {
					if (snap.val().hasOwnProperty("storeIndex")) {
						return resolve(snap.val().storeName[snap.val().storeIndex]);
					} else {
						return resolve(snap.val().storeName[0]);
					}
				}
				return resolve(snap.val().storeName);
			})
			.catch((e) => {
				console.error(e);
				reject(e);
			});
	});
}
export function getStoreList() {
	return new Promise((resolve, reject) => {
		var userID = auth.currentUser.uid;
		userdb
			.ref(`${userID}/`)
			.once("value")
			.then((snap) => {
				if (snap.val() === null) return resolve(null);
				if (snap.val().storeName === undefined) return resolve(null);
				if (typeof snap.val().storeName === "object") {
					return resolve([snap.val().storeName, snap.val().storeIndex]);
				}
				return resolve([]);
			})
			.catch((e) => {
				console.error(e);
				reject(e);
			});
	});
}
export function setStoreIndex(value) {
	var userID = auth.currentUser.uid;
	userdb.ref(userID + "/storeIndex").set(value);
	return;
}

export function getUserStoreUrl(storeName) {
	return new Promise(function (resolve, reject) {
		authdb
			.ref(`stores/${storeName}/meta/url`)
			.once("value")
			.then(function (snap) {
				return resolve(snap.val());
			});
	});
}

export function finishedSignup() {
	return new Promise((resolve, reject) => {
		authdb
			.ref(`newStores/${auth.currentUser.uid}/meta/finished`)
			.set(true)
			.then(() => {
				return resolve(true);
			})
			.catch((e) => {
				return reject(e);
			});
	});
}

export function getUserConfirmPref() {
	return new Promise(function (resolve, reject) {
		var userID = auth.currentUser.uid;
		userdb
			.ref(userID + "/preference/confirmPopup")
			.once("value")
			.then(function (snap) {
				if (snap.val() === null) {
					userdb.ref(userID + "/preference").set({
						confirmPopup: 1,
					});
					return resolve(1);
				} else {
					return resolve(snap.val());
				}
			});
	});
}

export function setUserConfirmPref(value) {
	var userID = auth.currentUser.uid;
	userdb.ref(userID + "/preference").set({
		confirmPopup: value,
	});
	return;
}

export function updateCurrencies(storeName, currencies) {
	authdb
		.ref("stores/" + storeName + "/meta/currencies")
		.update(currencies)
		.then((snap) => {
			console.log(snap);
		})
		.catch(console.error);
}

export function getStoreBids(storeName, whichType) {
	return new Promise(function (resolve, reject) {
		authdb
			.ref("stores/" + storeName + `/${whichType}`)
			.once("value", (snapshot) => {
				return resolve(snapshot);
			})
			.catch((e) => {
				console.log(e);
			});
	});
}

export function approveOrRejectBid(storeName, bitItem, functionType) {
	return new Promise(function (resolve, reject) {
		authdb.ref("stores/" + storeName + "/pending/" + bitItem.bidKey + "/" + functionType).set(true);
		return resolve(true);
	});
}

export function getItem(storeName, bitItem) {
	return new Promise(function (resolve, reject) {
		authdb
			.ref("stores/" + storeName + "/pending/" + bitItem.bidKey)
			.once("value")
			.then((snap) => {
				return resolve(snap);
			})
			.catch((e) => {
				return reject();
			});
	});
}

export function pushUpdate(storeName, whichType, snapKey, snapshot) {
	return new Promise(function (resolve, reject) {
		var newItem = snapshot;
		authdb.ref("stores/" + storeName + "/" + whichType + "/" + snapKey).set(newItem);
		authdb.ref("stores/" + storeName + "/pending/" + snapKey).remove();
		return resolve(true);
	});
}

export function getCurrencies(storeName) {
	return new Promise(function (resolve, reject) {
		authdb
			.ref("stores/" + storeName + "/meta/currencies")
			.once("value")
			.then((snap) => {
				return resolve(snap);
			})
			.catch((e) => {
				return reject();
			});
	});
}
export function saveCurrencies(storeName, items) {
	for (let i of items) {
		if (i.hasChanged) {
			authdb.ref("stores/" + storeName + "/meta/currencies/" + i.currency).set(i.rate);
		}
	}
}

export function getProducts(storeName) {
	let products;
	// storeName = "theTimekeeper"
	return fetch(
		`https://us-central1-oaklas-bookmark-tool.cloudfunctions.net/getShopifyStoreProducts?storeName=${storeName}`
	)
		.then((response) => {
			if (response.ok) {
				return response.json();
			}
		})
		.then((json) => {
			products = json.data;
			// get the rules
			return rulesdb.ref("/" + storeName).once("value");
		})
		.then((snapshot) => {
			const rules = snapshot.val();

			let productsAndRules = [];
			for (let i of products) {
				let rejectBelow = "";
				let acceptAbove = "";
				let unitDollar = "";
				let invLowerLim = "";
				let endDate = "";
				let isRule = false;
				if (rules !== null) {
					let rule = rules[i.id];
					if (rule) {
						rejectBelow = rule.autoRejectBelow || "";
						acceptAbove = rule.acceptAbove ? rule.acceptAbove.amount : "";
						invLowerLim = rule.acceptAbove ? rule.acceptAbove.invLowerLim : "";
						endDate = rule.acceptAbove ? rule.endDate : "";
						endDate = new Date(endDate).toISOString().substring(0, 10);
						isRule = true;
					}
				}

				let data = {
					productTitle: i.title,
					productId: i.id,
					productPrice: i.variants[0].price,
					rejectBelow: rejectBelow,
					acceptAbove: acceptAbove,
					unitDollar: unitDollar,
					invLowerLim: invLowerLim,
					endDate: endDate,
					isRule: isRule,
				};
				productsAndRules.push(data);
			}
			return productsAndRules;
		})
		.catch((e) => {
			console.error(e);
		});
}

export function getProductsInCollectionButton(storeName, collectionID) {
	const getShopifyCollectionProducts = authfb.functions().httpsCallable("getShopifyCollectionProducts");
	return new Promise((resolve, reject) => {
		let products;
		getShopifyCollectionProducts({ storeName, collectionID })
			.then((json) => {
				products = json.data;
				// get the rules
				var promises = [];
				promises[0] = offerableDB.ref(storeName).once("value");
				promises[1] = authdb.ref(`stores/${storeName}/meta/url`).once("value");
				return Promise.all(promises);
			})
			.then((snapshot) => {
				const url = snapshot[1].val();
				let retProducts = [];
				for (let i of products) {
					let unitDollar = "";
					let endDate = "";
					let checked = false;
					if (snapshot[0].val() === null) checked = false;
					else if (snapshot[0].val()[i.id]) checked = true;
					let data = {
						productTitle: i.title,
						productId: i.id,
						productPrice: i.variants[0].price,
						unitDollar: unitDollar,
						endDate: endDate,
						image: i.image !== null ? i.image.src : "",
						checked: checked,
						handle: i.handle,
						url: `${url}/products/${i.handle}`,
					};
					retProducts.push(data);
				}
				return resolve(retProducts);
			})
			.catch((e) => {
				console.error(e);
				reject(e);
			});
	});
}

export function getProductsInCollection(storeName, collectionID) {
	const getShopifyCollectionProducts = authfb.functions().httpsCallable("getShopifyCollectionProducts");
	return new Promise((resolve, reject) => {
		let products;
		getShopifyCollectionProducts({ storeName, collectionID })
			.then((json) => {
				console.log(json);
				products = json.data;
				// get the rules
				var promises = [];
				promises[0] = rulesdb.ref("/" + storeName).once("value");
				promises[1] = authdb.ref("stores/" + storeName + "/campaigns/active").once("value");
				promises[2] = authdb.ref(`stores/${storeName}/meta/url`).once("value");
				return Promise.all(promises);
			})
			.then((snapshot) => {
				const url = snapshot[2].val();
				const rules = snapshot[0].val();
				const activeCampagins = snapshot[1].val();
				let productsAndRules = [];
				for (let i of products) {
					// console.log(i)
					let rejectBelow = "";
					let acceptAbove = "";
					let unitDollar = "";
					let invLowerLim = "";
					let endDate = "";
					let isRule = false;
					let checked = false;
					if (rules !== null) {
						let rule = rules[i.id];
						if (rule) {
							rejectBelow = rule.autoRejectBelow || "";
							acceptAbove = rule.acceptAbove ? rule.acceptAbove.amount : "";
							invLowerLim = rule.acceptAbove ? rule.acceptAbove.invLowerLim : "";
							endDate = rule.acceptAbove ? rule.endDate : "";
							endDate = new Date(endDate).toISOString().substring(0, 10);
							isRule = true;
						}
					}
					for (let j in activeCampagins) {
						if (activeCampagins[j].productId === i.id) {
							if (activeCampagins[j].makeOffer === true) {
								checked = true;
							}
						}
					}
					let data = {
						productTitle: i.title,
						productId: i.id,
						productPrice: i.variants[0].price,
						rejectBelow: rejectBelow,
						acceptAbove: acceptAbove,
						unitDollar: unitDollar,
						invLowerLim: invLowerLim,
						endDate: endDate,
						image: i.image.src,
						isRule: isRule,
						checked: checked,
						url: `${url}/products/${i.handle}`,
					};
					productsAndRules.push(data);
				}
				return resolve(productsAndRules);
			})
			.catch((e) => {
				reject(e);
				console.error(e);
			});
	});
}

export function removeRules(storeName, rule) {
	return new Promise(function (resolve, reject) {
		rulesdb
			.ref(`/${storeName}/${rule.productId}`)
			.remove()
			.then(function (snap) {
				return resolve("removed");
			})
			.catch((e) => console.error(e));
	});
}

export function removeMasterRules(storeName, productsList) {
	return new Promise(function (resolve, reject) {
		for (let product of productsList) {
			rulesdb
				.ref(`/${storeName}/${product.productId}`)
				.remove()
				.then(function (snap) {
					return;
				})
				.catch((e) => console.error(e));
		}
		resolve("removed");
	});
}

export function submitMasterRules(storeName, masterRule, productsList) {
	return new Promise((resolve, reject) => {
		masterRule.endDate = new Date(masterRule.endDate).valueOf();
		for (let product of productsList) {
			const parsedRejectBelow = parseFloat(masterRule.rejectBelow);
			const parsedAcceptAbove = parseFloat(masterRule.acceptAbove);
			rulesdb.ref(storeName + "/" + product.productId).set({
				autoRejectBelow: parsedRejectBelow,
				endDate: parseFloat(masterRule.endDate),
				acceptAbove: {
					amount: parsedAcceptAbove,
					invLowerLim: 0,
				},
				image: product.image,
				productName: product.productTitle,
			});
		}
		return resolve();
	});
}

export function submitRules(storeName, data) {
	return new Promise(function (resolve, reject) {
		// convert to $ if unit is %
		data.acceptAndRejectBool = "%";

		data.endDate = new Date(data.endDate).valueOf();
		let d = new Date();
		let curDate = d.valueOf();

		if (data.endDate < curDate) {
			resolve("pastDate");
		}

		if (data.rejectBelow && data.acceptAbove && data.endDate) {
			let parsedRejectBelow = parseFloat(data.rejectBelow);
			let parsedAcceptAbove = parseFloat(data.acceptAbove);

			rulesdb.ref(storeName + "/" + data.productId).set({
				autoRejectBelow: parsedRejectBelow,
				endDate: parseFloat(data.endDate),
				acceptAbove: {
					amount: parsedAcceptAbove,
					invLowerLim: 0,
				},
				image: data.image,
				productName: data.productTitle,
			});
			return resolve("successBoth");
		} else {
			return resolve("failed");
		}
	});
}

export function pushNewCampaign(
	storeName,
	campaignName,
	productName,
	productId,
	startDate,
	endDate,
	reservePrice,
	location,
	image
) {
	return new Promise(function (resolve, reject) {
		var pushObj;
		if (reservePrice === "") {
			pushObj = {
				name: campaignName,
				productName: productName,
				productId: productId,
				startDate: startDate,
				endDate: endDate,
				image: image,
			};
		} else {
			pushObj = {
				name: campaignName,
				productName: productName,
				productId: productId,
				startDate: startDate,
				endDate: endDate,
				reservePrice: reservePrice,
				image: image,
			};
		}
		authdb
			.ref("stores/" + storeName + "/campaigns/" + location)
			.push(pushObj)
			.then((ref) => {
				return resolve(0);
			})
			.catch((e) => {
				return resolve(e);
			});
	});
}

export function getStoreCampaigns(storeName, whichType) {
	return new Promise(function (resolve, reject) {
		offerableDB.ref("stores/" + storeName + "/campaigns/" + whichType).once("value", (snapshot) => {
			return resolve(snapshot);
		});
	});
}
export function getOfferable(storeName) {
	return new Promise(function (resolve, reject) {
		offerableDB.ref("/" + storeName).once("value", (snapshot) => {
			return resolve(snapshot);
		});
	});
}

// db.deleteCampaign(storeName,typeName,campaignKey,productName,productId,startDateNum,endDateNum);
export function deleteCampaign(
	storeName,
	typeName,
	campaignKey,
	campaignName,
	productName,
	productId,
	startDateNum,
	endDateNum
) {
	return new Promise(function (resolve, reject) {
		if (typeName === "active") {
			//endDateNum = Math.round(new Date().getTime()/1000);
			authdb
				.ref("stores/" + storeName + "/campaigns/" + typeName + `/${campaignKey}`)
				.remove()
				.catch((e) => console.error(e));
			console.log(endDateNum);
			//to change them all into seconds
			endDateNum = Math.round(new Date(endDateNum * 1000.0).getTime() / 1000.0);
			startDateNum = Math.round(new Date(startDateNum * 1000).getTime() / 1000.0);
			authdb.ref("stores/" + storeName + "/campaigns/history/" + campaignKey).set({
				startDate: startDateNum,
				endDate: endDateNum,
				name: campaignName,
				productId: productId,
				productName: productName,
			});
		} else if (typeName === "future") {
			return new Promise(function (resolve, reject) {
				authdb
					.ref("stores/" + storeName + "/campaigns/" + typeName + `/${campaignKey}`)
					.remove()
					.catch((e) => console.error(e));
			});
		}
		return resolve("removed");
	});
}

export function pushSignupStep(info) {
	return new Promise(function (resolve, reject) {
		authdb
			.ref(`newStores/${auth.currentUser.uid}/meta/`)
			.update({
				storeName: info.storeName,
				abn: info.brn,
				url: info.storeURL,
				shopName: info.shopifyUrl,
			})
			.then((res) => {
				return resolve("success");
			})
			.catch((e) => {
				return resolve(e);
			});
	});
}

export function getNewStoreInfo(info) {
	return new Promise((resolve, reject) => {
		authdb
			.ref(`newStores/${auth.currentUser.uid}/meta/${info}`)
			.once("value")
			.then((res) => {
				return resolve(res.val());
			})
			.catch((e) => {
				return reject(e);
			});
	});
}

export function isOnline(storeName) {
	var ref = userdb.ref(`${auth.currentUser.uid}/isOnline`);
	return ref.once("value", (snapshot) => {
		ref.set(true);
		ref.onDisconnect().set(firebase.database.ServerValue.TIMESTAMP);
	});
}

export function getProductRules(storeName) {
	return new Promise(function (resolve, reject) {
		rulesdb
			.ref(`/${storeName}/`)
			.once("value")
			.then((snap) => {
				var arr = [];
				snap.forEach((rule) => {
					if (rule.key === "cart" || rule.key === "cartAbandonment") return;
					var obj = {};
					obj.acceptAbove = rule.val().acceptAbove.amount;
					obj.invLowerLim = rule.val().acceptAbove.invLowerLim;
					obj.rejectBelow = rule.val().autoRejectBelow;
					obj.endDate = new Date(rule.val().endDate).toISOString().substring(0, 10);
					obj.isRule = true;
					obj.image = rule.val().image;
					obj.productTitle = rule.val().productName;
					obj.productId = rule.key;
					arr.push(obj);
				});
				return resolve(arr);
			})
			.catch((e) => {
				return reject(e);
			});
	});
}

export function removeCartRules(storeName, rule) {
	return new Promise(function (resolve, reject) {
		if (rule.cartAbandonment) {
			rulesdb
				.ref(`/${storeName}/cartAbandonment/${rule.productId}`)
				.remove()
				.then(function (snap) {
					return resolve("removed");
				})
				.catch((e) => console.error(e));
		} else {
			rulesdb
				.ref(`/${storeName}/cart/${rule.productId}`)
				.remove()
				.then(function (snap) {
					return resolve("removed");
				})
				.catch((e) => console.error(e));
		}
	});
}

export function getCartRules(storeName) {
	return new Promise(function (resolve, reject) {
		var arr = [];
		rulesdb
			.ref(`/${storeName}/cart/`)
			.once("value")
			.then((snap) => {
				snap.forEach((rule) => {
					var obj = {};
					obj.acceptAbove = rule.val().upperPerc;
					obj.rejectBelow = rule.val().lowerPerc;
					obj.lowerAmount = rule.val().lowerAmount;
					obj.upperAmount = rule.val().upperAmount;
					obj.endDate = new Date(rule.val().endDate).toISOString().substring(0, 10);
					obj.isRule = true;
					obj.cartAbandonment = false;
					obj.productId = rule.key;
					arr.push(obj);
				});
				return null;
			})
			.then(() => {
				return rulesdb.ref(`/${storeName}/cartAbandonment/`).once("value");
			})
			.then((asnap) => {
				asnap.forEach((rule) => {
					var obj = {};
					obj.acceptAbove = rule.val().upperPerc;
					obj.rejectBelow = rule.val().lowerPerc;
					obj.lowerAmount = rule.val().lowerAmount;
					obj.upperAmount = rule.val().upperAmount;
					obj.endDate = new Date(rule.val().endDate).toISOString().substring(0, 10);
					obj.isRule = true;
					obj.cartAbandonment = true;
					obj.productId = rule.key;
					arr.push(obj);
				});
				return resolve(arr);
			})
			.catch((e) => {
				return reject(e);
			});
	});
}
export function getButtonColor(storeName) {
	return new Promise(function (resolve, reject) {
		authdb.ref("stores/" + storeName + "/meta/button").once("value", (snapshot) => {
			return resolve(snapshot.val());
		});
	});
}
export function setButtonColor(storeName, color, textColor) {
	authdb
		.ref("stores/" + storeName + "/meta/button")
		.set({ color: color, textColor: textColor })
		.catch(console.error);
}
export function getThemeColor(storeName) {
	return new Promise(function (resolve, reject) {
		authdb.ref("stores/" + storeName + "/meta/theme").once("value", (snapshot) => {
			return resolve(snapshot.val());
		});
	});
}
export function setThemeColor(storeName, color, textColor) {
	authdb
		.ref("stores/" + storeName + "/meta/theme")
		.set({ color: color, textColor: textColor })
		.catch(console.error);
}
export function getResponsePeriod(storeName) {
	return new Promise(function (resolve, reject) {
		authdb.ref("stores/" + storeName + "/meta/responsePeriod").once("value", (snapshot) => {
			return resolve(snapshot.val());
		});
	});
}

export function updateResponsePeriod(storeName, responsePeriod) {
	authdb
		.ref("stores/" + storeName + "/meta/responsePeriod")
		.set(responsePeriod)
		.catch(console.error);
}
export function getCartAbandonmentProducts(storeName) {
	return new Promise(function (resolve, reject) {
		cartAbandonmentDB.ref(`/${storeName}`).once("value", (snapshot) => {
			return resolve(snapshot.val());
		});
	});
}

export function updateCartAbandonmentProducts(list, storeName) {
	var obj = {};
	list.map((id) => (obj[id] = true));
	cartAbandonmentDB.ref(`/${storeName}`).set(obj).catch(console.error);
}

export function getSPP() {
	return fetch("https://us-central1-oaklas-user.cloudfunctions.net/SPPInfo")
		.then((response) => {
			return response.json();
		})
		.catch((e) => {
			console.error(e);
		});
}
