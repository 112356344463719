import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { db, fb } from "../firebase";
import Snackbar from "@material-ui/core/Snackbar";
import { HelpModal, Slider } from "./index";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import withSizes from "react-sizes";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		textAlign: "left"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	inputAccept: {
		color: "#22c78e"
	},
	inputReject: {
		color: "#ff4d4d"
	},
	textField: {
		width: "100%"
	},
	menu: {
		width: 200
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	fabStyle: {
		margin: theme.spacing(2)
	},
	buttonDiv: {
		textAlign: "center",
		marginTop: "1em"
	},
	button: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		textAlign: "center",
		display: "inline-block",
		color: "#35B2FF",
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	productName: {
		fontFamily: '"Nunito Sans", sans-serif',
		fontSize: "24px",
		fontWeight: "300",
		width: "100%",
		display: "block",
		paddingBottom: "10px",
		paddingTop: "10px",
		borderRadius: "5px",
		background: "#f6f6f8"
	}
});

class CartRules extends React.Component {
	state = {
		storeName: "",
		rejectBelow: "",
		rejectBelowBool: "$",
		acceptAbove: "",
		acceptAboveBool: "$",
		endDate: "",
		isRule: false,
		open: false,
		openSuccess: false,
		openDate: false,
		openDialog: false,
		openDeleteDialog: false,
		openInvalid: false,
		openOverlap: false,
		loadingC: false,
		loadingCA: false,
		from: 0,
		to: 0,
		rejectBelowA: "",
		acceptAboveA: "",
		endDateA: "",
		fromA: 0,
		toA: 0,
		checked: false
	};

	static propTypes = {
		collectionName: PropTypes.string.isRequired
	};

	handleOpenAll = name => {
		this.setState({ loadingC: false, loadingCA: false, [name]: true });
	};

	handleCloseAll = name => {
		this.setState({ loadingC: false, loadingCA: false, [name]: false });
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
			})
			.catch(e => console.log("error", e));
	}

	handleChange = (event, type) => {
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setState({ [name]: value });
		if (event.target.type === "checkbox") {
			this.setState({
				fromA: this.state.from,
				toA: this.state.to,
				acceptAboveA: this.state.acceptAbove,
				rejectBelowA: this.state.rejectBelow,
				endDateA: this.state.endDate
			});
		}
	};

	submitCartRules() {
		const { endDate, rejectBelow, acceptAbove, storeName, from, to } = this.state;
		let d = new Date();
		let curDate = d.valueOf();
		let newend = new Date(endDate).valueOf();
		if (newend < curDate) {
			this.handleOpenAll("openDate");
		} else if (Number(rejectBelow) >= Number(acceptAbove)) {
			this.handleOpenAll("openInvalid");
		} else if (Number(from) >= Number(to)) {
			this.handleOpenAll("openInvalid");
		} else if (rejectBelow && acceptAbove && endDate) {
			fb.submitCartRule({
				endDate: newend,
				lowerAmount: Number(from),
				upperAmount: Number(to),
				lowerPerc: Number(rejectBelow),
				upperPerc: Number(acceptAbove),
				storeName: storeName,
				cartAbandonment: false
			})
				.then(res => {
					console.log(res);
					if (res.data === true) {
						this.handleOpenAll("openSuccess");
						this.setState({
							endDate: "",
							rejectBelow: "",
							acceptAbove: "",
							from: "",
							to: ""
						});
					} else if (res.data === "newRuleOverlap") {
						this.handleOpenAll("openOverlap");
					}
					this.setState({ loadingC: false });
				})
				.catch(e => {
					this.handleOpenAll("open");
				});
		}
	}

	submitCartRulesAbandonment() {
		const { storeName, endDateA, rejectBelowA, acceptAboveA, fromA, toA } = this.state;
		let d = new Date();
		let curDate = d.valueOf();
		let newendA = new Date(endDateA).valueOf();
		if (newendA < curDate) {
			this.handleOpenAll("openDate");
		} else if (Number(rejectBelowA) >= Number(acceptAboveA)) {
			this.handleOpenAll("openInvalid");
		} else if (Number(fromA) >= Number(toA)) {
			this.handleOpenAll("openInvalid");
		} else if (rejectBelowA && acceptAboveA && endDateA) {
			fb.submitCartRule({
				endDate: newendA,
				lowerAmount: Number(fromA),
				upperAmount: Number(toA),
				lowerPerc: Number(rejectBelowA),
				upperPerc: Number(acceptAboveA),
				storeName: storeName,
				cartAbandonment: true
			})
				.then(res => {
					if (res.data === true) {
						this.handleOpenAll("openSuccess");
						this.setState({
							endDateA: "",
							rejectBelowA: "",
							acceptAboveA: "",
							fromA: "",
							toA: ""
						});
					} else if (res.data === "newRuleOverlap") {
						this.handleOpenAll("openOverlap");
					}
					this.setState({
						loadingCA: false
					});
				})
				.catch(e => {
					this.handleOpenAll("open");
				});
		}
	}

	sendDataBackToParent() {
		let data = {
			rejectBelow: this.state.rejectBelow,
			acceptAbove: this.state.acceptAbove,
			rejectBelowBool: "%",
			acceptAboveBool: "%",
			endDate: this.state.endDate,
			isRule: this.state.isRule
		};

		this.props.onSelectRule(data);
		this.handleOpenAll("openSuccess");
		this.setState({ rejectBelow: "", acceptAbove: "", rejectBelowBool: "%", acceptAboveBool: "%", isRule: false });
	}

	sendDeleteDataBackToParent() {
		this.props.onDeleteRule();
	}

	formSubmit = e => {
		e.preventDefault();
		this.setState({ loadingC: true });
		this.submitCartRules();
	};

	formSubmitAbandoment = e => {
		e.preventDefault();
		this.setState({ loadingCA: true });
		this.submitCartRulesAbandonment();
	};

	handleSliderChange = val => {
		this.setState({ rejectBelow: val[0], acceptAbove: val[1] });
	};
	handleSliderChangeA = val => {
		this.setState({ rejectBelowA: val[0], acceptAboveA: val[1] });
	};

	render() {
		const { classes, isMobile } = this.props;
		const {
			openInvalid,
			rejectBelow,
			acceptAbove,
			rejectBelowA,
			acceptAboveA,
			isRule,
			endDate,
			endDateA,
			open,
			openSuccess,
			openDate,
			from,
			to,
			fromA,
			toA,
			openOverlap,
			loadingC,
			loadingCA
		} = this.state;

		return (
			<div>
				<HelpModal
					open={this.state.openDialog}
					page={"/rules"}
					isSubmitCartRules={true}
					handleClose={e => {
						this.setState({ openDialog: false });
						if (e === "apply") {
							console.log(e);
							this.sendDataBackToParent();
						}
					}}
				/>

				<HelpModal
					open={this.state.openDeleteDialog}
					isDeleteCartRules={true}
					page={"/rules"}
					handleClose={e => {
						this.setState({ openDeleteDialog: false });
						if (e === "apply") {
							console.log(e);
							this.sendDeleteDataBackToParent();
						}
					}}
				/>
				{/* <Button
					onClick={() => {
						this.setState({ openDialog: true });
					}}>
					Here
				</Button> */}
				<Grid container spacing={2}>
					<Grid item xs={isMobile ? 12 : 6}>
						<h3
							style={{
								fontWeight: "100",
								textAlign: "center",
								color: "#929292"
							}}>
							CART
						</h3>
						<Paper className={classes.root} elevation={1}>
							{loadingC ? (
								<Lottie
									height={200}
									width={200}
									options={{
										loop: true,
										autoplay: true,
										animationData: loader.default,
										rendererSettings: {
											preserveAspectRatio: "xMidYMid slice"
										}
									}}
								/>
							) : (
								<form onSubmit={e => this.formSubmit(e)}>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Tooltip
												placement='top'
												title='e.g. if a cart cost $100 and the accept above rule was set to 70 (%), then all offers above $70 will be accepted automatically'>
												<TextField
													id='from'
													label='From'
													className={classes.textField}
													value={from}
													onChange={this.handleChange}
													name='from'
													margin='normal'
													type='number'
													InputLabelProps={{
														shrink: true
													}}
													InputProps={{
														inputProps: {
															min: 0,
															readOnly: isRule ? true : false
														},
														startAdornment: (
															<InputAdornment position='start'>$</InputAdornment>
														)
													}}
												/>
											</Tooltip>
										</Grid>
										<Grid item xs={6}>
											<Tooltip
												placement='top'
												title='e.g. if a cart cost $100 and the accept above rule was set to 70 (%), then all offers above $70 will be accepted automatically'>
												<TextField
													id='to'
													label='To'
													className={classes.textField}
													value={to}
													onChange={this.handleChange}
													name='to'
													margin='normal'
													type='number'
													InputLabelProps={{
														shrink: true
													}}
													InputProps={{
														inputProps: {
															min: 0,
															readOnly: isRule ? true : false
														},
														startAdornment: (
															<InputAdornment position='start'>$</InputAdornment>
														)
													}}
												/>
											</Tooltip>
										</Grid>

										<Grid container item xs={7} spacing={2}>
											<Grid item xs={12}>
												<Tooltip
													placement='top'
													title='e.g. if a cart cost $100 and the accept above rule was set to 70 (%), then all offers above $70 will be accepted automatically'>
													<TextField
														id='acceptAbove'
														label='Accept All Offers Above'
														className={classes.textField}
														value={acceptAbove}
														onChange={this.handleChange}
														name='acceptAbove'
														margin='normal'
														type='number'
														InputLabelProps={{
															shrink: true
														}}
														InputProps={{
															className: classes.inputAccept,
															inputProps: {
																min: 0,
																max: 100,
																readOnly: isRule ? true : false
															},
															endAdornment: (
																<InputAdornment position='end'>
																	<span style={{ color: "#22c78e" }}>%</span>
																</InputAdornment>
															)
														}}
													/>
												</Tooltip>
											</Grid>
											<Grid item xs={12}>
												<Tooltip
													placement='top'
													title='e.g. if a cart cost $100 and the reject below rule was set to 30 (%), then all offers below $30 will be rejected automatically'>
													<TextField
														id='rejectBelow'
														label='Reject All Offers Below'
														className={classes.textField}
														value={rejectBelow}
														onChange={this.handleChange}
														name='rejectBelow'
														margin='normal'
														type='number'
														InputLabelProps={{
															shrink: true
														}}
														InputProps={{
															className: classes.inputReject,
															inputProps: {
																min: 0,
																max: 100,
																readOnly: isRule ? true : false
															},
															endAdornment: (
																<InputAdornment position='end'>
																	<span style={{ color: "#ff4d4d" }}>%</span>
																</InputAdornment>
															)
														}}
													/>
												</Tooltip>
											</Grid>
										</Grid>
										<Grid container item xs={5}>
											<div style={{ margin: "12px 0px -32px" }}>
												<Slider
													low={rejectBelow}
													high={acceptAbove}
													sliderChange={this.handleSliderChange}
													isMobile={isMobile}
												/>
											</div>
										</Grid>
										<Grid item xs={8}>
											<TextField
												id='endDate'
												label='End Date'
												type='date'
												value={endDate}
												name='endDate'
												className={classes.textField}
												onChange={this.handleChange}
												margin='normal'
												InputLabelProps={{
													shrink: true
												}}
												InputProps={{
													readOnly: isRule ? true : false
												}}
											/>
										</Grid>
										<Grid item xs={4} className={classes.buttonDiv}>
											<Button
												type='submit'
												color='#35B2FF'
												variant='contained'
												className={classes.button}
												marign='normal'>
												Submit
											</Button>
										</Grid>
									</Grid>
								</form>
							)}

							<div>
								{/* Move into your own file */}
								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={open}
									onClose={() => this.handleCloseAll("open")}
									autoHideDuration={3000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={
										<span id='message-id'>
											Your Rule is invalid. Please make sure all values are filled
										</span>
									}
								/>

								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={openSuccess}
									onClose={() => this.handleCloseAll("openSuccess")}
									autoHideDuration={5000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={<span id='message-id'>You have succesfully added a Cart Rule</span>}
								/>

								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={openDate}
									onClose={() => this.handleCloseAll("openDate")}
									autoHideDuration={3000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={
										<span id='message-id'>Please enter a date greater than the current date</span>
									}
								/>

								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={openInvalid}
									onClose={() => this.handleCloseAll("openInvalid")}
									autoHideDuration={3000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={
										<span id='message-id'>
											Reject Below value must be smaller than Accept Above value
										</span>
									}
								/>

								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={openOverlap}
									onClose={() => this.handleCloseAll("openOverlap")}
									autoHideDuration={3000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={
										<span id='message-id'>
											The price range of the new rule cannot overlap with an existing rule.
										</span>
									}
								/>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={isMobile ? 12 : 6}>
						<h3
							style={{
								fontWeight: "100",
								textAlign: "center",
								color: "#929292"
							}}>
							CART ABANDONMENT
						</h3>
						<Paper className={classes.root} elevation={1}>
							{loadingCA ? (
								<Lottie
									height={200}
									width={200}
									options={{
										loop: true,
										autoplay: true,
										animationData: loader.default,
										rendererSettings: {
											preserveAspectRatio: "xMidYMid slice"
										}
									}}
								/>
							) : (
								<form onSubmit={e => this.formSubmitAbandoment(e)}>
									{from || to || acceptAbove || rejectBelow || endDate ? (
										<p
											style={{
												width: "100%",
												textAlign: "center",
												color: "#929292",
												margin: 0,
												display: "inline-block"
											}}>
											{" "}
											<Checkbox
												checked={this.state.checked}
												onChange={this.handleChange}
												name='checked'
												value='checked'
												color='primary'
											/>
											Same as Cart Rule
										</p>
									) : null}
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Tooltip
												placement='top'
												title='e.g. if a cart cost $100 and the accept above rule was set to 70 (%), then all offers above $70 will be accepted automatically'>
												<TextField
													id='fromA'
													label='From'
													className={classes.textField}
													value={fromA}
													onChange={this.handleChange}
													name='fromA'
													margin='normal'
													type='number'
													InputLabelProps={{
														shrink: true
													}}
													InputProps={{
														inputProps: {
															min: 0,
															readOnly: isRule ? true : false
														},
														startAdornment: (
															<InputAdornment position='start'>$</InputAdornment>
														)
													}}
												/>
											</Tooltip>
										</Grid>
										<Grid item xs={6}>
											<Tooltip
												placement='top'
												title='e.g. if a cart cost $100 and the accept above rule was set to 70 (%), then all offers above $70 will be accepted automatically'>
												<TextField
													id='toA'
													label='To'
													className={classes.textField}
													value={toA}
													onChange={this.handleChange}
													name='toA'
													margin='normal'
													type='number'
													InputLabelProps={{
														shrink: true
													}}
													InputProps={{
														inputProps: {
															min: 0,
															readOnly: isRule ? true : false
														},
														startAdornment: (
															<InputAdornment position='start'>$</InputAdornment>
														)
													}}
												/>
											</Tooltip>
										</Grid>

										<Grid container item xs={7} spacing={2}>
											<Grid item xs={12}>
												<Tooltip
													placement='top'
													title='e.g. if a cart cost $100 and the accept above rule was set to 70 (%), then all offers above $70 will be accepted automatically'>
													<TextField
														id='acceptAboveA'
														label='Accept All Offers Above'
														className={classes.textField}
														value={acceptAboveA}
														onChange={this.handleChange}
														name='acceptAboveA'
														margin='normal'
														type='number'
														InputLabelProps={{
															shrink: true
														}}
														InputProps={{
															className: classes.inputAccept,
															inputProps: {
																min: 0,
																max: 100,
																readOnly: isRule ? true : false
															},
															endAdornment: (
																<InputAdornment position='end'>
																	<span style={{ color: "#22c78e" }}>%</span>
																</InputAdornment>
															)
														}}
													/>
												</Tooltip>
											</Grid>
											<Grid item xs={12}>
												<Tooltip
													placement='top'
													title='e.g. if a cart cost $100 and the reject below rule was set to 30 (%), then all offers below $30 will be rejected automatically'>
													<TextField
														id='rejectBelowA'
														label='Reject All Offers Below'
														className={classes.textField}
														value={rejectBelowA}
														onChange={this.handleChange}
														name='rejectBelowA'
														margin='normal'
														type='number'
														InputLabelProps={{
															shrink: true
														}}
														InputProps={{
															className: classes.inputReject,
															inputProps: {
																min: 0,
																max: 100,
																readOnly: isRule ? true : false
															},
															endAdornment: (
																<InputAdornment position='end'>
																	<span style={{ color: "#ff4d4d" }}>%</span>
																</InputAdornment>
															)
														}}
													/>
												</Tooltip>
											</Grid>
										</Grid>
										<Grid container item xs={5}>
											<div style={{ margin: "12px 0px -32px" }}>
												<Slider
													low={rejectBelowA}
													high={acceptAboveA}
													sliderChange={this.handleSliderChangeA}
													isMobile={isMobile}
												/>
											</div>
										</Grid>
										<Grid item xs={8}>
											<TextField
												id='endDateA'
												label='End Date'
												type='date'
												value={endDateA}
												name='endDateA'
												className={classes.textField}
												onChange={this.handleChange}
												margin='normal'
												InputLabelProps={{
													shrink: true
												}}
												InputProps={{
													readOnly: isRule ? true : false
												}}
											/>
										</Grid>

										<Grid item xs={4} className={classes.buttonDiv}>
											<Button
												type='submit'
												color='#35B2FF'
												variant='contained'
												className={classes.button}
												marign='normal'>
												Submit
											</Button>
										</Grid>
									</Grid>
								</form>
							)}

							<div>
								{/* Move into your own file */}
								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={open}
									onClose={() => this.handleCloseAll("open")}
									autoHideDuration={3000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={
										<span id='message-id'>
											Your Rule is invalid. Please make sure all values are filled
										</span>
									}
								/>

								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={openSuccess}
									onClose={() => this.handleCloseAll("openSuccess")}
									autoHideDuration={5000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={<span id='message-id'>You have succesfully added a Cart Rule</span>}
								/>

								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={openDate}
									onClose={() => this.handleCloseAll("openDate")}
									autoHideDuration={3000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={
										<span id='message-id'>Please enter a date greater than the current date</span>
									}
								/>

								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={openInvalid}
									onClose={() => this.handleCloseAll("openInvalid")}
									autoHideDuration={3000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={
										<span id='message-id'>
											Reject Below value must be smaller than Accept Above value
										</span>
									}
								/>

								<Snackbar
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									open={openOverlap}
									onClose={() => this.handleCloseAll("openOverlap")}
									autoHideDuration={3000}
									ContentProps={{
										"aria-describedby": "message-id"
									}}
									message={
										<span id='message-id'>
											The price range of the new rule cannot overlap with an existing rule.
										</span>
									}
								/>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}
}

CartRules.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 700,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(CartRules));
