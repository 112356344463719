import React, { Component } from "react";
import { OfferButton, MasterButton } from "../components";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import { db, fb } from "../firebase";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import { OfferablePreview } from "../assets";
import CardMedia from "@material-ui/core/CardMedia";
import withSizes from "react-sizes";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		height: 30,
		width: 80
	},
	control: {
		padding: theme.spacing(2)
	},
	margin: {
		margin: theme.spacing(2)
	},
	button: {},
	fabStyle: {
		margin: theme.spacing(2)
	},
	heading: {
		textAlign: "center",
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular
	},
	media: {
		marginTop: "1.5em"
	},
	checked: true
});

class MakeOfferButton extends Component {
	state = {
		productsAndRules: [],
		queryString: "",
		loading: true,
		productLoading: false,
		collections: [],
		isExpanded: false,
		selectedCollection: "Select a Collection",
		selectedCollectionTitle: "",
		selectedCollectionID: "",
		isCollectionSelected: false,
		pages: "",
		storeName: "",
		name: "Select Product",
		productSelectedImage: "",
		display: true,
		activeCampaigns: [],
		futureCampaigns: [],
		masterActive: false
	};

	componentDidMount() {
		db.getUserStoreName()
			.then(snapshot => {
				db.getOfferable(snapshot).then(storeActiveCampaigns => {
					this.setState({ activeCampaigns: storeActiveCampaigns.val() }, () => {});
				});
				this.setState({ storeName: snapshot });
				return fb.getCollections(snapshot);
			})
			.then(collectionsList => {
				if (!collectionsList) {
					collectionsList = [];
					// db.getProducts()
				}
				this.setState({ loading: false, collections: collectionsList });
			})
			.catch(e => console.log("error: ", e));
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
		if (name === "selectedCollection") {
			this.selectCollection(this.state.collections[event.target.value]);
		}
	};

	filterResults = () => {
		const { queryString, productsAndRules } = this.state;
		return queryString === ""
			? productsAndRules
			: _.filter(productsAndRules, x =>
					x.productTitle.toLowerCase().includes(this.state.queryString.toLowerCase())
			  );
	};

	selectCollection(collection) {
		this.setState({
			productLoading: true,
			isExpanded: false,
			selectedCollectionTitle: collection.title,
			isCollectionSelected: true,
			selectedCollectionID: collection.id,
			display: false
		});
		this.getProductsBack(collection.id);
	}

	getProductsBack(collectionID) {
		let getCollectionsId;
		if (collectionID) {
			getCollectionsId = collectionID;
		} else {
			getCollectionsId = this.state.selectedCollectionID;
		}
		let productsAndRulesTest = [];
		this.setState({ productLoading: true });
		db.getProductsInCollectionButton(this.state.storeName, getCollectionsId)
			.then(results => {
				sessionStorage.clear();
				for (let item of results) {
					productsAndRulesTest.push(item);
					sessionStorage.setItem(item.productId, item.checked);
				}
				this.setState({
					masterActive: productsAndRulesTest.every(curr => {
						return curr.checked === true;
					})
				});
				this.setState({ productsAndRules: productsAndRulesTest });
				this.setState({ productLoading: false });
				return;
			})
			.catch(console.error);
	}

	gatheringMasterButtonValue = () => {
		this.getProductsBack();
	};

	handleChangeCollection = selectedOption => {
		this.setState({
			selectedCollection: selectedOption.value.title,
			productLoading: true,
			isExpanded: false,
			selectedCollectionTitle: selectedOption.value.title,
			isCollectionSelected: true,
			selectedCollectionID: selectedOption.value.id,
			display: false
		});
		this.setState({
			productName: "Select...",
			productId: "",
			productSelected: "Select...",
			productSelectedImage: ""
		});
		this.getProductsBack(selectedOption.value.id);
	};

	makeCollectionOptions = () => {
		const { collections } = this.state;
		let collectionOptions = [];
		collections.forEach((value, index) => {
			let product = {};
			product["label"] = value.title;
			product["value"] = value;
			collectionOptions.push(product);
		});
		return collectionOptions;
	};

	render() {
		const { classes, isMobile } = this.props;
		const { selectedCollectionTitle, isCollectionSelected, selectedCollectionID } = this.state;
		const products = this.filterResults();

		return (
			<div>
				<Grid
					container
					direction='column'
					spacing={2}
					style={{
						width: isMobile ? "auto" : "50%",
						margin: "0 auto"
					}}>
					{!this.state.loading ? (
						<div>
							<h1 style={{ textAlign: "center" }}>
								<span
									style={{
										color: "#35B2FF",
										fontWeight: 900
									}}>
									SELECT
								</span>
								<span style={{ fontWeight: 900 }}> PRODUCT(S)</span>
							</h1>
							<h3
								style={{
									fontFamily: '"Nunito Sans", sans-serif',
									fontWeight: "100",
									textAlign: "center",
									marginTop: "-1%",
									color: "rgba(0, 0, 0, 0.38)"
								}}>
								Display all collections to select from
							</h3>
						</div>
					) : null}
					{!this.state.loading ? (
						<div style={{ textAlign: "center" }}>
							<Select
								value={{
									value: this.state.selectedCollection,
									label: this.state.selectedCollection
								}}
								onChange={this.handleChangeCollection}
								options={this.makeCollectionOptions()}
								style={{
									fontSize: "1.5em",
									backgroundColor: "#f6f6f8",
									borderRadius: "0.2em"
								}}
								styles={{
									...styles,
									control: (base, state) => ({
										...base,
										backgroundColor: "#f6f6f8",
										border: "none", // default border color
										boxShadow: "none",
										borderRadius: "0.2em",
										padding: "0.3em",
										width: "100%"
									})
								}}></Select>
							{this.state.display ? (
								<CardMedia
									component='img'
									className={classes.media}
									width='auto'
									image={OfferablePreview}
									style={{
										display: "inline-block",
										width: "80%"
									}}
								/>
							) : null}
						</div>
					) : null}
					<div className={classes.root} style={{ textAlign: "center" }}>
						{isCollectionSelected ? (
							<TextField
								id='standard-full-width'
								value={this.state.queryString}
								placeholder='Search for a Product'
								onChange={this.handleChange("queryString")}
								fullWidth
								margin='normal'
								style={{
									display: "inline-block",
									textAlign: "center",
									width: "100%",
									fontSize: "1em",
									backgroundColor: "#f6f6f8",
									borderRadius: "0.2em"
								}}
								required={true}
								InputProps={{
									disableUnderline: true,
									style: {
										fontSize: "1em",
										fontFamily: "Nunito Sans",
										padding: "8px"
									}
								}}
							/>
						) : null}
					</div>
					{this.state.loading ? (
						<div style={{ display: "inline-block", height: "60vh" }}>
							<Lottie
								height={300}
								width={300}
								options={{
									loop: true,
									autoplay: true,
									animationData: loader.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice"
									}
								}}
								style={{
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%) translateX(-50%)",
									left: "50%"
								}}
							/>
						</div>
					) : (
						<Grid container justify='center' spacing={1}>
							{isCollectionSelected ? (
								<Grid
									item
									xs={12}
									style={{
										display: "block",
										marginBottom: "25px"
									}}>
									<MasterButton
										collectionName={selectedCollectionTitle}
										onSelectRule={this.gatheringMasterButtonValue}
										onDeleteRule={this.deleteMasterButtonValue}
										collectionID={selectedCollectionID}
										buttonActive={this.state.masterActive}
										updateProducts={this.updateProducts}
									/>
								</Grid>
							) : null}
						</Grid>
					)}
					{this.state.productLoading ? (
						<div style={{ display: "inline-block", height: "70vh" }}>
							<Lottie
								height={300}
								width={300}
								options={{
									loop: true,
									autoplay: true,
									animationData: loader.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice"
									}
								}}
								style={{
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%) translateX(-50%)",
									left: "50%"
								}}
							/>
						</div>
					) : products.length === 0 && isCollectionSelected === true ? (
						<h1
							style={{
								fontFamily: "sans-serif",
								fontWeight: "100",
								textAlign: "center",
								marginTop: "200px"
							}}>
							No Products Found
						</h1>
					) : (
						<Grid container justify='center' spacing={1}>
							{_.map(products, (rule, i) => (
								<Grid item key={i} style={{ width: "100%" }}>
									<Paper
										style={{
											spacing: {
												unit: 1,
												color: "primary"
											},
											textAlign: "center"
										}}>
										<OfferButton
											data={rule}
											id={products[i].productId}
											onSelectRule={this.gatheringMasterButtonValue}
											buttonActive={products[i].checked}
										/>
									</Paper>
								</Grid>
							))}
						</Grid>
					)}
				</Grid>
				<br />
				<br />
				<br />
				<br />
			</div>
		);
	}
}

MakeOfferButton.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(MakeOfferButton));
