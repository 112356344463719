import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { db } from "../firebase";
import {
	Typography,
	Snackbar,
	Grid,
	TextField,
	MenuItem
} from "@material-ui/core";
import Moment from "moment";

const timeRanges = [
	{
		value: 30,
		label: "Past 30 days"
	},
	{
		value: 14,
		label: "Past 14 days"
	},
	{
		value: 7,
		label: "Past 7 days"
	}
];
const styles = theme => ({
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		backgroundColor: "#F6F6F8",
		borderRadius: "4px"
	},

	inputDrop: {
		marginLeft: " -4px",
		padding: "0.2em 1.8em 0.2em 1em",
		fontWeight: "700",
		fontSize: "0.8em"
	},
	notchedOutline: {
		borderWidth: "1px",
		borderColor: "#f6f6f8 !important"
	}
});

class AvgOrderVal extends Component {
	state = {
		storeName: "",
		open: false,
		timeRange: 30,
		updated: false,
		totalBidPrice: 0,
		offerCount: 0
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: false,
		legendPosition: "top"
	};

	componentDidMount() {
		this.updateState(false);
	}
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
			updated: true
		});
		this.updateState(true);
	};

	updateState(notFromMount) {
		db.getUserStoreName()
			.then(snapshot => {
				this.setState({ storeName: snapshot });
				return db.getStoreBids(snapshot, "approved");
			})
			.then(snapshot => {
				this.getOfferData(snapshot, "approved");
			})
			.catch(console.error);

		if (notFromMount) {
			this.setState({ open: true });
		}
	}

	getOfferData = (snapshot, location) => {
		let totalBidPrice = 0;
		let offerCount = 0;
		snapshot.forEach(offer => {
			if (
				Moment().diff(offer.val().submittedDate, "days") <
					this.state.timeRange &&
				offer.val().order &&
				offer.val().payment
			) {
				totalBidPrice += Number(offer.val().bidPrice) || 0;
				offerCount++;
			}
		});
		this.setState({
			updated: false,
			totalBidPrice: totalBidPrice,
			offerCount: offerCount
		});
	};

	handleCloseSnackSuccess = () => {
		this.setState({ open: false });
	};

	render() {
		const { totalBidPrice, offerCount } = this.state;
		const { classes } = this.props;
		return (
			<div style={{ textAlign: "left" }}>
				<div>
					<Typography
						style={{
							color: "#000",
							fontSize: "1em",
							fontWeight: 700
						}}>
						Average Order Value
					</Typography>
				</div>
				<TextField
					id='outlined-select-timeRange'
					select
					className={classes.textField}
					value={this.state.timeRange}
					onChange={this.handleChange("timeRange")}
					style={{ marginRight: this.props.isMobile ? "4px" : null }}
					InputProps={{
						classes: {
							input: classes.inputDrop,
							notchedOutline: classes.notchedOutline
						}
					}}
					// margin='normal'
					variant='outlined'>
					{timeRanges.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br />
				<br />
				<Grid container spacing={4}>
					<Grid item xs={5} style={{ margin: "auto" }}>
						<Typography
							style={{
								textAlign: "center",
								color: "#3e3e3e",
								fontSize: "24px",
								fontWeight: "700"
							}}>
							$
							{!isNaN(
								Number(totalBidPrice / offerCount).toFixed(2)
							)
								? Number(totalBidPrice / offerCount).toFixed(2)
								: 0}
						</Typography>
					</Grid>
				</Grid>
				<br />

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={this.state.open}
					onClose={this.handleCloseSnackSuccess}
					autoHideDuration={3000}
					message={<span id='message-id'>Updated</span>}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(AvgOrderVal);
